import { Switch, Typography } from '@material-ui/core'

function RequiredToggle({ included, isReadOnly, value, name, onChange }) {
	return (
		<label style={{ cursor: included ? 'pointer' : 'default' }}>
			<Typography component="span" color={isReadOnly ? 'textSecondary' : 'textPrimary'}>
				Optional
			</Typography>
			<Switch name={name} disabled={!included || isReadOnly} checked={value} onChange={onChange} />
			<Typography component="span" color={isReadOnly ? 'textSecondary' : 'textPrimary'}>
				Required
			</Typography>
		</label>
	)
}

export default RequiredToggle
