import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import appInsightsSettings from './appInsightsConfig'
import history from '../../employer/browserHistory'

let reactPlugin = null
let ai = null

const isInProd = process.env.REACT_APP_CLOUD_ENV === 'prod'

if (isInProd) {
	reactPlugin = new ReactPlugin()
	ai = new ApplicationInsights({
		config: {
			instrumentationKey: appInsightsSettings.instrumentationKey,
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: history }
			}
		}
	})
	ai.loadAppInsights()
}

const logEvent = (name, eventData) => {
	if (ai) {
		ai.trackEvent({
			name: name,
			properties: {
				...eventData
			}
		})
	}
}

const withAppInsightsTracking = (Component, className) => {
	return isInProd ? withAITracking(reactPlugin, Component, Component.name, className) : Component
}

export default withAppInsightsTracking

export { reactPlugin, ai, logEvent }
