import Typography from '@material-ui/core/Typography'

function Footnotes() {
	return (
		<Typography variant="caption" color="textSecondary">
			Note: Any information submitted during this interview will be the sole property of
			HaystackPro, Inc. By continuing with this application you agree to allow HaystackPro, Inc. to
			use your video recording for educational and promotional purposes.
		</Typography>
	)
}

export default Footnotes
