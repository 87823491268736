import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, RadioGroup, FormGroup, FormControlLabel } from '@material-ui/core'
import { Radio, Checkbox } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
	question: { marginTop: spacing(2) },
	answer: { marginLeft: spacing(2) },
	textAnswer: { marginLeft: spacing(2), fontStyle: 'italic', whiteSpace: 'pre-wrap' }
}))

export default function CandidateApplicationAnswers({ jobApplicationQuestions, candidate }) {
	const classes = useStyles()
	return (
		<section>
			{jobApplicationQuestions.map((question, questionIndex) => (
				<div key={questionIndex} className={classes.question}>
					<Typography variant="subtitle2" color="textSecondary">
						{question.text}
					</Typography>
					{question.questionType === 'dropdown' ? (
						<RadioGroup
							row
							name={'' + questionIndex}
							value={
								candidate?.jobApplicationAnswers.find(it => it.questionId === question.id)
									.selectedChoiceIds[0] || 0
							}
							className={classes.answer}
						>
							{question.answerChoices.map(({ id, text }, index) => (
								<FormControlLabel
									key={id}
									value={id}
									control={<Radio size="small" />}
									label={<Typography variant="body2">{text}</Typography>}
								/>
							))}
						</RadioGroup>
					) : question.questionType === 'checkbox' ? (
						<FormGroup row className={classes.answer}>
							{question.answerChoices.map(({ id, text }, index) => (
								<FormControlLabel
									key={index}
									control={<Checkbox size="small" />}
									label={<Typography variant="body2">{text}</Typography>}
									checked={
										candidate?.jobApplicationAnswers
											.find(it => it.questionId === question.id)
											.selectedChoiceIds.includes(id) || false
									}
								/>
							))}
						</FormGroup>
					) : (
						<Typography variant="body2" className={classes.textAnswer} gutterBottom>
							"{candidate?.jobApplicationAnswers.find(it => it.questionId === question.id).text}"
						</Typography>
					)}
				</div>
			))}
		</section>
	)
}
