import { useCallback, useMemo, useState } from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'

import InfoIcon from '@material-ui/icons/InfoOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Search from '@material-ui/icons/Search'
import Clear from '@material-ui/icons/Clear'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ArrowUpward from '@material-ui/icons/ArrowUpward'

import MaterialTable from '@material-table/core'

import AddUserDialog, { queryKey } from './AddUserDialog'
import Error from '../../common/Error'
import CustomizedFreeAction from '../../common/CustomizedFreeAction'
import { getCompanyUsers, removeUser } from './usersService'
import PromptDialog from '../../common/PromptDialog'
import { useAuth } from '../auth/AuthProvider'

const icons = {
	Search: Search,
	ResetSearch: Clear,
	FirstPage: FirstPage,
	LastPage: LastPage,
	NextPage: ChevronRight,
	PreviousPage: ChevronLeft,
	SortArrow: ArrowUpward,
	DetailPanel: ChevronRight,
	Check: CheckCircleIcon,
	ThirdStateCheck: RadioButtonUncheckedIcon
}

const usersListOverrides = {
	Action: props => <CustomizedFreeAction {...props} />
}

const options = {
	toolbarButtonAlignment: 'left',
	paging: false
}

const useStyles = makeStyles(({ spacing }) => ({
	list: { margin: `${spacing(5)}px auto`, maxWidth: 1500 }
}))

export default function Users() {
	const { currentUser } = useAuth()
	const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
	const [removeUserPromptContext, setRemoveUserPromptContext] = useState({ open: false })

	const {
		isLoading,
		data: users,
		error
	} = useQuery({ queryKey: [queryKey], queryFn: () => getCompanyUsers() })
	const queryClient = useQueryClient()
	const { mutate } = useMutation({
		mutationFn: removeUser,
		onSuccess: (response, userId) => {
			queryClient.setQueryData([queryKey], prevUsers => prevUsers.filter(u => u.id !== userId))
		}
	})

	const userName = currentUser?.email
	const actions = useMemo(
		() => [
			{
				isFreeAction: true,
				onClick: () => setIsAddUserModalOpen(true),
				icon: AddIcon,
				tooltip: 'Add User'
			},
			user => ({
				icon: DeleteIcon,
				tooltip: 'Delete User',
				hidden: user.email === userName,
				onClick: (event, user) => setRemoveUserPromptContext({ open: true, userId: user.id })
			})
		],
		[userName]
	)

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				render: user => (
					<>
						{user.firstName !== null ? (
							`${user.firstName} ${user.lastName} `
						) : (
							<Typography color="textSecondary" display="inline">
								Invitation pending
							</Typography>
						)}
						{user.email === userName && (
							<Typography color="textSecondary" display="inline">
								(you)
							</Typography>
						)}
					</>
				)
			},
			{
				title: 'Email',
				field: 'email'
			}
		],
		[userName]
	)

	const closePrompt = useCallback(
		() => setRemoveUserPromptContext(context => ({ ...context, open: false })),
		[]
	)

	const deleteUser = useCallback(() => {
		closePrompt()
		mutate(removeUserPromptContext.userId)
	}, [removeUserPromptContext.userId, mutate, closePrompt])

	const classes = useStyles()
	return error ? (
		<Error />
	) : (
		<div className={classes.list}>
			<Typography gutterBottom variant="caption" color="textSecondary">
				<InfoIcon fontSize="inherit" style={{ verticalAlign: 'text-top' }} /> Create and manage
				users for your organization
			</Typography>
			<MaterialTable
				title="Users"
				isLoading={isLoading}
				data={users}
				actions={actions}
				components={usersListOverrides}
				columns={columns}
				icons={icons}
				options={options}
				style={{ minWidth: 600 }}
			/>
			<AddUserDialog isOpen={isAddUserModalOpen} onClose={() => setIsAddUserModalOpen(false)} />
			<PromptDialog
				open={removeUserPromptContext.open}
				title="Remove User"
				text="Are you sure you want to remove this user?"
				onProceed={deleteUser}
				onCancel={closePrompt}
			/>
		</div>
	)
}
