import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Button, Typography, MenuItem, InputAdornment } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import SaveIcon from '@material-ui/icons/Done'
import PhoneIcon from '@material-ui/icons/Phone'
import AddressIcon from '@material-ui/icons/Business'
import AddImageIcon from 'mdi-material-ui/ImagePlus'
import RemoveImageIcon from 'mdi-material-ui/ImageRemove'

import usStates from '../../common/usStates'
import { getCompanyLogoSrc } from '../../common/cloudStorageService'
import StatefulButton from '../../common/StatefullButton'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import { phoneRegex } from '../../common/commonRegex'
import InputMask from 'react-input-mask'

const companySchema = z.object({
	name: z.string().nonempty('Company name is required'),
	address: z.string().nonempty('Address is required'),
	city: z.string().nonempty('City is required'),
	state: z.string().nonempty('State is required'),
	zipCode: z.string().nonempty('ZIP code is required'),
	phone: z.string().regex(phoneRegex, 'Invalid phone number format'),
	logo: z.string().or(z.null()),
	logoFile: z.any().optional()
})

const useStyles = makeStyles(({ spacing }) => ({
	card: { width: 310, display: 'flex', flexDirection: 'column', height: '100%' },
	labelIcon: { verticalAlign: 'text-top' },
	fileInput: { display: 'none' },
	removeLogoButton: { marginLeft: spacing(1) },
	logo: { maxHeight: 100, objectFit: 'contain', marginTop: spacing(1) }
}))

export default function CompanyProfile({
	id,
	name,
	city,
	state,
	address,
	zipCode,
	phone,
	logo: initialLogo,
	save
}) {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors }
	} = useFormWithZod(companySchema, { defaultValues: { logo: initialLogo } })

	const { isLoading, isError, isSuccess, mutate } = useMutation({
		mutationFn: ({ logoFile, ...values }) => save({ id, logoFile: logoFile?.[0], ...values })
	})
	const removeLogo = () => {
		setValue('logoFile', null)
		setValue('logo', null)
	}

	const [logoFile, logo] = watch(['logoFile', 'logo'])

	const logoUrl = logoFile?.[0]
		? URL.createObjectURL(logoFile[0])
		: id && logo && getCompanyLogoSrc(id, logo)

	const classes = useStyles()
	return (
		<form onSubmit={handleSubmit(mutate)}>
			<Typography variant="h4" gutterBottom>
				Company
			</Typography>
			{logoUrl && <img src={logoUrl} className={classes.logo} alt="logo" />}
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant="caption" color="textSecondary" display="block" gutterBottom>
						Company Logo
					</Typography>
					<input
						{...register('logoFile')}
						accept="image/*"
						className={classes.fileInput}
						id="logo-upload"
						type="file"
					/>
					<label htmlFor="logo-upload">
						<Button variant="outlined" startIcon={<AddImageIcon />} component="span">
							Upload New
						</Button>
					</label>
					<Button
						variant="outlined"
						startIcon={<RemoveImageIcon />}
						className={classes.removeLogoButton}
						onClick={removeLogo}
					>
						Remove
					</Button>
				</Grid>

				<Grid item xs={12} md={8}>
					<TextField
						{...register('name')}
						label="Company Name"
						defaultValue={name}
						error={!!errors.name}
						helperText={errors.name?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<InputMask mask="999 999 9999" {...register('phone')} defaultValue={phone}>
						{inputProps => (
							<TextField
								label="Phone"
								{...inputProps}
								error={!!errors.phone}
								helperText={errors.phone?.message}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PhoneIcon />
										</InputAdornment>
									)
								}}
								fullWidth
							/>
						)}
					</InputMask>
				</Grid>
				<Grid item xs={12}>
					<TextField
						{...register('address')}
						label="Address"
						defaultValue={address}
						error={!!errors.address}
						helperText={errors.address?.message}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AddressIcon />
								</InputAdornment>
							)
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						{...register('city')}
						label="City"
						defaultValue={city}
						error={!!errors.city}
						helperText={errors.city?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={4} md={3}>
					<TextField
						{...register('state')}
						label="State"
						defaultValue={state}
						error={!!errors.state}
						helperText={errors.state?.message}
						SelectProps={{
							MenuProps: {
								PaperProps: {
									style: {
										maxHeight: 300
									}
								}
							}
						}}
						select
						fullWidth
					>
						{usStates.map(usState => (
							<MenuItem key={usState.abbreviation} value={usState.abbreviation}>
								{usState.abbreviation}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={8} md={3}>
					<TextField
						{...register('zipCode')}
						label="Zip Code"
						defaultValue={zipCode}
						error={!!errors.zipCode}
						helperText={errors.zipCode?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<StatefulButton
						startIcon={<SaveIcon />}
						type="submit"
						isLoading={isLoading}
						isSuccess={isSuccess}
					>
						Save
					</StatefulButton>
				</Grid>
				{isError && (
					<Grid item xs={12}>
						<Alert severity="error">Error saving company information</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	)
}
