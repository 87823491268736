import { isValidElement, useCallback } from 'react'
import { Grid } from '@material-ui/core'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DragHandle from '../../common/DragHandle'

function DraggableItem({ showHandle = true, children, innerRef, draggableProps, dragHandleProps }) {
	return (
		<li
			{...draggableProps}
			ref={innerRef}
			style={{ ...draggableProps.style, listStyleType: 'none' }}
		>
			<Grid container spacing={1} alignItems="center">
				{showHandle && (
					<Grid item>
						<DragHandle {...dragHandleProps} />
					</Grid>
				)}
				<Grid item style={{ flex: 1 }}>
					{children}
				</Grid>
			</Grid>
		</li>
	)
}

function SortableList({ children, onSortEnd }) {
	const onDragEnd = useCallback(
		({ source, destination }) => {
			// dropped outside the list
			if (!destination || source.index === destination.index) {
				return
			}

			onSortEnd({ oldIndex: source.index, newIndex: destination.index })
		},
		[onSortEnd]
	)
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable
				droppableId="droppable"
				renderClone={(draggable, draggableSnapshot, rubric) => (
					<DraggableItem {...draggable}>{children[rubric.source.index]}</DraggableItem>
				)}
			>
				{droppable => (
					<ul
						{...droppable.droppableProps}
						ref={droppable.innerRef}
						style={{ listStyleType: 'none', paddingLeft: 0 }}
					>
						{children.map(
							(child, index) =>
								isValidElement(child) && (
									<Draggable
										key={index}
										draggableId={`drag-${index}`}
										index={index}
										isDragDisabled={
											children.length <= 0 || (child.props && child.props.isSortable === false)
										}
									>
										{draggable => (
											<DraggableItem
												showHandle={children.length > 0 && child.props && child.props.isSortable}
												{...draggable}
											>
												{child}
											</DraggableItem>
										)}
									</Draggable>
								)
						)}
						{droppable.placeholder}
					</ul>
				)}
			</Droppable>
		</DragDropContext>
	)
}

export default SortableList
