import { useContext, useState, useCallback } from 'react'

import { Grid, MenuItem, TextField } from '@material-ui/core'

import usStates from '../common/usStates'
import countries from '../common/countries'
import JobApplicationContext from './jobApplicationContext'

export default function ContactInformation({ values = {}, validationSummary = {} }) {
	const { actions } = useContext(JobApplicationContext)

	const [touchedFields, setTouchedFields] = useState({})
	const [selectedCountry, setselectedCountry] = useState('United States')

	const updateInfo = useCallback(
		({ target }) => {
			actions.updateContactInfo({ [target.name]: target.value })
		},
		[actions]
	)

	const markAsTouched = ({ target }) => {
		setTouchedFields(fields => ({ ...fields, [target.name]: true }))
	}

	const onCountryChange = ({ target }) => {
		setselectedCountry(target.value)
		updateInfo({ target })
		actions.updateContactInfo({ state: '' })
	}

	const {
		firstName,
		lastName,
		email,
		emailConfirmation,
		phone,
		address,
		city,
		country = '',
		state = '',
		zipCode,
		referral
	} = values

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					id="first-name"
					label="First Name"
					name="firstName"
					value={firstName}
					error={touchedFields.firstName && !!validationSummary.firstName}
					helperText={touchedFields.firstName && validationSummary.firstName}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					id="last-name"
					label="Last Name"
					name="lastName"
					value={lastName}
					error={touchedFields.lastName && !!validationSummary.lastName}
					helperText={touchedFields.lastName && validationSummary.lastName}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<TextField
					variant="outlined"
					id="email"
					label="Email"
					name="email"
					type="email"
					value={email}
					error={touchedFields.email && !!validationSummary.email}
					helperText={touchedFields.email && validationSummary.email}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<TextField
					variant="outlined"
					id="confirm-email"
					label="Confirm Email"
					name="emailConfirmation"
					type="email"
					value={emailConfirmation}
					error={touchedFields.emailConfirmation && !!validationSummary.emailConfirmation}
					helperText={touchedFields.emailConfirmation && validationSummary.emailConfirmation}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<TextField
					variant="outlined"
					id="phone"
					label="Mobile Number"
					name="phone"
					value={phone}
					error={touchedFields.phone && !!validationSummary.phone}
					helperText={touchedFields.phone && validationSummary.phone}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={8}>
				<TextField
					variant="outlined"
					id="address"
					label="Address"
					name="address"
					value={address}
					error={touchedFields.address && !!validationSummary.address}
					helperText={touchedFields.address && validationSummary.address}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<TextField
					variant="outlined"
					id="city"
					label="City"
					name="city"
					value={city}
					error={touchedFields.city && !!validationSummary.city}
					helperText={touchedFields.city && validationSummary.city}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					id="address-country"
					label="Country"
					name="country"
					value={country}
					error={touchedFields.country && !!validationSummary.country}
					helperText={touchedFields.country && validationSummary.country}
					onChange={onCountryChange}
					onBlur={markAsTouched}
					fullWidth
					SelectProps={{
						MenuProps: {
							PaperProps: {
								style: {
									maxHeight: 300
								}
							}
						}
					}}
					select
				>
					{countries.map(({ name, abbreviation }) => (
						<MenuItem key={abbreviation} value={name}>
							{name}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={6} sm={3}>
				<TextField
					variant="outlined"
					id="address-state"
					label="State"
					name="state"
					value={state}
					error={touchedFields.state && !!validationSummary.state}
					helperText={
						(touchedFields.state && validationSummary.state) ||
						(selectedCountry !== 'United States' && '(Optional)')
					}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
					SelectProps={{
						MenuProps: {
							PaperProps: {
								style: {
									maxHeight: 300
								}
							}
						}
					}}
					select={selectedCountry === 'United States'} // Don't worry, this is temporal
				>
					{selectedCountry === 'United States' &&
						usStates.map(({ name, abbreviation }) => (
							<MenuItem key={abbreviation} value={abbreviation}>
								{name}
							</MenuItem>
						))}
				</TextField>
			</Grid>
			<Grid item xs={6} sm={3}>
				<TextField
					variant="outlined"
					id="zip"
					label="ZIP"
					name="zipCode"
					value={zipCode}
					error={touchedFields.zipCode && !!validationSummary.zipCode}
					helperText={touchedFields.zipCode && validationSummary.zipCode}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
			<Grid item sm={12}>
				<TextField
					variant="outlined"
					id="referral"
					label="Referral"
					name="referral"
					value={referral}
					error={touchedFields.referral && !!validationSummary.referral}
					helperText={
						(touchedFields.referral && validationSummary.referral) ||
						'(Optional) If anyone referred you, enter their name and how you know that person.'
					}
					onChange={updateInfo}
					onBlur={markAsTouched}
					fullWidth
				/>
			</Grid>
		</Grid>
	)
}
