import { useCallback, useMemo } from 'react'

import { Rating } from '@material-ui/lab'

import ApplicantsInStage from '../ApplicantsInStage'

const msPerDay = 1000 * 3600 * 24

export default function VideoInterviewStageApplicants({
	stage,
	candidates,
	isLoading,
	candidateActions,
	daysToCompleteInterview
}) {
	const getStatus = useCallback(
		candidate => {
			switch (candidate.status) {
				case 'interviewed':
				case 'interviewed_not_sure':
					return 'Completed'
				case 'invited':
					let d = candidate.invitedDateTime
					let invitedDateUtcMs = Date.UTC(
						d.getFullYear(),
						d.getMonth(),
						d.getDate(),
						d.getHours(),
						d.getMinutes(),
						d.getSeconds()
					)
					if (Date.now() - invitedDateUtcMs > daysToCompleteInterview * msPerDay) return 'Expired'
					else if (candidate.videoAnswers.length > 0) return 'Partially Completed'
					else return 'Invited'
				default:
					return 'Unknown'
			}
		},
		[daysToCompleteInterview]
	)

	const columns = useMemo(
		() => [
			{
				title: 'Date Invited',
				field: 'invitedDateTime',
				type: 'datetime',
				render: candidate =>
					candidate.invitedDateTime &&
					candidate.invitedDateTime.toLocaleString('en-US', {
						dateStyle: 'short',
						timeStyle: 'short'
					})
			},
			{
				title: 'Status',
				field: 'status',
				customSort: (candidate1, candidate2) =>
					getStatus(candidate1).localeCompare(getStatus(candidate2)),
				customFilterAndSearch: (searchTerm, candidate) =>
					getStatus(candidate).match(new RegExp(searchTerm, 'i')),
				render: getStatus
			},
			{
				title: 'Date Completed',
				field: 'interviewCompletedDateTime',
				type: 'datetime',
				render: candidate =>
					candidate.interviewCompletedDateTime &&
					candidate.interviewCompletedDateTime.toLocaleString('en-US', {
						dateStyle: 'short',
						timeStyle: 'short'
					})
			},
			{
				title: 'Avg. Video Rating',
				render: candidate => (
					<Rating
						name="averageVideoRating"
						precision={0.5}
						size="small"
						value={
							candidate.videoAnswers.reduce((sum, va) => sum + va.rating, 0) /
							candidate.videoAnswers.length
						}
						readOnly
					/>
				)
			}
		],
		[getStatus]
	)

	return (
		<ApplicantsInStage
			title="Video Interviews"
			stage={stage}
			columns={columns}
			candidateActions={candidateActions}
			candidates={candidates}
			isLoading={isLoading}
		/>
	)
}
