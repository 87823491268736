import { useCallback, useContext, useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

import CameraIcon from '@material-ui/icons/VideoCall'
import YouTubeIcon from '@material-ui/icons/YouTube'

//import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'

import { recordingStatuses } from '../../../../../../common/video-recorder/VideoRecorder'
import videoHosts from '../../../../../../common/videoHosts'
import EmployerVideoRecorder from './EmployerVideoRecorder'
import ExternalEmployerVideoSetup from './ExternalEmployerVideoSetup'
import EmployerVideosContext from './employerVideosContext'

const videoKinds = {
	haystack: 'haystack-video',
	youtube: 'youtube-video'
}

function EmployerVideoTypeSelector({ videoType, description, video }) {
	const { recordingStatus } = useContext(EmployerVideosContext)
	const [tabIndex, setTabIndex] = useState(
		video?.videoHost === videoHosts.youtube ? videoKinds.youtube : videoKinds.haystack
	)

	// Todo: probably should be moved to EmployerVideos, since once the video is published a thumbnail should be updated
	/*useEffect(() => {
		const connect = new HubConnectionBuilder()
			.withUrl('/api/hubs/videos')
			.withAutomaticReconnect()
			.build()

		setConnection(connect)
	}, [])

	useEffect(() => {
		if (connection && connection.state === HubConnectionState.Disconnected) {
			connection
				.start()
				.then(() => connection.invoke('SubscribeToGroup', companyId.toString()))
				.catch(error => console.log(error))
			connection.on('videoPublished', video => {
				// actions.updateEmployerVideo(video)
				setIsLoading(false)
			})
		}
		return () => {
			if (connection) {
				connection.off('videoPublished')
				connection.stop()
			}
		}
	}, [companyId, connection])*/

	const handleTabChange = useCallback((event, newTabIndex) => {
		if (newTabIndex === null) return

		setTabIndex(newTabIndex)
	}, [])

	const disabled =
		recordingStatus === recordingStatuses.preRecordingCountDownStarted ||
		recordingStatus === recordingStatuses.recording

	return (
		<Grid container spacing={3} justifyContent="center">
			<Grid item sm={12}>
				<Typography variant="subtitle2" color="textSecondary" align="center">
					{description}
				</Typography>
			</Grid>
			<Grid item sm={12} align="center">
				<ToggleButtonGroup size="small" value={tabIndex} exclusive onChange={handleTabChange}>
					<ToggleButton value={videoKinds.haystack} disabled={disabled}>
						<CameraIcon />
						&nbsp; My Video
					</ToggleButton>
					<ToggleButton value={videoKinds.youtube} disabled={disabled}>
						<YouTubeIcon />
						&nbsp; YouTube Link
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>

			{tabIndex === videoKinds.haystack ? (
				<Grid sm={12} item style={{ minHeight: 500 }}>
					<EmployerVideoRecorder videoType={videoType} video={video} />
				</Grid>
			) : (
				tabIndex === videoKinds.youtube && (
					<Grid sm={12} item align="center" style={{ minHeight: 500 }}>
						<ExternalEmployerVideoSetup video={video} />
					</Grid>
				)
			)}
		</Grid>
	)
}

export default EmployerVideoTypeSelector
