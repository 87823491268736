import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import EmbeddedVideoPlayer from '../../common/employer-video/EmbeddedVideoPlayer'
import PublishedVideoPlayer from '../../common/employer-video/PublishedVideoPlayer'
import { jobVideoTypes } from '../../common/outboundVideoTypes'
import videoHosts from '../../common/videoHosts'

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(2)
	},
	message: {
		padding: theme.spacing(2),
		maxWidth: 900,
		margin: 'auto'
	},
	videoContainer: {
		maxWidth: 640,
		margin: 'auto'
	}
}))

const stepMap = {
	finished: {
		title: 'Uploading Your Video',
		message:
			'The interview is finished, but we need to finish uploading your videos. Please leave this tab open.',
		color: 'textPrimary'
	},
	failed: {
		title: 'Ouch...',
		message: 'Something went wrong with your interview. Please try again later.',
		color: 'error'
	},
	done: {
		title: 'All Done!',
		message:
			'Thank you for completing the interview! We will review your answers and get back to you soon.',
		color: 'textPrimary'
	},
	invalidLink: {
		title: 'Invalid Link',
		message:
			'No interview available! If you followed the link from the email and received this message, please contact us.',
		color: 'error'
	},
	serverError: {
		title: 'Internal Error',
		message: 'There is a problem on our end. Please, try again later. Sorry for the inconvenience!',
		color: 'error'
	},
	closed: {
		title: 'Position Closed',
		message: 'This position has been closed',
		color: 'textPrimary'
	},
	expired: {
		title: 'Expired',
		message: 'The deadline to do the interview is over',
		color: 'textPrimary'
	},
	browserNotSupported: {
		title: 'Browser Not Supported',
		message:
			"You browser doesn't support video recording. Please use one of more modern browsers like Chrome or Firefox.",
		color: 'error'
	}
}
function StatusMessage({ step, videos = [] }) {
	const meetYourBossVideo = videos.find(
		video => video.videoType === jobVideoTypes.meetYourBoss.name
	)
	const workDayVideo = videos.find(video => video.videoType === jobVideoTypes.workDay.name)

	const classes = useStyles()
	return (
		<div className={classes.root}>
			<div className={classes.message}>
				<Typography variant="h4" color={stepMap[step].color} gutterBottom>
					{stepMap[step].title}
				</Typography>
				<Typography variant="h6" component="p" color={stepMap[step].color}>
					{stepMap[step].message}
				</Typography>
			</div>
			{step === 'done' &&
				(meetYourBossVideo?.displayToCandidates || workDayVideo?.displayToCandidates) && (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h5" align="center">
								In the meantime, we have prepared the following for you to better understand what
								this role will entail:
							</Typography>
						</Grid>

						{workDayVideo?.displayToCandidates && (
							<Grid item xs={12}>
								<Typography variant="h6" align="center" gutterBottom>
									"A Day in the Life" for this position
								</Typography>
								<div className={classes.videoContainer}>
									{workDayVideo.videoHost === videoHosts.youtube ? (
										<EmbeddedVideoPlayer sourceVideo={workDayVideo.srcUrl} />
									) : (
										<PublishedVideoPlayer srcUrl={workDayVideo.srcUrl} />
									)}
								</div>
							</Grid>
						)}

						{meetYourBossVideo?.displayToCandidates && (
							<Grid item xs={12}>
								<Typography variant="h6" align="center" gutterBottom>
									Meet your future boss
								</Typography>
								<div className={classes.videoContainer}>
									{meetYourBossVideo.videoHost === videoHosts.youtube ? (
										<EmbeddedVideoPlayer sourceVideo={meetYourBossVideo.srcUrl} />
									) : (
										<PublishedVideoPlayer srcUrl={meetYourBossVideo.srcUrl} />
									)}
								</div>
							</Grid>
						)}
					</Grid>
				)}
		</div>
	)
}

export default StatusMessage
