import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import DropZone from 'react-dropzone'

const useStyles = makeStyles(({ spacing, palette }) => ({
	dropZone: {
		height: '100%',
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		transition: 'background 250ms',
		outline: 'none',
		'&:hover': { background: palette.action.hover }
	},
	logo: { maxHeight: 120 - spacing(2), maxWidth: '100%' },
	buttonContainer: { position: 'absolute', top: 0, right: 0 }
}))

export default function ImageUploader({ url, onImageChange, placeholder }) {
	const classes = useStyles()

	return (
		<DropZone onDrop={(files, ...rest) => onImageChange(files[0])}>
			{({ getRootProps, getInputProps }) => (
				<div {...getRootProps()} className={classes.dropZone}>
					<input {...getInputProps()} accept="image/*" />
					{/* true is a temp value, indicating the url needs to be set */}
					{url && url !== true ? (
						<img alt="Uploaded Logo Preview" src={url} className={classes.logo} />
					) : (
						<Typography color="textSecondary">
							{placeholder || 'Drop the logo here, or click to select it'}
						</Typography>
					)}
				</div>
			)}
		</DropZone>
	)
}
