export const companyDetailsQuestions = [
	{
		name: 'organizationType',
		title: 'Organization type',
		options: [
			'Public Company',
			'Self-Employed',
			'Government Agency',
			'Non-profit',
			'Privately Held',
			'Partnership',
			'Sole Proprietorship',
			'Other'
		]
	},
	{
		name: 'industry',
		title: 'Industry',
		options: [
			'Accommodation and Food Services',
			'Administrative, Support, Waste Management and Remediation Services',
			'Agriculture, Forestry, Fishing and Hunting',
			'Arts, Entertainment, and Recreation',
			'Construction',
			'Educational Services',
			'Finance and Insurance',
			'Health Care and Social Assistance',
			'Information (includes software and media)',
			'Management of Companies and Enterprises',
			'Manufacturing',
			'Mining, Quarrying, and Oil and Gas Extraction',
			'Other Services (except Public Administration)',
			'Professional, Scientific, and Technical Services',
			'Public Administration',
			'Real Estate, Rental and Leasing',
			'Retail Trade',
			'Technology (eg. SaaS or IT services)',
			'Transportation and Warehousing',
			'Utilities',
			'Wholesale Trade'
		]
	},
	{
		name: 'companySize',
		title: 'Company size',
		options: [
			'1-10 employees',
			'11-25 employees',
			'26-50 employees',
			'51-200 employees',
			'201-500 employees',
			'501-1000 employees',
			'1000+ employees'
		]
	},
	{
		name: 'companyRole',
		title: 'Your position at the company',
		options: ['Owner', 'Executive', 'Recruiter', 'HR', 'Administrative', 'Hiring Manager', 'Other']
	},
	{
		name: 'workplaceType',
		title: 'Work place type',
		options: ['On-site', 'Hybrid', 'Remote']
	},
	{
		name: 'employeeType',
		title: 'What kind employees do you hire?',
		options: ['Full time', 'Part time', 'Contract', 'Temporary', 'Volunteer', 'Internship', 'Other']
	},
	{
		name: 'positionsType',
		title: 'Positions you typically hire for',
		options: [
			'Engineering',
			'Sales',
			'Marketing',
			'Customer Service',
			'Management',
			'Design',
			'Operations',
			'Programming',
			'Accounting',
			'Other'
		]
	},
	{
		name: 'activeJobOpenings',
		title: 'Active job openings you usually have',
		options: ['0-5', '6-10', '11-20', '21-30', '31-40', '41-50', '50+']
	},
	{
		name: 'foundUsThrough',
		title: 'How did you hear about Haystack?',
		options: [
			'Online Search',
			'Referral from a friend or colleague',
			'Social media',
			'Industry event',
			'Email marketing',
			'Online advertisement',
			'Other'
		]
	}
]
