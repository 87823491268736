import ApplicantsInStage from '../ApplicantsInStage'

const columns = [
	{
		title: 'Date Applied',
		field: 'appliedDateTime',
		type: 'datetime',
		render: applicant =>
			applicant.appliedDateTime.toLocaleString('en-US', {
				dateStyle: 'short',
				timeStyle: 'short'
			})
	},
	{
		title: 'Video Interview',
		field: 'interviewCompletedDateTime',
		type: 'datetime',
		render: applicant =>
			applicant.interviewCompletedDateTime &&
			applicant.interviewCompletedDateTime.toLocaleString('en-US', {
				dateStyle: 'short',
				timeStyle: 'short'
			})
	}
]

export default function FaceToFaceStageApplicants({
	stage,
	candidates,
	isLoading,
	candidateActions
}) {
	return (
		<ApplicantsInStage
			title="In-person Interviews"
			description="Currently, the purpose of this tab is to list candidates that are in the live interview stage of the hiring process. 
			There are no active functions on this tab. More features to come!"
			stage={stage}
			columns={columns}
			candidateActions={candidateActions}
			candidates={candidates}
			isLoading={isLoading}
		/>
	)
}
