import { useContext, useRef, useMemo, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Badge, Typography } from '@material-ui/core'

import Search from '@material-ui/icons/Search'
import Clear from '@material-ui/icons/Clear'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Refresh from '@material-ui/icons/Refresh'

import MaterialTable from '@material-table/core'

import { ApplicantDashboardContext } from './applicantDashboardStore'
import routes from '../../../routes'

const icons = {
	Search: Search,
	ResetSearch: Clear,
	FirstPage: FirstPage,
	LastPage: LastPage,
	NextPage: ChevronRight,
	PreviousPage: ChevronLeft,
	SortArrow: ArrowUpward,
	Retry: Refresh
}

const pageSize = 10

const tableOptions = {
	pageSizeOptions: [pageSize, 15, 20],
	pageSize,
	toolbarButtonAlignment: 'left',
	thirdSortClick: false
}

export default function ApplicantsInStage({
	title,
	stage,
	columns,
	candidateActions,
	candidates,
	isLoading,
	options = {},
	description = ''
}) {
	const actions = useContext(ApplicantDashboardContext)
	const tableRef = useRef()
	const { slug } = useParams()
	const history = useHistory()

	const updateSortedCandidates = useCallback(
		() => actions.setSortedCandidates(stage, tableRef.current.dataManager.sortedData),
		[stage, actions]
	)

	// we need to ensure that the order of applicants in the table is going to be the same in CandidateDetailsDialog
	useEffect(() => {
		updateSortedCandidates()
	}, [candidates, updateSortedCandidates])

	const stageColumns = useMemo(
		() => [
			{
				title: 'Candidate',
				field: 'fullName',
				render: candidate => (
					<Badge
						color="secondary"
						badgeContent="?"
						invisible={!candidate.status.includes('not_sure')}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						style={{ paddingRight: 8 }}
					>
						{candidate.fullName}
					</Badge>
				)
			},
			...columns
		],
		[columns]
	)

	const combinedOptions = useMemo(() => ({ ...tableOptions, ...options }), [options])

	const onRowClick = useCallback(
		(e, candidate) => history.push(routes.jobApplicants.getLink(slug, stage, candidate.id)),
		[history, slug, stage]
	)

	return (
		<MaterialTable
			icons={icons}
			style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, boxShadow: 'none' }}
			title={
				<>
					<Typography variant="h6">{title}</Typography>
					{description && <Typography variant="body2">{description}</Typography>}
				</>
			}
			columns={stageColumns}
			isLoading={isLoading}
			data={candidates}
			actions={candidateActions}
			tableRef={tableRef}
			options={combinedOptions}
			onRowClick={onRowClick}
			onOrderChange={updateSortedCandidates}
		/>
	)
}
