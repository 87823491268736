import { useState } from 'react'

import { Button, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useQuery } from '@tanstack/react-query'

import LargeLoader from '../../../common/LargeLoader'
import { useAuth } from '../../auth/AuthProvider'
import { getUsage } from '../profileService'
import { EditSubscriptionDetailsDialog } from './EditSubscriptionDetailsDialog'
import { CancelSubscriptionConfirmationDialog } from './CancelSubscriptionConfirmationDialog'
import SubscriptionUsage from './SubscriptionUsage'

function ActiveSubscription() {
	const [showEditSubscriptionModal, setShowEditSubscriptionModal] = useState(false)
	const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)

	const { currentUser } = useAuth()

	const {
		isLoading,
		error,
		data: subscriptionUsage
	} = useQuery({ queryKey: ['subscription-usage'], queryFn: getUsage })

	return isLoading ? (
		<LargeLoader />
	) : error ? (
		<Alert severity="error">Unable to load subscription information</Alert>
	) : (
		<>
			<SubscriptionUsage {...subscriptionUsage} />
			<Typography paragraph style={{ paddingTop: '1em' }}>
				{subscriptionUsage.isTrial
					? `Your FREE Trial is active. ${
							subscriptionUsage.lastDigits
								? `Next payment is due: ${new Date(subscriptionUsage.dueDate).toLocaleDateString()}`
								: `It expires on ${new Date(
										currentUser.trialEndDate
								  ).toLocaleDateString()}. Subscribe now to ensure uninterrupted service. You'll be charged only after the trial period expires.`
					  }`
					: `You subscription is active. Next payment is due: ${new Date(
							subscriptionUsage.dueDate
					  ).toLocaleDateString()}`}
			</Typography>

			{subscriptionUsage.lastDigits && (
				<Typography paragraph>
					<strong>Current card:</strong> **** **** **** {subscriptionUsage.lastDigits}
				</Typography>
			)}

			<Button
				color="primary"
				variant="contained"
				onClick={() => setShowEditSubscriptionModal(true)}
			>
				{subscriptionUsage.lastDigits ? 'Edit payment information' : 'Subscribe'}
			</Button>
			{!subscriptionUsage.isTrial && (
				<Button onClick={() => setShowCancelSubscriptionModal(true)}>Cancel subscription</Button>
			)}

			<EditSubscriptionDetailsDialog
				isOpen={showEditSubscriptionModal}
				onClose={() => setShowEditSubscriptionModal(false)}
			/>
			<CancelSubscriptionConfirmationDialog
				isOpen={showCancelSubscriptionModal}
				subscriptionUsage={subscriptionUsage}
				onClose={() => setShowCancelSubscriptionModal(false)}
			/>
		</>
	)
}

export default ActiveSubscription
