import { useCallback, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { DialogContent, DialogActions } from '@material-ui/core'
import { Box, Button, Slide, Divider, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import VideocamIcon from '@material-ui/icons/Videocam'

import { useMutation } from '@tanstack/react-query'

import haystackClientDb from '../../../../../../common/clientStorage'
import videoHosts from '../../../../../../common/videoHosts'
import CustomDialog from '../../../../../../common/CustomDialog'
import { recordingStatuses } from '../../../../../../common/video-recorder/VideoRecorder'
import EmployerVideoTypeSelector from './EmployerVideoTypeSelector'
import EmployerVideosContext from './employerVideosContext'

const useStyles = makeStyles(({ spacing }) => ({
	videoIcon: { verticalAlign: 'sub', paddingRight: spacing(1) }
}))

const EmployerVideoSetupDialog = ({
	jobId,
	show,
	video,
	videoType,
	title,
	description,
	actions
}) => {
	const [recordingStatus, setRecordingStatus] = useState()
	const [promptOverlay, togglePromptOverlay] = useState({ open: false })

	const {
		isLoading: isVideoPublishing,
		error: videoPublishingError,
		mutate: publishVideo,
		reset: resetPublishVideoMutation
	} = useMutation({
		mutationFn: ({ video, blob }) => actions.publishVideo(video, blob),
		onSuccess: async () => {
			await haystackClientDb.employerVideoSlicesRepo.deleteAllVideoSlices(jobId, videoType)
			setRecordingStatus()
		}
	})

	const {
		isLoading: isVideoLinkSaving,
		error: videoLinkSavingError,
		mutate: saveEmbeddedVideoUrl,
		reset: resetSaveEmbeddedVideoUrlMutation
	} = useMutation({
		mutationFn: video => actions.saveEmbeddedVideoUrl(video),
		onSuccess: savedVideo => {
			if (video) actions.updateEmployerVideo(savedVideo)
			else actions.addEmployerVideo(savedVideo)
		}
	})

	const canClose = !recordingStatus || recordingStatus === recordingStatuses.stopped

	const handleOnClose = useCallback(
		async (e, reason) => {
			if (!canClose) return false
			if (video?.isDirty) {
				let decisionCallback
				const canProceedPromise = new Promise(resolve => (decisionCallback = resolve))
				togglePromptOverlay({ open: true, decisionCallback })

				if (!(await canProceedPromise)) return false
			}

			actions.closeVideoRecordingDialog()
			return true
		},
		[actions, canClose, video]
	)

	const uploadRecordedVideo = useCallback(async () => {
		const blob = await haystackClientDb.employerVideoSlicesRepo.getFullVideoAsBlob(jobId, videoType)
		publishVideo({ video: video || { videoType }, blob })
	}, [publishVideo, jobId, video, videoType])

	// Todo: wrap the methods below in actions object,
	//  handle errors from async calls

	const updateRecordingStatus = useCallback(
		async ({ from, to }) => {
			if (video && to === recordingStatuses.preRecordingCountDownStarted) {
				let decisionCallback
				const canProceedPromise = new Promise(resolve => (decisionCallback = resolve))
				togglePromptOverlay({ open: true, decisionCallback })

				if (!(await canProceedPromise)) return false
			}

			setRecordingStatus(to)

			if (to === recordingStatuses.recording) {
				await haystackClientDb.employerVideoSlicesRepo.deleteAllVideoSlices(jobId, videoType)
			}

			if (from === recordingStatuses.recording && to === recordingStatuses.stopped) {
				const blob = await haystackClientDb.employerVideoSlicesRepo.getFullVideoAsBlob(
					jobId,
					videoType
				)
				const videoUrl = URL.createObjectURL(blob)
				const payload = {
					videoType,
					srcUrl: videoUrl,
					videoHost: videoHosts.haystack,
					isDirty: true
				}

				if (video) actions.updateEmployerVideo(payload)
				else actions.addEmployerVideo(payload)
			}

			return true
		},
		[actions, video, jobId, videoType]
	)

	const saveEmbeddedVideoLink = useCallback(
		async ({ providerVideoId }, opts) => {
			if (video && video.videoHost !== videoHosts.youtube) {
				let decisionCallback
				const canProceedPromise = new Promise(resolve => (decisionCallback = resolve))
				togglePromptOverlay({ open: true, decisionCallback })

				if (!(await canProceedPromise)) return false
			}

			saveEmbeddedVideoUrl({ videoType, providerVideoId }, opts)
			return true
		},
		[video, videoType, saveEmbeddedVideoUrl]
	)

	const classes = useStyles()
	return (
		<EmployerVideosContext.Provider
			value={{
				jobId,
				recordingStatus,
				isVideoSaving: isVideoPublishing || isVideoLinkSaving,
				updateRecordingStatus,
				saveEmbeddedVideoLink,
				uploadRecordedVideo
			}}
		>
			<CustomDialog
				open={show}
				onClose={handleOnClose}
				maxWidth="md"
				TransitionComponent={Slide}
				TransitionProps={{ direction: 'up' }}
				fullWidth
				title={title}
				titleIconComponent={<VideocamIcon className={classes.videoIcon} />}
			>
				<DialogContent style={{ paddingTop: 12, paddingBottom: 12 }}>
					<EmployerVideoTypeSelector
						videoType={videoType}
						description={description}
						video={video}
					/>
					{(videoPublishingError || videoLinkSavingError) && (
						<Alert
							severity="error"
							onClose={() => {
								resetPublishVideoMutation()
								resetSaveEmbeddedVideoUrlMutation()
							}}
						>
							{videoPublishingError && 'Error during video upload. Your video might not be saved.'}
							{videoLinkSavingError && 'Error saving the link'}
						</Alert>
					)}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button variant="outlined" disabled={!canClose} onClick={handleOnClose}>
						Close
					</Button>
				</DialogActions>
				{promptOverlay.open && (
					<Box
						sx={{
							position: 'absolute',
							inset: 0,
							background: 'rgb(255,255,255,0.9)',
							pt: 30,
							zIndex: 1
						}}
					>
						<Typography variant="h6" align="center" paragraph>
							This action will erase current video. Are you sure?
						</Typography>
						<Box sx={{ textAlign: 'center' }}>
							<Button
								color="primary"
								variant="outlined"
								onClick={() => {
									promptOverlay.decisionCallback?.(true)
									togglePromptOverlay({ open: false })
								}}
							>
								Yes, proceed and erase video
							</Button>
							&nbsp;&nbsp;
							<Button
								color="primary"
								variant="outlined"
								onClick={() => {
									promptOverlay.decisionCallback?.(false)
									togglePromptOverlay({ open: false })
								}}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				)}
			</CustomDialog>
		</EmployerVideosContext.Provider>
	)
}

export default EmployerVideoSetupDialog
