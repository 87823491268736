import React from 'react'

import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { DialogActions, Button } from '@material-ui/core'

export default function ConfirmationDialog({ open, title, description, onConfirm, onClose }) {
	return (
		<Dialog open={open}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				<DialogContentText>{description}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					variant="contained"
					onClick={() => {
						onConfirm()
						onClose()
					}}
				>
					Confirm
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	)
}
