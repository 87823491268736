import history from './browserHistory'
import routes from './routes'
import { getIdToken } from './auth/authService'

async function prepareRequest(url, options = { headers: {} }) {
	const token = await getIdToken()
	options.headers = options.headers || {}
	options.headers.Authorization = `Bearer ${token}`

	return options
}

async function makeRequest(url, options) {
	const optionsToUse = await prepareRequest(url, options)
	const response = await fetch(url, optionsToUse)
	if (response.ok) return response.json()
	else if (response.status === 401) history.push(routes.jobs.path, { sessionExpired: true })
	else if (response.status === 403) window.location.reload() // login state and registration status will be refreshed on reload to determine the state of the user
	else throw response.json ? await response.json() : response
}

export const get = async url => makeRequest(url)

export const post = async (url, payload, { headers, ...restOptions } = {}) =>
	makeRequest(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		...restOptions,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers
		}
	})

export const put = async (url, payload, { headers, ...restOptions } = {}) =>
	makeRequest(url, {
		method: 'PUT',
		body: JSON.stringify(payload),
		...restOptions,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers
		}
	})

export const patch = async (url, payload, { headers, ...restOptions } = {}) =>
	makeRequest(url, {
		method: 'PATCH',
		body: JSON.stringify(payload),
		...restOptions,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers
		}
	})

export const postFormData = async (url, formData, options = {}) =>
	makeRequest(url, {
		method: 'POST',
		body: formData
	})

export const putFormData = async (url, formData, options = {}) =>
	makeRequest(url, {
		method: 'PUT',
		body: formData
	})

export const deleteResource = async url => makeRequest(url, { method: 'DELETE' })
