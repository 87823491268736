import { useState, useCallback, useEffect, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Typography, Grid } from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views'

import { getCompanyStorageUri } from './applicantService'
import EmployerNoteEditor from './EmployerNoteEditor'
import InteractiveRating from './InteractiveRating'

const useStyles = makeStyles(({ spacing, palette }) => ({
	root: { padding: spacing(2, 0), overflow: 'hidden' },
	tabContainer: { display: 'inline-flex', width: 'auto' },
	videoContainer: { padding: spacing(1) },
	video: { width: 650, height: 350, backgroundColor: palette.primary.main },
	missingVideo: {
		width: 650,
		height: 350,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	}
}))

export default function CandidateVideoInterviewDetails({
	jobId,
	videoInterviewQuestions,
	candidate,
	isCurrentApplicant
}) {
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
	const [durationIsReady, setDurationIsReady] = useState({})
	const videoControlsRef = useRef({})
	const blobContainerUrlRef = useRef()

	const loadAnswerVideo = useCallback(
		async answerId => {
			if (!isCurrentApplicant) return
			const videoControl = videoControlsRef.current[answerId]
			if (!videoControl || videoControl.src) return
			if (!blobContainerUrlRef.current)
				blobContainerUrlRef.current = (await getCompanyStorageUri()).uri

			const url = new URL(blobContainerUrlRef.current)
			// Todo: this path building logic is duplicated on the server, figure out one place to have it
			url.pathname += `/jobs/${jobId}/candidates/${candidate.id}/answers/${answerId}/video.webm`
			videoControl.src = url.href
			const shadowVideoControl = videoControlsRef.current[answerId + '-shadow']
			shadowVideoControl.src = url.href
			shadowVideoControl.onloadedmetadata = function () {
				// hacky fix duration for videos that were recorded as unseekable webm
				if (shadowVideoControl.duration === Infinity) {
					// set it to bigger than the actual duration
					shadowVideoControl.currentTime = Number.MAX_SAFE_INTEGER
					shadowVideoControl.ontimeupdate = function () {
						this.ontimeupdate = () => {}
						// setting it to just 0 doesn't always work, hence setting to something close to 0 first :-|
						shadowVideoControl.currentTime = 0.1
						shadowVideoControl.currentTime = videoControl.currentTime
						if (!videoControl.paused) {
							shadowVideoControl.play()
						}
						setDurationIsReady(duration => ({ ...duration, [answerId]: true }))
					}
				}
			}
		},
		[jobId, candidate?.id, isCurrentApplicant]
	)

	useEffect(() => {
		const videoAnswerIds = videoInterviewQuestions.reduce((accumulator, { id }) => {
			let videoAnswer = candidate?.videoAnswers.find(v => v.videoQuestionId === id)
			if (videoAnswer) accumulator.push(videoAnswer.id)
			return accumulator
		}, [])
		loadAnswerVideo(videoAnswerIds[0])
	}, [candidate?.videoAnswers, videoInterviewQuestions, loadAnswerVideo])

	const questionSelected = useCallback(
		async (e, activeTab) => {
			// Will pause and reset the time on all other videos to avoid background playback
			for (let key in videoControlsRef.current) {
				if (!key.startsWith(e.currentTarget.attributes.answerid.value.toString())) {
					const video = videoControlsRef.current[key]

					if (video) {
						video.currentTime = 0
						video.pause()
					}
				}
			}

			setActiveQuestionIndex(activeTab)
			const answerId = e.currentTarget.attributes.answerid.value
			await loadAnswerVideo(answerId)
		},
		[loadAnswerVideo]
	)

	const classes = useStyles()
	return (
		<section className={classes.root}>
			<Grid container spacing={3} wrap="nowrap">
				<Grid item xs={9}>
					<Tabs
						orientation="vertical"
						textColor="secondary"
						value={activeQuestionIndex}
						onChange={questionSelected}
					>
						{videoInterviewQuestions.map((it, index) => {
							const videoAnswer = candidate?.videoAnswers.find(v => v.videoQuestionId === it.id)

							return (
								videoAnswer && (
									<Tab key={index} answerid={videoAnswer.id} label={`Question #${index + 1}`} />
								)
							)
						})}
					</Tabs>
				</Grid>
				<Grid item xs={10}>
					<SwipeableViews index={activeQuestionIndex} animateHeight>
						{videoInterviewQuestions.map((it, index) => {
							const videoAnswer = candidate?.videoAnswers.find(v => v.videoQuestionId === it.id)
							return (
								<div key={index} className={classes.videoContainer}>
									<Grid key={index} container spacing={1}>
										<Grid item xs={9}>
											<Typography variant="subtitle2" color="textSecondary">
												{it.text}
											</Typography>
										</Grid>
										<Grid item>
											{videoAnswer ? (
												<>
													<video
														className={classes.video}
														style={
															durationIsReady[videoAnswer.id]
																? { position: 'static' }
																: { position: 'absolute', visibility: 'hidden' }
														}
														controls
														onError={e => console.log('error event:', e.nativeEvent)}
														ref={el => (videoControlsRef.current[videoAnswer.id + '-shadow'] = el)}
													/>
													{!durationIsReady[videoAnswer.id] && (
														<video
															className={classes.video}
															controls
															onError={e => console.log('error event:', e.nativeEvent)}
															ref={el => (videoControlsRef.current[videoAnswer.id] = el)}
														/>
													)}
												</>
											) : (
												<Typography
													variant="h5"
													color="textSecondary"
													className={classes.missingVideo}
												>
													The video for this question is missing
													<Typography variant="caption" color="textSecondary">
														Usually this happens if the candidate didn't complete the interview
													</Typography>
												</Typography>
											)}
										</Grid>
										{videoAnswer && (
											<Grid item container alignItems="center">
												<Typography display="inline">Rate this answer</Typography>
												&nbsp;
												<InteractiveRating {...videoAnswer} />
											</Grid>
										)}
									</Grid>
								</div>
							)
						})}
					</SwipeableViews>
				</Grid>
			</Grid>
			<EmployerNoteEditor
				label="Interview Notes"
				candidateId={candidate?.id}
				noteType="candidateVideoInterview"
				noteText={candidate?.videoNote || ''}
			/>
		</section>
	)
}
