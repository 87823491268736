import { Box, Grid, Typography, Divider } from '@material-ui/core'

function LabelledDivider({ text, ...rest }) {
	return (
		<Grid component={Box} container alignItems="center" {...rest}>
			<Grid item component={Box} sx={{ flex: 1 }}>
				<Divider variant="middle" />
			</Grid>
			<Typography variant="caption" color="textSecondary">
				{text}
			</Typography>
			<Grid item component={Box} sx={{ flex: 1 }}>
				<Divider variant="middle" />
			</Grid>
		</Grid>
	)
}

export default LabelledDivider
