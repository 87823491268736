import { useState, useCallback, useEffect, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import AddFromListIcon from '@material-ui/icons/PlaylistAdd'

import ExampleQuestionPicker from '../../../../../common/ExampleQuestionPicker'
import SortableList from '../../../../employer-common/SortableList'
import { saveQuestionsOrder } from '../interviewSetupService'
import exampleQuestions from './example-video-questions'
import QuestionSetup, { defaultTimings } from './QuestionSetup'

const useStyles = makeStyles(({ spacing }) => ({
	root: { padding: spacing(1) },
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: spacing(2)
	}
}))

export default function InterviewQuestions({
	jobId,
	questions = [],
	validationSummary = {},
	dispatch,
	hasInterviews = false,
	updateHeight = () => {}
}) {
	const [exampleQuestionsDialogOpen, toggleExampleQuestionsDialog] = useState(false)
	const actions = useMemo(
		() => [
			{
				label: 'Create New',
				Icon: AddIcon,
				onClick: () => {
					dispatch({ type: 'addQuestion' })
				}
			},
			{
				label: 'Choose from Examples',
				Icon: AddFromListIcon,
				onClick: () => {
					toggleExampleQuestionsDialog(true)
				}
			}
		],
		[dispatch]
	)

	useEffect(updateHeight, [updateHeight, questions, validationSummary])

	const updateQuestion = useCallback(
		(id, changeSet) => dispatch({ type: 'updateQuestion', id, changeSet }),
		[dispatch]
	)

	const deleteQuestion = useCallback(id => dispatch({ type: 'deleteQuestion', id }), [dispatch])

	const updateQuestionOrder = useCallback(
		async ({ oldIndex, newIndex }) => {
			dispatch({ type: 'updateQuestionOrder', oldIndex, newIndex })
			await saveQuestionsOrder(jobId, oldIndex, newIndex)
		},
		[dispatch, jobId]
	)

	const handleQuestionSelected = useCallback(
		question => {
			question.order = questions.length === 0 ? 0 : questions[questions.length - 1].order + 1
			question.readSeconds = defaultTimings.read
			question.answerSeconds = defaultTimings.answer
			dispatch({
				type: 'addQuestion',
				question: { ...question, clientId: Symbol(), isExampleQuestion: true }
			})
		},
		[dispatch, questions]
	)

	const classes = useStyles()
	const anyQuestionIsInEditMode = questions.some(it => !it.id)
	return (
		<div className={classes.root}>
			<Typography variant="caption" color="textSecondary" align="center" display="block">
				Add up to 5 questions
			</Typography>
			<SortableList onSortEnd={updateQuestionOrder}>
				{questions.map((question, index) => (
					<QuestionSetup
						key={question.id || '__new__'}
						jobId={jobId}
						{...question}
						enabled={!hasInterviews && !validationSummary.isReadOnly}
						isSortable={!hasInterviews && !validationSummary.isReadOnly}
						deleteQuestion={deleteQuestion}
						updateQuestion={updateQuestion}
						onTextChange={updateHeight}
					/>
				))}
			</SortableList>
			{!anyQuestionIsInEditMode && !hasInterviews && (
				<div className={classes.buttonContainer}>
					{actions.map(({ label, Icon, onClick }, index) => (
						<Button
							key={index}
							startIcon={<Icon />}
							disabled={validationSummary.isReadOnly || validationSummary.maxQuestionCountReached}
							color="secondary"
							onClick={onClick}
						>
							{label}
						</Button>
					))}
				</div>
			)}
			{hasInterviews && (
				<Typography variant="caption" align="center" component="p">
					You can't add or edit questions because candidates have already completed interviews for
					this job.
				</Typography>
			)}

			{validationSummary.explanation && (
				<Typography variant="caption" align="center" component="p">
					{validationSummary.explanation}
				</Typography>
			)}

			<ExampleQuestionPicker
				open={exampleQuestionsDialogOpen}
				closeDialog={() => toggleExampleQuestionsDialog(false)}
				onQuestionSelect={handleQuestionSelected}
				exampleQuestions={exampleQuestions}
			/>
		</div>
	)
}
