import { useCallback, useRef, useState } from 'react'

import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Grow, Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CancelIcon from '@material-ui/icons/Block'
import ResetDefaultIcon from '@material-ui/icons/Replay'
import SaveIcon from '@material-ui/icons/Done'

import SimpleMDEReact from 'react-simplemde-editor'
import 'easymde/dist/easymde.min.css'

import operationStates from '../../../../../common/operationStates'
import operations from './interviewInvitationAsyncOperations'

const mdeEditorOptions = {
	hideIcons: ['side-by-side', 'fullscreen', 'preview'],
	status: false
}

const useStyles = makeStyles(({ spacing }) => ({
	editorPlaceholder: {
		minWidth: '600px',
		minHeight: '375px'
	}
}))

function InterviewInvitationEditSectionDialog({
	open,
	value,
	sectionId,
	requestProgress,
	onCancel,
	onReset,
	onSave
}) {
	const { operation, state, message } = requestProgress
	const editorRef = useRef()
	const [dialogAppeared, setDialogAppeared] = useState(false)
	const classes = useStyles()

	const onSaveClick = useCallback(() => {
		const valueToSave = editorRef.current.codemirror.getValue()

		const wrapper = editorRef.current.codemirror.getWrapperElement()
		if (valueToSave.trim().length === 0) {
			wrapper.classList.add('invalid')
			return
		} else {
			wrapper.classList.remove('invalid')
		}

		onSave(sectionId, valueToSave)
	}, [sectionId, onSave])

	const onResetClick = useCallback(() => {
		onReset(sectionId)
	}, [sectionId, onReset])

	const dialogEnterHandler = useCallback(() => {
		setDialogAppeared(true)
	}, [setDialogAppeared])

	const dialogExitHandler = useCallback(() => {
		setDialogAppeared(false)
	}, [setDialogAppeared])

	return (
		<Dialog
			open={open}
			TransitionComponent={Grow}
			TransitionProps={{ onEntered: dialogEnterHandler, onExited: dialogExitHandler }}
			transitionDuration={200}
			onClose={onCancel}
		>
			{operation === operations.loadConfig && state === operationStates.error ? (
				<DialogContent>
					<Typography color="error" style={{ margin: '32px 0' }}>
						{message}
					</Typography>
				</DialogContent>
			) : (
				<>
					{dialogAppeared ? (
						<SimpleMDEReact
							getMdeInstance={instance => (editorRef.current = instance)}
							options={mdeEditorOptions}
							value={value}
						/>
					) : (
						<div className={classes.editorPlaceholder}></div>
					)}
					{state === operationStates.error && (
						<Typography variant="caption" color="error" style={{ margin: '4px 16px 0' }}>
							{message}
						</Typography>
					)}
				</>
			)}
			<DialogActions>
				<Button
					startIcon={<CancelIcon />}
					disabled={state === operationStates.inProgress}
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					startIcon={
						operation === operations.loadDefaultConfig && state === operationStates.inProgress ? (
							<CircularProgress size={17} />
						) : (
							<ResetDefaultIcon />
						)
					}
					disabled={
						state === operationStates.inProgress ||
						(operation === operations.loadConfig && state === operationStates.error)
					}
					onClick={onResetClick}
				>
					Reset to Default
				</Button>
				<Button
					variant="contained"
					startIcon={
						operation === operations.saveConfig && state === operationStates.inProgress ? (
							<CircularProgress size={17} />
						) : (
							<SaveIcon />
						)
					}
					color="secondary"
					disabled={
						state === operationStates.inProgress ||
						(operation === operations.loadConfig && state === operationStates.error)
					}
					onClick={onSaveClick}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default InterviewInvitationEditSectionDialog
