import { createTheme } from '@material-ui/core/styles'
import './assets/haystack-font.css'

const theme = createTheme({
	palette: {
		primary: {
			main: '#6868AC'
		},
		secondary: {
			main: '#E8AD47'
		}
	},
	typography: {
		fontFamily: 'ProximaNova'
	}
})

export default theme
