import { useEffect, useRef } from 'react'

import { Box } from '@material-ui/core'

export default function CameraPicker({ proposedDeviceId, enableDevice = true, sx, ...rest }) {
	const videoElRef = useRef(null)

	useEffect(() => {
		const videoEl = videoElRef.current
		if (enableDevice) {
			if (proposedDeviceId) {
				navigator.mediaDevices
					.getUserMedia({
						video: {
							deviceId: proposedDeviceId,
							width: { ideal: 270 }
						}
					})
					.then(stream => {
						console.log(stream)
						videoElRef.current.srcObject = stream
					})
					.catch((...args) => console.log(args))
			}
		} else {
			videoEl?.srcObject?.getTracks().forEach(track => track.stop())
			videoEl && (videoEl.srcObject = null)
		}

		return () => {
			videoEl?.srcObject?.getTracks().forEach(track => track.stop())
			videoEl && (videoEl.srcObject = null)
		}
	}, [enableDevice, proposedDeviceId])

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'middle',
				transform: 'rotateY(180deg)',
				height: 200,
				backgroundColor: 'black',
				...sx
			}}
			{...rest}
		>
			<video
				id="camera-preview"
				ref={videoElRef}
				muted
				autoPlay
				playsInline
				style={{ width: 270, height: '100%' }}
			/>
		</Box>
	)
}
