import { useEffect } from 'react'

import { Box, Typography, TextField, Grid } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import InputMask from 'react-input-mask'
import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import StatefulButton from '../../common/StatefullButton'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import { phoneRegex } from '../../common/commonRegex'
import { useAuth } from '../auth/AuthProvider'
import { createEmployer } from './registrationService'

const validationSchema = z.object({
	firstName: z
		.string()
		.nonempty('First name is required. We will only use it to properly address you.'),
	lastName: z
		.string()
		.nonempty('Last name is required. We will only use it to properly address you.'),
	phone: z.string().regex(phoneRegex, 'Phone number is invalid').or(z.literal(''))
})

export default function UserRegistration({ isActiveStep, onContentChange }) {
	const { currentUser, updateProfile, refreshUserStatus } = useAuth()

	const { mutate, isLoading, error } = useMutation({
		mutationFn: values =>
			Promise.all([updateProfile(values), createEmployer({ ...values, email: currentUser.email })]),
		onSuccess: () => refreshUserStatus(),
		onError: console.log
	})

	const [firstName, ...lastNameParts] = currentUser?.displayName?.split(' ') || ['', '']

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useFormWithZod(validationSchema, {
		defaultValues: { firstName: firstName, lastName: lastNameParts.join(' '), phone: '' }
	})

	useEffect(() => {
		onContentChange()
	}, [error, errors, onContentChange])

	return (
		<Box component="form" onSubmit={handleSubmit(mutate)}>
			<Grid container spacing={2} direction="column" alignItems="center">
				{error && (
					<Alert severity="error">
						<AlertTitle>Oh, no!</AlertTitle>We encountered an error updating your account.
					</Alert>
				)}
				<Grid item>
					<Typography>Let's get to know each other! We are Haystack and you?</Typography>
				</Grid>
				<Grid item>
					<TextField
						label="First Name"
						variant="outlined"
						{...register('firstName')}
						error={!!errors.firstName}
						helperText={errors.firstName?.message}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item>
					<TextField
						label="Last Name"
						variant="outlined"
						{...register('lastName')}
						error={!!errors.lastName}
						helperText={errors.lastName?.message}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item>
					<InputMask mask="999 999 9999" {...register('phone')}>
						{inputProps => (
							<TextField
								label="Phone (optional)"
								variant="outlined"
								{...inputProps}
								error={!!errors.phone}
								helperText={errors.phone?.message}
								style={{ width: 300 }}
							/>
						)}
					</InputMask>
				</Grid>
				<Grid item style={{ alignSelf: 'center' }}>
					<StatefulButton type="submit" color="primary" variant="contained" isLoading={isLoading}>
						Next
					</StatefulButton>
				</Grid>
			</Grid>
		</Box>
	)
}
