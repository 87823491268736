import { useContext } from 'react'
import { Typography } from '@material-ui/core'

import EmployerVideos from '../interview-setup/video-introduction/EmployerVideos'
import { companyVideoTypes, jobVideoTypes } from '../../../../common/outboundVideoTypes'
import JobSetupContext from './jobSetupContext'

const CompanyVideos = ({ companyId, employerVideos }) => {
	const actions = useContext(JobSetupContext)

	return (
		<EmployerVideos
			companyId={companyId}
			employerVideos={employerVideos}
			videoTypes={companyVideoTypes}
			description={
				<>
					<Typography paragraph>
						Employer introduction videos humanize employers and improve candidate engagement. Make
						your job ad come alive with a face, voice, and personality. This is your opportunity to
						showcase the attributes that make your company the ideal place for candidates to work
						at.
					</Typography>
					<Typography paragraph>
						These two videos below <strong>{companyVideoTypes.companyInfo.title}</strong> and{' '}
						<strong>{companyVideoTypes.workWithUs.title}</strong> will be shown when the candidates
						first apply.
					</Typography>
					<Typography>
						(There are three more employer video options when you set up your video interview. These
						will only be shown to candidates who are invited to complete the interview. They are:{' '}
						<strong>{jobVideoTypes.jobPosition.title}</strong>,{' '}
						<strong>{jobVideoTypes.workDay.title}</strong>, and{' '}
						<strong>{jobVideoTypes.meetYourBoss.title}</strong>.)
					</Typography>
				</>
			}
			onVideoAdd={actions.addEmployerVideo}
			onVideoChange={actions.updateEmployerVideo}
		/>
	)
}

export default CompanyVideos
