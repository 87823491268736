import { createContext } from 'react'
import { enqueueSnackbar } from 'notistack'

import { stageNames } from './stageConstants'
import routes from '../../../routes'
import history from '../../../browserHistory'

import {
	advanceCandidate,
	reinviteApplicantToInterview,
	rejectCandidate,
	saveNotes,
	saveRating,
	sendRejectionEmail,
	toggleCandidateAcknowledgement,
	undoStageTransition
} from './applicantService'

export const ApplicantDashboardContext = createContext()

const getStageName = status => {
	switch (status) {
		case 'applied':
		case 'applied_not_sure':
			return stageNames.applied
		case 'invited':
		case 'interviewed':
		case 'interviewed_not_sure':
			return stageNames.video
		case 'face':
		case 'face_scheduled': // Deprecated: leaving here for now for backwards compatibility
		case 'face_not_sure':
			return stageNames.face
		case 'hired':
			return stageNames.hired
		case 'rejected':
			return stageNames.rejected
		default:
			return
	}
}

export const statusesToMarkAsNew = ['applied', 'interviewed', 'face']

export const getInitialState = ({ candidates, slug }) => {
	const state = {
		jobSlug: slug,
		videoInterviewNotSetupWarningDialogContext: { open: false },
		rejectionEmailDialogContext: { open: false },
		confirmationDialogContext: { open: false }
	}

	Object.values(stageNames).reduce((state, stageName) => {
		const stageCandidates = candidates.filter(c => getStageName(c.status) === stageName)
		state[stageName] = {
			candidates: stageCandidates,
			sortedCandidates: stageCandidates
		}
		return state
	}, state)

	return state
}

const toggleLoadingIndicator = (state, stageName, isLoading) => ({
	...state,
	[stageName]: { ...state[stageName], isLoading }
})

const getCandidate = (state, candidateId) => {
	for (let key in stageNames) {
		const candidate = state[stageNames[key]].candidates.find(c => c.id === candidateId)
		if (candidate) return candidate
	}
}

const updateCandidate = (state, candidate, payload) => {
	const currentStage = getStageName(candidate.status)
	const newStage = payload.status ? getStageName(payload.status) : currentStage
	if (newStage === currentStage) {
		return {
			...state,
			[currentStage]: {
				...state[currentStage],
				candidates: state[currentStage].candidates.map(c =>
					c.id === candidate.id ? { ...c, ...payload } : c
				)
			}
		}
	} else {
		let candidateToMove
		let remainingCandidatesInOldStage = []
		let currentCandidateIndex
		state[currentStage].candidates.forEach((c, index) => {
			if (c.id === candidate.id) {
				currentCandidateIndex = index
				candidateToMove = { ...c, ...payload }
				// cleaning up after material-table pollutes the entity with tableData
				delete candidateToMove.tableData
			} else {
				remainingCandidatesInOldStage.push(c)
			}
		})

		const newState = {
			...state,
			[currentStage]: {
				...state[currentStage],
				candidates: remainingCandidatesInOldStage
			}
		}

		const isDialogOpen = history.location.pathname.includes(
			routes.jobApplicants.getLink(state.jobSlug, currentStage, candidate.id)
		)
		if (isDialogOpen) {
			// if no candidates left in the stage, close the dialog
			if (remainingCandidatesInOldStage.length === 0) {
				history.replace(routes.jobApplicants.getLink(state.jobSlug, currentStage))
			} else {
				let newIndex = currentCandidateIndex
				if (currentCandidateIndex >= remainingCandidatesInOldStage.length) {
					// if the last candidate in the array of candidates is moved to another stage, we need to show the previous candidate in the dialog
					newIndex = currentCandidateIndex - 1
				}

				const candidateId = remainingCandidatesInOldStage[newIndex].id
				const routeParts = history.location.pathname.split('/')
				routeParts[5] = candidateId

				history.replace(routeParts.join('/'))
			}
		}

		if (newStage) {
			newState[newStage] = {
				...state[newStage],
				candidates: [...state[newStage].candidates, candidateToMove]
			}
		}

		return newState
	}
}

export const createActions = (dispatch, getState) => ({
	setSortedCandidates: (stageName, sortedCandidates) =>
		dispatch(state => ({ ...state, [stageName]: { ...state[stageName], sortedCandidates } })),
	advanceCandidate: async candidate => {
		const stageName = getStageName(candidate.status)
		dispatch(state => toggleLoadingIndicator(state, stageName, true))

		let status, invitedDateTime, previousStatus
		try {
			;({ status, invitedDateTime, previousStatus } = await advanceCandidate(candidate.id))
		} catch (e) {
			console.log(e)
			enqueueSnackbar(`Error advancing ${candidate.fullName}`, {
				variant: 'error'
			})
			dispatch(state => toggleLoadingIndicator(state, stageName, false))
			return
		}

		enqueueSnackbar(`${candidate.fullName} successfully advanced to the next stage`, {
			variant: 'success'
		})

		dispatch(state => {
			const updatedState = updateCandidate(state, candidate, {
				status,
				invitedDateTime,
				previousStatus
			})
			return toggleLoadingIndicator(updatedState, stageName, false)
		})
	},
	toggleCandidateAcknowledgement: async candidate => {
		const stageName = getStageName(candidate.status)
		dispatch(state => toggleLoadingIndicator(state, stageName, true))
		// Todo: try/catch
		const { status, previousStatus } = await toggleCandidateAcknowledgement(candidate.id)

		dispatch(state => {
			const updatedState = updateCandidate(state, candidate, { status, previousStatus })
			return toggleLoadingIndicator(updatedState, stageName, false)
		})
	},
	rejectCandidate: async candidate => {
		const stageName = getStageName(candidate.status)
		dispatch(state => toggleLoadingIndicator(state, stageName, true))

		let status, previousStatus
		try {
			;({ status, previousStatus } = await rejectCandidate(candidate.id))
		} catch (e) {
			console.log(e)
			enqueueSnackbar(`Error rejecting ${candidate.fullName}`, { variant: 'error' })
			dispatch(state => toggleLoadingIndicator(state, stageName, false))
			return
		}

		enqueueSnackbar(`${candidate.fullName} moved to Rejected tab`, { variant: 'success' })

		dispatch(state => {
			const updatedState = updateCandidate(state, candidate, { status, previousStatus })
			return toggleLoadingIndicator(updatedState, stageName, false)
		})
	},
	sendRejectionEmail: async (candidateIds, subject, body) => {
		const stageName = stageNames.rejected
		dispatch(state => toggleLoadingIndicator(state, stageName, true))

		// Todo: try/catch
		const updatedCandidates = await sendRejectionEmail(candidateIds, subject, body)

		dispatch(state => {
			let newState = state
			for (let i = 0; i < candidateIds.length; i++) {
				const id = candidateIds[i]
				const { status, previousStatus } = updatedCandidates.find(uc => uc.id === id)
				const candidate = getCandidate(state, id)
				newState = updateCandidate(newState, candidate, { status, previousStatus })
			}
			return toggleLoadingIndicator(newState, stageName, false)
		})
	},
	undoStageTransition: async candidates => {
		// for now all candidates are always from the same stage
		const stageName = getStageName(candidates[0].status)
		dispatch(state => toggleLoadingIndicator(state, stageName, true))

		// Todo: try/catch
		const updatedCandidates = await undoStageTransition(candidates.map(c => c.id))

		dispatch(state => {
			let newState = state
			for (let i = 0; i < candidates.length; i++) {
				const { status, previousStatus } = updatedCandidates.find(uc => uc.id === candidates[i].id)
				newState = updateCandidate(newState, candidates[i], { status, previousStatus })
			}
			return toggleLoadingIndicator(newState, stageName, false)
		})
	},
	updateNotes: async (candidateId, noteType, noteText) => {
		// Todo: try/catch
		const { note, videoNote } = await saveNotes(candidateId, noteType, noteText)

		dispatch(state => {
			const candidate = getCandidate(state, candidateId)
			return updateCandidate(state, candidate, { note, videoNote })
		})
	},
	updateRating: async (answerId, rating) => {
		const savePromise = saveRating(answerId, rating)
		dispatch(state => {
			let stageName
			let candidate
			for (let key in stageNames) {
				candidate = state[stageNames[key]].candidates.find(c =>
					c.videoAnswers.some(va => va.id === answerId)
				)
				if (candidate) {
					stageName = stageNames[key]
					break
				}
			}
			return {
				...state,
				[stageName]: {
					...state[stageName],
					candidates: state[stageName].candidates.map(c =>
						c === candidate
							? {
									...c,
									videoAnswers: c.videoAnswers.map(va =>
										va.id === answerId ? { ...va, rating } : va
									)
							  }
							: c
					)
				}
			}
		})
		try {
			await savePromise
		} catch (e) {
			console.log(e)
			// Todo: show error, roll rating back
			throw e
		}
	},
	reInviteCandidateToInterview: async candidate => {
		const stageName = getStageName(candidate.status)
		dispatch(state => toggleLoadingIndicator(state, stageName, true))
		// Todo: try/catch
		const { status, invitedDateTime, previousStatus } = await reinviteApplicantToInterview(
			candidate.id
		)
		dispatch(state => {
			const updatedState = updateCandidate(state, candidate, {
				status,
				invitedDateTime,
				previousStatus
			})
			return toggleLoadingIndicator(updatedState, stageName, false)
		})
	},
	// Dialogs show/hide
	showVideoInterviewNotSetupWarningDialog: candidate =>
		dispatch(state => ({
			...state,
			videoInterviewNotSetupWarningDialogContext: { open: true, candidate }
		})),
	hideVideoInterviewNotSetupWarningDialog: () =>
		dispatch(state => ({
			...state,
			videoInterviewNotSetupWarningDialogContext: {
				...state.videoInterviewNotSetupWarningDialogContext,
				open: false
			}
		})),
	showRejectionEmailDialog: candidateIds =>
		dispatch(state => ({
			...state,
			rejectionEmailDialogContext: { open: true, candidateIds }
		})),
	hideRejectionEmailDialog: () =>
		dispatch(state => ({
			...state,
			rejectionEmailDialogContext: { ...state.rejectionEmailDialogContext, open: false }
		})),
	showConfirmationDialog: dialogContext =>
		dispatch(state => ({ ...state, confirmationDialogContext: { ...dialogContext, open: true } })),
	hideConfirmationDialog: () =>
		dispatch(state => ({
			...state,
			confirmationDialogContext: { ...state.confirmationDialogContext, open: false }
		}))
})

export const reduce = (state, action) => action(state)
