import { post, put, deleteResource } from '../../../restService'

export const createQuestion = async (jobId, videoQuestion) =>
	await post(`/api/jobs/${jobId}/video-questions`, videoQuestion)

export const updateQuestion = async videoQuestion =>
	await put(`/api/video-questions/${videoQuestion.id}`, videoQuestion)

export const saveQuestionsOrder = async (jobId, oldIndex, newIndex) =>
	await put(`/api/jobs/${jobId}/video-questions/reorder`, { oldIndex, newIndex })

export const deleteQuestion = async id => await deleteResource(`/api/video-questions/${id}`)
