import ObservableQueue from '../../common/ObservableQueue'
import interviewService from '../interviewService'

const maxRetries = 2

function invokeCallbacks(callbacks, item) {
	for (let i = 0; i < callbacks.length; i++) {
		callbacks[i](item)
	}
}

class VideoInterviewUploadService {
	constructor(applicantToken) {
		this._callbacks = { onError: [], onUploadProgress: [], onUploadFinished: [] }
		this._applicantToken = applicantToken
		this._onEnqueue = this._onEnqueue.bind(this)

		const queue = new ObservableQueue()
		queue.onEnqueue(this._onEnqueue)

		this._queue = queue
	}

	async _onEnqueue() {
		// to properly keep track of progress, the total number of items to upload since subscription time should be updated each time a new item is added to the queue
		this._callbacks.onUploadProgress.forEach(callback => callback._totalItemsToUpload++)
		if (this._uploadInProgress) return

		// resetting batch counter once we enter the batch upload loop
		// TODO: upload multiple slices with one call
		this._callbacks.onUploadProgress.forEach(callback => {
			callback._totalItemsToUpload = 1
		})

		await this._upload()

		invokeCallbacks(this._callbacks.onUploadFinished)
	}

	dispose() {
		this._queue.offEnqueue(this._onEnqueue)
		this._callbacks = { onError: [], onUploadProgress: [], onUploadFinished: [] }
	}

	uploadSlice(answerId, sliceIndex, blob, isFinal) {
		this._queue.enqueue({ answerId, sliceIndex, blob, isFinal })
	}

	onError(callback) {
		this._callbacks.onError.push(callback)
	}

	offError(callback) {
		this._callbacks.onError = this._callbacks.onError.filter(c => c !== callback)
	}

	async _upload() {
		this._uploadInProgress = true
		while (!this._queue.isEmpty()) {
			let retryCount = 0
			while (retryCount <= maxRetries) {
				try {
					await interviewService.uploadTimeSlice(this._applicantToken, this._queue.peek(0))
				} catch (e) {
					// TODO: should probably branch logic slightly based on type of the error, e.g. if connectivity issue then wait, if server times out just retry, etc.
					if (retryCount >= maxRetries) {
						this._queue.offEnqueue(this._upload)
						this._uploadInProgress = false
						invokeCallbacks(this._callbacks.onError, this._queue.peek(0))
						invokeCallbacks(this._callbacks.onUploadFinished)
						console.log(e)
						throw e
					} else {
						// wait
						await new Promise(resolve => setTimeout(resolve, 3000))
						retryCount++
						continue
					}
				}
				this._queue.dequeue()

				this._callbacks.onUploadProgress.forEach(callback => {
					const currentProgress = Math.round(
						((callback._totalItemsToUpload - this._queue.count()) * 100) /
							callback._totalItemsToUpload
					)

					callback(currentProgress)
				})

				// if the awaiter didn't throw, the operation is considered a success, and no more retries needed
				break
			}
		}
		this._uploadInProgress = false
	}

	isUploading() {
		return this._uploadInProgress
	}

	onUploadProgress(callback) {
		// to track progress for each subscriber individually, need to know how many items were in the queue at subscription time
		callback._totalItemsToUpload = this._queue.count()
		this._callbacks.onUploadProgress.push(callback)
	}

	onUploadFinished(callback) {
		this._callbacks.onUploadFinished.push(callback)
	}
}

export default VideoInterviewUploadService
