import { useMemo } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { Box, Grid, Typography, TextField, Link, useMediaQuery } from '@material-ui/core'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import StatefulButton from '../../common/StatefullButton'
import RoundedCornersTriangle from '../../common/RoundedCornersTriangle'
import LabelledDivider from '../../common/LabelledDivider'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import routes from '../routes'
import OAuthSignInOptions from './OAuthSignInOptions'
import { useAuth } from './AuthProvider'

const signupSchema = z
	.object({
		email: z.string().email('Invalid email address').nonempty('Email is required'),
		password: z
			.string()
			.min(6, 'Password must be at least 6 characters')
			.nonempty('Password is required'),
		confirmPassword: z.string().nonempty('Password confirmation is required')
	})
	.refine(({ password, confirmPassword }) => password === confirmPassword, {
		message: 'Passwords must match',
		path: ['confirmPassword']
	})

export default function Signup() {
	const { signUp, sendEmailVerification } = useAuth()

	const { search } = useLocation()
	const queryParams = useMemo(() => new URLSearchParams(search), [search])
	const email = queryParams.get('email')

	const { mutate, isLoading, error } = useMutation({
		mutationFn: async values => {
			await signUp(values.email, values.password)
			await sendEmailVerification()
		}
	})

	const {
		formState: { errors },
		register,
		handleSubmit
	} = useFormWithZod(signupSchema, {
		defaultValues: { email: email || '', password: '', confirmPassword: '' }
	})

	const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'))

	return (
		<Box
			component="section"
			sx={{ px: isXs ? 4 : 10, pt: 10, maxWidth: 1300, mx: 'auto' }}
			pt={{ xs: 1, sm: 4, md: 10 }}
		>
			<Grid container spacing={isXs ? 2 : 6} alignItems="center">
				<Grid item container md={7} alignItems="center">
					<Grid item style={{ flex: 1 }}>
						<Box display={{ xs: 'none', md: 'block' }}>
							<Typography variant="h2" gutterBottom>
								Try Free for 14 Days. No credit card required. No restrictions!
							</Typography>
						</Box>
						<Box display={{ xs: 'block', md: 'none' }}>
							<Typography variant="h5" component="p">
								Try Free for 14 Days. No credit card required.
							</Typography>
						</Box>
					</Grid>
					<Grid item>
						<Box display={{ xs: 'none', md: 'block' }}>
							<RoundedCornersTriangle innerText="record. connect." />
						</Box>
					</Grid>
				</Grid>
				<Grid item md={5}>
					<Typography component="h3" variant={isXs ? 'h4' : 'h3'}>
						Create Account
					</Typography>
					<Typography
						component="h4"
						variant={isXs ? 'subtitle2' : 'h5'}
						color="textSecondary"
						paragraph
					>
						Use Haystack to make better hires quickly
					</Typography>
					<OAuthSignInOptions email={email} />
					<LabelledDivider sx={{ pt: 2 }} text="or signup with email" />
					{error && (
						<Typography variant="body2" color="error" gutterBottom>
							{error.message}
						</Typography>
					)}
					<form onSubmit={handleSubmit(mutate)}>
						<TextField
							{...register('email')}
							label="Email"
							variant="outlined"
							margin="normal"
							disabled={!!email}
							error={!!errors.email}
							helperText={errors.email?.message}
							fullWidth
						/>
						<TextField
							{...register('password')}
							label="Password"
							variant="outlined"
							margin="normal"
							type="password"
							error={!!errors.password}
							helperText={errors.password?.message}
							fullWidth
						/>
						<TextField
							{...register('confirmPassword')}
							label="Confirm password"
							variant="outlined"
							margin="normal"
							type="password"
							autoComplete="password"
							error={!!errors.confirmPassword}
							helperText={errors.confirmPassword?.message}
							fullWidth
						/>
						<Box sx={{ my: 2, textAlign: 'center' }}>
							<StatefulButton
								type="submit"
								color="primary"
								variant="contained"
								size="large"
								isLoading={isLoading}
							>
								Sign up
							</StatefulButton>
						</Box>
						<Typography variant="caption" align="center" paragraph>
							By continuing you accept our{' '}
							<Link href="https://haystackpro.com/terms-and-conditions/">terms and conditions</Link>{' '}
							and <Link href="https://haystackpro.com/privacy-cookies/">privacy policy</Link>
						</Typography>
						<Typography align="center" paragraph>
							Already have an account? <RouterLink to={routes.login.path}>Log in</RouterLink>
						</Typography>
					</form>
				</Grid>
			</Grid>
		</Box>
	)
}
