import { useCallback, useState } from 'react'

import { DialogContent, DialogContentText } from '@material-ui/core'
import { DialogActions, Button } from '@material-ui/core'
import operationStates from './operationStates'
import StatefulButton from './StatefullButton'
import CustomDialog from './CustomDialog'

export default function PromptDialog({ open, title, text, onProceed, onCancel }) {
	const [operationState, setOperationState] = useState(operationStates.initial)

	const onProceedClick = useCallback(async () => {
		setOperationState(operationStates.inProgress)
		try {
			await onProceed()
			setOperationState(operationStates.success)
		} catch (e) {
			setOperationState(operationStates.error)
		}
	}, [onProceed])

	return (
		<CustomDialog
			open={open}
			onClose={onCancel}
			aria-labelledby="prompt-dialog-title"
			aria-describedby="prompt-dialog-description"
			title={title}
		>
			<DialogContent>
				<DialogContentText id="prompt-dialog-description">{text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<StatefulButton
					variant="contained"
					color="primary"
					state={operationState}
					onClick={onProceedClick}
				>
					{operationState === operationStates.inProgress ? 'In progress' : 'Proceed'}
				</StatefulButton>
				<Button onClick={onCancel} autoFocus>
					Cancel
				</Button>
			</DialogActions>
		</CustomDialog>
	)
}
