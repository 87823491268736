import Jobs from './jobs/Jobs'
import JobSetup from './jobs/job/application-form/JobSetup'
import JobCandidates from './jobs/job/applicants/JobCandidates'
import VideoInterviewSetup from './jobs/job/interview-setup/VideoInterviewSetup'
import Profile from './profile/Profile'
import ContactUs from './feedback/ContactUs'
import JobLinks from './jobs/job/links/JobLinks'
import NewJobSetup from './jobs/job/NewJobSetup'
import Error from '../common/Error'
import Signup from './auth/Signup'
import Login from './auth/Login'
import PasswordResetInitializer from './auth/PasswordResetInitializer'
import EmailConfirmation from './auth/EmailConfirmation'
import Users from './users/Users'
import Registration from './registration/Registration'

// The order is important the routes should always go from the most specific to the least specific
const routes = {
	jobApplicants: {
		path: '/jobs/:slug/candidates/:stageName?/:candidateId?/:detailsSectionName?',
		component: JobCandidates,
		getLink: (slug, stageName, candidateId, detailsSectionName) => {
			let url = `/jobs/${slug}/candidates`
			if (stageName) {
				url += `/${stageName}`
				if (candidateId) {
					url += `/${candidateId}`
					if (detailsSectionName) {
						url += `/${detailsSectionName}`
					}
				}
			}
			return url
		}
	},
	editInterview: {
		path: '/jobs/:slug/interview-questions',
		component: VideoInterviewSetup,
		getLink: slug => `/jobs/${slug}/interview-questions`
	},
	jobLinks: {
		path: '/jobs/:slug/links',
		component: JobLinks,
		getLink: slug => `/jobs/${slug}/links`
	},
	jobApplicationForm: {
		path: '/jobs/:slug/job-application-form',
		component: JobSetup,
		getLink: slug => `/jobs/${slug}/job-application-form`
	},
	editJob: {
		path: '/jobs/:slug',
		component: NewJobSetup,
		getLink: slug => `/jobs/${slug}`
	},
	jobs: {
		path: '/jobs',
		component: Jobs,
		navLinkText: 'Jobs',
		getLink: () => '/jobs'
	},
	usersDashboard: {
		path: '/users',
		component: Users,
		navLinkText: 'Users'
	},
	contactUs: {
		path: '/contactUs',
		component: ContactUs,
		navLinkText: 'Contact',
		isPublic: true
	},
	confirm: { path: '/confirm', component: EmailConfirmation, isPublic: true },
	profile: { path: '/profile', component: Profile },
	error: { path: '/error', component: Error },
	signup: { path: '/signup', component: Signup, isPublicOnly: true },
	login: { path: '/login', component: Login, isPublicOnly: true },
	passwordReset: { path: '/password-reset', component: PasswordResetInitializer, isPublic: true },
	registration: { path: '/registration', component: Registration, isSpecialRoute: true }
}

export const publicRoutes = Object.values(routes).filter(r => r.isPublic)
export const publicOnlyRoutes = Object.values(routes).filter(r => r.isPublicOnly)
export const securedRoutes = Object.values(routes).filter(
	r => !r.isPublic && !r.isPublicOnly && !r.isSpecialRoute
)

export default routes
