import JobInfoEditor from './job-info/JobInfoEditor'
import JobDescriptionEditor from './job-info/JobDescriptionEditor'
import JobPostingCustomization from './customization/JobApplicationFormCustomization'
import CompanyVideos from './CompanyVideos'
import JobDocumentsSetup from './documents/JobDocumentsSetup'
import JobQuestionsSetup from './questions/JobQuestionsSetup'

import {
	addNewJobDoc,
	createJob,
	deleteJobDoc,
	updateJob,
	updateJobAppearance,
	saveJobDoc,
	createJobQuestion,
	updateJobQuestion as persistJobQuestion,
	saveQuestionsOrder,
	deleteJobQuestion
} from '../../jobService'
import { questionTypeMap } from '../../../../common/jobInfoMapper'
import operationStates from '../../../../common/operationStates'

const isEmptyString = string => !(string && string.trim())

const toClientModel = state => ({
	...state,
	generalInfo: { ...state.generalInfo, description: state.fullDescription.description }
})

export const steps = [
	{
		label: 'Job Info',
		Component: JobInfoEditor,
		stateSubtree: 'generalInfo',
		getValidationSummary(state) {
			const requiredFields = ['title', 'companyName', 'location', 'city', 'state', 'zipcode']
			const summary = { isValid: true }
			requiredFields.forEach(name => {
				if (isEmptyString(state[this.stateSubtree][name])) {
					summary[name] = 'Please fill out this field'
					summary.isValid = false
				}
			})

			return summary
		},
		async onNext(state, dispatch, onJobCreated) {
			const step = steps[state.currentStep]
			dispatch(state =>
				updatePersistenceStatus(state, step.stateSubtree, operationStates.inProgress)
			)

			// a shorter way to cleanup unwanted properties
			const { docsConfig, employerVideosConfig, questionsConfig, ...payload } = state

			if (!state.slug) {
				const job = await createJob(payload)
				dispatch(state => ({ ...getInitialState(job), currentStep: state.currentStep }))
				onJobCreated(job.slug)
			} else {
				const { slug } = await updateJob(toClientModel(payload))
				if (state.slug !== slug) {
					dispatch(state => ({ ...state, slug }))
					onJobCreated(slug)
				}
			}

			dispatch(state => updatePersistenceStatus(state, step.stateSubtree, operationStates.success))
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, step.stateSubtree, operationStates.initial)
			)
		}
	},
	{
		label: 'Job Description',
		Component: JobDescriptionEditor,
		stateSubtree: 'fullDescription',
		getValidationSummary(state) {
			// Todo:
			//   show saving indicator on the step already!
			const summary = { isValid: !!state.id }
			if (isEmptyString(state[this.stateSubtree].description)) {
				summary.description = 'Job description cannot be empty'
				summary.isValid = false
			}
			return summary
		},
		async onNext(state, dispatch) {
			const step = steps[state.currentStep]
			dispatch(state =>
				updatePersistenceStatus(state, step.stateSubtree, operationStates.inProgress)
			)

			await updateJob(toClientModel(state))

			dispatch(state => updatePersistenceStatus(state, step.stateSubtree, operationStates.success))
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, step.stateSubtree, operationStates.initial)
			)
		}
	},
	{
		label: 'Logo & Design',
		Component: JobPostingCustomization,
		stateSubtree: 'appearance',
		getValidationSummary(state) {
			const { isCustomLogo, customLogoUrl } = state[this.stateSubtree]
			return { isValid: !(isCustomLogo && customLogoUrl === true) }
		},
		async onNext(state, dispatch) {
			const step = steps[state.currentStep]
			dispatch(state =>
				updatePersistenceStatus(state, step.stateSubtree, operationStates.inProgress)
			)

			const {
				validationSummary,
				persistenceStatus,
				companyLogoFile,
				customLogoFile,
				...appearance
			} = state[this.stateSubtree]
			if (appearance.isCustomLogo) {
				// we need to make sure we only submit one file in case the user picked both and switched between them in UI to decide
				// if there is a company logo and user specifically decides to use no logo then we 'override' the company logo with empty custom logo so no files should be submitted
				if (appearance.customLogoUrl && customLogoFile) {
					appearance.logoFile = customLogoFile
				}
			} else if (companyLogoFile) {
				// in this case we need to make sure that only company logo file is submitted
				appearance.logoFile = companyLogoFile
			}

			await updateJobAppearance(state.id, appearance)

			dispatch(state => updatePersistenceStatus(state, step.stateSubtree, operationStates.success))
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, step.stateSubtree, operationStates.initial)
			)
		}
	},
	{
		label: 'Company Videos',
		Component: CompanyVideos,
		stateSubtree: 'employerVideosConfig',
		getValidationSummary() {
			return { isValid: true }
		},
		async onNext(state, dispatch) {
			// the state is persisted for each individual operation in this step, so we just mark this step as saved
			const { stateSubtree } = steps[state.currentStep]
			dispatch(state => ({
				...state,
				[stateSubtree]: {
					...state[stateSubtree],
					completedOnce: true
				}
			}))
		}
	},
	{
		label: 'Documents',
		Component: JobDocumentsSetup,
		stateSubtree: 'docsConfig',
		getValidationSummary(state) {
			const summary = { isValid: true }

			const otherDoc = state[this.stateSubtree].documents.find(doc => doc.documentType === 'custom')
			if (otherDoc && isEmptyString(otherDoc.displayName)) {
				summary[otherDoc.documentType] = 'Please specify the name of this document'
				summary.isValid = false
			}
			return summary
		},
		async onNext(state, dispatch) {
			const { stateSubtree } = steps[state.currentStep]
			dispatch(state => ({
				...state,
				[stateSubtree]: {
					...state[stateSubtree],
					completedOnce: true
				}
			}))
		}
	},
	{
		label: 'Job Application Form Questions',
		Component: JobQuestionsSetup,
		stateSubtree: 'questionsConfig',
		getValidationSummary(state) {
			const summary = { isValid: true }

			if (state[this.stateSubtree].questions.filter(it => !it.isInEditMode).length === 5) {
				summary.maxQuestionCountReached = true
				summary.explanation = "You've reached the maximum number of questions"
				return summary
			}

			if (state.hasAppliedCandidates) {
				summary.isReadOnly = true
				summary.explanation =
					'Since there are candidates who have already applied to this job, questions cannot be changed.'
				return summary
			}

			state[this.stateSubtree].questions.reduce(
				(sum, { id, text, questionType, answerChoices, isInEditMode }) => {
					if (isEmptyString(text)) {
						sum[id] = { text: 'Please fill out this field' }
						summary.isValid = false
					}
					if (isEmptyString(questionType)) {
						sum[id] = {
							questionType: 'Please select a type of answer for this question'
						}
						summary.isValid = false
					} else if (
						(questionType === 'single' || questionType === 'multiple') &&
						answerChoices.length < 2
					) {
						// TODO: make sure that choices are not empty string
						sum[id] = {
							answerChoices: 'Please provide at least 2 choices'
						}
						summary.isValid = false
					}

					if (isInEditMode) {
						summary.isValid = false
					}
					return sum
				},
				summary
			)
			return summary
		},
		async onNext(state, dispatch) {
			// the state is persisted for each individual operation in this step, so we just mark this step as saved
			const { stateSubtree } = steps[state.currentStep]
			dispatch(state => ({
				...state,
				[stateSubtree]: {
					...state[stateSubtree],
					completedOnce: true
				}
			}))
		}
	}
]

export const byOrder = (a, b) => a.order - b.order

export const getInitialState = job => {
	const state = { ...job, currentStep: 0 }
	state.fullDescription = { description: job.generalInfo.description }
	state.employerVideosConfig = {
		employerVideos: job.employerVideos,
		companyId: job.companyId
	}
	state.docsConfig = { documents: job.documents }

	state.questionsConfig = {
		questions: job.questions.map(q => {
			const question = { ...q, questionType: questionTypeMap[q.questionType] }
			question.answerChoices.sort(byOrder)
			return question
		})
	}

	state.questionsConfig.questions.sort(byOrder)

	steps.forEach(step => {
		const stepConfig = state[step.stateSubtree]
		stepConfig.validationSummary = step.getValidationSummary?.(state)
		stepConfig.persistenceStatus = operationStates.initial
	})
	return state
}

export const reduce = (state, action) => action(state)

const updatePersistenceStatus = (state, stateSubtree, status) => ({
	...state,
	[stateSubtree]: {
		...state[stateSubtree],
		persistenceStatus: status,
		completedOnce:
			status === operationStates.error
				? false
				: state[stateSubtree].completedOnce || status === operationStates.success
	}
})

const addJobDoc = (state, doc) => ({
	...state,
	docsConfig: {
		...state.docsConfig,
		documents: [...state.docsConfig.documents, doc]
	}
})

const removeJobDoc = (state, doc) => ({
	...state,
	docsConfig: {
		...state.docsConfig,
		documents: state.docsConfig.documents.filter(d => d !== doc)
	}
})

const updateJobDoc = (state, doc) => ({
	...state,
	docsConfig: {
		...state.docsConfig,
		documents: state.docsConfig.documents.map(d => (d.documentType === doc.documentType ? doc : d))
	}
})

const addJobQuestion = (state, question) => ({
	...state,
	questionsConfig: {
		...state.questionsConfig,
		questions: [...state.questionsConfig.questions, question]
	}
})

const removeJobQuestion = (state, question) => ({
	...state,
	questionsConfig: {
		...state.questionsConfig,
		questions: state.questionsConfig.questions.filter(d => d !== question),
		questionBeingRemoved: question
	}
})

const updateJobQuestion = (state, question) => ({
	...state,
	questionsConfig: {
		...state.questionsConfig,
		questions: state.questionsConfig.questions.map(q => (question.id === q.id ? question : q))
	}
})

const changeQuestionsOrder = (state, oldIndex, newIndex) => {
	const reordered = [...state.questionsConfig.questions]
	reordered[oldIndex].order = newIndex
	const increment = oldIndex > newIndex ? 1 : -1

	for (let i = newIndex; i !== oldIndex; i += increment) {
		reordered[i].order += increment
	}

	reordered.sort(byOrder)

	return {
		...state,
		questionsConfig: { ...state.questionsConfig, questions: reordered }
	}
}

export const createActions = (dispatch, jobId, onJobCreated, onEditComplete) => ({
	updateGeneralInfo: payload =>
		dispatch(state => {
			const step = steps[state.currentStep]
			const newState = {
				...state,
				[step.stateSubtree]: {
					...state[step.stateSubtree],
					...payload
				}
			}
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)
			return newState
		}),

	setDescription: description =>
		dispatch(state => {
			const step = steps[state.currentStep]
			const newState = {
				...state,
				[step.stateSubtree]: {
					...state[step.stateSubtree],
					description
				}
			}
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)
			return newState
		}),

	updateAppearance: payload =>
		dispatch(state => {
			const step = steps[state.currentStep]
			return { ...state, [step.stateSubtree]: { ...state[step.stateSubtree], ...payload } }
		}),
	setLogo: (isCustomLogo, logoFile) =>
		dispatch(state => {
			const step = steps[state.currentStep]
			const logo = URL.createObjectURL(logoFile)
			const newState = {
				...state,
				[step.stateSubtree]: {
					...state[step.stateSubtree],
					isCustomLogo,
					[isCustomLogo ? 'customLogoUrl' : 'companyLogoUrl']: logo,
					[isCustomLogo ? 'customLogoFile' : 'companyLogoFile']: logoFile
				}
			}
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)
			return newState
		}),
	setLogoMode: logoMode =>
		dispatch(state => {
			const step = steps[state.currentStep]
			let newStateSubtree = { ...state[step.stateSubtree] }
			switch (logoMode) {
				case 'company':
					newStateSubtree.isCustomLogo = false
					break
				case 'custom':
					newStateSubtree.isCustomLogo = true
					newStateSubtree.customLogoUrl = newStateSubtree.customLogoUrl || true
					break
				case 'none':
					newStateSubtree.isCustomLogo = true
					newStateSubtree.customLogoUrl = null
					break
				default:
					break
			}
			const newState = { ...state, [step.stateSubtree]: newStateSubtree }
			newStateSubtree.validationSummary = step.getValidationSummary(newState)

			return newState
		}),

	addEmployerVideo: video =>
		dispatch(state => ({
			...state,
			employerVideosConfig: {
				...state.employerVideosConfig,
				employerVideos: [...state.employerVideosConfig.employerVideos, video]
			}
		})),

	updateEmployerVideo: video =>
		dispatch(state => ({
			...state,
			employerVideosConfig: {
				...state.employerVideosConfig,
				employerVideos: state.employerVideosConfig.employerVideos.map(v =>
					v.videoType === video.videoType ? { ...v, ...video } : v
				)
			}
		})),

	addDocument: async doc => {
		const isCustom = doc.documentType === 'custom'
		const isEmptyName = !doc.displayName || !doc.displayName.trim()
		const docBeingAdded = { ...doc, isReadOnly: !isCustom || !isEmptyName }

		if (!isCustom || isEmptyName) {
			dispatch(state => {
				const step = steps[state.currentStep]

				const newState = addJobDoc(state, docBeingAdded)
				newState.docsConfig.validationSummary = step.getValidationSummary(newState)

				return isCustom && isEmptyName
					? newState
					: updatePersistenceStatus(
							newState,
							steps[state.currentStep].stateSubtree,
							operationStates.inProgress
					  )
			})
		}
		// Custom docs are saved after given a name
		if (isCustom && isEmptyName) return

		try {
			const newDoc = await addNewJobDoc(jobId, doc)

			dispatch(state =>
				updatePersistenceStatus(updateJobDoc(state, newDoc), 'docsConfig', operationStates.success)
			)
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, 'docsConfig', operationStates.initial)
			)
		} catch (e) {
			dispatch(state =>
				updatePersistenceStatus(
					removeJobDoc(state, docBeingAdded),
					'docsConfig',
					operationStates.error
				)
			)
			console.log(e)
		}
	},
	updateDocument: async (doc, skipPersistence) => {
		// optimistic approach, we assume the persistence goes well, and revert if it doesnt'
		dispatch(state => {
			const step = steps[state.currentStep]
			const docBeingUpdated = state[step.stateSubtree].documents.find(d => d.id === doc.id)

			const newState = updateJobDoc(state, doc)
			newState[step.stateSubtree].docBeingUpdated = docBeingUpdated
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)

			return skipPersistence
				? newState
				: updatePersistenceStatus(
						newState,
						steps[state.currentStep].stateSubtree,
						operationStates.inProgress
				  )
		})

		if (skipPersistence) return

		try {
			await saveJobDoc(doc)

			dispatch(state => updatePersistenceStatus(state, 'docsConfig', operationStates.success))
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, 'docsConfig', operationStates.initial)
			)
		} catch (e) {
			dispatch(state =>
				updatePersistenceStatus(
					updateJobDoc(state, state.docsConfig.docBeingUpdated),
					'docsConfig',
					operationStates.error
				)
			)
			console.log(e)
		}
	},
	removeDocument: async id => {
		// optimistic approach, we assume the persistence goes well, and revert if it doesnt'
		dispatch(state => {
			const step = steps[state.currentStep]
			let docBeingDeleted = state[step.stateSubtree].documents.find(d => d.id === id)

			const newState = removeJobDoc(state, docBeingDeleted)
			newState[step.stateSubtree].docBeingDeleted = docBeingDeleted
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)

			return id
				? updatePersistenceStatus(newState, step.stateSubtree, operationStates.inProgress)
				: newState
		})

		if (!id) return

		try {
			await deleteJobDoc(id)

			dispatch(state => updatePersistenceStatus(state, 'docsConfig', operationStates.success))
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, 'docsConfig', operationStates.initial)
			)
		} catch (e) {
			dispatch(state =>
				updatePersistenceStatus(
					addJobDoc(state, state.docsConfig.docBeingDeleted),
					'docsConfig',
					operationStates.error
				)
			)
			console.log(e)
		}
	},

	addQuestion: question => {
		dispatch(state => {
			const step = steps[state.currentStep]
			const id = `temp-id-${new Date().getTime()}`
			const { questions } = state[step.stateSubtree]
			const newState = addJobQuestion(state, {
				...question,
				id,
				order: questions.length === 0 ? 0 : questions[questions.length - 1].order + 1,
				answerChoices: question.answerChoices.map((ac, index) => ({ ...ac, order: index }))
			})
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)
			return newState
		})
	},
	removeQuestion: async id => {
		const isTempId = id.toString().startsWith('temp-id-')
		dispatch(state => {
			const step = steps[state.currentStep]
			let questionBeingRemoved = state[step.stateSubtree].questions.find(d => d.id === id)

			const newState = removeJobQuestion(state, questionBeingRemoved)
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)

			return isTempId
				? newState
				: updatePersistenceStatus(newState, step.stateSubtree, operationStates.inProgress)
		})

		if (isTempId) return

		try {
			await deleteJobQuestion(id)

			dispatch(state => updatePersistenceStatus(state, 'questionsConfig', operationStates.success))
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, 'questionsConfig', operationStates.initial)
			)
		} catch (e) {
			dispatch(state =>
				updatePersistenceStatus(
					addJobQuestion(state, state.questionsConfig.questionBeingRemoved),
					'questionsConfig',
					operationStates.error
				)
			)
			console.log(e)
		}
	},
	updateQuestion: (id, changeSet) => {
		dispatch(state => {
			const step = steps[state.currentStep]
			const questionBeingUpdated = state[step.stateSubtree].questions.find(q => q.id === id)

			const newState = updateJobQuestion(state, { ...questionBeingUpdated, ...changeSet })
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)

			return newState
		})
	},
	updateQuestionOrder: async (oldIndex, newIndex) => {
		if (oldIndex === newIndex) return

		dispatch(state =>
			updatePersistenceStatus(
				changeQuestionsOrder(state, oldIndex, newIndex),
				steps[state.currentStep].stateSubtree,
				operationStates.inProgress
			)
		)

		try {
			await saveQuestionsOrder(jobId, oldIndex, newIndex)

			dispatch(state => updatePersistenceStatus(state, 'questionsConfig', operationStates.success))
			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, 'questionsConfig', operationStates.initial)
			)
		} catch (e) {
			console.log(e)

			dispatch(state =>
				updatePersistenceStatus(
					changeQuestionsOrder(state, newIndex, oldIndex),
					'questionsConfig',
					operationStates.error
				)
			)
		}
	},
	saveQuestion: async question => {
		dispatch(state => {
			const step = steps[state.currentStep]
			const newState = updateJobQuestion(state, { ...question, isInEditMode: false })
			newState[step.stateSubtree].validationSummary = step.getValidationSummary(newState)

			return updatePersistenceStatus(
				newState,
				steps[state.currentStep].stateSubtree,
				operationStates.inProgress
			)
		})

		try {
			if (question.id.toString().startsWith('temp-id-')) {
				// if id is a temp one, don't submit it, the backend will generate the correct numeric value
				const { id, ...payload } = question
				payload.questionType = questionTypeMap[payload.questionType]
				const newQuestion = await createJobQuestion(jobId, payload)

				dispatch(state =>
					updatePersistenceStatus(
						{
							...state,
							questionsConfig: {
								...state.questionsConfig,
								questions: state.questionsConfig.questions.map(q =>
									q.id === id
										? { ...newQuestion, questionType: questionTypeMap[newQuestion.questionType] }
										: q
								)
							}
						},
						'questionsConfig',
						operationStates.success
					)
				)
			} else {
				await persistJobQuestion({
					...question,
					questionType: questionTypeMap[question.questionType]
				})
				dispatch(state =>
					updatePersistenceStatus(state, 'questionsConfig', operationStates.success)
				)
			}

			setTimeout(dispatch, 3000, state =>
				updatePersistenceStatus(state, 'questionsConfig', operationStates.initial)
			)
		} catch (e) {
			dispatch(state => updatePersistenceStatus(state, 'questionsConfig', operationStates.error))
			console.log(e)
		}
	},

	proceed: async (state, targetStepIndex = state.currentStep) => {
		const currentStepIndex = state.currentStep
		// go to the specified step if the current step is not last (and let the persistence, if any, run 'in the background')
		if (targetStepIndex < steps.length) {
			dispatch(state => ({ ...state, currentStep: targetStepIndex, error: false }))
		}

		const step = steps[currentStepIndex]

		// questionable
		if (state[step.stateSubtree].validationSummary?.isReadOnly) return

		try {
			await step.onNext(state, dispatch, onJobCreated)
		} catch (e) {
			console.log('error:', e)
			dispatch(state => ({
				...state,
				[step.stateSubtree]: {
					...state[step.stateSubtree],
					persistenceStatus: operationStates.error
				},
				error: {
					title: `Error saving ${step.label}`,
					description: "The operation hasn't completed successfully",
					stepIndex: currentStepIndex
				}
			}))

			return
		}

		if (targetStepIndex > state.currentStep && state.currentStep === steps.length - 1) {
			onEditComplete(state)
		}
	},
	navigateToErrorredStep: () =>
		dispatch(state => ({ ...state, currentStep: state.error.stepIndex, error: false }))
})
