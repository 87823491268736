import { Grid, Badge, withStyles } from '@material-ui/core'

const StyledBadge = withStyles({
	badge: {
		fontSize: '1.5rem',
		height: '1.5rem',
		width: '1.5rem',
		borderRadius: '50%'
	}
})(Badge)

const SubscriptionUsageItem = ({ icon, badgeContent, text }) => {
	return (
		<Grid item xs={12} sm={6}>
			<Grid container alignItems="center" direction="column">
				<Grid item>
					<StyledBadge badgeContent={badgeContent} color="secondary" overlap="circular">
						{icon}
					</StyledBadge>
				</Grid>
				<Grid item>{text}</Grid>
			</Grid>
		</Grid>
	)
}

export default SubscriptionUsageItem
