import ApplicantsInStage from '../ApplicantsInStage'
import { stageDisplayNames, stageNames } from '../stageConstants'

const stageDisplayNameByStatusLookup = {
	applied: stageDisplayNames[stageNames.applied],
	applied_not_sure: stageDisplayNames[stageNames.applied],
	invited: stageDisplayNames[stageNames.video],
	interviewed: stageDisplayNames[stageNames.video],
	interviewed_not_sure: stageDisplayNames[stageNames.video],
	face: stageDisplayNames[stageNames.face],
	face_scheduled: stageDisplayNames[stageNames.face], // Deprecated: leaving here for now for backwards compatibility
	face_not_sure: stageDisplayNames[stageNames.face],
	hired: stageDisplayNames[stageNames.hired],
	rejected: stageDisplayNames[stageNames.rejected]
}

const columns = [
	{
		title: 'Date Applied',
		field: 'appliedDateTime',
		type: 'datetime',
		render: applicant =>
			applicant.appliedDateTime.toLocaleString('en-US', {
				dateStyle: 'short',
				timeStyle: 'short'
			})
	},
	{
		title: 'Interview Completed',
		field: 'interviewCompletedDateTime',
		type: 'datetime',
		render: applicant =>
			applicant.interviewCompletedDateTime &&
			applicant.interviewCompletedDateTime.toLocaleString('en-US', {
				dateStyle: 'short',
				timeStyle: 'short'
			})
	},
	{
		title: 'Rejected From',
		field: 'previousStatus',
		lookup: stageDisplayNameByStatusLookup
	}
]

export default function RejectedStageApplicants({
	stage,
	candidates,
	isLoading,
	candidateActions
}) {
	return (
		<ApplicantsInStage
			title="Rejected"
			stage={stage}
			columns={columns}
			candidateActions={candidateActions}
			candidates={candidates}
			isLoading={isLoading}
			options={{ selection: true }}
		/>
	)
}
