import { useState, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button, Paper, IconButton, Snackbar } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from 'mdi-material-ui/Check'
import CopyIcon from '@material-ui/icons/FileCopy'
import JobLink from './JobLink'
import { createJobLink, getJobWithLinks, updateJobLink } from '../../jobService'
import LargeLoader from '../../../../common/LargeLoader'

const useStyles = makeStyles(({ spacing }) => ({
	root: { maxWidth: 1000, margin: `${spacing(5)}px auto ${spacing(2)}px` },
	content: { padding: spacing(3), maxWidth: 1000 },
	buttonContainer: {
		margin: spacing(2),
		textAlign: 'center',
		'& > :nth-child(n+2)': { marginLeft: spacing(2) }
	},
	helpInfo: { marginTop: spacing(4) },
	tabContentContainer: { padding: spacing(3, 1.5) },
	nestedList: { paddingLeft: spacing(3), listStyleType: 'lower-alpha' }
}))

export default function JobLinksEditor(props) {
	const { jobSlug, onEditComplete } = props
	const [showLoader, setShowLoader] = useState(false)
	const [displayCopyNotification, setDisplayCopyNotification] = useState(false)
	const [jobInfo, setJobInfo] = useState()
	const [error, setError] = useState()

	useEffect(() => {
		getJobWithLinks(jobSlug)
			.then(jobWithLinks => {
				const jobInfo = jobWithLinks.links.reduce(
					(grouped, link) => {
						grouped[link.network ? 'socialLinks' : 'jobBoardLinks'].push(link)
						return grouped
					},
					{ jobBoardLinks: [], socialLinks: [] }
				)
				jobInfo.token = jobWithLinks.token
				jobInfo.id = jobWithLinks.id
				setJobInfo(jobInfo)
			})
			.catch(e => {
				setError('Unable to load job links information')
				console.log(e)
			})
	}, [jobSlug])

	const addNewLink = useCallback(({ currentTarget }) => {
		setJobInfo(jobInfo => ({
			...jobInfo,
			[currentTarget.name]: [
				...jobInfo[currentTarget.name],
				{ label: '', network: currentTarget.id }
			]
		}))
	}, [])

	const createLink = useCallback(
		async (category, label, network) => {
			setJobInfo(jobInfo => ({
				...jobInfo,
				[category]: jobInfo[category].map(it => (it.id ? it : { label }))
			}))

			setShowLoader(true)
			let newLink = {}
			let error
			try {
				newLink = await createJobLink(jobInfo.id, { label, network })
			} catch (e) {
				error = 'Error creating job link'
			}
			setShowLoader(false)

			const { id, token } = newLink
			setJobInfo(jobInfo => ({
				...jobInfo,
				[category]: jobInfo[category].map(it =>
					it.id ? it : { ...it, id, token, label, network, error }
				)
			}))
		},
		[jobInfo?.id]
	)

	const cancel = useCallback(
		category =>
			setJobInfo(jobInfo => ({ ...jobInfo, [category]: jobInfo[category].filter(it => it.id) })),
		[]
	)

	const saveLink = useCallback(async (category, id, label) => {
		setJobInfo(jobInfo => ({
			...jobInfo,
			[category]: jobInfo[category].map(it => (it.id === id ? { ...it, label } : it))
		}))

		setShowLoader(true)
		try {
			await updateJobLink({ id, label })
		} catch (e) {
			console.log(e)
			setJobInfo(jobInfo => ({
				...jobInfo,
				[category]: jobInfo[category].map(it =>
					it.id === id ? { ...it, error: 'Error saving the link' } : it
				)
			}))
		}
		setShowLoader(false)
	}, [])

	const hideCopiedNotification = useCallback((event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setDisplayCopyNotification(false)
	}, [])

	const onCopyJobLink = useCallback(() => {
		setDisplayCopyNotification(true)
	}, [])

	const classes = useStyles()
	return (
		<section className={classes.root}>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={displayCopyNotification}
				autoHideDuration={4000}
				onClose={hideCopiedNotification}
			>
				<Alert elevation={6} variant="filled" severity="success" onClose={hideCopiedNotification}>
					Copied link to clipboard!
				</Alert>
			</Snackbar>
			<Typography variant="h4" gutterBottom>
				Job Application Links
			</Typography>
			<Paper className={classes.content}>
				<Grid container spacing={3}>
					<Grid item sm={7}>
						<Grid item sm={12}>
							<Typography variant="subtitle2" color="textSecondary" gutterBottom>
								Create a custom web link to add to your job board postings (LinkedIn, Indeed, etc.),
								any social media postings about the position, and anyplace else you'd like to post
								your job offer
							</Typography>
							{jobInfo ? (
								<>
									{jobInfo.jobBoardLinks.map(link => (
										<JobLink
											key={link.id}
											{...link}
											category="jobBoardLinks"
											jobToken={jobInfo.token}
											createLink={createLink}
											updateLink={saveLink}
											cancel={cancel}
											onCopyLink={onCopyJobLink}
											showLoader={showLoader} // We can pass it like this because it only affects the editing workflow
										/>
									))}
									{jobInfo.socialLinks.length > 0 && (
										<>
											<Typography variant="h6" paragraph>
												Social Links
											</Typography>
											{jobInfo.socialLinks.map(link => (
												<JobLink
													key={link.id}
													{...link}
													category="socialLinks"
													jobToken={jobInfo.token}
													createLink={createLink}
													updateLink={saveLink}
													cancel={cancel}
													onCopyLink={onCopyJobLink}
												/>
											))}
										</>
									)}
								</>
							) : error ? (
								<Alert severity="error">
									<AlertTitle>Error loading data</AlertTitle>
									{error}
								</Alert>
							) : (
								<LargeLoader title="Loading Links" />
							)}
							<div className={classes.buttonContainer}>
								<Button
									startIcon={<AddIcon />}
									name="jobBoardLinks"
									color="secondary"
									disabled={jobInfo && jobInfo.jobBoardLinks.some(it => !it.id)}
									onClick={addNewLink}
								>
									Create Link
								</Button>
							</div>
						</Grid>
					</Grid>
					<Grid item sm={5}>
						<Alert severity="info" variant="standard" className={classes.helpInfo}>
							Create a separate link and give them distinguished names for each place you post, so
							you can track where your applicants come from and determine which job board ads and
							social media posts are the most effective.
						</Alert>
						<Alert severity="info" variant="standard" className={classes.helpInfo}>
							<AlertTitle>Example</AlertTitle>
							Let’s say you are posting a job ad on Craigslist:
							<ol style={{ padding: 0 }}>
								<li>
									Create a link (on the left) by clicking the{' '}
									<Button startIcon={<AddIcon />} size="small">
										Create Link
									</Button>{' '}
									button.
								</li>
								<li>
									Give your link a Name, “Craigslist Ad #1” for tracking purposes (only you will see
									the Name).
								</li>
								<li>
									Click on the{' '}
									<IconButton size="small" color="primary">
										<CopyIcon />
									</IconButton>{' '}
									(Copy) button.
								</li>
								<li>
									Open your craigslist ad, paste the link at the top or bottom of the Description
									section with the instructions “To apply, go to the following site: (Link)”
								</li>
							</ol>
						</Alert>
					</Grid>
				</Grid>
				<div className={classes.buttonContainer} style={{ textAlign: 'right' }}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<DoneIcon />}
						onClick={onEditComplete}
					>
						Finish
					</Button>
				</div>
			</Paper>
		</section>
	)
}
