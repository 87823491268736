import { useMemo, useRef, useReducer, useCallback } from 'react'

const useAsyncActions = (createActions, initialState, initialize) => {
	const stateRef = useRef(initialState)
	const reduce = (state, action) => (stateRef.current = action(state))
	const [state, dispatch] = useReducer(reduce, initialState, initialize)
	// to capture state that is a result of initialize after the very first call of useReducer
	stateRef.current = state

	const getState = useCallback(() => stateRef.current, [])
	const actions = useMemo(() => createActions(dispatch, getState), [createActions, getState])

	return [state, actions]
}

export default useAsyncActions
