const exampleQuestions = {
	Filterable: [
		{
			type: 'single',
			text: 'What range for total compensation are you targeting?',
			answers: ['Less than 30K', '30-35K', '40-45K', '45-50K', '50K+']
		},
		{
			type: 'single',
			text: 'Are you authorized to work in the U.S.?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'What is your highest level of education achieved?',
			answers: ['High School', 'College', 'Graduate Degree', 'PhD']
		},
		{
			type: 'single',
			text: 'Are you willing to travel as part of this job?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'Are you willing to relocate to our location if necessary?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'Are you bi/multilingual?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'Are you comfortable with our location?',
			answers: ['Yes', 'No', 'Somewhat']
		},
		{
			type: 'single',
			text: 'Are you willing to undergo a formal background check?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'Can you interact with people on an up-beat and friendly basis, regardless of circumstances?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'Are you willing to participate in pre-employment and periodic drug screening?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'When asked, can you provide at least 3 professional references we can contact?',
			answers: ['Yes', 'No']
		},
		{
			type: 'single',
			text: 'This is a remote position. Do you have access to a computer, a stable, high speed internet connection, and a private space to work remotely?',
			answers: ['Yes', 'No']
		},
		{
			type: 'multiple',
			text: 'What days would you be able to work?',
			answers: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
		},
		{
			type: 'multiple',
			text: 'What shifts are you available?',
			answers: ['Morning', 'Noon', 'Evening', 'Late Night', 'Early Morning']
		},
		{
			type: 'multiple',
			text: 'What type of work schedule are you interested in?',
			answers: ['Full Time', 'Part Time', 'Seasonal/Temporary']
		},
		{
			type: 'single',
			text: 'How soon would you be available to start?',
			answers: ['Today', 'Next Week', 'In Two Weeks', 'In Three Weeks', 'In Four Weeks Or More']
		},
		{
			type: 'single',
			text: 'Are you willing to work overtime if necessary?',
			answers: ['Yes', 'No', 'Maybe']
		},
		{
			type: 'single',
			text: 'How much relevant experience do you have?',
			answers: ['None', 'Less than 1 year', '1-2 years', '2-5 years', '5+ years']
		},
		{
			type: 'single',
			text: 'How many jobs have you had in the past 2 years?',
			answers: ['None', '1', '2', '3', '4', '5+']
		}
	],
	Written: [
		{
			type: 'short',
			text: 'What was your degree for your highest level of education?'
		},
		{
			type: 'long',
			text: 'What motivates you?'
		},
		{
			type: 'long',
			text: 'Why should we hire you?'
		}
	]
}

export default exampleQuestions
