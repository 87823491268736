import { post, get } from '../../restService'

export const updateSubscriptionDetails = async args =>
	await post('api/payments/update-details', args)

export const getPrefillBillingDetails = async () => await get('api/payments/prefill-details', {})

export const cancelSubscription = async () => await post('api/payments/cancel', {})

export const reactivateSubscription = async () => await post('api/payments/reactivate', {})
