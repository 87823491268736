export const stageNames = {
	applied: 'applied',
	video: 'video',
	face: 'face',
	hired: 'hired',
	rejected: 'rejected'
}

export const stageDisplayNames = {
	[stageNames.applied]: 'New',
	[stageNames.video]: 'Video',
	[stageNames.face]: 'Face to Face',
	[stageNames.hired]: 'Hired',
	[stageNames.rejected]: 'Rejected'
}
