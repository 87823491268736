import { useCallback, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

import InputDeviceSelector from '../../../../../../common/video-recorder/InputDeviceSelector'
import MicCanvas from '../../../../../../common/video-recorder/MicCanvas'
import { Alert } from '@material-ui/lab'
import DeviceActivation from './DeviceActivation'
import CameraPicker from '../../../../../../interview/interview-setup/CameraPicker'

const deviceManager = navigator.mediaDevices

export default function DevicePicker({
	selectedMicId,
	selectedCameraId,
	onSelectMic,
	onSelectCamera
}) {
	const [state, setState] = useState({ micDevices: [], cameraDevices: [] })

	const setAvailableDevices = useCallback(
		devices => {
			const cameraDevices = devices.filter(device => device.kind === 'videoinput')
			const micDevices = devices.filter(device => device.kind === 'audioinput')
			setState(prevState => ({
				...prevState,
				cameraDevices: cameraDevices,
				micDevices: micDevices,
				needsPermissions: false
			}))
			if (cameraDevices.length > 0) onSelectCamera(cameraDevices[0].deviceId)
			if (micDevices.length > 0) onSelectMic(micDevices[0]?.deviceId)
		},
		[onSelectCamera, onSelectMic]
	)

	useEffect(() => {
		deviceManager.enumerateDevices().then(devices => {
			// As of this writing, the way to detect if the permissions were not yet given is to check if device labels are empty (sigh)
			if (devices.every(d => !d.label)) {
				setState(prevState => ({ ...prevState, needsPermissions: true }))
			} else {
				setAvailableDevices(devices)
			}
		})
	}, [setAvailableDevices])

	const requestDevicePermissions = useCallback(() => {
		deviceManager
			.getUserMedia({ audio: true, video: true })
			.then(() => deviceManager.enumerateDevices().then(setAvailableDevices))
			.catch(e => setState(prevState => ({ ...prevState, permissionIsDenied: true })))
	}, [setAvailableDevices])

	return state.permissionIsDenied ? (
		<Grid item>
			<Alert severity="error">
				You haven't allowed the browser to access your video and/or microphone device. These must be
				enabled for recording video introductions. To grant access to your devices, please open your
				browser's preferences -&gt; Websites and and change camera setting for haystackpro.com to
				"Allow". After performing this change, please reload the page.
			</Alert>
		</Grid>
	) : state.needsPermissions ? (
		<DeviceActivation onRequestDevicePermissions={requestDevicePermissions} />
	) : (
		<Grid container spacing={3} justifyContent="center">
			<Grid item xs={12} sm={5}>
				<InputDeviceSelector
					inputDevices={state.cameraDevices}
					onSelectDevice={onSelectCamera}
					deviceId={selectedCameraId}
					tooltip="To verify your selected video device is working, check if you see the video feed from you camera below"
					label="Camera"
					sx={{ mb: 1 }}
				/>
				<CameraPicker proposedDeviceId={selectedCameraId} />
			</Grid>
			<Grid item xs={12} sm={5}>
				<InputDeviceSelector
					inputDevices={state.micDevices}
					onSelectDevice={onSelectMic}
					deviceId={selectedMicId}
					tooltip="To verify your microphone is working, speak and see if the yellow bars move in sync with your voice"
					label="Microphone"
					sx={{ mb: 1 }}
				/>
				<MicCanvas microphone={selectedMicId} enable={!!selectedMicId} />
			</Grid>
		</Grid>
	)
}
