import { Timeline } from '@material-ui/lab'

import ApplicantActivityTimelineStep from './ApplicantActivityTimelineStep'

export default function CandidateActivityTimeline({ candidate }) {
	let hireStatus = ''
	if (candidate.status === 'rejected' || candidate.status === 'rejected_mailed') {
		hireStatus = 'Rejected'
	} else if (candidate.status === 'hired') {
		hireStatus = 'Hired'
	}

	return (
		<Timeline position="alternate">
			{candidate.appliedDateTime && (
				<ApplicantActivityTimelineStep
					date={candidate.appliedDateTime}
					label="Applied"
					isLastItem={!candidate.invitedDateTime && !hireStatus}
				/>
			)}
			{candidate.invitedDateTime && (
				<ApplicantActivityTimelineStep
					date={candidate.invitedDateTime}
					label="Invited"
					isLastItem={!candidate.interviewCompletedDateTime && !hireStatus}
				/>
			)}
			{candidate.interviewCompletedDateTime && (
				<ApplicantActivityTimelineStep
					date={candidate.interviewCompletedDateTime}
					label="Video interview completed"
					isLastItem={!hireStatus}
				/>
			)}
			{hireStatus && (
				<ApplicantActivityTimelineStep
					isLastItem
					label={hireStatus || ''}
					overrideActive={hireStatus}
				/>
			)}
		</Timeline>
	)
}
