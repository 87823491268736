import { useCallback, useContext, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
	Button,
	CircularProgress,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import ErrorIcon from '@material-ui/icons/Error'

import ContactInformation from './ContactInformation'
import DocumentsUpload from './DocumentsUpload'
import JobApplicationQuestions from './JobApplicationQuestions'
import JobApplicationSubmission from './JobApplicationSubmission'
import { logEvent } from '../common/telemetry/appInsigntsService'
import JobApplicationContext from './jobApplicationContext'

const useStyles = makeStyles(({ spacing }) => ({
	header: { margin: spacing(2.5, 0, 2.5, 7) },
	buttonContainer: { marginTop: spacing(3), '& > :nth-child(n+1)': { marginRight: spacing(2) } },
	applicationForm: { marginRight: spacing(3) },
	error: { marginTop: spacing(1) }
}))

export default function JobApplicationForm({ injectedState }) {
	const { jobInfo, actions } = useContext(JobApplicationContext)

	const steps = useMemo(() => {
		const steps = [
			{
				label: 'Contact Information',
				Component: ContactInformation,
				statePropName: 'contactInfo',
				onProceed: async ({ contactInfo }) => {
					await actions.saveApplicantInfo(contactInfo.values)
				}
			}
		]

		if (jobInfo.documents.length > 0) {
			steps.push({
				label: 'Documents',
				Component: DocumentsUpload,
				statePropName: 'uploads',
				onProceed: async ({ contactInfo, uploads }) => {
					await actions.uploadFiles(contactInfo.candidateId, uploads.files)
				}
			})
		}

		if (jobInfo.questions.length > 0)
			steps.push({
				label: 'Questions',
				Component: JobApplicationQuestions,
				statePropName: 'answers',
				onProceed: async ({ contactInfo, answers }) => {
					await actions.saveAnswers(contactInfo.candidateId, answers.values)
				}
			})

		steps.push({
			label: 'Consent & Submit',
			Component: JobApplicationSubmission,
			statePropName: 'consents',
			onProceed: async ({ contactInfo, consents }) => {
				await actions.submitConsents(contactInfo.candidateId, consents.acknowledgements)
			}
		})
		return steps
	}, [jobInfo.questions.length, jobInfo.documents, actions])

	const onProceedToNextStep = useCallback(
		async ({ currentTarget }) => {
			const stepIndex = steps.findIndex(s => s.statePropName === currentTarget.name)

			logEvent('Progressed to Next Step in Job Application', { progressedToStep: stepIndex })

			await steps[stepIndex].onProceed(injectedState)
		},
		[steps, injectedState]
	)

	const classes = useStyles()
	return (
		<>
			<Typography variant="h5" className={classes.header}>
				Apply Here
			</Typography>
			<Stepper
				orientation="vertical"
				activeStep={injectedState.jobApplicationStepIndex}
				className={classes.applicationForm}
			>
				{steps.map(({ label, Component, statePropName }, index) => (
					<Step
						key={label}
						active={injectedState.jobApplicationStepIndex === index && !injectedState.keepCollapsed}
						completed={
							injectedState.jobApplicationStepIndex > index ||
							(injectedState.jobApplicationStepIndex === index && injectedState.showAsCompleted)
						}
					>
						<StepLabel
							error={!!(injectedState[statePropName] || {}).error}
							StepIconComponent={
								(injectedState[statePropName] || {}).error
									? ErrorIcon
									: (injectedState[statePropName] || {}).inProgress && CircularProgress
							}
							StepIconProps={
								(injectedState[statePropName] || {}).error ? { color: 'error' } : { size: 24 }
							}
						>
							{label}
						</StepLabel>
						<StepContent>
							<Component {...injectedState[statePropName]} />
							{(injectedState[statePropName] || {}).error && (
								<Alert severity="error" className={classes.error}>
									{injectedState[statePropName].error.detail ||
										injectedState[statePropName].error.title ||
										'Request failed'}
								</Alert>
							)}
							<div className={classes.buttonContainer}>
								{index !== 0 && (
									<Button
										onClick={actions.goToPreviousStep}
										disabled={(injectedState[statePropName] || {}).inProgress}
									>
										Back
									</Button>
								)}
								<Button
									name={statePropName}
									variant="contained"
									color="primary"
									disabled={
										!((injectedState[statePropName] || {}).validationSummary || {}).isValid ||
										injectedState[statePropName].inProgress
									}
									startIcon={
										(injectedState[statePropName] || {}).inProgress && (
											<CircularProgress size={15} />
										)
									}
									onClick={onProceedToNextStep}
								>
									{index === steps.length - 1 ? 'Submit' : 'Next'}
								</Button>
							</div>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</>
	)
}
