import { useState, useEffect, useCallback, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Switch, Typography } from '@material-ui/core'

import InterviewInvitationCustomizationToggles from './InterviewInvitationCustomizationToggles'
import InterviewInvitationEditSectionDialog from './InterviewInvitationEditSectionDialog'
import LargeLoader from '../../../../../common/LargeLoader'

import {
	getInvitationEmailConfig,
	getInvitationEmailDefault,
	saveInvitationEmailConfig
} from '../../../../jobs/jobService'
import operations from './interviewInvitationAsyncOperations'
import operationStates from '../../../../../common/operationStates'

const verticalScroll = { 'overflow-y': 'auto' }

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
	sectionEditor: {
		'& .CodeMirror-wrap': {
			height: 400,
			borderColor: '#ddd'
		},
		'& .invalid': {
			borderColor: palette.error.main
		},
		'& .CodeMirror-scroll': verticalScroll,
		'& .MuiTypography-paragraph': { marginBottom: 0 }
	},
	annotationsSwitch: {
		marginLeft: spacing(0.5),
		marginBottom: spacing(1)
	},
	preview: {
		'& .editable-section': {
			'&::after': {
				display: showAnnotations => (showAnnotations ? 'block' : 'none'),
				position: 'absolute',
				inset: '0 -110px 0 0',
				cursor: 'pointer',
				borderTop: '1px gray dotted',
				borderBottom: '1px gray dotted',
				backgroundColor: 'rgba(0,0,0,0.025)',
				transition: 'background-color 400ms',
				textAlign: 'right',
				paddingRight: spacing(1),
				content: "'Click to edit '",
				...typography.caption
			},
			'&:hover::after': {
				backgroundColor: 'rgba(0,0,0,0.075)'
			}
		}
	}
}))

const sectionEditors = ['introduction', 'invitationDetails']

export default function InterviewInvitation({
	jobId,
	updateHeight,
	isActive = true,
	isPreviewDataReady = true
}) {
	const [invitationEmailSettings, setInvitationEmailSettings] = useState()
	const [requestProgress, setRequestProgress] = useState({})
	const [previewHtml, setPreviewHtml] = useState()
	const [editDialogContext, setEditDialogContext] = useState({ open: false })
	const [showAnnotations, toggleAnnotations] = useState(true)

	const [initialLoadError, setInitialLoadingError] = useState()

	const previewDivRef = useRef()

	useEffect(() => {
		if (!isActive || !jobId || !isPreviewDataReady) return
		var isMounted = true

		getInvitationEmailConfig(jobId)
			.then(({ contentHtml, introduction, invitationDetails, ...restSettings }) => {
				if (!isMounted) return

				setPreviewHtml(contentHtml)
				setInvitationEmailSettings({
					...restSettings,
					introduction: { value: introduction, touched: false },
					invitationDetails: { value: invitationDetails, touched: false }
				})
				updateHeight()
			})
			.catch(async e => {
				setInitialLoadingError('Error! Unable to load the invitation settings!')
				console.log(await e.json())
			})
		return () => {
			isMounted = false
		}
	}, [jobId, updateHeight, isActive, isPreviewDataReady])

	useEffect(() => {
		if (!previewDivRef.current) return

		const imgElements = previewDivRef.current.querySelectorAll('img')

		if (imgElements.length === 0) return

		for (let i = 0; i < imgElements.length; i++) {
			imgElements[i].addEventListener('load', updateHeight, { once: true })
		}
	}, [previewHtml, updateHeight])

	/*const isFieldInvalid = useCallback(
		fieldName =>
			invitationEmailSettings[fieldName].value.trim().length === 0 &&
			invitationEmailSettings[fieldName].touched,
		[invitationEmailSettings]
	)*/

	const saveConfig = useCallback(
		async (name, value) => {
			let configToSave = {
				...invitationEmailSettings,
				introduction: invitationEmailSettings.introduction.value,
				invitationDetails: invitationEmailSettings.invitationDetails.value,
				[name]: value
			}

			setRequestProgress(operationsState => ({
				...operationsState,
				operation: operations.saveConfig,
				state: operationStates.inProgress
			}))
			let contentHtml
			try {
				;({ contentHtml } = await saveInvitationEmailConfig(jobId, configToSave))
			} catch (e) {
				setRequestProgress(operationsState => ({
					...operationsState,
					state: operationStates.error,
					message: 'Error saving email settings'
				}))
				console.log('Could not save invitation email configuration', await e.json())
				return
			}

			setPreviewHtml(contentHtml)
			updateHeight()
			setRequestProgress(operationsState => ({
				...operationsState,
				state: operationStates.success
			}))
		},
		[jobId, invitationEmailSettings, updateHeight]
	)

	const handleCustomizationToggleChange = useCallback(
		async (name, isChecked) => {
			setInvitationEmailSettings(config => ({ ...config, [name]: isChecked }))
			await saveConfig(name, isChecked)
		},
		[saveConfig]
	)

	const openEditDialog = useCallback(
		async sectionId => {
			setEditDialogContext({
				open: true,
				sectionId,
				value: invitationEmailSettings[sectionId].value
			})
		},
		[invitationEmailSettings]
	)

	const onClickInsideOfPreview = useCallback(
		({ target, currentTarget }) => {
			if (!showAnnotations) return

			// check whether a click comes from any of the editable sections
			let element = target
			while (element !== currentTarget && !sectionEditors.includes(element.id)) {
				element = element.parentElement
			}

			if (element === currentTarget) return

			openEditDialog(element.id)
		},
		[showAnnotations, openEditDialog]
	)

	const closeDialog = useCallback(
		() => setEditDialogContext(context => ({ ...context, open: false })),
		[]
	)

	const loadSectionDefaults = useCallback(async sectionId => {
		setRequestProgress(operationsState => ({
			...operationsState,
			operation: operations.loadDefaultConfig,
			state: operationStates.inProgress
		}))
		try {
			const defaults = await getInvitationEmailDefault()
			setEditDialogContext(context => ({
				...context,
				value: defaults[sectionId]
			}))
			setRequestProgress(operationsState => ({
				...operationsState,
				operation: operations.loadDefaultConfig,
				state: operationStates.success
			}))
		} catch (e) {
			setRequestProgress(operationsState => ({
				...operationsState,
				operation: operations.loadDefaultConfig,
				state: operationStates.error,
				message: 'Unable to load default text'
			}))
			// Todo: log to AppInsights?
			console.log(await e.json())
		}
	}, [])

	const saveSection = useCallback(
		async (sectionId, valueToSave) => {
			setInvitationEmailSettings(config => ({ ...config, [sectionId]: { value: valueToSave } }))
			await saveConfig(sectionId, valueToSave)
			closeDialog()
		},
		[saveConfig, closeDialog]
	)

	const classes = useStyles(showAnnotations)

	if (!isActive) {
		return null
	}

	return (
		<div>
			<Typography variant="h6" align="center" gutterBottom>
				Customize Your Job Invitation E-mail
			</Typography>
			{previewHtml ? (
				<>
					<label>
						<Typography component="span">Preview</Typography>
						<Switch
							name="annotations"
							checked={showAnnotations}
							onChange={() => toggleAnnotations(isShown => !isShown)}
						/>
						<Typography component="span">Edit</Typography>
					</label>

					<InterviewInvitationCustomizationToggles
						settings={invitationEmailSettings}
						requestProgress={requestProgress}
						onToggleChange={handleCustomizationToggleChange}
					/>

					<div
						ref={previewDivRef}
						className={classes.preview}
						dangerouslySetInnerHTML={{ __html: previewHtml }}
						onClick={onClickInsideOfPreview}
					/>

					<InterviewInvitationEditSectionDialog
						{...editDialogContext}
						requestProgress={requestProgress}
						onSave={saveSection}
						onReset={loadSectionDefaults}
						onCancel={closeDialog}
					/>
				</>
			) : initialLoadError ? (
				<Typography variant="h6" color="error" align="center">
					{initialLoadError}
				</Typography>
			) : (
				<LargeLoader title="Loading interview invitation email..." />
			)}
		</div>
	)
}
