import '../assets/lightened-background-patterns.css'
import { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Link, Typography } from '@material-ui/core'
import clsx from 'clsx'

import JobApplicationContext from './jobApplicationContext'

const useStyles = makeStyles(({ spacing }) => ({
	header: {
		padding: spacing(1, 0),
		minHeight: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative'
	},
	logo: { maxHeight: 100 },
	title: { padding: spacing(3), textAlign: 'center' },
	locationIcon: { verticalAlign: 'text-top' }
}))

export default function JobApplicationHeader() {
	const { jobInfo } = useContext(JobApplicationContext)
	const {
		appearance: {
			companyLogoUrl,
			isCustomLogo,
			customLogoUrl,
			backgroundTextureId,
			backgroundColor
		},
		generalInfo
	} = jobInfo

	const classes = useStyles()
	return (
		<header>
			<div
				className={clsx(
					classes.header,
					backgroundTextureId >= 0 && `lightened-pattern-${backgroundTextureId}`
				)}
				style={{ backgroundColor: backgroundColor || '#fff' }}
			>
				{((companyLogoUrl && !isCustomLogo) || (isCustomLogo && customLogoUrl)) && (
					<img
						alt="Company logo"
						src={isCustomLogo ? customLogoUrl : companyLogoUrl}
						className={classes.logo}
					/>
				)}
			</div>
			<div className={classes.title}>
				<Typography variant="h3" align="center">
					{generalInfo.title}
				</Typography>
				<Typography variant="h6" align="center">
					{generalInfo.companyName}
				</Typography>
				<Typography variant="h6" align="center" color="textSecondary" display="block">
					{generalInfo.location}, {generalInfo.city}, {generalInfo.state} {generalInfo.zipcode}
				</Typography>
				{generalInfo.website && (
					<Link
						variant="h6"
						color="initial"
						href={`https://${generalInfo.website.replace('https://', '')}`}
						target="_blank"
					>
						<strong>{generalInfo.website}</strong>
					</Link>
				)}
			</div>
		</header>
	)
}
