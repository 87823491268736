import { useState, useEffect, useRef } from 'react'

function formatDuration(secs) {
	if (!secs) return '0:00'

	let divisor_for_minutes = secs % (60 * 60)
	let minutes = Math.floor(divisor_for_minutes / 60)

	let divisor_for_seconds = divisor_for_minutes % 60
	let seconds = Math.ceil(divisor_for_seconds)

	return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
}

export default function useTimer(timer, onTimerElapsed) {
	const [secondsRemaining, setSecondsRemaining] = useState(timer.totalSeconds)
	const intervalIdRef = useRef(false)

	useEffect(() => {
		if (intervalIdRef.current && (secondsRemaining === 0 || !timer.start)) {
			clearInterval(intervalIdRef.current)
			intervalIdRef.current = false
			onTimerElapsed(timer.totalSeconds)
		}
	}, [secondsRemaining, timer.totalSeconds, timer.start, onTimerElapsed])

	useEffect(() => {
		if (!timer.totalSeconds) return

		setSecondsRemaining(timer.totalSeconds)

		if (timer.start) {
			intervalIdRef.current = setInterval(() => {
				setSecondsRemaining(secondsRemaining => secondsRemaining - 1)
			}, 1000)
		}

		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current)
				intervalIdRef.current = false
			}
		}
	}, [timer.totalSeconds, timer.start])

	return [
		timer.totalSeconds < 60 ? secondsRemaining : formatDuration(secondsRemaining),
		secondsRemaining
	]
}
