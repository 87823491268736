import { Box, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useMutation } from '@tanstack/react-query'

import StatefulButton from '../../../common/StatefullButton'
import { useAuth } from '../../auth/AuthProvider'
import ActiveSubscription from './ActiveSubscription'
import { reactivateSubscription } from './paymentService'

const Subscription = () => {
	const { currentUser, updateUserStatus } = useAuth()

	const { mutate, isLoading, error } = useMutation({
		mutationFn: reactivateSubscription,
		onSuccess: () => updateUserStatus({ isSubscriptionActive: true })
	})

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h4">Subscription</Typography>
			</Grid>
			<Grid item xs={12}>
				{currentUser.isSubscriptionActive ? (
					<ActiveSubscription />
				) : (
					<>
						<Typography paragraph>
							Your subscription is currently inactive. You can re-activate your subscription by
							clicking the button below.
						</Typography>
						<StatefulButton
							color="primary"
							variant="contained"
							isLoading={isLoading}
							onClick={mutate}
						>
							Re-activate subscription
						</StatefulButton>

						{error && (
							<Box sx={{ mt: 2 }}>
								<Alert severity="error">An error has occurred. Please try again later</Alert>
							</Box>
						)}
					</>
				)}
			</Grid>
		</Grid>
	)
}

export default Subscription
