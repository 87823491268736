import { useState, useReducer, useCallback, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
	Typography,
	Stepper,
	Step,
	StepButton,
	StepLabel,
	Paper,
	Button,
	Box
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import SwipeableViews from 'react-swipeable-views'
import { useQuery } from '@tanstack/react-query'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DoneIcon from '@material-ui/icons/Done'

import operationStates from '../../../../common/operationStates'
import LargeLoader from '../../../../common/LargeLoader'
import { jobVideoTypes } from '../../../../common/outboundVideoTypes'
import { Notify } from '../../../Notify'
import { getJobWithVideoInterviewSettings, updateVideoInterviewDeadline } from '../../jobService'
import EmployerVideos from './video-introduction/EmployerVideos'
import InterviewQuestions from './questions/InterviewQuestions'
import InterviewPreview from './InterviewPreview'
import InterviewDeadline from './InterviewDeadline'
import InterviewInvitation from './invitation-email-customization/InterviewInvitation'

const steps = [
	{ label: 'Engage Your Candidates' },
	{ label: 'Video Questions' },
	{ label: 'Preview' },
	{ label: 'Deadline' },
	{ label: 'Invitation' }
]

const maxQuestionCountReachedExplanation = "You've reached the maximum number of questions"

const getInitialValidationSummary = jobInfo => {
	const summary = {}
	if (jobInfo.totalCompletedInterviews > 0) {
		summary.isReadOnly = true
		summary.explanation =
			'Since there are candidates who have already completed the Haystack interview, questions cannot be changed.'
	} else if (jobInfo.videoQuestions.length > 4) {
		summary.maxQuestionCountReached = true
		summary.explanation = maxQuestionCountReachedExplanation
	}
	return summary
}

const reduce = (state, action) => {
	switch (action.type) {
		case 'setInitialState':
			const { jobInfo } = action
			const { id, companyId, title, slug, employerVideos, videoQuestions, daysToComplete } = jobInfo
			videoQuestions.sort((a, b) => a.order - b.order)
			return {
				jobId: id,
				companyId,
				jobTitle: title,
				jobSlug: slug,
				canProceed: true,
				daysToComplete,
				employerVideos,
				videoInterviewQuestionsConfig: {
					questions: videoQuestions.map(it => ({ ...it, clientId: Symbol() })),
					validationSummary: getInitialValidationSummary(jobInfo)
				}
			}
		case 'addEmployerVideo': {
			return {
				...state,
				employerVideos: [...state.employerVideos, action.video]
			}
		}
		case 'updateEmployerVideo': {
			return {
				...state,
				employerVideos: state.employerVideos.map(v =>
					v.videoType === action.video.videoType ? { ...v, ...action.video } : v
				)
			}
		}
		case 'addQuestion': {
			const questions = state.videoInterviewQuestionsConfig.questions
			const order = questions.length === 0 ? 0 : questions[questions.length - 1].order + 1
			const updatedQuestions = [
				...state.videoInterviewQuestionsConfig.questions,
				action.question ? { ...action.question, order } : { clientId: Symbol(), text: '', order }
			]
			const persistedQuestions = updatedQuestions.filter(it => it.id)
			return {
				...state,
				canProceed: action.question && action.question.id,
				videoInterviewQuestionsConfig: {
					...state.videoInterviewQuestionsConfig,
					questions: updatedQuestions,
					validationSummary: {
						maxQuestionCountReached: persistedQuestions.length > 4,
						explanation: persistedQuestions.length > 4 && maxQuestionCountReachedExplanation
					}
				}
			}
		}
		case 'updateQuestion': {
			const questions = state.videoInterviewQuestionsConfig.questions.map(it =>
				action.id === it.clientId ? { ...it, ...action.changeSet } : it
			)
			const persistedQuestions = questions.filter(it => it.id)
			return {
				...state,
				canProceed: questions.every(it => it.id),
				videoInterviewQuestionsConfig: {
					...state.videoInterviewQuestionsConfig,
					questions,
					validationSummary: {
						maxQuestionCountReached: persistedQuestions.length > 4,
						explanation: persistedQuestions.length > 4 && maxQuestionCountReachedExplanation
					}
				}
			}
		}
		case 'deleteQuestion': {
			const questions = state.videoInterviewQuestionsConfig.questions.filter(
				({ clientId }) => clientId !== action.id
			)
			return {
				...state,
				canProceed: questions.every(it => it.id),
				videoInterviewQuestionsConfig: {
					...state.videoInterviewQuestionsConfig,
					questions,
					validationSummary: {}
				}
			}
		}
		case 'updateQuestionOrder':
			const reordered = [...state.videoInterviewQuestionsConfig.questions]
			const question = reordered.splice(action.oldIndex, 1)[0]
			reordered.splice(action.newIndex, 0, question)
			return {
				...state,
				canProceed: reordered.every(it => it.id),
				videoInterviewQuestionsConfig: {
					...state.videoInterviewQuestionsConfig,
					questions: reordered
				}
			}
		case 'updateDaysToComplete':
			return {
				...state,
				daysToComplete: action.daysToComplete,
				daysToCompletePersistenceStatus: operationStates.inProgress
			}
		case 'setDaysToCompletePersistenceStatus': {
			return { ...state, daysToCompletePersistenceStatus: action.value }
		}
		default:
			return state
	}
}

const useStyles = makeStyles(({ spacing }) => ({
	root: { maxWidth: 1000, margin: `${spacing(5)}px auto ${spacing(2)}px` },
	stepper: { padding: spacing(3) },
	errorMessage: {
		margin: `${spacing(5)}px auto`
	}
}))
const initialState = { videoInterviewQuestionsConfig: { questions: [] } }
export default function VideoInterviewConfigurator({ jobSlug, onEditComplete }) {
	// shared state
	const [state, dispatch] = useReducer(reduce, initialState)

	// component's local state
	const [currentStep, setCurrentStep] = useState(0)

	const { isLoading, isError } = useQuery({
		queryKey: ['jobs', jobSlug],
		enabled: !!jobSlug,
		refetchOnWindowFocus: false,
		queryFn: () => getJobWithVideoInterviewSettings(jobSlug),
		onSuccess: jobInfo => dispatch({ type: 'setInitialState', jobInfo }),
		onError: e => console.log(e)
	})

	const updateHeightRef = useRef()

	const onStepLabelButtonClick = useCallback(
		({ currentTarget }) => setCurrentStep(+currentTarget.value),
		[]
	)

	const isLastStep = currentStep === steps.length - 1

	const onNextButtonClick = useCallback(() => {
		if (isLastStep) onEditComplete()
		else setCurrentStep(stepIndex => stepIndex + 1)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [isLastStep, onEditComplete])

	const updateDaysToComplete = useCallback(
		async daysToComplete => {
			dispatch({ type: 'updateDaysToComplete', daysToComplete })
			try {
				await updateVideoInterviewDeadline(state.jobId, daysToComplete)
				dispatch({ type: 'setDaysToCompletePersistenceStatus', value: operationStates.success })
			} catch (e) {
				// Todo: set error message
				dispatch({ type: 'setDaysToCompletePersistenceStatus', value: operationStates.error })
			}
		},
		[state.jobId]
	)

	const onEmployerVideoAdd = useCallback(video => dispatch({ type: 'addEmployerVideo', video }), [])
	const onEmployerVideoChange = useCallback(
		video => dispatch({ type: 'updateEmployerVideo', video }),
		[]
	)

	const classes = useStyles()

	return isLoading ? (
		<LargeLoader title="Loading Video Interview configuration" />
	) : isError ? (
		<Alert severity="error" className={classes.errorMessage}>
			<AlertTitle>Error loading data</AlertTitle>
			Unable to load job configuration for one-one video interview
		</Alert>
	) : (
		<section className={classes.root}>
			<Notify companyId={state.companyId} />
			<Typography variant="h4">One Way Video Interview Setup</Typography>
			<Typography variant="subtitle2" color="textSecondary" paragraph>
				{state.jobTitle}
			</Typography>
			<Paper className={classes.stepper} variant="outlined">
				<Stepper activeStep={currentStep} nonLinear>
					{steps.map(({ label }, index) => (
						<Step key={index} disabled={index !== currentStep && !state.canProceed}>
							<StepButton value={index} onClick={onStepLabelButtonClick}>
								<StepLabel>{label}</StepLabel>
							</StepButton>
						</Step>
					))}
				</Stepper>
				<SwipeableViews
					index={currentStep}
					animateHeight
					action={actions => (updateHeightRef.current = actions.updateHeight)}
				>
					<EmployerVideos
						companyId={state.companyId}
						jobId={state.jobId}
						employerVideos={state.employerVideos}
						videoTypes={jobVideoTypes}
						description={
							<>
								<Typography variant="h5" gutterBottom>
									Engage your candidates
								</Typography>
								<Typography gutterBottom>
									The candidates you invite to complete the one-way video interview will have access
									to these employer videos. The videos will provide them with valuable information
									about the role, as well as the person they will be reporting to.
								</Typography>
								<Typography gutterBottom>
									During traditional interviews, employers often share more information about the
									position and allow candidates to ask questions. This is the perfect opportunity
									for you to answer all the potential questions that candidates are likely to have
									about the job.
								</Typography>
								<Typography gutterBottom>
									These videos further humanize your company and improve candidate engagement.
									Candidates are far more likely to feel comfortable completing their video
									interview knowing that your company has completed videos as well.
								</Typography>
								<Typography gutterBottom>
									The <strong>"Job Description"</strong> video (below) will be shown to candidates
									before they begin their video interview and <strong>"A day in the life"</strong>{' '}
									and <strong>"Meet your boss"</strong> will be shown after their interview is
									completed.
								</Typography>
							</>
						}
						onVideoAdd={onEmployerVideoAdd}
						onVideoChange={onEmployerVideoChange}
					/>
					<InterviewQuestions
						dispatch={dispatch}
						jobSlug={state.jobSlug}
						jobId={state.jobId}
						updateHeight={updateHeightRef.current}
						{...state.videoInterviewQuestionsConfig}
					/>
					<InterviewPreview {...state.videoInterviewQuestionsConfig} />
					<InterviewDeadline
						daysToComplete={state.daysToComplete}
						updateDaysToComplete={updateDaysToComplete}
					/>
					<InterviewInvitation
						jobId={state.jobId}
						updateHeight={updateHeightRef.current}
						isActive={isLastStep}
						isPreviewDataReady={
							state.daysToCompletePersistenceStatus !== operationStates.inProgress
						}
					/>
				</SwipeableViews>
				<Box
					sx={{
						mt: 1,
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row-reverse'
					}}
				>
					<Button
						variant="contained"
						color="primary"
						disabled={!state.canProceed}
						startIcon={currentStep === steps.length - 1 && <DoneIcon />}
						endIcon={currentStep < steps.length - 1 && <ChevronRightIcon />}
						onClick={onNextButtonClick}
					>
						{currentStep < steps.length - 1 ? 'Next' : 'Finish'}
					</Button>
					{currentStep !== 0 && (
						<Button
							variant="outlined"
							startIcon={<ChevronLeftIcon />}
							onClick={() => {
								setCurrentStep(currentStep - 1)
								window.scrollTo({ top: 0, behavior: 'smooth' })
							}}
						>
							Back
						</Button>
					)}
				</Box>
			</Paper>
		</section>
	)
}
