import { memo, useEffect, useCallback, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import LogoSetup from './LogoSetup'
import ColorPicker from './ColorPicker'
import PatternPicker from './PatternPicker'

import JobSetupContext from '../jobSetupContext'

const useStyles = makeStyles(({ spacing }) => ({
	root: { padding: spacing(1, 1) } // Grid's negative margin workaround
}))

export default memo(function JobPostingCustomization({
	companyLogoUrl,
	customLogoUrl,
	isCustomLogo,
	backgroundColor,
	backgroundTextureId,
	updateHeight,
	isActiveView
}) {
	const actions = useContext(JobSetupContext)

	const logoSet = customLogoUrl || companyLogoUrl
	useEffect(() => {
		updateHeight && updateHeight()
	}, [logoSet, updateHeight])

	const setBackgroundTexture = useCallback(
		backgroundTextureId => actions.updateAppearance({ backgroundTextureId }),
		[actions]
	)
	const setBackgroundColor = useCallback(
		backgroundColor => actions.updateAppearance({ backgroundColor }),
		[actions]
	)

	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item sm={12}>
					<LogoSetup
						customLogoUrl={customLogoUrl}
						companyLogoUrl={companyLogoUrl}
						isCustomLogo={isCustomLogo}
					/>
				</Grid>
				<Grid item sm={12}>
					<Typography variant="h6">Background</Typography>
					<Grid container spacing={3}>
						<Grid item sm={6}>
							<ColorPicker color={backgroundColor} onColorChange={setBackgroundColor} />
						</Grid>
						<Grid item sm={6}>
							<PatternPicker
								onTextureChange={setBackgroundTexture}
								patternIndex={backgroundTextureId}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
})
