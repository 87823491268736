import { Link, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Tabs, Tab, Chip, Typography } from '@material-ui/core'

import { statusesToMarkAsNew } from './applicantDashboardStore'
import routes from '../../../routes'

const useStyles = makeStyles(({ spacing }) => ({
	tabTitle: { display: 'flex', alignItems: 'center' },
	tabSubtitle: { textTransform: 'none', lineHeight: 0.8 },
	badge: { height: 17 },
	smallerChipLabel: { paddingLeft: 6, paddingRight: 6 }
}))

export default function JobApplicationStages({ currentStageIndex, stages, ...state }) {
	const { slug } = useParams()

	const classes = useStyles()
	return (
		<AppBar position="static" elevation={2} color="default">
			<Tabs
				variant="fullWidth"
				textColor="primary"
				indicatorColor="primary"
				value={currentStageIndex}
			>
				{stages.map((stage, index) => {
					const newCount = state[stage.name].candidates.filter(c =>
						statusesToMarkAsNew.includes(c.status)
					).length
					return (
						<Tab
							key={index}
							component={Link}
							to={routes.jobApplicants.getLink(slug, stage.name)}
							label={
								<>
									<div className={classes.tabTitle}>
										{stage.displayText}&nbsp;
										{newCount !== 0 && (
											<Chip
												component="span"
												size="small"
												color="secondary"
												className={classes.badge}
												classes={{ labelSmall: classes.smallerChipLabel }}
												label={newCount}
											/>
										)}
									</div>
									<Typography
										variant="caption"
										color="textSecondary"
										display="block"
										className={classes.tabSubtitle}
									>
										Total: {state[stage.name].candidates.length}
									</Typography>
								</>
							}
						/>
					)
				})}
			</Tabs>
		</AppBar>
	)
}
