import { useCallback, useContext } from 'react'

import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@material-ui/core'
import JobApplicationContext from './jobApplicationContext'

export default function JobApplicationQuestions({ values: answers = [] }) {
	const {
		jobInfo: { questions },
		actions
	} = useContext(JobApplicationContext)

	const onTextValueChange = useCallback(
		({ target }) => actions.updateAnswer(+target.name, target.value),
		[actions]
	)

	const onSingleValueChange = useCallback(
		({ target }) => actions.updateAnswer(+target.name, +target.value),
		[actions]
	)

	const onMultipleValueChange = useCallback(
		({ target }) => {
			const questionId = +target.name
			const changedChoiceId = +target.value

			const answerChoiceIds = target.checked
				? [...(answers[questionId] || []), changedChoiceId]
				: answers[questionId].filter(a => a !== changedChoiceId)

			actions.updateAnswer(questionId, answerChoiceIds)
		},
		[actions, answers]
	)

	return (
		<section>
			{questions.map(({ id: questionId, text, questionType, answerChoices }) => (
				<div key={questionId} style={{ marginTop: '2em' }}>
					<Typography>
						<strong>{text}</strong>
					</Typography>
					{questionType === 'single' ? (
						<RadioGroup
							row
							onChange={onSingleValueChange}
							name={'' + questionId}
							value={answers[questionId] || ''}
						>
							{answerChoices.map(({ id, text }) => (
								<FormControlLabel key={id} value={id} control={<Radio />} label={text} />
							))}
						</RadioGroup>
					) : questionType === 'multiple' ? (
						<FormGroup row>
							{answerChoices.map(({ id, text }, index) => (
								<FormControlLabel
									key={id}
									control={<Checkbox />}
									label={text}
									name={'' + questionId}
									value={id}
									checked={!!(answers[questionId] && answers[questionId].includes(id))}
									onChange={onMultipleValueChange}
								/>
							))}
						</FormGroup>
					) : questionType === 'short' ? (
						<TextField
							variant="outlined"
							fullWidth
							name={'' + questionId}
							value={answers[questionId] || ''}
							onChange={onTextValueChange}
						/>
					) : (
						questionType === 'long' && (
							<TextField
								variant="outlined"
								rows={3}
								multiline
								fullWidth
								name={'' + questionId}
								value={answers[questionId] || ''}
								onChange={onTextValueChange}
							/>
						)
					)}
				</div>
			))}
		</section>
	)
}
