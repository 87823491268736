import { useState, useEffect, useCallback } from 'react'

import { Alert, AlertTitle } from '@material-ui/lab'

import JobList from './job-list/JobList'
import LargeLoader from '../../common/LargeLoader'
import WelcomeMessage from './WelcomeMessage'
import { getJobs } from './jobService'

export default function Jobs() {
	const [jobs, setJobs] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		var isMounted = true
		getJobs()
			.then(jobs => {
				if (isMounted) {
					setJobs(jobs)
				}
			})
			.catch(error => {
				console.log(error)
				setError(true)
			})
		return () => {
			isMounted = false
		}
	}, [])

	const toggleJobStatus = useCallback(id => {
		setJobs(jobs =>
			jobs.map(job =>
				id === job.id ? { ...job, status: job.status === 'open' ? 'close' : 'open' } : job
			)
		)
	}, [])

	if (jobs) {
		return error ? (
			<Alert severity="error" style={{ margin: '50px auto' }}>
				<AlertTitle>Error</AlertTitle>
				Unable to load jobs data
			</Alert>
		) : jobs.length ? (
			<JobList jobs={jobs} toggleJobStatus={toggleJobStatus} />
		) : (
			<WelcomeMessage />
		)
	} else {
		return <LargeLoader title="Loading jobs..." />
	}
}
