import { LinearProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		margin: spacing(2),
		textAlign: 'center'
	}
}))

export default function UploadProgress({ uploadProgressPercentage }) {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography variant="subtitle2">Please don't close or reload the page...</Typography>
			<LinearProgress
				variant="determinate"
				value={uploadProgressPercentage}
				size={10}
				color="secondary"
				thickness={6}
			/>
		</div>
	)
}
