import { useCallback, useEffect, useState } from 'react'

import { DialogContent, List, Switch } from '@material-ui/core'
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import ReportIcon from '@material-ui/icons/BarChart'
import { getNotifications, createNotification, removeNotification } from '../jobService'
import CustomDialog from '../../../common/CustomDialog'

export default function NotificationSettingsDialog({ open, job = {}, onClose }) {
	const [notifications, setNotifications] = useState()

	useEffect(() => {
		if (!open) return

		//if (notifications) return

		// Todo: error handling
		getNotifications(job.id).then(setNotifications).catch(console.log)
	}, [job.id, open])

	const updateSetting = useCallback(
		async ({ target }) => {
			if (target.checked) {
				const newNotification = await createNotification(job.id)
				setNotifications(notifications => [...notifications, newNotification])
			} else {
				const notificationId = +target.id
				await removeNotification(notificationId)
				setNotifications(notifications => notifications.filter(n => n.id !== notificationId))
			}
		},
		[job.id]
	)
	return (
		<CustomDialog open={open} onClose={onClose} title="Notifications">
			<DialogContent>
				<Alert variant="standard" severity="info">
					Notifications will only be sent on days/weeks when there is new account activity. These
					settings are turned off automatically when this job is closed.
				</Alert>
				{notifications ? (
					<List>
						<ListItem>
							<ListItemIcon>
								<ReportIcon />
							</ListItemIcon>
							<ListItemText
								id="daily-digest"
								primary="Daily Digest"
								secondary="Email me daily summary of new applicants and new completed video interviews"
							/>
							<ListItemSecondaryAction>
								<Switch
									id={notifications.length > 0 && notifications[0].id}
									edge="end"
									name="daily_digest"
									onChange={updateSetting}
									checked={notifications.length > 0}
									inputProps={{ 'aria-labelledby': 'daily-digest' }}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					</List>
				) : (
					'Loading'
				)}
			</DialogContent>
		</CustomDialog>
	)
}
