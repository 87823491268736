import React, { useContext, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import { VideoInterviewContext } from '../InterviewApp'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	videoContainer: {
		maxWidth: 640,
		margin: 0,
		minHeight: 300,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	video: {
		width: '100%',
		height: 'auto',
		transform: 'rotateY(180deg)',
		display: initializing => (initializing ? 'none' : 'block'),
		[theme.breakpoints.down('xs')]: { maxHeight: 300, backgroundColor: 'black' }
	}
}))

export default function Video({ handleUploadSlice, record, onCameraReady }) {
	const [{ micDevice, vidDevice }] = useContext(VideoInterviewContext)
	const [initializing, setInitializing] = useState(true)

	const classes = useStyles(initializing)

	const videoRef = useRef(null)
	const recorder = useRef(false)
	const sliceIndex = useRef(0)

	useEffect(() => {
		const videoEl = videoRef.current

		navigator.mediaDevices
			.getUserMedia({
				audio: micDevice,
				video: {
					...vidDevice,
					width: { ideal: 640 },
					height: { ideal: 480 },
					frameRate: { ideal: 30 }
				}
			})
			.then(stream => {
				videoEl.srcObject = stream
				recorder.current = new MediaRecorder(stream, {
					audioBitsPerSecond: 128000,
					videoBitsPerSecond: 1250000
				})
			})
			.catch(error => {
				// TODO: show error 'getting access to media devices'
			})

		return () => {
			if (recorder.current) {
				recorder.current.stream &&
					recorder.current.stream.getTracks().forEach(track => track.stop())

				if (videoEl) {
					videoEl.srcObject = null
				}
			}
		}
	}, [micDevice, vidDevice, onCameraReady])

	useEffect(() => {
		const onDataAvailable = e => {
			if (e.data && e.data.size > 0) {
				handleUploadSlice(e.data, sliceIndex.current++, recorder.current.state === 'inactive')
			}
		}

		recorder.current && (recorder.current.ondataavailable = onDataAvailable)

		return () => {
			recorder.current && recorder.current.removeEventListener('dataavailable', onDataAvailable)
		}
	}, [handleUploadSlice])

	useEffect(() => {
		if (!recorder.current) return
		if (record) {
			sliceIndex.current = 0
			recorder.current.start(1000)
		} else if (recorder.current.state !== 'inactive') {
			recorder.current.stop()
		}
	}, [record])

	return (
		<div className={classes.videoContainer}>
			{initializing && <Typography variant="h6">Turning camera on...</Typography>}
			<video
				id="video-interview"
				ref={videoRef}
				className={classes.video}
				playsInline
				muted
				autoPlay
				onLoadedData={() => {
					setInitializing(false)
					onCameraReady()
				}}
			/>
		</div>
	)
}

Video.propTypes = {
	handleUploadSlice: PropTypes.func.isRequired,
	record: PropTypes.bool
}
