import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Paper, DialogContent } from '@material-ui/core'

import VideoPlusIcon from 'mdi-material-ui/VideoPlus'
import CustomDialog from '../../../common/CustomDialog'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		margin: `${spacing(4)}px auto`,
		padding: spacing(4),
		maxWidth: 900
	},
	buttonContainer: {
		marginTop: spacing(3),
		display: 'flex',
		justifyContent: 'space-between'
	},
	dialogToggleButton: { marginBottom: spacing(2) }
}))

export default function VideoInterviewSetupWelcomeMessage({ onBack, onProceed, onSkip }) {
	const [benefitsDialogIsOpen, toggleBenefitsDialog] = useState(false)
	const classes = useStyles()
	return (
		<Paper className={classes.root}>
			<Typography variant="h4" align="center" paragraph>
				One Way Video Interview Questions
			</Typography>
			<Typography paragraph>
				Send your favorite candidates an invitation to do a Haystack video interview. You pick the
				questions in advance (on the next page). The invitation has a link and the candidate can do
				the interview whenever it’s convenient for them. Questions pop-up on their screen and
				Haystack records them while they answer.
			</Typography>
			<Typography paragraph>
				Send out hundreds of invitations if you like. Later, you can quickly watch and evaluate
				candidate responses whenever it’s convenient for you.
			</Typography>

			<Button
				color="secondary"
				className={classes.dialogToggleButton}
				onClick={() => toggleBenefitsDialog(true)}
			>
				What value do I get from that?
			</Button>
			<CustomDialog open={benefitsDialogIsOpen} onClose={() => toggleBenefitsDialog(false)}>
				<DialogContent>
					<Typography variant="h6" gutterBottom>
						Save Time & Money
					</Typography>
					<Typography paragraph>
						You’ll be able to evaluate up to about 10 candidates in about the same time it takes to
						do one 30-minute phone screening. This often shortens the traditional hiring cycle by
						days or weeks. Since it’s automated, there are no more scheduling conflicts, back and
						forth scheduling emails, or no-shows. Stop wasting time on candidates who you can tell
						early on aren’t a good fit for the job. Just move on to the next candidate and they will
						have no idea that you only watched 30 seconds of their video. Try doing that in a live
						(phone, video, or in-person) interview without feeling like a jerk and potentially
						damaging your company’s reputation. Stop wasting time on candidates who aren’t serious
						about the job. They often won’t complete the Haystack Interview once they receive the
						invitation.
					</Typography>
					<Typography variant="h6" gutterBottom>
						Make Better Decisions
					</Typography>
					<Typography paragraph>
						With video, you can see the candidates to better evaluate their communication skills,
						attitude, and personality. Haystack eliminates interviewer inconsistencies and biases
						that influence interviews. Make truly Apples to Apples comparison because Haystack asks
						the exact same interview questions, in exactly the same way, every time. Re-watch videos
						if you are not sure or you are comparing candidates. Share videos with colleagues to get
						their feedback.
					</Typography>
					<Typography variant="h6" gutterBottom>
						Better Results
					</Typography>
					<Typography paragraph>
						The time savings allows you to evaluate more candidates than you normally would. You’re
						more likely to find a “diamond in the rough” by giving people with weaker resumes a
						chance to interview. A shortened hiring cycle allows you to make offers faster to the
						best candidates and gives you a competitive edge for the best candidates over other
						companies that are hiring. At minimum, it decreases the chance that candidates have
						already accepted another offer by the time you decide to hire them.
					</Typography>
					<Typography variant="h6" gutterBottom>
						Maintain Work Productivity
					</Typography>
					<Typography paragraph>
						Pause your evaluations to deal with a work issue that just came up, and resume once it’s
						resolved. Or evaluate candidates in the evening instead of allowing hiring to interfere
						with your productivity during a busy workday.
					</Typography>
					<Typography variant="h6" gutterBottom>
						Positive Candidate Experience
					</Typography>
					<Typography paragraph>
						They can complete the interview when and where it’s convenient for while having a “tech
						savvy” early impression of your company.
					</Typography>
				</DialogContent>
			</CustomDialog>

			<Typography paragraph>Now, let’s set up your video interview questions!</Typography>
			<div className={classes.buttonContainer}>
				<Button size="large" onClick={onBack}>
					Back
				</Button>
				<Button
					size="large"
					variant="contained"
					color="primary"
					startIcon={<VideoPlusIcon />}
					onClick={onProceed}
				>
					Setup Video Interview
				</Button>
				<Button size="large" onClick={onSkip}>
					Skip For Now
				</Button>
			</div>
		</Paper>
	)
}
