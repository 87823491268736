import { useHistory, useParams } from 'react-router-dom'
import JobLinksEditor from './JobLinksEditor'
import routes from '../../../routes'

export default function JobLinks() {
	const { slug } = useParams()
	const history = useHistory()

	return (
		<JobLinksEditor jobSlug={slug} onEditComplete={() => history.push(routes.jobs.getLink())} />
	)
}
