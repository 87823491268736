import { Box, Grid, Typography } from '@material-ui/core/'

const Greetings = ({ header, children }) => (
	<Box component="header" sx={{ px: 2, py: 4 }}>
		<Grid container justifyContent="center">
			<Grid item sm={12} md={8}>
				<Typography variant="h2">{header}</Typography>
			</Grid>
			<Grid item sm={12} md={8}>
				{children}
			</Grid>
		</Grid>
	</Box>
)

export default Greetings
