import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const Footer = () => (
	<AppBar position="static">
		<Toolbar>
			<Typography variant="caption" color="inherit">
				© {new Date().getFullYear()} Haystack All Rights Reserved
			</Typography>
		</Toolbar>
	</AppBar>
)

export default Footer
