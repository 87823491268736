export const jobVideoTypes = {
	jobPosition: {
		name: 'jobPosition',
		title: 'Job Description',
		description: 'Share a little more information about the position'
	},
	workDay: {
		name: 'workDay',
		title: 'A Day in the Life',
		description: 'Describe for your candidates what a typical day would look like for this role'
	},
	meetYourBoss: {
		name: 'meetYourBoss',
		title: 'Meet the Boss',
		description: 'Introduce the person candidates will report to if hired'
	}
}

export const companyVideoTypes = {
	companyInfo: {
		name: 'companyInfo',
		title: 'About our Company',
		description: 'Company history, products/services, future plans, etc.'
	},
	workWithUs: {
		name: 'workWithUs',
		title: 'Why work with us',
		description: 'Make your pitch: Mission, culture, benefits, etc.'
	}
}
