import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		margin: `${spacing(4)}px auto`,
		maxWidth: 900
	}
}))

export default function Error() {
	const classes = useStyles()
	return (
		<Alert severity="error" className={classes.root}>
			<AlertTitle>Oops!</AlertTitle>
			We were unable to complete your request. Please try again, or contact the Haystack support
			team.
		</Alert>
	)
}
