import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
	iframeEmbedded: {
		width: '100%',
		aspectRatio: '16/9',
		border: 'none'
	}
}))

const EmbeddedVideoPlayer = ({ sourceVideo }) => {
	const classes = useStyles()

	return (
		<iframe
			className={classes.iframeEmbedded}
			src={sourceVideo}
			title="Embedded video"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			allowFullScreen
		/>
	)
}

export default EmbeddedVideoPlayer
