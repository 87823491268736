import { Redirect, Route, Switch } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import Header from './Header'
import Footer from './Footer'
import LargeLoader from '../common/LargeLoader'

import { useAuth } from './auth/AuthProvider'
import withAppInsightsTracking from '../common/telemetry/appInsigntsService'
import routes, { publicOnlyRoutes, publicRoutes, securedRoutes } from './routes'
import Registration from './registration/Registration'
import { getTrialWillEndMessage } from './bannerMessages'

const useStyles = makeStyles({
	root: {
		display: 'flex',
		minHeight: '100dvh'
	},
	main: {
		display: 'flex',
		flex: 1,
		alignItems: 'flex-start',
		background: 'url(bg-smoke-scaled.jpg) no-repeat top center/100%'
	},
	appInsightsWrapper: {
		margin: '0 auto'
	},
	alert: {
		justifyContent: 'center',
		fontSize: 'large'
	}
})

function EmployerApp() {
	const { currentUser, initializing } = useAuth()

	const classes = useStyles()

	return (
		<Grid container direction="column" alignItems="stretch" className={classes.root}>
			<Grid item>
				<Header isRegistrationComplete={currentUser?.isRegistrationComplete} />
				{currentUser?.trialWillEnd && !currentUser?.hasPaymentMethod && (
					<Alert severity="warning" className={classes.alert}>
						{getTrialWillEndMessage(currentUser.trialEndDate)}
					</Alert>
				)}
			</Grid>

			<Grid item className={classes.main}>
				{initializing ? (
					<LargeLoader title="Authenticating..." />
				) : (
					<Switch>
						{!currentUser &&
							publicOnlyRoutes.map(({ component: Component, path }) => (
								<Route key={path} path={path}>
									<Component />
								</Route>
							))}

						{publicRoutes.map(({ component: Component, path }) => (
							<Route key={path} path={path}>
								<Component />
							</Route>
						))}

						{currentUser && !currentUser.isRegistrationComplete
							? [
									<Route key="registration" path="/registration">
										<Registration />
									</Route>,
									<Redirect key="registration-redirect" to="/registration" />
							  ]
							: currentUser &&
							  !currentUser.isSubscriptionActive && [
									<Route key="profile" path={routes.profile.path}>
										<routes.profile.component />
									</Route>,
									<Redirect key="profile-redirect" to={routes.profile.path} />
							  ]}

						{currentUser?.isRegistrationComplete &&
							currentUser.isSubscriptionActive &&
							securedRoutes.map(({ component: Component, exact, path }) => (
								<Route key={path} path={path} exact={exact}>
									<Component />
								</Route>
							))}
						<Redirect to={currentUser ? routes.jobs.path : routes.login.path} />
					</Switch>
				)}
			</Grid>

			<Grid item>
				<Footer />
			</Grid>
		</Grid>
	)
}

export default withAppInsightsTracking(EmployerApp)
