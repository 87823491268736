import { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
	videoContainer: {
		maxWidth: 640,
		margin: 0,
		minHeight: 360,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	video: {
		maxHeight: 360,
		maxWidth: 640,
		transform: 'rotateY(180deg)',
		display: initializing => (initializing ? 'none' : 'block'),
		[theme.breakpoints.down('xs')]: { maxHeight: 360, backgroundColor: 'black' }
	}
}))

const VideoFeed = ({ camDevice, micDevice, isRecording, onInitialized, onSliceRecorded }) => {
	const [initializingDevices, setInitializingDevices] = useState(true)
	const [errorHasOccurred, setErrorHasOccurred] = useState(false)

	const videoRef = useRef(null)
	const recorder = useRef(false)

	const sliceIndex = useRef(0)

	useEffect(() => {
		if (!(camDevice && micDevice)) return
		const onDataAvailable = e => {
			if (e.data && e.data.size > 0) {
				onSliceRecorded(e.data, sliceIndex.current++, recorder.current.state === 'inactive')
			}
		}

		const videoElement = videoRef.current

		navigator.mediaDevices
			.getUserMedia({
				audio: micDevice,
				video: {
					deviceId: camDevice,
					width: { ideal: 640 },
					height: { ideal: 480 },
					frameRate: { ideal: 30 }
				}
			})
			.then(stream => {
				videoElement.srcObject = stream
				recorder.current = new MediaRecorder(stream, {
					audioBitsPerSecond: 128000,
					videoBitsPerSecond: 1250000
				})

				recorder.current.addEventListener('dataavailable', onDataAvailable)
			})
			.catch(err => {
				console.log(err)
				setErrorHasOccurred(true)
			})

		return () => {
			if (recorder.current) {
				recorder.current.removeEventListener('dataavailable', onDataAvailable)
				recorder.current.stream.getTracks().forEach(track => track.stop())
				videoElement.srcObject = null
			}
		}
	}, [camDevice, micDevice, onSliceRecorded])

	useEffect(() => {
		if (!recorder.current) return
		if (isRecording) {
			sliceIndex.current = 0
			recorder.current.start(1000)
		} else {
			if (recorder.current.state !== 'inactive') {
				recorder.current.stop()
			}
		}
	}, [isRecording])

	const classes = useStyles(initializingDevices)
	return (
		<div>
			<div className={classes.videoContainer}>
				{initializingDevices && <Typography variant="h6">Turning camera on...</Typography>}
				<video
					id="video-interview"
					ref={videoRef}
					className={classes.video}
					playsInline
					muted
					autoPlay
					onLoadedData={e => {
						setInitializingDevices(false)
						onInitialized(e)
					}}
				/>
			</div>
			{errorHasOccurred && (
				<Alert severity="error">
					An unexpected error has occurred when playing the video feed. Please try reloading the
					page, and if the error persists, contact support.
				</Alert>
			)}
		</div>
	)
}

export default VideoFeed
