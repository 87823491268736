import ThumbUp from '@material-ui/icons/ThumbUp'
import ThumbDown from '@material-ui/icons/ThumbDown'
import QuestionableIcon from 'mdi-material-ui/Help'
import RedoInterviewIcon from 'mdi-material-ui/RestartAlert'
import SendEmailIcon from 'mdi-material-ui/EmailSendOutline'
import UndoIcon from '@material-ui/icons/Undo'

import AppliedStageApplicants from './applicants-per-stage/AppliedStageApplicants'
import VideoInterviewStageApplicants from './applicants-per-stage/VideoInterviewStageApplicants'
import FaceToFaceStageApplicants from './applicants-per-stage/FaceToFaceStageApplicants'
import HiredStageApplicants from './applicants-per-stage/HiredStageApplicants'
import RejectedStageApplicants from './applicants-per-stage/RejectedStageApplicants'

import { stageNames, stageDisplayNames } from './stageConstants'

const getStages = (actions, isVideoInterviewConfigured) => {
	const rejectedAction = {
		tooltip: 'Move candidate to Rejected Tab',
		icon: ThumbDown,
		onClick: (e, candidate) => actions.rejectCandidate(candidate)
	}
	return [
		{
			displayText: stageDisplayNames[stageNames.applied],
			name: stageNames.applied,
			Component: AppliedStageApplicants,
			candidateActions: [
				{
					tooltip: isVideoInterviewConfigured
						? 'Advance candidate and invite them to do the one-way video interview'
						: 'Advance candidate to face to face interview',
					icon: () => <ThumbUp color="secondary" />,
					onClick: (e, candidate) => {
						isVideoInterviewConfigured
							? actions.advanceCandidate(candidate)
							: actions.showVideoInterviewNotSetupWarningDialog(candidate)
					}
				},
				{
					tooltip: 'Mark candidate as questionable, but leave them at this stage',
					icon: QuestionableIcon,
					onClick: (e, applicant) => actions.toggleCandidateAcknowledgement(applicant)
				},
				rejectedAction
			]
		},
		{
			displayText: stageDisplayNames[stageNames.video],
			name: stageNames.video,
			Component: VideoInterviewStageApplicants,
			candidateActions: [
				candidate => ({
					tooltip: 'Re-do: DELETE videos and re-send interview invitation',
					icon: () => <RedoInterviewIcon color="error" />,
					onClick: (e, candidate) =>
						actions.showConfirmationDialog({
							title: 'Redo Video Interview?',
							description:
								'This action will remove existing videos and re-send the interview invite',
							onConfirm: () => actions.reInviteCandidateToInterview(candidate)
						}),
					hidden: !candidate.interviewCompletedDateTime
				}),
				candidate => ({
					tooltip: 'Re-send invite to interview',
					icon: () => <SendEmailIcon color="primary" />,
					onClick: (e, candidate) =>
						actions.showConfirmationDialog({
							title: 'Re-send Video Interview Invite?',
							description: 'The candidate will receive a new video interview invite email',
							onConfirm: () => actions.reInviteCandidateToInterview(candidate)
						}),
					hidden: !!candidate.interviewCompletedDateTime
				}),
				candidate => ({
					tooltip: 'Advance candidate to face to face interview',
					icon: () => (
						<ThumbUp color={!candidate.interviewCompletedDateTime ? 'disabled' : 'secondary'} />
					),
					onClick: (e, candidate) => actions.advanceCandidate(candidate),
					disabled: !candidate.interviewCompletedDateTime
				}),
				candidate => ({
					tooltip: 'Mark candidate as viewed, but leave them here',
					icon: QuestionableIcon,
					onClick: (e, candidate) => actions.toggleCandidateAcknowledgement(candidate),
					disabled: !candidate.interviewCompletedDateTime
				}),
				rejectedAction
			]
		},
		{
			displayText: stageDisplayNames[stageNames.face],
			name: stageNames.face,
			Component: FaceToFaceStageApplicants,
			candidateActions: [
				{
					tooltip: 'Undo and put applicant back',
					icon: UndoIcon,
					onClick: (e, candidate) => actions.undoStageTransition([candidate])
				},
				{
					tooltip: 'Advance candidate',
					icon: () => <ThumbUp color="secondary" />,
					onClick: (e, candidate) => actions.advanceCandidate(candidate)
				},
				{
					tooltip: 'Mark candidate as questionable',
					icon: QuestionableIcon,
					onClick: (e, applicant) => actions.toggleCandidateAcknowledgement(applicant)
				},
				rejectedAction
			]
		},
		{
			displayText: stageDisplayNames[stageNames.hired],
			name: stageNames.hired,
			Component: HiredStageApplicants,
			candidateActions: [
				{
					tooltip: 'Undo hiring and put applicant back',
					icon: UndoIcon,
					onClick: (e, candidate) => actions.undoStageTransition([candidate])
				},
				rejectedAction
			]
		},
		{
			displayText: stageDisplayNames[stageNames.rejected],
			name: stageNames.rejected,
			Component: RejectedStageApplicants,
			candidateActions: [
				{
					tooltip: 'Undo rejection and put applicant back',
					icon: UndoIcon,
					onClick: (e, candidates) =>
						// if options.selection is true, MaterialTable always invokes the callback with an array, but the same actions are also used in applicant details dialog which passes a single applicant
						actions.undoStageTransition(Array.isArray(candidates) ? candidates : [candidates])
				},
				{
					tooltip: 'Send Rejection',
					icon: SendEmailIcon,
					onClick: (e, candidates) => {
						// if options.selection is true, MaterialTable always invokes the callback with an array, but the same actions are also used in applicant details dialog which passes a single applicant
						actions.showRejectionEmailDialog(
							Array.isArray(candidates) ? candidates.map(it => it.id) : [candidates.id]
						)
					}
				}
			]
		}
	]
}

export default getStages
