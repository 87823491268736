import { Redirect, useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { Box } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { useAuth } from './AuthProvider'
import LargeLoader from '../../common/LargeLoader'
import PasswordResetFinalizer from './PasswordResetFinalizer'
import routes from '../routes'
import EmailConfirmationError from './EmailConfirmationError'

function EmailConfirmation() {
	const { currentUser, applyConfirmationCode, refreshUser, verifyPasswordResetCode } = useAuth()
	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)

	const mode = queryParams.get('mode')
	const oobCode = queryParams.get('oobCode')

	const { isLoading, error } = useQuery({
		queryKey: ['confirm'],
		retry: 2,
		refetchOnWindowFocus: false,
		enabled: !!oobCode && !!mode,
		queryFn: async () => {
			switch (mode) {
				case 'verifyEmail':
					if (currentUser?.emailVerified) return {}
					await applyConfirmationCode(oobCode)
					if (currentUser) await refreshUser()
					break
				case 'resetPassword':
					await verifyPasswordResetCode(oobCode)
					break
				default:
					throw new Error('Invalid link')
			}
			return {} // queryFn must return something
		}
	})

	return (
		<Box sx={{ mx: 'auto', pt: 5, maxWidth: 1000 }}>
			{isLoading && oobCode && mode ? (
				<LargeLoader title="Verifying..." />
			) : !oobCode || !mode ? (
				<Alert severity="error">
					<AlertTitle>Invalid link</AlertTitle>
					Something is wrong with the confirmation link. If you have copied it, please make sure you
					have copied the whole link.
				</Alert>
			) : error ? (
				<EmailConfirmationError errorMessage={error.message} />
			) : mode === 'verifyEmail' ? (
				<Redirect to={routes.registration.path} />
			) : mode === 'resetPassword' ? (
				<PasswordResetFinalizer code={oobCode} />
			) : (
				<Redirect to="/" />
			)}
		</Box>
	)
}

export default EmailConfirmation
