import {useContext} from "react"

import {makeStyles} from '@material-ui/core/styles'
import {Alert, AlertTitle} from '@material-ui/lab'
import JobApplicationContext from "./jobApplicationContext"

const useStyles = makeStyles(({spacing}) => ({
	root: { padding: spacing(0, 3, 3) },
	emailInfo: { marginTop: spacing(2) }
}))

export default function JobApplicationSubmissionStatus({ showEmailInfo }) {
	const { jobInfo } = useContext(JobApplicationContext)
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Alert severity="success">
				<AlertTitle>Thank you for applying!</AlertTitle>
				We will review your application soon.
			</Alert>
			{showEmailInfo && <Alert severity="info" variant="filled" className={classes.emailInfo}>
				<AlertTitle>Important!</AlertTitle>
				We just sent you a confirmation from{' '}
				<strong>
					"{jobInfo.generalInfo.companyName} {'<noreply@haystackpro.com>'}."
				</strong>{' '}
				If it’s not in your inbox, please check your spam folder and mark the email as{' '}
				<strong>
					<u>not</u>
				</strong>{' '}
				junk so our future emails go to your primary inbox. We’ll be sending employment updates
				from this address, including interview invitations for this position!
			</Alert>}
		</div>
	)
}
