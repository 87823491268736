import { useCallback } from 'react'

import { Box, MenuItem, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const InputDeviceSelector = ({
	inputDevices = [],
	deviceId,
	onSelectDevice,
	disable,
	tooltip,
	label,
	...rest
}) => {
	const handleInputDeviceChange = useCallback(
		e => {
			onSelectDevice(e.target.value)
		},
		[onSelectDevice]
	)

	return (
		<Box {...rest}>
			<TextField
				label={label}
				value={deviceId || ''}
				select
				disabled={inputDevices.length < 1 || disable}
				margin="normal"
				fullWidth
				onChange={handleInputDeviceChange}
			>
				{inputDevices.length < 1 ? (
					<MenuItem value="loading">Loading devices...</MenuItem>
				) : (
					inputDevices.map((device, key) => (
						<MenuItem key={key} value={device.deviceId}>
							{device.label}
						</MenuItem>
					))
				)}
			</TextField>
			<Alert severity="info" style={{ textAlign: 'left' }}>
				{tooltip}
			</Alert>
		</Box>
	)
}

export default InputDeviceSelector
