import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'

import useTimer from '../../common/hooks/useTimer'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		margin: spacing(-0.5, 0)
	},
	timerText: { display: 'inline-block', verticalAlign: 'middle', marginLeft: spacing(1) },
	progress: { verticalAlign: 'middle' }
}))

export default function Timer({ timer, onTimerElapsed, color }) {
	const classes = useStyles()

	const [timerText, secondsRemaining] = useTimer(timer, onTimerElapsed)

	return (
		<div className={classes.root} style={{ color }}>
			<CircularProgress
				className={classes.progress}
				thickness={22}
				size={25}
				color="inherit"
				variant="determinate"
				value={(secondsRemaining * 100) / timer.totalSeconds}
			/>
			<Typography component="span" className={classes.timerText} variant="h4">
				{timerText}
			</Typography>
		</div>
	)
}
