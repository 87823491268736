import { useReducer, useRef, useMemo } from 'react'

import { Link as RouterLink } from 'react-router-dom'
import { Typography, Paper, Grid, Button, Link, CircularProgress, Dialog } from '@material-ui/core'
import { Stepper, Step, StepLabel, StepButton } from '@material-ui/core'
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'

import SwipeableViews from 'react-swipeable-views'

import JobApplicationPreview from './JobApplicationPreview'
import routes from '../../../routes'

import JobSetupContext from './jobSetupContext'

import { reduce, getInitialState, createActions, steps } from './jobSetupStore'
import operationStates from '../../../../common/operationStates'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
	wizard: {
		padding: spacing(4),
		maxWidth: 1500,
		alignSelf: 'stretch',
		[breakpoints.up('lg')]: { margin: `${spacing(3)}px auto`, alignSelf: 'auto' }
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row-reverse',
		margin: spacing(6, 3, 0, 3)
	},
	prevFab: { position: 'fixed', top: '50%', left: spacing(1) },
	nextFab: { position: 'fixed', top: '50%', right: spacing(1) },
	stepContent: { margin: spacing(0, 1), padding: spacing(0, 1), minHeight: 300 }
}))

export default function JobApplicationEditor({ job, onJobCreated, onEditComplete }) {
	const [state, dispatch] = useReducer(reduce, job, getInitialState)
	const actions = useMemo(
		() => createActions(dispatch, state.id, onJobCreated, onEditComplete),
		[state.id, onJobCreated, onEditComplete]
	)

	const updateHeightRef = useRef()

	const onBackButtonClick = async () => {
		await actions.proceed(state, state.currentStep - 1)
	}

	const onStepLabelButtonClick = async ({ currentTarget }) => {
		await actions.proceed(state, +currentTarget.value)
	}

	const onNextButtonClick = async () => {
		await actions.proceed(state, state.currentStep + 1)
	}

	const classes = useStyles()
	return (
		<JobSetupContext.Provider value={actions}>
			<Paper className={classes.wizard}>
				<Typography variant="h4" gutterBottom>
					Job Application Setup
				</Typography>
				<Stepper activeStep={state.currentStep} nonLinear>
					{steps.map(({ label, stateSubtree: stateSubtreeName }, index) => {
						const stepPersistenceStatus = state[stateSubtreeName].persistenceStatus

						const isError = stepPersistenceStatus === operationStates.error
						const isInProgress = stepPersistenceStatus === operationStates.inProgress
						const isSuccess = stepPersistenceStatus === operationStates.success

						return (
							<Step
								key={label}
								disabled={
									index !== state.currentStep &&
									!state[steps[state.currentStep].stateSubtree].validationSummary?.isValid
								}
								completed={!!state[stateSubtreeName].completedOnce}
							>
								<StepButton value={index} onClick={onStepLabelButtonClick}>
									<StepLabel
										error={isError}
										StepIconComponent={isInProgress ? CircularProgress : undefined}
										StepIconProps={isInProgress ? { size: 24 } : undefined}
									>
										<span style={{ position: 'absolute' }}>
											{isInProgress ? 'Saving...' : isSuccess && 'Saved!'}
										</span>
										<span style={{ visibility: isInProgress || isSuccess ? 'hidden' : 'visible' }}>
											{label}
										</span>
									</StepLabel>
								</StepButton>
							</Step>
						)
					})}
				</Stepper>
				<Grid container>
					<Grid item sm={6}>
						<SwipeableViews
							index={state.currentStep}
							//onChangeIndex={index => dispatch({ type: 'setStep', index })}
							animateHeight
							action={actions => (updateHeightRef.current = actions.updateHeight)}
						>
							{steps.map(({ label, Component, stateSubtree, helpTooltipContent }, index) => (
								<div key={label} className={classes.stepContent}>
									<Typography variant="h5" gutterBottom>
										{label}
									</Typography>
									<Component
										{...state[stateSubtree]}
										isActiveView={state.currentStep === index}
										updateHeight={updateHeightRef.current}
									/>
									{state[stateSubtree].validationSummary?.explanation && (
										<Typography variant="caption" align="center" component="p">
											{state[stateSubtree].validationSummary?.explanation}
										</Typography>
									)}
								</div>
							))}
						</SwipeableViews>
						<div className={classes.buttonContainer}>
							<Button
								variant="contained"
								color="primary"
								disabled={!state[steps[state.currentStep].stateSubtree].validationSummary.isValid}
								startIcon={state.currentStep === steps.length - 1 && <DoneIcon />}
								endIcon={state.currentStep < steps.length - 1 && <ChevronRightIcon />}
								onClick={onNextButtonClick}
							>
								{state.currentStep < steps.length - 1 ? 'Save and Next' : 'Finish'}
							</Button>
							{state.currentStep !== 0 && (
								<Button
									variant="outlined"
									// TODO: Maybe we can leave it always enabled? To even submit the data we need the data to be valid anyways...
									// disabled={!state[steps[state.currentStep].stateSubtree].validationSummary.isValid}
									startIcon={<ChevronLeftIcon />}
									onClick={onBackButtonClick}
								>
									Back
								</Button>
							)}
						</div>
					</Grid>
					<Grid item sm={6}>
						<JobApplicationPreview {...state} />
					</Grid>
				</Grid>
				{state.error && (
					<Dialog open={!!state.error}>
						<DialogTitle>
							<ErrorIcon color="error" fontSize="large" style={{ verticalAlign: 'bottom' }} />{' '}
							{state.error.title}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>{state.error.description}</DialogContentText>
							<DialogContentText>
								Check you Internet connectivity and try again. If the problem persists,{' '}
								<Link component={RouterLink} to={routes.contactUs.path}>
									report a problem
								</Link>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button color="secondary" onClick={actions.navigateToErrorredStep}>
								Go to step
							</Button>
							<Button color="secondary" onClick={() => actions.proceed(state)}>
								Retry
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</Paper>
		</JobSetupContext.Provider>
	)
}
