import { useEffect, useState } from 'react'

import { Typography, CircularProgress } from '@material-ui/core'

import CheckIcon from '@material-ui/icons/Done'

function SimpleSavingIndicator({ errorMsg = 'Error', isLoading, isSuccess, isError }) {
	const [isVisible, setIsVisible] = useState(isLoading || isError || isSuccess)

	useEffect(() => {
		setIsVisible(isLoading || isError || isSuccess)
	}, [isLoading, isError, isSuccess])

	useEffect(() => {
		if (!isSuccess) return

		const timeOutId = setTimeout(setIsVisible, 3000, false)

		return () => clearTimeout(timeOutId)
	}, [isSuccess])

	return (
		isVisible && (
			<Typography variant="caption" color={isError ? 'error' : 'primary'} component="div">
				{isLoading ? (
					<span>
						Saving <CircularProgress size={9} thickness={5} />
					</span>
				) : isError ? (
					errorMsg
				) : (
					isSuccess && (
						<span>
							Saved <CheckIcon fontSize="inherit" />
						</span>
					)
				)}
			</Typography>
		)
	)
}

export default SimpleSavingIndicator
