import DragHandleIcon from 'mdi-material-ui/DragHorizontal'

function DragHandle(props) {
	return (
		<div {...props}>
			<DragHandleIcon style={{ verticalAlign: 'middle' }} />
		</div>
	)
}

export default DragHandle
