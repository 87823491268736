import { makeStyles } from '@material-ui/core/styles'

import { Typography, Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core'
import CameraIcon from '@material-ui/icons/VideoCall'

const mediaContainerHeight = 148

const useStyles = makeStyles(({ spacing, palette }) => ({
	root: {
		borderRadius: spacing(2),
		borderStyle: 'dashed',
		height: 'calc(100% - 46px)',
		maxWidth: 280
	},
	mediaContainer: {
		height: mediaContainerHeight,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		background: ({ hasMedia }) => (hasMedia ? palette.common.black : palette.background.paper)
	},
	videoIcon: { fontSize: 80 },
	videoDescription: { textAlign: 'center', height: `calc(100% - ${mediaContainerHeight}px)` }
}))

const EmployerVideoCard = ({ title, description, videoType, thumbnailUrl, srcUrl, onClick }) => {
	const classes = useStyles({ hasMedia: thumbnailUrl || srcUrl })

	return (
		<Card className={classes.root} elevation={5}>
			<CardActionArea name={videoType} style={{ height: '100%' }} onClick={onClick}>
				{thumbnailUrl ? (
					<CardMedia component="img" src={thumbnailUrl} className={classes.mediaContainer} />
				) : srcUrl ? (
					<CardMedia component="video" src={srcUrl} className={classes.mediaContainer} />
				) : (
					<div className={classes.mediaContainer}>
						<CameraIcon color="inherit" className={classes.videoIcon} />
						<Typography variant="h6">Record</Typography>
						<Typography variant="subtitle2">or link a YouTube video</Typography>
					</div>
				)}
				<CardContent className={classes.videoDescription}>
					<Typography variant="h5">{title}</Typography>
					<Typography variant="body2" color="inherit">
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}

export default EmployerVideoCard
