import { useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'

import { useMutation } from '@tanstack/react-query'

import { useAuth } from '../auth/AuthProvider'
import StatefulButton from '../../common/StatefullButton'

function EmailVerification({ onContentChange }) {
	const { currentUser, sendEmailVerification, refreshUser } = useAuth()

	const {
		mutate: sendAgain,
		isLoading: isSending,
		error: sendError,
		isSuccess: isSent
	} = useMutation({
		mutationFn: sendEmailVerification,
		onError: console.log
	})

	const {
		mutate: reCheck,
		isLoading: isChecking,
		error: checkError,
		isSuccess: isChecked
	} = useMutation({
		mutationFn: refreshUser,
		onError: console.log
	})

	useEffect(() => {
		onContentChange()
	}, [sendError, checkError, isSent, isChecked, onContentChange])

	return (
		<div>
			<Typography paragraph>
				We sent you a verification email to <strong>{currentUser.email}</strong>. Please check you
				inbox and follow the instructions.
			</Typography>
			<Typography gutterBottom>
				Haven't received the email? Has it been lost in the vastness of your inbox (check spam too,
				just in case)?
			</Typography>
			<Box sx={{ textAlign: 'center', pb: 3 }}>
				<StatefulButton
					variant="contained"
					color="primary"
					isLoading={isSending}
					onClick={sendAgain}
				>
					Send it again
				</StatefulButton>
				{sendError && (
					<Typography variant="caption" color="error" display="block">
						Error sending the email
					</Typography>
				)}
				{isSent && (
					<Typography variant="caption" display="block">
						Email has been sent
					</Typography>
				)}
			</Box>
			<Typography gutterBottom>Already verified using another browser or device?</Typography>
			<Box sx={{ textAlign: 'center', pb: 0.5 }}>
				<StatefulButton
					variant="contained"
					color="primary"
					isLoading={isChecking}
					onClick={reCheck}
				>
					Let us check
				</StatefulButton>
				{checkError && (
					<Typography variant="caption" color="error" display="block">
						Error checking the status
					</Typography>
				)}
				{isChecked && (
					<Typography variant="caption" display="block">
						Still not verified...
					</Typography>
				)}
			</Box>
		</div>
	)
}

export default EmailVerification
