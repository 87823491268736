import { useEffect, useRef } from 'react'

const MicCanvas = ({ microphone, enable, height = 30 }) => {
	const canvasRef = useRef(null)
	const audioStream = useRef(null)

	useEffect(() => {
		if (!enable) return

		const canvas = canvasRef.current
		const canvasContext = canvas.getContext('2d')

		canvas.height = height

		navigator.mediaDevices
			.getUserMedia({
				audio: {
					deviceId: microphone
				}
			})
			.then(stream => {
				audioStream.current = stream
				const context = new AudioContext()

				const analyser = context.createAnalyser()
				analyser.fftSize = 256

				const source = context.createMediaStreamSource(stream)
				source.connect(analyser)

				const bufferLength = analyser.frequencyBinCount
				const freqs = new Uint8Array(bufferLength)
				canvasContext.clearRect(0, 0, canvas.width, height)

				function draw() {
					requestAnimationFrame(draw)
					analyser.getByteFrequencyData(freqs)

					canvasContext.fillStyle = 'rgb(0, 0, 0)'
					canvasContext.fillRect(0, 0, canvas.width, height)

					const barWidth = (canvas.width / bufferLength) * 2
					let barHeight
					let x = 0

					for (let i = 0; i < bufferLength; i++) {
						barHeight = freqs[i] / 4

						canvasContext.fillStyle = `rgb(${barHeight + 200}, 175, 55)`
						canvasContext.fillRect(x, height - barHeight / 2, barWidth, barHeight)

						x += barWidth + 1
					}
				}

				draw()
			})

		return () => {
			if (audioStream.current) {
				audioStream.current.getTracks().forEach(track => track.stop())
				audioStream.current = null
			}
		}
	}, [microphone, enable, height])

	return <canvas ref={canvasRef} style={{ width: '100%' }} />
}

export default MicCanvas
