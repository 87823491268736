import { memo, useCallback, useState, useEffect, useContext } from 'react'

import { Grid, TextField, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import usStates from '../../../../../common/usStates'
import JobSetupContext from '../jobSetupContext'

const useStyles = makeStyles(({ spacing }) => ({
	root: { overflow: 'hidden', padding: spacing(0, 1) } // one of the suggested workarounds for scrolling content caused by Grid
}))

export default memo(function JobInfoEditor({
	title = '',
	companyName = '',
	location = '',
	city = '',
	state = '',
	zipcode = '',
	website = '',
	validationSummary,
	isActiveView,
	updateHeight
}) {
	const [touchedFields, setTouchedFields] = useState({})
	const actions = useContext(JobSetupContext)

	useEffect(() => {
		updateHeight && updateHeight()
	}, [updateHeight, validationSummary.isValid])

	const updateGeneralInfo = useCallback(
		({ target }) => {
			setTouchedFields(fields => ({ ...fields, [target.name]: true }))
			actions.updateGeneralInfo({ [target.name]: target.value })
		},
		[actions]
	)

	useEffect(() => {
		if (isActiveView) {
			//dispatch({ type: SET_IF_CAN_SAVE, payload: { canSave: validationSummary.isValid } })
		}
	}, [actions, isActiveView, validationSummary])

	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item sm={12}>
					<TextField
						label="Company Name"
						name="companyName"
						value={companyName}
						error={touchedFields.companyName && !!validationSummary.companyName}
						helperText={touchedFields.companyName && validationSummary.companyName}
						onChange={updateGeneralInfo}
						fullWidth
					/>
				</Grid>
				<Grid item sm={12}>
					<TextField
						label="Website"
						name="website"
						value={website}
						helperText="(Optional) Will be displayed to applicants"
						onChange={updateGeneralInfo}
						fullWidth
					/>
				</Grid>
				<Grid item sm={12}>
					<TextField
						label="Job Title"
						name="title"
						value={title}
						error={touchedFields.title && !!validationSummary.title}
						helperText={touchedFields.title && validationSummary.title}
						onChange={updateGeneralInfo}
						fullWidth
					/>
				</Grid>
				<Grid item sm={12}>
					<TextField
						label="Job Address"
						name="location"
						value={location}
						placeholder="Business Address"
						error={touchedFields.location && !!validationSummary.location}
						helperText={touchedFields.location && validationSummary.location}
						onChange={updateGeneralInfo}
						fullWidth
					/>
				</Grid>
				<Grid item sm={6}>
					<TextField
						label="City"
						name="city"
						value={city}
						error={touchedFields.city && !!validationSummary.city}
						helperText={touchedFields.city && validationSummary.city}
						onChange={updateGeneralInfo}
						fullWidth
					/>
				</Grid>
				<Grid item sm={3}>
					<TextField
						label="State"
						name="state"
						value={state}
						error={touchedFields.state && !!validationSummary.state}
						helperText={touchedFields.state && validationSummary.state}
						onChange={updateGeneralInfo}
						SelectProps={{
							MenuProps: {
								PaperProps: {
									style: {
										maxHeight: 300
									}
								}
							}
						}}
						select
						fullWidth
					>
						{usStates.map(({ name, abbreviation }) => (
							<MenuItem key={abbreviation} value={abbreviation}>
								{abbreviation}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item sm={3}>
					<TextField
						label="Zip"
						name="zipcode"
						value={zipcode}
						error={touchedFields.zipcode && !!validationSummary.zipcode}
						helperText={touchedFields.zipcode && validationSummary.zipcode}
						onChange={updateGeneralInfo}
						fullWidth
					/>
				</Grid>
			</Grid>
		</div>
	)
})
