export const docDisplayText = {
	resume: 'Resume',
	cover_letter: 'Cover Letter',
	reference: 'References'
}
export const questionTypeMap = {
	single: 'dropdown',
	multiple: 'checkbox',
	short: 'short_answer',
	long: 'paragraph',
	paragraph: 'long',
	dropdown: 'single',
	checkbox: 'multiple',
	short_answer: 'short'
}
