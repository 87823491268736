import '../../../../../assets/background-patterns.css'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, TextField, MenuItem } from '@material-ui/core'
import clsx from 'clsx'

const textureNames = [
	'Jigsaw',
	'Overcast',
	'Formal',
	'Topography',
	'General',
	'Jupiter',
	'Architect',
	'Cutout',
	'Hideout',
	'Graph Paper',
	'YYY',
	'Squares',
	'Piano Man',
	'Pie Factory',
	'Dominos',
	'Hexigons',
	'Autumn',
	'Temple',
	'Death Star',
	'Church on Sunday',
	'I Like Food',
	'Overlapping Hexagons',
	'Bamboo',
	'Bathroom Floor',
	'Cork Screws',
	'Happy Intersection',
	'Kiwi',
	'Lips',
	'Steel Beams',
	'Bubbles'
]

const useStyles = makeStyles({
	selectedPattern: {
		height: 200,
		border: '1px solid rgba(0,0,0,0.23)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
})

export default function PatternPicker({ onTextureChange, patternIndex }) {
	const updateTexture = ({ target }) => onTextureChange(target.value === -1 ? null : target.value)

	const classes = useStyles()
	return (
		<div>
			<Typography variant="subtitle2">Texture</Typography>

			<TextField
				margin="dense"
				variant="outlined"
				value={patternIndex || patternIndex === 0 ? patternIndex : -1}
				select
				fullWidth
				onChange={updateTexture}
			>
				<MenuItem key={-1} value={-1}>
					None
				</MenuItem>
				{textureNames.map((title, index) => (
					<MenuItem key={index} value={index}>
						{title}
					</MenuItem>
				))}
			</TextField>
			<div
				className={clsx(classes.selectedPattern, patternIndex >= 0 && `pattern-${patternIndex}`)}
			>
				{patternIndex === null && <Typography color="textSecondary">Select Texture</Typography>}
			</div>
		</div>
	)
}
