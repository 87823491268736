import { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Paper, TextField, IconButton, Grid, Button } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete'

import AnswerChoicesEditor from './AnswerChoicesEditor'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		padding: spacing(2),
		marginTop: spacing(2)
	},
	editor: { flex: 1 },
	question: { marginBottom: spacing(2) },
	questionTypes: { justifyContent: 'space-between' },
	buttonContainer: { textAlign: 'right', marginTop: spacing(1) },
	cancelButton: { marginLeft: spacing(2) }
}))

const answerTypeDisplayNames = {
	single: 'Choose one',
	multiple: 'Choose multiple',
	short: 'Short free form answer',
	long: 'Long free form answer'
}

function QuestionSetup({
	id,
	text,
	questionType,
	order,
	isInEditMode = false,
	answerChoices = [],
	validationSummary = {},
	enabled,
	saveQuestion,
	updateQuestion,
	deleteQuestion,
	updateHeight
}) {
	const [touched, setTouched] = useState({})

	useEffect(updateHeight, [updateHeight, answerChoices.length, isInEditMode, questionType])

	const updateQuestionText = ({ target }) => {
		setTouched(touched => ({ ...touched, [target.name]: true }))
		updateQuestion(id, { [target.name]: target.value })
	}

	const getSummary = () => {
		const choices = ['single', 'multiple'].includes(questionType)
			? `: ${answerChoices.map(({ text }) => text).join(', ')}`
			: ''
		return answerTypeDisplayNames[questionType] + choices
	}

	const classes = useStyles()
	return (
		<Paper variant="outlined" className={classes.root}>
			<Grid container spacing={2} alignItems="center">
				<Grid item className={classes.editor}>
					<TextField
						className={classes.question}
						placeholder="Question"
						name="text"
						value={text}
						error={!!(touched.text && validationSummary.text)}
						helperText={isInEditMode ? touched.text && validationSummary.text : getSummary()}
						disabled={!enabled}
						multiline
						fullWidth
						autoFocus={isInEditMode && !text}
						onChange={updateQuestionText}
						onFocus={() => !isInEditMode && updateQuestion(id, { isInEditMode: true })}
					/>
					{isInEditMode && (
						<>
							<AnswerChoicesEditor
								questionId={id}
								questionType={questionType}
								answerChoices={answerChoices}
								updateQuestion={updateQuestion}
							/>
							<div className={classes.buttonContainer}>
								<Button
									color="secondary"
									size="medium"
									disabled={Object.keys(validationSummary).length > 0}
									startIcon={<DoneIcon />}
									onClick={() => saveQuestion({ id, text, questionType, order, answerChoices })}
								>
									Save
								</Button>
								<Button
									size="medium"
									className={classes.cancelButton}
									startIcon={<DeleteIcon />}
									onClick={() => deleteQuestion(id)}
								>
									Remove
								</Button>
							</div>
						</>
					)}
				</Grid>
				{!isInEditMode && (
					<Grid item>
						<IconButton
							size="medium"
							color="primary"
							disabled={!enabled}
							onClick={() => updateQuestion(id, { isInEditMode: true })}
						>
							<EditIcon />
						</IconButton>
					</Grid>
				)}
			</Grid>
		</Paper>
	)
}

export default QuestionSetup
