import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import StatefulButton from '../../common/StatefullButton'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import { useAuth } from './AuthProvider'

const passwordResetRequestSchema = z.object({
	email: z.string().email('Invalid email address').nonempty('Email is required')
})

function PasswordResetInitializer() {
	const { sendPasswordResetEmail } = useAuth()
	const { search } = useLocation()
	const queryParams = useMemo(() => new URLSearchParams(search), [search])
	const email = queryParams.get('email')

	const { mutate, isLoading, isSuccess, error } = useMutation({
		mutationFn: values => sendPasswordResetEmail(values.email)
	})

	const {
		formState: { errors },
		register,
		handleSubmit,
		getValues
	} = useFormWithZod(passwordResetRequestSchema, {
		defaultValues: { email: email || '', password: '', confirmPassword: '' }
	})

	return (
		<Box component="form" sx={{ mx: 'auto', pt: 10, width: 300 }} onSubmit={handleSubmit(mutate)}>
			{isSuccess ? (
				<>
					<Typography variant="h4" align="center" gutterBottom>
						Check your email
					</Typography>
					<Typography variant="body2" color="textSecondary" align="center" gutterBottom>
						We sent the instructions to
					</Typography>
					<Typography variant="body2" align="center" gutterBottom>
						{getValues('email')}
					</Typography>
				</>
			) : (
				<Grid container spacing={3} direction="column" alignItems="stretch">
					<Grid item>
						<Typography variant="h4" align="center" gutterBottom>
							Password Reset
						</Typography>
						<Typography variant="body2" color="textSecondary" align="center">
							We'll send you instructions to the email below
						</Typography>
					</Grid>
					<Grid item>
						<TextField
							{...register('email')}
							variant="outlined"
							label="Email"
							error={!!errors.email}
							helperText={errors.email?.message}
							fullWidth
						/>
					</Grid>
					{error && (
						<Grid item>
							<Alert severity="error">Unable to send the email</Alert>
						</Grid>
					)}
					<Grid item align="center">
						<StatefulButton
							variant="contained"
							size="large"
							color="primary"
							type="submit"
							isLoading={isLoading}
						>
							Send Password Reset Email
						</StatefulButton>
					</Grid>
				</Grid>
			)}
		</Box>
	)
}

export default PasswordResetInitializer
