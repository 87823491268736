import { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grow, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import PermCameraMicIcon from '@material-ui/icons/PermCameraMic'

const useStyles = makeStyles(({ spacing }) => ({
	accessMessage: {
		margin: 'auto',
		padding: spacing(5),
		width: 'auto',
		display: 'grid',
		maxWidth: 1200,
		gridAutoFlow: 'row',
		justifyItems: 'center',
		gap: spacing(3)
	},
	cameraMicIcon: {
		fontSize: 248
	}
}))

const DeviceActivation = ({ onRequestDevicePermissions }) => {
	const classes = useStyles()
	const [permissionDenied, setPermissionDenied] = useState(false)

	const onPermissionDenied = useCallback(e => {
		console.log(e)
		if (e.message.includes('Permission denied')) {
			setPermissionDenied(true)
		}
	}, [])

	return (
		<div className={classes.accessMessage}>
			<Grow in={true} timeout={1000}>
				<PermCameraMicIcon className={classes.cameraMicIcon} color="secondary" />
			</Grow>
			{permissionDenied && (
				<Alert severity="error">
					You haven't allowed the browser to access your video and/or microphone device. These must
					be enabled for recording video introductions. To grant access to your devices, please open
					your browser's preferences -> Websites and and change camera setting for haystackpro.com
					to "Allow". After performing this change, please reload the page.
				</Alert>
			)}
			<Typography>
				To record video, Haystack needs access to your device's camera and microphone. Click the
				button below to enable access to your camera and microphone, and click the "Allow" button
				when prompted for camera and microphone access. You may get these pop-ups separately for
				each device.
			</Typography>
			<Button
				disabled={permissionDenied}
				color="secondary"
				variant="contained"
				onClick={() => onRequestDevicePermissions(onPermissionDenied)}
			>
				Request camera and microphone access
			</Button>
		</div>
	)
}

export default DeviceActivation
