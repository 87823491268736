import { useState, useCallback, useMemo } from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Paper, Stepper, Step, StepLabel, Button, useMediaQuery, Divider } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'

import Welcome from './Welcome'
import Complete from './Complete'
import SelectMediaDevice from './SelectMediaDevice'
import MicrophonePicker from './MicrophonePicker'
import CameraPicker from './CameraPicker'
import InterviewInfo from './InterviewInfo'
import { jobVideoTypes } from '../../common/outboundVideoTypes'

const generateSteps = (applicantName, currentStepIndex, setErrorState, videos) => {
	const introVideo = videos?.find(video => video.videoType === jobVideoTypes.jobPosition.name)
	const workDayVideo = videos?.find(video => video.videoType === jobVideoTypes.workDay.name)
	const meetYourBossVideo = videos?.find(video => video.videoType === jobVideoTypes.meetYourBoss.name)
	const generatedSteps = [
		{
			label: 'Welcome',
			component: (
				<Welcome
					applicantName={applicantName}
					jobDescriptionVideo={introVideo}
					isWorkDayVideoSetup={workDayVideo?.displayToCandidates}
					isMeetYourBossVideoSetup={meetYourBossVideo?.displayToCandidates}
				/>
			)
		}
	]

	if (
		introVideo?.displayToCandidates ||
		workDayVideo?.displayToCandidates ||
		meetYourBossVideo?.displayToCandidates
	) {
		generatedSteps.push({
			label: 'Interview Setup',
			component: <InterviewInfo />
		})
	}

	generatedSteps.push({
		label: 'Microphone',
		component: (
			<SelectMediaDevice
				type="audio"
				name="microphone"
				directionsText="Speak into your microphone. If a wave is moving in the box, click 'Next'."
				enableDevice={currentStepIndex === generatedSteps.length}
				DevicePicker={MicrophonePicker}
				setError={setErrorState}
			/>
		)
	})
	generatedSteps.push({
		label: 'Camera',
		component: (
			<SelectMediaDevice
				type="video"
				name="camera"
				directionsText="If you see yourself in the box, click 'Next'."
				enableDevice={currentStepIndex === generatedSteps.length}
				DevicePicker={CameraPicker}
				setError={setErrorState}
			/>
		)
	})
	generatedSteps.push({ label: 'All Set', component: <Complete /> })

	return generatedSteps
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
	wizard: {
		margin: `${spacing(3)}px auto`,
		padding: spacing(2),
		maxWidth: 760,
		[breakpoints.down('xs')]: {
			margin: 0,
			minHeight: '100vh'
		}
	},
	step: {
		[breakpoints.down('xs')]: {
			paddingRight: 0
		}
	},
	buttonContainer: { display: 'flex', justifyContent: 'space-between' },
	backButton: ({ step }) => ({ margin: '1em', visibility: step === 0 ? 'hidden' : 'visible' }),
	mobileWizardButton: { margin: spacing(-1), borderRadius: 0, boxShadow: 'none' }
}))

const SetupStep = ({ isMobile, label, children }) => (
	<div>
		{isMobile && <Typography variant="h5">{label}</Typography>}
		{isMobile && <Divider light style={{ marginBottom: '2em' }} />}
		{children}
	</div>
)

export default function VideoInterviewSetup({ applicantName, jobTitle, companyName, videos }) {
	const [step, setStep] = useState(0)
	const [hasError, setErrorState] = useState(false)

	const classes = useStyles({ step })
	const { breakpoints } = useTheme()
	const isMobile = useMediaQuery(breakpoints.down('xs'))

	const steps = useMemo(() => {
		return generateSteps(applicantName, step, setErrorState, videos)
	}, [applicantName, step, setErrorState, videos])

	const goBack = useCallback(() => {
		setStep(prevStep => (prevStep > 0 ? prevStep - 1 : 0))
	}, [])

	const goNext = useCallback(() => {
		setStep(prevStep => (prevStep < steps.length - 1 ? prevStep + 1 : steps.length - 1))
	}, [steps.length])

	if (!steps) return null

	return (
		<Paper className={classes.wizard}>
			<Typography variant="h4" align="center" className={classes.jobTitle}>
				{jobTitle}
			</Typography>
			<Typography variant="h6" align="center">
				{companyName}{' '}
				<Typography color="textSecondary" variant="subtitle2" display="inline" component="span">
					via Haystack
				</Typography>
			</Typography>
			<Stepper activeStep={step} color="secondary">
				{steps.map((s, index) => (
					<Step key={index} className={classes.step}>
						<StepLabel>{!isMobile && s.label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<SwipeableViews
				index={step}
				animateHeight={!isMobile}
				onChangeIndex={step => setStep(step)}
				disabled={hasError}
				resistance
			>
				{steps.map(step => (
					<SetupStep key={step.label} isMobile={isMobile} label={step.label}>
						{step.component}
					</SetupStep>
				))}
			</SwipeableViews>

			<div className={classes.buttonContainer}>
				<Button variant="outlined" size="large" className={classes.backButton} onClick={goBack}>
					Back
				</Button>
				{step !== steps.length - 1 && (
					<Button
						variant="contained"
						size="large"
						color="primary"
						style={{ margin: '1em' }}
						onClick={goNext}
						disabled={hasError}
					>
						Next
					</Button>
				)}
			</div>
		</Paper>
	)
}
