import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: theme.spacing(5)
	}
}))

export default function LargeLoader({ title }) {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography variant="h5" gutterBottom>
				{title}
			</Typography>
			<CircularProgress size={100} />
		</div>
	)
}
