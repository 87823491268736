import { Box, Typography } from '@material-ui/core'

import EmbeddedVideoPlayer from '../../common/employer-video/EmbeddedVideoPlayer'
import PublishedVideoPlayer from '../../common/employer-video/PublishedVideoPlayer'
import VideoPlayer from '../../employer/jobs/job/interview-setup/video-introduction/employer-recording-dashboard/VideoPlayer'
import videoHosts from '../../common/videoHosts'
import Footnotes from './Footnotes'

const Welcome = ({
	applicantName,
	jobDescriptionVideo,
	isWorkDayVideoSetup,
	isMeetYourBossVideoSetup
}) => {
	const isJobDescriptionVideoSetup = jobDescriptionVideo?.displayToCandidates
	return (
		<div>
			<Typography variant="h6" paragraph>
				Hi {applicantName},
			</Typography>
			<Typography paragraph>
				Congratulations on advancing to the next step in the hiring process!
				{!isJobDescriptionVideoSetup &&
					!isWorkDayVideoSetup &&
					!isMeetYourBossVideoSetup &&
					' This 1-way video interview should take no more than 15 minutes.'}
			</Typography>

			{isJobDescriptionVideoSetup || isWorkDayVideoSetup || isMeetYourBossVideoSetup ? (
				<>
					<Typography paragraph>
						We created{' '}
						{(isJobDescriptionVideoSetup && isWorkDayVideoSetup) ||
						(isJobDescriptionVideoSetup && isMeetYourBossVideoSetup) ||
						(isWorkDayVideoSetup && isMeetYourBossVideoSetup)
							? 'a couple videos'
							: 'a video'}{' '}
						for you to learn more about{' '}
						{(isJobDescriptionVideoSetup || isWorkDayVideoSetup) && ' the position'}
						{isMeetYourBossVideoSetup &&
							`${
								isJobDescriptionVideoSetup || isWorkDayVideoSetup ? ' and' : ''
							} who you’ll be reporting to`}
						.
						{isJobDescriptionVideoSetup &&
							' Below, you’ll find a video that describes this position in a little more detail.'}
					</Typography>
					{(isWorkDayVideoSetup || isMeetYourBossVideoSetup) && (
						<Typography paragraph>
							Once you complete your interview, you’ll be able to watch
							{isWorkDayVideoSetup && isMeetYourBossVideoSetup
								? ` 2 ${isJobDescriptionVideoSetup ? 'additional ' : ''} videos:`
								: isJobDescriptionVideoSetup
								? ' 1 additional video:'
								: ''}{' '}
							{isWorkDayVideoSetup && (
								<span>
									<strong>"A day in the life"</strong> for this role
								</span>
							)}
							{isWorkDayVideoSetup && isMeetYourBossVideoSetup && ', and '}
							{isMeetYourBossVideoSetup && <strong>"Meet your boss."</strong>}
						</Typography>
					)}
				</>
			) : (
				<Typography paragraph>
					Please use a stable internet connection (WiFi) and{' '}
					<b>allow access to your camera and microphone when prompted.</b>
				</Typography>
			)}
			{isJobDescriptionVideoSetup && (
				<>
					<Typography gutterBottom variant="subtitle2" align="center">
						A little more about this role
					</Typography>
					<Box sx={{ textAlign: 'center', height: 380 }}>
						{jobDescriptionVideo.videoHost === videoHosts.youtube ? (
							<EmbeddedVideoPlayer sourceVideo={jobDescriptionVideo.srcUrl} />
						) : jobDescriptionVideo.videoHost === videoHosts.bunny ? (
							<PublishedVideoPlayer srcUrl={jobDescriptionVideo.srcUrl} />
						) : (
							<VideoPlayer videoSrc={jobDescriptionVideo.srcUrl} />
						)}
					</Box>
				</>
			)}

			{!isJobDescriptionVideoSetup && !isWorkDayVideoSetup && !isMeetYourBossVideoSetup && (
				<Footnotes />
			)}
		</div>
	)
}

export default Welcome
