import React from 'react'

import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	DialogContentText,
	Typography
} from '@material-ui/core'
import { DialogActions, Slide, useMediaQuery } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	dialogActions: { padding: theme.spacing(1, 3) }
}))

function StartInterviewNote({ open, onStartClick, onCancelClick }) {
	const classes = useStyles()
	const { breakpoints } = useTheme()
	const isMobile = useMediaQuery(breakpoints.down('xs'))

	return (
		<Dialog
			open={open}
			fullScreen={isMobile}
			TransitionComponent={Slide}
			TransitionProps={{ direction: isMobile ? 'up' : 'down' }}
		>
			<DialogTitle disableTypography>
				<Typography variant="h5">Final Note</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText variant="h6" component="p">
					When the interview begins, you won't be able to pause while answering, or re-do your
					answers. However, you can pause between questions.
				</DialogContentText>
				<DialogContentText variant="h6" component="p">
					Also, please{' '}
					<u>
						<b>don’t refresh the page</b>
					</u>{' '}
					during the interview. Refreshing will submit the answer you’ve recorded so far for the
					question you are on, then, skip to the next question.
				</DialogContentText>
				<DialogContentText variant="h6" component="p">
					Do you want to proceed or continue practicing?
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button variant="outlined" size="large" onClick={onCancelClick}>
					Continue Practicing
				</Button>
				<Button variant="contained" size="large" color="primary" onClick={onStartClick}>
					Begin Interview
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default StartInterviewNote
