import { useState } from 'react'
import { Button, Typography, Dialog } from '@material-ui/core'

import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import LargeLoader from '../../../common/LargeLoader'
import operationStates from '../../../common/operationStates'
import { useAuth } from '../../auth/AuthProvider'
import { cancelSubscription } from './paymentService'

export const CancelSubscriptionConfirmationDialog = ({ isOpen, subscriptionUsage, onClose }) => {
	const [runningOperationState, setRunningOperationState] = useState(operationStates.initial)
	const { updateUserStatus } = useAuth()

	const handleCancelSubscription = e => {
		setRunningOperationState(operationStates.inProgress)
		cancelSubscription()
			.then(result => {
				updateUserStatus({ isSubscriptionActive: false })
				onClose()
			})
			.catch(error => setRunningOperationState(operationStates.error))
	}

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle id="form-dialog-title">Cancel Subscription</DialogTitle>
			<DialogContent>
				<DialogContentText>
					It's sad to see you go! If you want to cancel your subscription, click on the "Cancel
					subscription" button below. You can restore you subscription at any time.
				</DialogContentText>
				{!subscriptionUsage ? (
					<LargeLoader title="Loading usage data..." />
				) : (
					!subscriptionUsage.error && (
						<div>
							<Typography gutterBottom>Look at what we achieved together:</Typography>
							<ul>
								<li>
									Total Jobs: <strong>{subscriptionUsage.totalJobPostings}</strong>
								</li>
								<li>
									Open Jobs: <strong>{subscriptionUsage.activeJobPostings}</strong>
								</li>
								<li>
									Total Applicants: <strong>{subscriptionUsage.totalApplicants}</strong>
								</li>
								<li>
									Video Interviews: <strong>{subscriptionUsage.completedInterviews}</strong>
								</li>
							</ul>
						</div>
					)
				)}
				<Typography gutterBottom>Are you sure you want cancel the subscription?</Typography>
				{runningOperationState !== operationStates.initial &&
					(runningOperationState === operationStates.error ? (
						<Alert severity="error">An error has occurred. Please try again later.</Alert>
					) : (
						<Alert severity="info">Processing request. Please wait...</Alert>
					))}
				<DialogActions>
					<Button onClick={onClose}>Go Back</Button>
					<Button onClick={handleCancelSubscription} variant="contained">
						Cancel Subscription
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}
