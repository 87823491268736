import ApplicantsInStage from '../ApplicantsInStage'

const columns = [
	{
		title: 'Date Applied',
		field: 'appliedDateTime',
		type: 'datetime',
		defaultSort: 'desc',
		render: candidate =>
			candidate.appliedDateTime.toLocaleString('en-US', {
				dateStyle: 'short',
				timeStyle: 'short'
			})
	},
	{ title: 'Source', field: 'source' },
	{ title: 'Referred', field: 'referral' }
]

export default function AppliedStageApplicants({ stage, candidates, isLoading, candidateActions }) {
	return (
		<ApplicantsInStage
			title="Applied"
			columns={columns}
			candidateActions={candidateActions}
			candidates={candidates}
			stage={stage}
			isLoading={isLoading}
		/>
	)
}
