import { Typography } from '@material-ui/core'
import Footnotes from './Footnotes'

const InterviewInfo = () => {
	return (
		<div>
			<Typography paragraph>
				This 1-way video interview should take no more than 15 minutes.
			</Typography>
			<Typography paragraph>
				Please use a stable internet connection (WiFi) and{' '}
				<b>allow access to your camera and microphone when prompted.</b>
			</Typography>
			<Footnotes />
		</div>
	)
}

export default InterviewInfo
