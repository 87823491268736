import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
	poweredBy: {
		padding: spacing(0, 1),
		backgroundColor: 'rgb(255,255,255,0.4)',
		borderRadius: '0 0 0 4px',
		position: 'absolute',
		top: 0,
		right: 0
	}
}))

export default function PoweredByHaystack() {
	const classes = useStyles()
	return (
		<Typography variant="body2" align="right" className={classes.poweredBy} display="inline">
			Powered by Haystack ©
		</Typography>
	)
}
