import { useContext, useState, useEffect, useCallback } from 'react'

import { DialogContent, DialogActions } from '@material-ui/core'
import { TextField, Button, Typography } from '@material-ui/core'

import { ApplicantDashboardContext } from '../applicantDashboardStore'
import CustomDialog from '../../../../../common/CustomDialog'

export default function RejectionEmailDialog({
	open,
	candidateIds,
	jobTitle,
	companyName,
	onClose
}) {
	const actions = useContext(ApplicantDashboardContext)
	const [subject, setSubject] = useState('Thank you for your application')
	const [body, setBody] = useState('')

	useEffect(
		() =>
			setBody(`Thank you for your interest in our ${jobTitle} position. We regret to inform you that we decided to go in a different direction. We wish you the best of luck with your job search.

Kind Regards,

${companyName}`),
		[companyName, jobTitle]
	)

	const showConfirmationDialog = useCallback(() => {
		actions.showConfirmationDialog({
			title: 'Send Rejection Email?',
			description: 'Applicant(s) will be permanently rejected. This cannot be undone.',
			onConfirm: () => actions.sendRejectionEmail(candidateIds, subject, body)
		})

		onClose()
	}, [subject, body, candidateIds, actions, onClose])

	return (
		<CustomDialog
			title="Send Rejection Email"
			open={open}
			onClose={onClose}
			maxWidth="md"
			fullWidth
		>
			<DialogContent>
				<Typography variant="subtitle2" gutterBottom>
					Subject
				</Typography>
				<TextField
					name="subject"
					value={subject}
					fullWidth
					onChange={({ target }) => setSubject(target.value)}
				/>
				<Typography variant="subtitle2" style={{ marginTop: 16 }}>
					Body
				</Typography>
				<Typography>
					Dear <i>{'{applicant name}'}</i>,
				</Typography>
				<TextField
					name="body"
					multiline
					variant="filled"
					value={body}
					label="Editable content"
					margin="normal"
					fullWidth
					onChange={({ target }) => setBody(target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="contained" onClick={showConfirmationDialog}>
					Send
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</DialogActions>
		</CustomDialog>
	)
}
