import { Link } from 'react-router-dom'

import { Box, Grid, TextField, Typography, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import StatefulButton from '../../common/StatefullButton'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import routes from '../routes'
import { useAuth } from './AuthProvider'

const passwordResetConfirmSchema = z
	.object({
		password: z
			.string()
			.min(6, 'Password must be at least 6 characters')
			.nonempty('Password is required'),
		confirmPassword: z.string().nonempty('Password confirmation is required')
	})
	.refine(data => data.password === data.confirmPassword, {
		message: 'Passwords must match',
		path: ['confirmPassword']
	})

function PasswordResetFinalizer({ code }) {
	const { confirmPasswordReset } = useAuth()

	const { mutate, isLoading, isSuccess, error } = useMutation({
		mutationFn: values => confirmPasswordReset(code, values.password)
	})

	const {
		formState: { errors },
		register,
		handleSubmit
	} = useFormWithZod(passwordResetConfirmSchema, {
		defaultValues: { password: '', confirmPassword: '' }
	})

	return (
		<Box component="form" sx={{ mx: 'auto', pt: 10, width: 300 }} onSubmit={handleSubmit(mutate)}>
			{isSuccess ? (
				<>
					<Typography variant="h4" align="center" gutterBottom>
						Success!
					</Typography>
					<Typography variant="body2" color="textSecondary" align="center" paragraph>
						Now you can login with your new password
					</Typography>
					<Box sx={{ textAlign: 'center' }}>
						<Button
							size="large"
							variant="contained"
							color="primary"
							component={Link}
							to={routes.login.path}
						>
							Log In
						</Button>
					</Box>
				</>
			) : (
				<Grid container spacing={3} direction="column" alignItems="stretch">
					<Grid item>
						<Typography variant="h4" align="center" gutterBottom>
							Set New Password
						</Typography>
					</Grid>
					<Grid item>
						<TextField
							{...register('password')}
							variant="outlined"
							label="New password"
							type="password"
							error={!!errors.password}
							helperText={errors.password?.message}
							fullWidth
						/>
					</Grid>
					<Grid item>
						<TextField
							{...register('confirmPassword')}
							variant="outlined"
							label="Confirm new password"
							type="password"
							error={!!errors.confirmPassword}
							helperText={errors.confirmPassword?.message}
							fullWidth
						/>
					</Grid>
					{error && (
						<Grid item>
							<Alert severity="error">{error.message || 'Unable to reset password'}</Alert>
						</Grid>
					)}
					<Grid item align="center">
						<StatefulButton
							variant="contained"
							size="large"
							color="primary"
							type="submit"
							isLoading={isLoading}
						>
							Reset Password
						</StatefulButton>
					</Grid>
				</Grid>
			)}
		</Box>
	)
}

export default PasswordResetFinalizer
