import { useCallback } from 'react'

import { Grid, makeStyles, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useMutation, useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import operationStates from '../../../common/operationStates'
import StatefulButton from '../../../common/StatefullButton'
import useFormWithZod from '../../../common/hooks/useFormWithZod'
import { getPrefillBillingDetails } from './paymentService'

const status = {
	[operationStates.inProgress]: 'info',
	[operationStates.error]: 'error',
	[operationStates.success]: 'success'
}

const paymentDetailsSchema = z.object({
	fullName: z.string().nonempty('Name on card is required'),
	address: z.string().nonempty('Address is required'),
	zip: z.string().nonempty('ZIP code is required')
})

const cardElementOptions = {
	style: {
		base: {
			fontSize: '16px',
			color: '#424770',
			'::placeholder': {
				color: '#aab7c4'
			}
		},
		invalid: {
			color: '#9e2146'
		}
	},
	hidePostalCode: true,
	iconStyle: 'solid'
}

const useStyles = makeStyles(({ shape }) => ({
	creditInput: {
		border: '1px solid',
		borderRadius: shape.borderRadius,
		padding: '18px 14px',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		'&:hover': {
			borderColor: `rgba(0, 0, 0, 0.87)`
		}
	}
}))

export default function CreditCardDetails({
	onFormValidationChange,
	formSubmitCallback,
	allowTransaction,
	submitButtonText = 'Submit',
	transactionStatus = { status: operationStates.initial, message: '' }
}) {
	const { createPaymentMethod } = useStripe()
	const { getElement } = useElements()

	const {
		formState: { errors },
		register,
		setValue,
		handleSubmit
	} = useFormWithZod(paymentDetailsSchema, {
		defaultValues: { fullName: '', address: '', zip: '' }
	})

	useQuery({
		queryKey: ['prefill-details'],
		queryFn: getPrefillBillingDetails,
		onSuccess: data => {
			for (let key in data) setValue(key, data[key])
		}
	})

	const { mutate, isLoading, error } = useMutation({
		mutationFn: async values => {
			const { error, paymentMethod } = await createPaymentMethod({
				type: 'card',
				card: getElement(CardElement)
			})

			if (error) throw error

			await formSubmitCallback(paymentMethod.id, values)
		},
		onError: error => {
			console.log('onError - error', error)
		}
	})

	const onCardElementChange = useCallback(
		event => {
			if (event.empty || !event.complete || event.error) {
				onFormValidationChange(false)
			} else {
				onFormValidationChange(true)
			}
		},
		[onFormValidationChange]
	)

	const classes = useStyles()
	return (
		<form onSubmit={handleSubmit(mutate)}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						{...register('fullName')}
						variant="outlined"
						label="Name on card"
						InputLabelProps={{ shrink: true }}
						error={!!errors.fullName}
						helperText={errors.fullName?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						{...register('address')}
						variant="outlined"
						label="Billing address"
						InputLabelProps={{ shrink: true }}
						error={!!errors.address}
						helperText={errors.address?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						{...register('zip')}
						variant="outlined"
						label="ZIP code"
						InputLabelProps={{ shrink: true }}
						error={!!errors.zip}
						helperText={errors.zip?.message}
						fullWidth
					/>
				</Grid>

				<Grid item xs={12}>
					<div className={classes.creditInput}>
						<CardElement onChange={onCardElementChange} options={cardElementOptions} />
					</div>
				</Grid>
				<Grid item xs={12}>
					<StatefulButton
						isLoading={isLoading}
						color="primary"
						variant="contained"
						disabled={!allowTransaction}
						type="submit"
					>
						{submitButtonText}
					</StatefulButton>
				</Grid>
				{error && transactionStatus.status === operationStates.error && (
					<Grid item xs={12}>
						<Alert severity={status[transactionStatus.status]}>{transactionStatus.message}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	)
}
