import React from 'react'

import { Typography, CircularProgress } from '@material-ui/core'

export default function InterviewLoader() {
	return (
		<div style={{ margin: '5em auto', textAlign: 'center' }}>
			<Typography variant="h5" gutterBottom>
				Loading interview...
			</Typography>
			<CircularProgress size={100} />
		</div>
	)
}
