import { useEffect, useRef, useMemo } from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import JobApplicationContext from '../../../../job-application/jobApplicationContext'
import JobApplication from '../../../../job-application/JobApplication'
import { createActions } from '../../../../job-application/jobApplicationReducer'

const noOpActions = createActions()
for (let key in noOpActions) {
	noOpActions[key] = () => {}
}

const useStyles = makeStyles(({ spacing, palette }) => ({
	preview: {
		background: palette.background.default,
		transform: 'translate(-25%, -25%) scale(0.5)',
		width: '200%',
		paddingBottom: spacing(2),
		border: `2px solid ${palette.grey[400]}`,
		borderRadius: spacing(1)
	}
}))

export default function JobApplicationPreview({
	currentStep,
	generalInfo = {
		companyName: 'Your Company Name',
		website: 'YourCompanySite.com',
		title: 'Job Title'
	},
	fullDescription = { description: 'Job description goes here' },
	appearance,
	docsConfig,
	questionsConfig,
	employerVideosConfig
}) {
	const previewContainerRef = useRef(false)
	const previewContainerWrapperRef = useRef(false)

	useEffect(() => {
		// Job application preview is scaled using css translate, which doesn't affect the actual flow of elements in the DOM,
		// hence the actual height is 2x bigger then the perceived height of the scaled element.
		// This is a pathetic attempt to compensate for that.
		previewContainerWrapperRef.current.style.height =
			previewContainerRef.current.getBoundingClientRect().height + 'px'
	})

	const anyDocuments = docsConfig.documents.length > 0
	const anyQuestions = questionsConfig.questions.length > 0

	const jobApplicationPreviewStages = useMemo(
		() => [
			{ jobApplicationStepIndex: 0, isPreview: true },
			{ jobDescriptionExpanded: true, isPreview: true },
			{ isPreview: true },
			{ isPreview: true },
			{
				jobApplicationStepIndex: anyDocuments ? 1 : 0,
				keepCollapsed: !anyDocuments,
				showAsCompleted: !anyDocuments,
				isPreview: true
			},
			{
				jobApplicationStepIndex: +anyQuestions + +anyDocuments,
				keepCollapsed: !anyQuestions,
				showAsCompleted: !anyQuestions,
				isPreview: true
			}
		],
		[anyDocuments, anyQuestions]
	)

	const classes = useStyles()
	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Job Application Preview
			</Typography>
			<Typography variant="caption" color="textSecondary">
				This is the form the applicant will fill out when applying for the job
			</Typography>
			<div ref={previewContainerWrapperRef}>
				<div className={classes.preview} ref={previewContainerRef}>
					<JobApplicationContext.Provider
						value={{
							jobInfo: {
								generalInfo: { ...generalInfo, description: fullDescription.description },
								appearance,
								documents: docsConfig.documents,
								questions: questionsConfig.questions,
								employerVideos: employerVideosConfig.employerVideos.filter(
									v => v.displayToCandidates
								)
							},
							actions: noOpActions
						}}
					>
						<JobApplication injectedState={jobApplicationPreviewStages[currentStep]} />
					</JobApplicationContext.Provider>
				</div>
			</div>
		</div>
	)
}
