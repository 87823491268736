import { Link } from 'react-router-dom'

import { Box, Grid, Typography, TextField } from '@material-ui/core'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import StatefulButton from '../../common/StatefullButton'
import RoundedCornersTriangle from '../../common/RoundedCornersTriangle'
import LabelledDivider from '../../common/LabelledDivider'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import routes from '../routes'
import { useAuth } from './AuthProvider'
import OAuthSignInOptions from './OAuthSignInOptions'

const loginSchema = z.object({
	email: z.string().email('Invalid email address').nonempty('Email is required'),
	password: z.string().nonempty('Password is required')
})

export default function Login() {
	const { signIn } = useAuth()

	const { mutate, isLoading, error } = useMutation({
		mutationFn: values => signIn(values.email, values.password)
	})

	const {
		formState: { errors },
		register,
		handleSubmit,
		watch
	} = useFormWithZod(loginSchema, { defaultValues: { email: '', password: '' } })

	const email = watch('email')

	return (
		<Box
			component="section"
			sx={{ maxWidth: 1300, width: '100%', mx: 'auto' }}
			pt={{ xs: 0, sm: 4, md: 10 }}
			px={{ xs: 4, sm: 10 }}
		>
			<Grid container spacing={6} alignItems="center">
				<Grid item md={7} style={{ textAlign: 'center' }}>
					<Box display={{ xs: 'none', md: 'block' }}>
						<RoundedCornersTriangle
							size={212}
							innerText="Haystack helps businesses and job seekers create human connections from the first step of the hiring process through video"
						/>
					</Box>
				</Grid>
				<Grid item md={5}>
					<Typography variant="h3" gutterBottom>
						Login
					</Typography>
					<Typography variant="h5" color="textSecondary" gutterBottom>
						Instantly connect with your candidates
					</Typography>
					<OAuthSignInOptions />
					<LabelledDivider sx={{ pt: 2 }} text="or login with email" />
					{error && (
						<Typography variant="body2" color="error" gutterBottom>
							{error.message}
						</Typography>
					)}
					<form onSubmit={handleSubmit(mutate)}>
						<TextField
							{...register('email')}
							label="Email"
							variant="outlined"
							margin="normal"
							error={!!errors.email}
							helperText={errors.email?.message}
							fullWidth
						/>
						<TextField
							{...register('password')}
							label="Password"
							variant="outlined"
							type="password"
							error={!!errors.password}
							helperText={errors.password?.message}
							margin="normal"
							fullWidth
						/>

						<Link
							to={routes.passwordReset.path + (email ? `?${new URLSearchParams({ email })}` : '')}
						>
							Forgot password?
						</Link>

						<Box sx={{ my: 2, textAlign: 'center' }}>
							<StatefulButton
								type="submit"
								color="primary"
								variant="contained"
								size="large"
								isLoading={isLoading}
							>
								Log In
							</StatefulButton>
						</Box>

						<Typography align="center" paragraph>
							Not registered? <Link to={routes.signup.path}>Sign Up</Link>
						</Typography>
					</form>
				</Grid>
			</Grid>
		</Box>
	)
}
