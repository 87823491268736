import { Grid, Typography } from '@material-ui/core'

import { useMutation } from '@tanstack/react-query'

import StatefulButton from '../../common/StatefullButton'
import { GoogleLogo, MicrosoftLogo } from './SocialLogos'
import { useAuth } from './AuthProvider'

function OAuthSignInOptions({ email }) {
	const { signInWithGoogle, signInWithMicrosoft } = useAuth()
	const {
		mutate: withGoogle,
		isLoading: isLoadingGoogle,
		error: googleError
	} = useMutation({ mutationFn: () => signInWithGoogle(email) })
	const {
		mutate: withMs,
		isLoading: isLoadingMs,
		error: msError
	} = useMutation({ mutationFn: () => signInWithMicrosoft(email) })

	const isAnyInProgress = isLoadingGoogle || isLoadingMs

	return (
		<Grid container spacing={1} direction="column" alignItems="stretch">
			<Grid item>
				<StatefulButton
					variant="outlined"
					color="primary"
					startIcon={<GoogleLogo />}
					isLoading={isLoadingGoogle}
					disabled={isAnyInProgress}
					fullWidth
					onClick={withGoogle}
				>
					Sign In with Google
				</StatefulButton>
				{googleError && (
					<Typography variant="caption" color="error">
						{googleError.message}
					</Typography>
				)}
			</Grid>
			<Grid item>
				<StatefulButton
					variant="outlined"
					color="primary"
					startIcon={<MicrosoftLogo />}
					isLoading={isLoadingMs}
					disabled={isAnyInProgress}
					fullWidth
					onClick={withMs}
				>
					Sign In with Microsoft
				</StatefulButton>
				{msError && (
					<Typography variant="caption" color="error">
						{msError.message}
					</Typography>
				)}
			</Grid>
		</Grid>
	)
}

export default OAuthSignInOptions
