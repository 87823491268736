import { useEffect } from 'react'

import { Grid, MenuItem, Typography, TextField, Box } from '@material-ui/core'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'
import InputMask from 'react-input-mask'

import StatefulButton from '../../common/StatefullButton'
import usStates from '../../common/usStates'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import { phoneRegex } from '../../common/commonRegex'
import { createCompany } from './registrationService'
import { useAuth } from '../auth/AuthProvider'

const companySchema = z.object({
	name: z.string().nonempty('Company name is required'),
	phone: z.string().regex(phoneRegex, 'Phone number is invalid'),
	address: z.string().nonempty('Address is required'),
	city: z.string().nonempty('City is required'),
	state: z.string().nonempty('State is required'),
	zipCode: z.string().nonempty('ZIP code is required')
})

export default function CompanyRegistration({ onContentChange }) {
	const { updateUserStatus } = useAuth()

	const { mutate, isLoading, error } = useMutation({
		mutationFn: createCompany,
		onSuccess: () => updateUserStatus({ isCompanySetup: true }),
		onError: error => {
			console.log('onError - error', error)
		}
	})

	const {
		register,
		formState: { errors },
		handleSubmit
	} = useFormWithZod(companySchema)

	useEffect(() => {
		onContentChange()
	}, [error, errors, onContentChange])

	return (
		<Box component="form" sx={{ p: 1.5 }} onSubmit={handleSubmit(mutate)}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={8}>
					<TextField
						label="Company Name"
						variant="outlined"
						{...register('name')}
						error={!!errors.name}
						helperText={errors.name?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<InputMask mask="999 999 9999" {...register('phone')}>
						{inputProps => (
							<TextField
								label="Company Phone"
								variant="outlined"
								{...inputProps}
								error={!!errors.phone}
								helperText={errors.phone?.message}
								fullWidth
							/>
						)}
					</InputMask>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Address"
						variant="outlined"
						{...register('address')}
						error={!!errors.address}
						helperText={errors.address?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="City"
						variant="outlined"
						{...register('city')}
						error={!!errors.city}
						helperText={errors.city?.message}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6} sm={3}>
					<TextField
						label="State"
						variant="outlined"
						{...register('state')}
						error={!!errors.state}
						helperText={errors.state?.message}
						SelectProps={{
							MenuProps: {
								PaperProps: {
									style: {
										maxHeight: 300
									}
								}
							}
						}}
						select
						fullWidth
					>
						{usStates.map(({ abbreviation }) => (
							<MenuItem key={abbreviation} value={abbreviation}>
								{abbreviation}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={6} sm={3}>
					<TextField
						label="Zip Code"
						variant="outlined"
						{...register('zipCode')}
						error={!!errors.zipCode}
						helperText={errors.zipCode?.message}
						fullWidth
					/>
				</Grid>
				{error && (
					<Grid item xs={12} align="center">
						<Typography variant="caption" color="error">
							Unable to process your request, please try again
						</Typography>
					</Grid>
				)}
				<Grid item xs={12} align="center">
					<StatefulButton isLoading={isLoading} type="submit" variant="contained" color="primary">
						Create
					</StatefulButton>
				</Grid>
			</Grid>
		</Box>
	)
}
