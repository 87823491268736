import { useCallback, useContext, useState } from 'react'
import { Button, Grid } from '@material-ui/core'

import ReRecordIcon from '@material-ui/icons/SettingsBackupRestore'
import UploadIcon from '@material-ui/icons/CloudUpload'

import videoHosts from '../../../../../../common/videoHosts'
import VideoRecorder, {
	recordingStatuses
} from '../../../../../../common/video-recorder/VideoRecorder'
import haystackDb from '../../../../../../common/clientStorage'
import VideoPlayerContainer from './VideoPlayerContainer'
import Outline from './Outline'
import Tips from './Tips'
import DevicePicker from './DevicePicker'
import EmployerVideosContext from './employerVideosContext'
import StatefulButton from '../../../../../../common/StatefullButton'

const views = {
	main: 'main',
	devicePicker: 'devicePicker',
	recorder: 'recorder'
}

function EmployerVideoRecorder({ videoType, video }) {
	const [currentView, setCurrentView] = useState(views.main)
	const [selectedMicId, setSelectedMicId] = useState()
	const [selectedCameraId, setSelectedCameraId] = useState()

	const { jobId, isVideoSaving, uploadRecordedVideo, updateRecordingStatus } =
		useContext(EmployerVideosContext)

	const onSliceRecorded = useCallback(
		async (slice, sliceIndex, isFinalSlice) => {
			haystackDb.employerVideoSlicesRepo.add({ jobId, videoType, slice })
		},
		[jobId, videoType]
	)

	const onVideoRecordingCancel = useCallback(() => setCurrentView(views.main), [])

	const onRecordingStatusChange = useCallback(
		async ({ from, to }) => {
			if (from === recordingStatuses.recording && to === recordingStatuses.stopped) {
				setCurrentView(views.main)
			}
			return await updateRecordingStatus({ from, to })
		},
		[updateRecordingStatus]
	)

	return (!video ||
		(video.videoHost !== videoHosts.haystack && video.videoHost !== videoHosts.bunny)) &&
		currentView === views.main ? (
		<Grid container spacing={3}>
			<Grid item xs={12} align="center">
				<Button
					color="secondary"
					variant="contained"
					onClick={() => setCurrentView(views.devicePicker)}
				>
					Select Camera and Microphone
				</Button>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Outline videoType={videoType} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<Tips />
			</Grid>
		</Grid>
	) : currentView === views.devicePicker ? (
		<Grid container spacing={3}>
			<Grid item xs={12} align="center">
				<DevicePicker
					selectedMicId={selectedMicId}
					selectedCameraId={selectedCameraId}
					onSelectMic={setSelectedMicId}
					onSelectCamera={setSelectedCameraId}
				/>
			</Grid>
			<Grid item xs={12} align="center">
				<Button onClick={() => setCurrentView(views.main)}>Cancel</Button>
				<Button
					variant="contained"
					color="secondary"
					style={{ marginLeft: 16 }}
					onClick={() => setCurrentView(views.recorder)}
				>
					Looks Good
				</Button>
			</Grid>
		</Grid>
	) : currentView === views.recorder ? (
		<Grid container spacing={3} justifyContent="center">
			<Grid item xs={11} align="center">
				<VideoRecorder
					micDeviceId={selectedMicId}
					cameraDeviceId={selectedCameraId}
					onRecordingStatusChange={onRecordingStatusChange}
					onSliceRecorded={onSliceRecorded}
					onCancel={onVideoRecordingCancel}
				/>
			</Grid>
		</Grid>
	) : (
		<Grid container spacing={3} justifyContent="center" style={{ minHeight: 300 }}>
			<Grid item xs={11}>
				<VideoPlayerContainer
					srcUrl={video.srcUrl}
					isPublished={video.videoHost === videoHosts.bunny}
				/>
			</Grid>
			{video?.isDirty && (
				<Grid item>
					<StatefulButton
						variant="contained"
						color="secondary"
						endIcon={<UploadIcon />}
						isLoading={isVideoSaving}
						onClick={uploadRecordedVideo}
					>
						Save Video
					</StatefulButton>
				</Grid>
			)}
			<Grid item>
				<Button
					variant="outlined"
					color="secondary"
					endIcon={<ReRecordIcon />}
					onClick={() => {
						setCurrentView(views.devicePicker)
					}}
				>
					Re-record
				</Button>
			</Grid>
		</Grid>
	)
}

export default EmployerVideoRecorder
