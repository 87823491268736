import { initializeApp } from 'firebase/app'

import {
	connectAuthEmulator,
	createUserWithEmailAndPassword,
	getAuth,
	sendEmailVerification as firebaseSendEmailVerification,
	signInWithEmailAndPassword,
	applyActionCode,
	updateProfile as firebaseUpdateProfile,
	sendPasswordResetEmail as firebaseSendPasswordResetEmail,
	verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
	confirmPasswordReset as firebaseConfirmPasswordReset,
	GoogleAuthProvider,
	OAuthProvider,
	signInWithPopup
} from 'firebase/auth'

import config from '../../clientSettings'
import userFriendlyErrorMap from './userFriendlyErrorMap'
import { get } from '../restService'

const app = initializeApp(config.auth)
const auth = getAuth(app)

if (process.env.NODE_ENV === 'development') {
	connectAuthEmulator(auth, 'http://localhost:9099')
}

function getUser(credentials) {
	if (!credentials) return null
	const { email, uid, emailVerified, displayName } = credentials
	return { email, uid, emailVerified, displayName }
}

export function onAuthStateChanged(callback) {
	return auth.onAuthStateChanged(credentials => callback(getUser(credentials)))
}

export function getEmployerStatus() {
	return get('/api/employers/me/status')
}

async function performApiCall(fn) {
	try {
		return await fn()
	} catch (error) {
		throw new Error(userFriendlyErrorMap[error.code] || error.message, { cause: error })
	}
}

export async function signUp(email, password) {
	return performApiCall(async () => {
		const credentials = await createUserWithEmailAndPassword(auth, email, password)
		return getUser(credentials)
	})
}

export async function login(email, password) {
	return performApiCall(async () => {
		const credentials = await signInWithEmailAndPassword(auth, email, password)
		return getUser(credentials)
	})
}

export function signInWithGoogle(email) {
	return performApiCall(async () => {
		const provider = new GoogleAuthProvider()
		provider.setCustomParameters({ login_hint: email })
		return signInWithPopup(auth, provider)
	})
}

export function signInWithMicrosoft(email) {
	return performApiCall(async () => {
		const provider = new OAuthProvider('microsoft.com')
		provider.setCustomParameters({ login_hint: email })
		return signInWithPopup(auth, provider)
	})
}

export async function logout() {
	return performApiCall(async () => auth.signOut())
}

export async function getIdToken({ forceRefresh } = { forceRefresh: false }) {
	return performApiCall(async () => auth.currentUser?.getIdToken(forceRefresh))
}

export async function sendEmailVerification() {
	return performApiCall(async () => firebaseSendEmailVerification(auth.currentUser))
}

export async function getFreshUserData() {
	return performApiCall(async () => {
		await auth.currentUser?.reload()
		return getUser(auth.currentUser)
	})
}

export async function applyConfirmationCode(code) {
	return performApiCall(async () => {
		await applyActionCode(auth, code)
		return getUser(auth.currentUser)
	})
}

export async function updateProfile({ displayName, photoURL }) {
	return performApiCall(async () => {
		await firebaseUpdateProfile(auth.currentUser, { displayName, photoURL })
		return getUser(auth.currentUser)
	})
}

export async function sendPasswordResetEmail(email) {
	return performApiCall(async () => firebaseSendPasswordResetEmail(auth, email))
}

export async function verifyPasswordResetCode(code) {
	return performApiCall(async () => firebaseVerifyPasswordResetCode(auth, code))
}

export async function confirmPasswordReset(code, password) {
	return performApiCall(async () => firebaseConfirmPasswordReset(auth, code, password))
}
