import { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import LargeLoader from '../../../../common/LargeLoader'
import JobApplicationEditor from './JobApplicationEditor'
import { getJobSettings } from '../../jobService'
import routes from '../../../routes'

export default function JobSetup() {
	const { slug } = useParams()
	const history = useHistory()
	const [job, setJob] = useState()

	useEffect(() => {
		let requestIsCancelled = false

		getJobSettings(slug).then(job => {
			if (requestIsCancelled) return

			setJob(job)
		})

		return () => {
			requestIsCancelled = true
		}
	}, [slug])

	const onEditComplete = useCallback(() => history.push(routes.jobs.getLink()), [history])
	const onJobTitleChange = useCallback(
		newSlug => history.replace(routes.jobApplicationForm.getLink(newSlug)),
		[history]
	)
	return job ? (
		<JobApplicationEditor
			job={job}
			onJobCreated={onJobTitleChange}
			onEditComplete={onEditComplete}
		/>
	) : (
		<LargeLoader title={`Loading...`} />
	)
}
