import { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import EmployerNoteEditor from './EmployerNoteEditor'
import { getDocumentShortLivedAccessUrl } from '../../../../common/cloudStorageService'

const getInitialPreviewUrl = (filename, docType) => {
	const [name, extension] = filename.split('.')
	let uri
	if (name.toLowerCase() !== docType.toLowerCase()) {
		uri = `/uploads/applicants/documents/${filename}`
		if (extension.toLowerCase() !== 'pdf') {
			uri = `https://view.officeapps.live.com/op/embed.aspx?src=${window.origin}/${uri}`
		}
	}
	return uri
}

function DocumentPreview({ candidateDocId, candidateId, noteText, filename, docType }) {
	const { spacing } = useTheme()

	const [url, setUrl] = useState(filename ? getInitialPreviewUrl(filename, docType) : '')

	useEffect(() => {
		if (url || !filename) return
		// if url is empty that means it needs blob storage url
		getDocumentShortLivedAccessUrl(candidateDocId).then(({ url }) => {
			if (filename.split('.').pop().toLowerCase() === 'pdf') {
				setUrl(url)
			} else {
				setUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`)
			}
		})
	}, [url, filename, candidateDocId])

	return (
		<div style={{ padding: spacing(3) }}>
			<Grid container spacing={3} justifyContent="center">
				<Grid item>
					<iframe
						title="Document Preview"
						src={url}
						style={{ height: '85vh', width: 850, maxWidth: '100vw', border: 'none' }}
					/>
				</Grid>
				<Grid item sm={12} md={3} xl={2}>
					<EmployerNoteEditor
						label="Notes"
						candidateId={candidateId}
						noteType="candidateApplication"
						noteText={noteText}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default DocumentPreview
