import { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import SwipeableViews from 'react-swipeable-views'

import LargeLoader from '../../../common/LargeLoader'
import JobApplicationSetupCompleteMessage from './JobApplicationSetupCompleteMessage'
import VideoInterviewSetupWelcomeMessage from './VideoInterviewSetupWelcomeMessage'
import JobApplicationEditor from './application-form/JobApplicationEditor'
import VideoInterviewConfigurator from './interview-setup/VideoInterviewConfigurator'
import JobLinksEditor from './links/JobLinksEditor'

import { getNewJobTemplate, getJobSettings } from '../jobService'
import routes from '../../routes'

export default function NewJobSetup() {
	const { slug } = useParams()
	const history = useHistory()
	const [currentStepIndex, setCurrentStepIndex] = useState(0)
	const [newJob, setNewJob] = useState()
	const [job, setJob] = useState({ generalInfo: {} })

	const fetchNeededRef = useRef(true)

	useEffect(() => {
		let requestIsCancelled = false

		if (slug === 'new') {
			getNewJobTemplate().then(jobTemplate => {
				if (requestIsCancelled) return
				jobTemplate.linksConfig = {
					socialLinks: [],
					jobBoardLinks: []
				}

				setNewJob(jobTemplate)
			})
		} else if (fetchNeededRef.current) {
			getJobSettings(slug).then(job => {
				if (requestIsCancelled) return
				setNewJob(job)
			})
		}

		return () => {
			requestIsCancelled = true
		}
	}, [slug])

	const onJobCreated = useCallback(
		newSlug => {
			fetchNeededRef.current = false
			// in case if user refreshes the page after the new job was actually created, it will make like editing the existing job
			history.replace(routes.editJob.getLink(newSlug))
		},
		[history]
	)

	const onJobApplicationEditComplete = useCallback(job => {
		setJob(job)
		setCurrentStepIndex(index => index + 1)
	}, [])

	const handleOnTransitionEnd = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return newJob ? (
		<div id="my-div">
			<SwipeableViews
				index={currentStepIndex}
				style={{ width: '100vw' }}
				onTransitionEnd={handleOnTransitionEnd}
			>
				<JobApplicationEditor
					job={newJob}
					onJobCreated={onJobCreated}
					onEditComplete={onJobApplicationEditComplete}
				/>
				<JobApplicationSetupCompleteMessage
					onBack={() => setCurrentStepIndex(index => index - 1)}
					onProceed={() => setCurrentStepIndex(index => index + 1)}
					onSkip={() => setCurrentStepIndex(index => index + 2)}
				/>
				<JobLinksEditor
					jobSlug={job.slug}
					onEditComplete={() => setCurrentStepIndex(index => index + 1)}
				/>
				<VideoInterviewSetupWelcomeMessage
					onBack={() => setCurrentStepIndex(index => index - 2)}
					onProceed={() => setCurrentStepIndex(index => index + 1)}
					onSkip={() => history.push(routes.jobs.getLink())}
				/>
				<VideoInterviewConfigurator
					jobSlug={job.slug}
					onEditComplete={() => history.push(routes.jobs.getLink())}
				/>
			</SwipeableViews>
		</div>
	) : (
		<LargeLoader title={`Loading...`} />
	)
}
