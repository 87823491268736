import { get, post, put, patch } from '../../../restService'

export const toViewModel = candidate => ({
	...candidate,
	appliedDateTime: new Date(candidate.appliedDateTime),
	invitedDateTime: candidate.invitedDateTime && new Date(candidate.invitedDateTime),
	interviewCompletedDateTime:
		candidate.interviewCompletedDateTime && new Date(candidate.interviewCompletedDateTime),
	faceToFaceDateTime: candidate.faceToFaceDateTime && new Date(candidate.faceToFaceDateTime),
	rejection_email_date: candidate.rejection_email_date && new Date(candidate.rejection_email_date)
})

// Todo: should take a real company id, once the storage is company based, not employer based
//  -1 is just a filler for now, should be removed once storage transition is complete
export const getCompanyStorageUri = async (companyId = -1) => {
	return await get(`/api/companies/${companyId}/storage-root-url`)
}

export async function advanceCandidate(candidateId) {
	const candidate = await put(`/api/candidates/${candidateId}/advance`, {})
	return toViewModel(candidate)
}

export async function toggleCandidateAcknowledgement(candidateId) {
	const candidate = await put(`/api/candidates/${candidateId}/toggle`, {})
	return toViewModel(candidate)
}

export async function rejectCandidate(candidateId) {
	const candidate = await put(`/api/candidates/${candidateId}/reject`, {})
	return toViewModel(candidate)
}

export async function undoStageTransition(candidateIds) {
	const candidates = await post(`/api/candidates/undo-stage-transition`, candidateIds)
	return candidates.map(c => toViewModel(c))
}

export async function reinviteApplicantToInterview(candidateId) {
	const candidate = await put(`api/candidates/${candidateId}/reinvite`, {})
	return toViewModel(candidate)
}

export async function saveRating(answerId, rating) {
	return await patch(`/api/video-answers/${answerId}/rating`, { rating })
}

export const saveNotes = async (candidateId, noteType, noteText) =>
	await patch(`/api/candidates/${candidateId}/notes`, { noteType, noteText })

export const sendRejectionEmail = async (candidateIds, subject, body) =>
	await post(`/api/candidates/send-rejection-email`, {
		candidateIds,
		subject,
		body
	})
