async function makeRequest(url, options) {
	const response = await fetch(url, options)
	if (response.ok) return response.json()
	else throw response
}

export const get = async url => makeRequest(url)

export const post = async (url, payload, { headers, ...restOptions } = {}) =>
	makeRequest(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		...restOptions,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers
		}
	})

export const postFormData = async (url, formData, options = {}) =>
	makeRequest(url, {
		method: 'POST',
		body: formData
	})

export const deleteResource = async url => makeRequest(url, { method: 'DELETE' })
