import { memo, useState, useCallback, useEffect, useMemo, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import AddFromListIcon from '@material-ui/icons/PlaylistAdd'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import SortableList from '../../../../employer-common/SortableList'
import ExampleQuestionPicker from '../../../../../common/ExampleQuestionPicker'
import QuestionSetup from './QuestionSetup'
import exampleQuestions from './example-questions'
import JobSetupContext from '../jobSetupContext'

const useStyles = makeStyles(({ spacing }) => ({
	root: { padding: spacing(1, 0) },
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: spacing(2)
	}
}))

export default memo(function JobQuestionsSetup({
	questions = [],
	validationSummary,
	updateHeight = () => {}
}) {
	const actions = useContext(JobSetupContext)
	const [exampleQuestionsDialogOpen, toggleExampleQuestionsDialog] = useState(false)

	useEffect(() => updateHeight(), [updateHeight, questions.length])

	const buttons = useMemo(
		() => [
			{
				label: 'Create New',
				Icon: AddIcon,
				onClick: () => actions.addQuestion({ text: '', answerChoices: [], isInEditMode: true })
			},
			{
				label: 'Choose from Examples',
				Icon: AddFromListIcon,
				onClick: async () => {
					toggleExampleQuestionsDialog(true)
				}
			}
		],
		[actions]
	)

	const updateQuestionOrder = useCallback(
		({ oldIndex, newIndex }) =>
			oldIndex !== newIndex && actions.updateQuestionOrder(oldIndex, newIndex),
		[actions]
	)

	const onQuestionSelected = useCallback(
		exampleQuestion => {
			const question = {
				questionType: exampleQuestion.type,
				text: exampleQuestion.text,
				answerChoices: (exampleQuestion.answers || []).map(text => ({ text })),
				isInEditMode: true
			}

			actions.addQuestion(question)
		},
		[actions]
	)

	const closeExampleQuestionPicker = useCallback(() => toggleExampleQuestionsDialog(false), [])

	const classes = useStyles()
	const anyQuestionInEditMode = questions.find(it => it.isInEditMode)
	return (
		<div className={classes.root}>
			<Typography variant="caption" color="textSecondary">
				<InfoIcon fontSize="inherit" style={{ verticalAlign: 'text-top' }} /> Create questions
				applicants have to fill out when applying (Limit 5 Questions)
			</Typography>
			<SortableList onSortEnd={updateQuestionOrder}>
				{questions.map(question => (
					<QuestionSetup
						key={question.id}
						{...question}
						enabled={
							!validationSummary.isReadOnly &&
							(!anyQuestionInEditMode || anyQuestionInEditMode === question)
						}
						isSortable={!validationSummary.isReadOnly && !anyQuestionInEditMode}
						validationSummary={validationSummary[question.id || question.clientId]}
						updateQuestion={actions.updateQuestion}
						saveQuestion={actions.saveQuestion}
						deleteQuestion={actions.removeQuestion}
						updateHeight={updateHeight}
					/>
				))}
			</SortableList>
			{!anyQuestionInEditMode && (
				<div className={classes.buttonContainer}>
					{buttons.map(({ label, Icon, onClick }, index) => (
						<Button
							key={index}
							startIcon={<Icon />}
							disabled={validationSummary.isReadOnly || validationSummary.maxQuestionCountReached}
							onClick={onClick}
							color="secondary"
						>
							{label}
						</Button>
					))}
				</div>
			)}

			<ExampleQuestionPicker
				open={exampleQuestionsDialogOpen}
				closeDialog={closeExampleQuestionPicker}
				onQuestionSelect={onQuestionSelected}
				exampleQuestions={exampleQuestions}
			/>
		</div>
	)
})
