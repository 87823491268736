import { get, post, deleteResource, put, patch, putFormData } from '../restService'
import { toViewModel } from './job/applicants/applicantService'

// Todo: eventually, when supporting multiple companies do this:
// export const getJobs = companyId => get(`/api/companies/${companyId}/job-overviews`)
export const getJobs = companyId => get(`/api/job-overviews`)

export const cloneJob = id => post(`/api/jobs/${id}/clone`, {})
export const getNewJobTemplate = () => get('/api/jobs/new-job-template')

export const getJobSettings = jobSlug => get(`/api/jobs/${jobSlug}?variant=job-settings`)
export const getJobWithLinks = jobSlug => get(`/api/jobs/${jobSlug}?variant=job-links`)
export const getJobWithVideoInterviewSettings = jobSlug =>
	get(`/api/jobs/${jobSlug}?variant=video-interview-settings`)
export const getJobWithCandidates = async jobSlug => {
	const jobInfo = await get(`/api/jobs/${jobSlug}?variant=candidates`)
	jobInfo.candidates = jobInfo.candidates.map(toViewModel)
	return jobInfo
}

export const createJob = async job => await post('/api/jobs/', job)
export const updateJob = async job => await put(`/api/jobs/${job.id}`, job)

export const updateJobAppearance = async (jobId, jobAppearance) => {
	const formData = new FormData()
	for (let key in jobAppearance) {
		if (jobAppearance[key] !== null) {
			formData.append(key, jobAppearance[key])
		}
	}
	return await putFormData(`/api/jobs/${jobId}/appearance`, formData)
}

export const addNewJobDoc = async (jobId, doc) => await post(`/api/jobs/${jobId}/documents`, doc)
export const saveJobDoc = async doc => await put(`/api/job-documents/${doc.id}`, doc)
export const deleteJobDoc = async docId => await deleteResource(`/api/job-documents/${docId}`)

export const createJobQuestion = (jobId, question) => post(`/api/jobs/${jobId}/questions`, question)
export const updateJobQuestion = question => put(`/api/job-questions/${question.id}`, question)
export const saveQuestionsOrder = (jobId, oldIndex, newIndex) =>
	put(`/api/jobs/${jobId}/questions/reorder`, { oldIndex, newIndex })
export const deleteJobQuestion = questionId => deleteResource(`/api/job-questions/${questionId}`)

export const updateVideoInterviewDeadline = (jobId, daysToComplete) =>
	patch(`/api/jobs/${jobId}/days-to-complete`, { daysToComplete: daysToComplete })

export const getInvitationEmailConfig = jobId =>
	get(`/api/jobs/${jobId}/interview-invitation-email/config`)
export const getInvitationEmailDefault = () => get(`/api/interview-invitation-emails/default`)
export const saveInvitationEmailConfig = (jobId, config) =>
	put(`/api/jobs/${jobId}/interview-invitation-email/config`, config)

export const createJobLink = (jobId, payload) => post(`/api/jobs/${jobId}/links`, payload)

export const updateJobLink = payload => put(`/api/job-links/${payload.id}`, payload)

export const getNotifications = jobId => get(`/api/jobs/${jobId}/notifications`)
export const createNotification = jobId => post(`/api/jobs/${jobId}/notifications`, {})
export const removeNotification = id => deleteResource(`/api/notifications/${id}`)

export const toggleJobStatus = jobId => patch(`/api/jobs/${jobId}/toggle-status`, {})
