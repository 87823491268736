import { useState, useCallback, useContext, useEffect } from 'react'

import { Typography, Grid, TextField, MenuItem } from '@material-ui/core'

import { VideoInterviewContext } from '../InterviewApp'

export default function SelectMediaDevice({
	type,
	name,
	directionsText,
	enableDevice,
	DevicePicker,
	setError,
	noPreview = false
}) {
	const [{ micDevice, vidDevice }, dispatch] = useContext(VideoInterviewContext)

	const [availableDevices, setDevices] = useState([])
	const [errorMsg, setErrorMsg] = useState(false)

	const selectDevice = useCallback(
		deviceId =>
			dispatch({
				type: type === 'audio' ? 'setMic' : 'setCam',
				deviceId: deviceId
			}),
		[type, dispatch]
	)

	useEffect(() => {
		if (!enableDevice) return
		navigator.mediaDevices
			.getUserMedia({ [type]: true }) // makes sure the browser has access to the media devices of the requested type, otherwise .enumerateDevices() returns devices with empty labels.
			.then(() => navigator.mediaDevices.enumerateDevices())
			.then(devices => {
				const inputDevices = devices.filter(device => device.kind === `${type}input`)
				setDevices(inputDevices)

				if (inputDevices.length) selectDevice(inputDevices[0].deviceId)
				else setErrorMsg(`You have no ${name}s connected.  Please plug one in and try again.`)
			})
			.catch(e => {
				console.log(e.name, e)
				let errorMessage = 'An error occurred, please try again.'
				if (e.name) {
					switch (e.name) {
						case 'NotAllowedError':
							errorMessage = `Access to your ${name} is not allowed. Make sure you allow the browser to use your ${name}. If you don't see a permission prompt. Check you browser settings.`
							break
						case 'NotFoundError':
						case 'DevicesNotFoundError':
							errorMessage = `We encountered an error retrieving your ${type} devices. Please make sure you have a ${name} connected.`
							break
						case 'NotReadableError':
							errorMessage = `Can't access your ${name}. Most likely it's used by another tab or application.`
							break
						case 'AbortError':
							errorMessage = `Can't access your ${name}. Most likely it's used by another tab or application or the device is disconnected.`
							break
						case 'SecurityError':
							errorMessage = `Your system setting do not allow access to ${type} devices.`
							break
						case 'OverconstrainedError':
							errorMessage = "Can't find the specified device"
							break
						default:
							errorMessage =
								'Unexpected error occured, please try again. If the issue persists, please contact support.'
					}
				}

				setErrorMsg(errorMessage)
				setError(true)
			})
	}, [name, enableDevice, type, selectDevice, setError])

	const selectedDevice = type === 'audio' ? micDevice : vidDevice
	return (
		<div>
			<Typography variant="body1" component="p" style={{ marginBottom: '1em' }}>
				{directionsText} If not, select a different {name}.
			</Typography>
			<Grid container justifyContent="space-around">
				<Grid item xs={10} sm={5} style={{ textAlign: 'center' }}>
					{errorMsg ? (
						<>
							<Typography variant="body1" component="p" color="error">
								{errorMsg}
							</Typography>
							{/* <Button variant="outlined" onClick={handleClearError} style={{ marginLeft: 1 }}>
								OK
							</Button> */}
						</>
					) : (
						!noPreview &&
						DevicePicker && (
							<DevicePicker
								enableDevice={enableDevice}
								proposedDeviceId={selectedDevice.deviceId}
							/>
						)
					)}
				</Grid>
				<Grid item xs={10} sm={5} style={{ textAlign: 'center' }}>
					<TextField
						id={`select-${name}`}
						label={`Select ${name}`}
						value={selectedDevice.deviceId ? selectedDevice.deviceId.exact : 'loading'}
						onChange={e => selectDevice(e.target.value)}
						select
						disabled={!selectedDevice.deviceId}
						margin="dense"
						style={{ maxWidth: 300, textAlign: 'left' }}
						fullWidth
					>
						{!selectedDevice.deviceId ? (
							<MenuItem value="loading">Loading devices...</MenuItem>
						) : (
							availableDevices.map((device, key) => (
								<MenuItem key={key} value={device.deviceId}>
									{device.label}
								</MenuItem>
							))
						)}
					</TextField>
				</Grid>
			</Grid>
		</div>
	)
}
