import { useContext, useCallback, useState } from 'react'

import { Rating } from '@material-ui/lab'

import { ApplicantDashboardContext } from './applicantDashboardStore'
import SimpleSavingIndicator from './SimpleSavingIndicator'
import operationStates from '../../../../common/operationStates'

function InteractiveRating({ id, rating }) {
	const actions = useContext(ApplicantDashboardContext)
	const [ratingPersistenceState, setRatingPersistenceState] = useState()

	const persistRating = useCallback(
		async ({ target }, rating) => {
			const answerId = target.name
			setRatingPersistenceState(operationStates.inProgress)
			try {
				await actions.updateRating(+answerId, rating)
				setRatingPersistenceState(operationStates.success)
			} catch (e) {
				setRatingPersistenceState(operationStates.error)
			}
		},
		[actions]
	)

	return (
		<>
			<Rating name={id.toString()} value={rating} size="small" onChange={persistRating} />
			&nbsp;
			<SimpleSavingIndicator
				isLoading={ratingPersistenceState === operationStates.inProgress}
				isSuccess={ratingPersistenceState === operationStates.success}
				isError={ratingPersistenceState === operationStates.error}
				errorMsg="Error saving rating!"
			/>
		</>
	)
}

export default InteractiveRating
