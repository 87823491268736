import { useContext } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import JobApplicationContext from './jobApplicationContext'

export default function JobApplicationSubmission({ acknowledgements = {acknowledge : false}, validationSummary = {} }) {
	const { actions } = useContext(JobApplicationContext)
	return (
		<FormControl error={!!validationSummary.acknowledge}>
			<FormControlLabel
				control={<Checkbox/>}
				label="I agree to allow this employer, Haystack, and Haystack's affiliates to contact me using the Contact Information I provided on this form."
				name="acknowledge"
				checked={acknowledgements.acknowledge}
				onChange={({ target }) => actions.toggleConsent({[target.name]: target.checked })}
			/>
			{validationSummary.acknowledge && <FormHelperText>{validationSummary.acknowledge}</FormHelperText>}
		</FormControl>
	)
}
