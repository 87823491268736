import { useCallback, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'

import RecordIcon from '@material-ui/icons/RadioButtonChecked'
import StopIcon from '@material-ui/icons/Stop'
import CancelIcon from '@material-ui/icons/Block'

import clsx from 'clsx'

import Timer from '../../interview/video-interview/Timer'
import VideoFeed from './VideoFeed'

export const recordingStatuses = {
	stopped: 'stopped',
	preRecordingCountDownStarted: 'preRecordingCountDownStarted',
	recording: 'recording'
}

const statusLabels = {
	[recordingStatuses.stopped]: {
		recorder: 'Not recording yet',
		button: { text: 'Start Recording', icon: <RecordIcon color="error" /> }
	},
	[recordingStatuses.preRecordingCountDownStarted]: {
		recorder: 'Recording in',
		button: { text: 'Cancel', icon: <CancelIcon /> }
	},
	[recordingStatuses.recording]: {
		recorder: (
			<span>
				<RecordIcon color="error" style={{ verticalAlign: 'middle' }} /> Recording
			</span>
		),
		button: { text: 'Stop Recording', icon: <StopIcon /> }
	}
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
	videoWrapper: {
		position: 'relative',
		minHeight: 300,
		background: palette.common.black
	},
	interviewPanel: {
		paddingBottom: spacing(0.1),
		maxWidth: 640,
		[breakpoints.down('xs')]: {
			margin: 0,
			flexGrow: 1
		}
	},
	timerLabel: {
		verticalAlign: 'middle',
		marginRight: spacing(0.5)
	},
	timer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 55,
		minWidth: 115,
		position: 'absolute',
		top: 0,
		right: 0,
		backgroundColor: 'rgba(255,255,255,0.8)',
		padding: spacing(0, 1),
		transition: 'transform 200ms, height 200ms, width 200ms, background-color 200ms',
		transformOrigin: 'top right',
		'&.fullScreen': {
			transform: 'scale(2)',
			height: '50%',
			width: '50%',
			backgroundColor: 'rgba(255,255,255,0.5)'
		}
	}
}))

const noOp = () => {}

const VideoRecorder = ({
	micDeviceId,
	cameraDeviceId,
	onRecordingStatusChange = noOp,
	onSliceRecorded = noOp,
	onCancel
}) => {
	const [recordingStatus, setRecordingStatus] = useState(recordingStatuses.stopped)
	const [cameraIsReady, setCameraIsReady] = useState(false)
	const [startDelay, setStartDelay] = useState({ totalSeconds: 3, start: false })

	const onTimerFinished = useCallback(() => {
		setRecordingStatus(recordingStatuses.recording)
		onRecordingStatusChange({
			from: recordingStatuses.preRecordingCountDownStarted,
			to: recordingStatuses.recording
		})
	}, [onRecordingStatusChange])

	const onButtonClick = useCallback(async () => {
		if (recordingStatus === recordingStatuses.stopped) {
			const canProceed = await onRecordingStatusChange({
				from: recordingStatus,
				to: recordingStatuses.preRecordingCountDownStarted
			})

			if (canProceed) {
				setStartDelay({ ...startDelay, start: true })
				setRecordingStatus(recordingStatuses.preRecordingCountDownStarted)
			}
		} else {
			setStartDelay({ ...startDelay, start: false })
			setRecordingStatus(recordingStatuses.stopped)
			onRecordingStatusChange({ from: recordingStatus, to: recordingStatuses.stopped })
		}
	}, [recordingStatus, onRecordingStatusChange, startDelay])

	const handleCameraReady = useCallback(() => {
		setCameraIsReady(true)
	}, [])

	const onSliceReceived = useCallback(
		(blob, sliceIndex, isFinalSlice) => {
			onSliceRecorded(blob, sliceIndex, isFinalSlice)
			if (isFinalSlice) {
				onRecordingStatusChange({
					from: recordingStatuses.recording,
					to: recordingStatuses.stopped
				})
			}
		},
		[onSliceRecorded, onRecordingStatusChange]
	)

	const isRecording = recordingStatus === recordingStatuses.recording

	const classes = useStyles()
	return (
		<div className={classes.interviewPanel}>
			<div className={classes.videoWrapper}>
				<VideoFeed
					isRecording={isRecording}
					camDevice={cameraDeviceId}
					micDevice={micDeviceId}
					onInitialized={handleCameraReady}
					onSliceRecorded={onSliceReceived}
				/>
				{cameraIsReady && (
					<div className={clsx(classes.timer, !isRecording && cameraIsReady && 'fullScreen')}>
						<Typography
							variant="subtitle2"
							color={isRecording ? 'error' : 'textPrimary'}
							className={classes.timerLabel}
						>
							{statusLabels[recordingStatus].recorder}
						</Typography>
						{startDelay.totalSeconds > 0 && !isRecording && (
							<Timer timer={startDelay} onTimerElapsed={onTimerFinished} />
						)}
					</div>
				)}
			</div>
			<Grid container spacing={2} style={{ marginTop: 16 }} justifyContent="center">
				{onCancel && recordingStatus === recordingStatuses.stopped && (
					<Grid item>
						<Button onClick={onCancel}>Cancel</Button>
					</Grid>
				)}

				<Grid item>
					<Button
						disabled={!cameraIsReady}
						variant="contained"
						color="secondary"
						endIcon={statusLabels[recordingStatus].button.icon}
						onClick={onButtonClick}
					>
						{statusLabels[recordingStatus].button.text}
					</Button>
				</Grid>
			</Grid>
		</div>
	)
}

export default VideoRecorder
