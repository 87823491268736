import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, Checkbox } from '@material-ui/core'
import RequiredToggle from './RequiredToggle'

const useStyles = makeStyles(({ spacing }) => ({
	listItem: {
		display: 'flex',
		alignItems: 'center',
		padding: spacing(1, 1, 1, 2),
		margin: spacing(1)
	},
	listLabel: { flex: 1, cursor: isReadOnly => (isReadOnly ? 'default' : 'pointer') }
}))
export default function JobDocumentConfig({
	id,
	documentType,
	displayName,
	included,
	required,
	isReadOnly = false,
	toggleDocument,
	updateDocument
}) {
	const classes = useStyles(isReadOnly)
	return (
		<Paper className={classes.listItem} elevation={included ? 4 : 1}>
			<label className={classes.listLabel}>
				<Checkbox
					checked={included}
					name={documentType}
					onChange={e =>
						toggleDocument(e.currentTarget.checked, { id, documentType, required, displayName })
					}
					disabled={isReadOnly}
				/>
				<Typography
					component="span"
					style={{ verticalAlign: 'middle' }}
					color={isReadOnly ? 'textSecondary' : 'textPrimary'}
				>
					{displayName}
				</Typography>
			</label>
			<RequiredToggle
				name={documentType}
				included={included}
				value={required}
				isReadOnly={isReadOnly}
				onChange={e => {
					updateDocument({ id, documentType, required: e.currentTarget.checked, displayName })
				}}
			/>
		</Paper>
	)
}
