import { FormControlLabel, Switch } from '@material-ui/core'

function ShowToCandidatesToggle({ value, onToggle, videoId }) {
	return (
		<FormControlLabel
			label="Show to candidates"
			disabled={!videoId}
			control={
				<Switch
					name="displayToCandidates"
					checked={value}
					onChange={e => {
						onToggle(videoId, e.target.checked)
					}}
				/>
			}
		/>
	)
}

export default ShowToCandidatesToggle
