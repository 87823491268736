import { post, patch, postFormData } from '../restService'

export const saveEmbeddedVideoUrl = async (companyId, jobId, video) =>
	await post(
		jobId ? `/api/jobs/${jobId}/employer-videos` : `/api/companies/${companyId}/employer-videos`,
		video
	)

export const toggleShowToCandidates = async (id, displayToCandidates) =>
	await patch(`/api/employer-videos/${id}/displayToCandidates`, displayToCandidates)

export const publishVideo = async (videoId, blob) => {
	const formData = new FormData()
	formData.append('videoFile', blob)
	return await postFormData(`/api/employer-videos/${videoId}/publish`, formData)
}

export const publishNewVideo = async ({ companyId, jobId, videoType }, blob) => {
	const formData = new FormData()
	formData.append('videoFile', blob)
	if (companyId) {
		formData.append('companyId', companyId)
	}
	if (jobId) {
		formData.append('jobId', jobId)
	}
	formData.append('videoType', videoType)
	return await postFormData('/api/employer-videos/publish', formData)
}
