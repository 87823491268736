import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'

import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'

import EmployerApp from './employer/EmployerApp'
import history from './employer/browserHistory'
import theme from './haystack-theme'
import { AuthProvider } from './employer/auth/AuthProvider'

const queryClient = new QueryClient()

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<Router history={history}>
					<CssBaseline />
					<MuiThemeProvider theme={theme}>
						<SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
							<EmployerApp />
						</SnackbarProvider>
					</MuiThemeProvider>
				</Router>
			</AuthProvider>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
