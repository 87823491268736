import { useState, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { TextField, IconButton, Grid } from '@material-ui/core'

import RemoveIcon from 'mdi-material-ui/MinusCircleOutline'

import SortableList from '../../../../employer-common/SortableList'

const useStyles = makeStyles(({ spacing, palette }) => ({
	answer: { backgroundColor: palette.background.paper },
	emptyField: { marginTop: spacing(0.5), marginLeft: spacing(4) }
}))

const Answer = ({
	sortIndex,
	text,
	indexOfInputToFocus,
	setInputOfIndexToFocus,
	updateAnswer,
	deleteAnswer
}) => {
	const focusNext = useCallback(
		({ key }) => {
			if (key === 'Enter') {
				setInputOfIndexToFocus(index => index + 1)
			}
		},
		[setInputOfIndexToFocus]
	)

	const inputRefHandler = useCallback(
		el => el !== null && indexOfInputToFocus === sortIndex && el.focus(),
		[indexOfInputToFocus, sortIndex]
	)

	const onFocus = useCallback(() => setInputOfIndexToFocus(sortIndex), [
		setInputOfIndexToFocus,
		sortIndex
	])

	const onBlur = useCallback(() => setInputOfIndexToFocus(false), [setInputOfIndexToFocus])

	const classes = useStyles()
	return (
		<Grid container spacing={1} alignItems="center" className={classes.answer}>
			<Grid item>
				<TextField
					variant="outlined"
					margin="none"
					size="small"
					name={sortIndex.toString()}
					value={text}
					inputRef={inputRefHandler}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={updateAnswer}
					onKeyPress={focusNext}
				/>
			</Grid>
			<Grid item>
				<IconButton name={sortIndex.toString()} size="small" onClick={deleteAnswer}>
					<RemoveIcon color="error" />
				</IconButton>
			</Grid>
		</Grid>
	)
}

function AnswerList({ answerChoices, updateAnswer, deleteAnswer, onSortEnd }) {
	const [indexOfInputToFocus, setInputOfIndexToFocus] = useState(false)

	const inputRefHandler = useCallback(
		el =>
			el !== null &&
			(answerChoices.length === 0 || indexOfInputToFocus === answerChoices.length) &&
			el.focus(),
		[indexOfInputToFocus, answerChoices.length]
	)
	const classes = useStyles()
	return (
		<>
			<SortableList onSortEnd={onSortEnd}>
				{answerChoices.map((answer, index) => (
					<Answer
						key={index}
						{...answer}
						sortIndex={index}
						indexOfInputToFocus={indexOfInputToFocus}
						setInputOfIndexToFocus={setInputOfIndexToFocus}
						updateAnswer={updateAnswer}
						deleteAnswer={deleteAnswer}
					/>
				))}
			</SortableList>
			{(answerChoices.length === 0 || answerChoices[answerChoices.length - 1]) && (
				<TextField
					variant="outlined"
					margin="none"
					size="small"
					name={answerChoices.length.toString()}
					value={''}
					className={classes.emptyField}
					inputRef={inputRefHandler}
					onChange={updateAnswer}
					onFocus={() => setInputOfIndexToFocus(answerChoices.length)}
					onBlur={() => setInputOfIndexToFocus(false)}
				/>
			)}
		</>
	)
}

export default AnswerList
