import { useContext, useState } from 'react'

import { useTheme } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import CloudUploadIcon from '@material-ui/icons/Folder'
import JobApplicationContext from './jobApplicationContext'

export default function DocumentsUpload({ files = false, validationSummary }) {
	const { jobInfo, actions } = useContext(JobApplicationContext)
	const [touchedInputs, setTouchedInputs] = useState({})
	const filesToUpload = files
		? Object.values(files)
		: [
				...jobInfo.documents.map(({ documentType, displayName, required }) => ({
					type: documentType,
					displayName,
					required,
					file: false
				}))
		  ]

	const onFileSelected = ({ target }) => {
		setTouchedInputs(inputs => ({ ...inputs, [target.name]: true }))
		actions.updateFiles(
			filesToUpload.map(fileInfo =>
				fileInfo.type === target.id ? { ...fileInfo, file: target.files[0] } : fileInfo
			)
		)
	}

	const { spacing } = useTheme()
	return (
		<div>
			{filesToUpload.map(({ type, displayName, required, file }) => (
				<div key={type} style={{ marginTop: spacing(3) }}>
					<input
						accept={'.doc, .docx, .pdf'}
						id={type}
						name={type}
						type="file"
						style={{ display: 'none' }}
						onChange={onFileSelected}
					/>
					<Typography
						color={touchedInputs[type] && validationSummary[type] ? 'error' : 'textPrimary'}
					>
						{displayName} (PDF or Word)
						{!required && (
							<Typography component="span" color="textSecondary">
								&nbsp;(Optional)
							</Typography>
						)}
					</Typography>

					<label htmlFor={type}>
						<Button variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
							Browse...
						</Button>
					</label>
					{(file || (touchedInputs[type] && validationSummary[type])) && (
						<Typography
							variant="body2"
							color={touchedInputs[type] && validationSummary[type] ? 'error' : 'secondary'}
							component="span"
							style={{ marginLeft: spacing(2) }}
						>
							{(touchedInputs[type] && validationSummary[type]) || file.name}
						</Typography>
					)}
				</div>
			))}
		</div>
	)
}
