import { Typography, Grid, TextField, Box } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { z } from 'zod'
import { useMutation } from '@tanstack/react-query'

import useFormWithZod from '../../common/hooks/useFormWithZod'
import { useAuth } from '../auth/AuthProvider'
import StatefulButton from '../../common/StatefullButton'
import Greetings from './Greetings'
import { sendFeedback } from './feedbackService'

const gridSpacing = 3

let validationSchema = z.object({
	message: z
		.string()
		.min(1, 'Please, enter your question, feedback or request')
		.max(4000, 'The message should not exceed 4000 characters')
})

function ContactUs() {
	const { currentUser } = useAuth()

	if (!currentUser?.displayName) {
		validationSchema = validationSchema.extend({
			name: z.string().min(1, 'Please, enter your full name')
		})
	}
	if (!currentUser?.emailVerified) {
		validationSchema = validationSchema.extend({
			email: z.string().email('Please, enter a valid email so that we can contact you back')
		})
	}

	const {
		register,
		handleSubmit,
		formState: { errors, isValid }
	} = useFormWithZod(validationSchema)

	const { mutate, isError, isSuccess, isLoading } = useMutation({
		mutationFn: data => {
			const fullName = currentUser?.displayName ? currentUser.displayName : data.name
			const email = currentUser?.emailVerified ? currentUser.email : data.email
			return sendFeedback({ message: data.message, fullName, email })
		}
	})

	return isSuccess ? (
		<Box sx={{ mx: 'auto', mt: 6 }}>
			<Typography variant="h4" align="center">
				Thank you! We'll be in touch!
			</Typography>
		</Box>
	) : (
		<Box sx={{ flex: 1 }}>
			<Greetings header={`Hi ${currentUser?.displayName || 'there'}!`}>
				<Typography variant="h5">Please fill out the form below in order to contact us.</Typography>
			</Greetings>
			<Box component="form" sx={{ p: gridSpacing / 2 }} onSubmit={handleSubmit(mutate)}>
				<Grid container justifyContent="center" alignItems="center" spacing={gridSpacing}>
					{isError && (
						<Grid item xs={11} sm={9} lg={8}>
							<Alert severity="error" align>
								<AlertTitle>
									<span role="img" aria-label="horrified emoji">
										😱
									</span>{' '}
									Oops! We broke something!{' '}
									<span role="img" aria-label="horrified emoji">
										😱
									</span>
								</AlertTitle>
								We may not be able to receive your message this time!
							</Alert>
						</Grid>
					)}
					<Grid item xs={11} sm={9} lg={8}>
						<Typography variant="h4" gutterBottom>
							Contact Us
						</Typography>
						<TextField
							{...register('message')}
							label="What would you like to talk to us about?"
							multiline
							minRows="6"
							maxRows="6"
							margin="none"
							variant="outlined"
							helperText={errors.message?.message}
							error={!!errors.message}
							fullWidth
						/>
					</Grid>
					{!currentUser?.emailVerified && (
						<Grid item xs={11} sm={9} lg={8}>
							<TextField
								{...register('email')}
								label="Enter you email address"
								type="text"
								variant="outlined"
								helperText={errors.email?.message}
								error={!!errors.email}
								fullWidth
							/>
						</Grid>
					)}
					{!currentUser?.displayName && (
						<Grid item xs={11} sm={8}>
							<TextField
								{...register('name')}
								label="Enter you full name"
								type="text"
								variant="outlined"
								helperText={errors.name?.message}
								error={!!errors.name}
								fullWidth
							/>
						</Grid>
					)}
				</Grid>
				<Grid container justifyContent="center" alignItems="center" spacing={gridSpacing}>
					<Grid item xs={11} sm={2} lg={1}>
						<StatefulButton
							type="submit"
							variant="contained"
							color="primary"
							size="large"
							disabled={!isValid}
							isLoading={isLoading}
							fullWidth
						>
							Send
						</StatefulButton>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default ContactUs
