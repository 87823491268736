import { useState, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'

import LargeLoader from '../../common/LargeLoader'
import UserProfile from './UserProfile'
import CompanyProfile from './CompanyProfile'
import Subscription from './subscription/Subscription'
import Billing from './Billing'
import {
	getCompanyProfile,
	getEmployerProfile,
	updateUserProfile,
	updateCompanyProfile
} from './profileService'
import { useAuth } from '../auth/AuthProvider'

const useStyles = makeStyles(({ spacing }) => ({
	root: { maxWidth: 800, margin: `${spacing(4)}px auto` }
}))

export default function Profile() {
	const [profile, setProfile] = useState()
	const [tab, setTab] = useState('subscription')
	const { currentUser } = useAuth()
	const trialEndDate = new Date(currentUser.trialEndDate)
	const isTrial = trialEndDate > new Date()

	useEffect(() => {
		getCompanyProfile().then(companyProfileInfo => {
			setProfile(p => ({ ...p, company: { ...companyProfileInfo } }))
		})
		getEmployerProfile().then(userProfile => {
			setProfile(p => ({ ...p, user: { ...userProfile } }))
		})
	}, [])

	const saveUserProfile = useCallback(async userProfile => {
		await updateUserProfile(userProfile)
		setProfile(p => ({ ...p, user: { ...p.user, ...userProfile } }))
	}, [])

	const saveCompanyProfile = useCallback(async companyProfile => {
		await updateCompanyProfile(companyProfile)
		setProfile(p => ({ ...p, company: { ...p.company, ...companyProfile } }))
	}, [])

	const classes = useStyles()
	return profile ? (
		<TabContext value={tab}>
			<Grid container spacing={3} wrap="nowrap" className={classes.root}>
				<Grid item>
					<Box sx={{ pt: 3 }}>
						<TabList orientation="vertical" onChange={(e, newTab) => setTab(newTab)}>
							<Tab label="Subscription" value="subscription" />
							<Tab label="User" value="personal-info" />
							<Tab label="Company" value="company" />
							<Tab label="Billing" value="billing" disabled={isTrial} />
						</TabList>
					</Box>
				</Grid>
				<Grid item>
					<TabPanel value="subscription">
						<Subscription />
					</TabPanel>
					<TabPanel value="personal-info">
						<UserProfile {...profile.user} save={saveUserProfile} />
					</TabPanel>
					<TabPanel value="company">
						<CompanyProfile {...profile.company} save={saveCompanyProfile} />
					</TabPanel>
					<TabPanel value="billing">
						<Billing />
					</TabPanel>
				</Grid>
			</Grid>
		</TabContext>
	) : (
		<LargeLoader title="Loading profile information" />
	)
}
