import { post } from '../restService'

export const createCompany = async company => {
	return await post('api/companies', company)
}

export const createEmployer = async employer => {
	return post('api/employers', employer)
}

export const createCompanyDetails = companyDetails => {
	return post('api/companies/details', companyDetails)
}
