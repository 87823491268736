import { useCallback, useState } from 'react'

import { Button } from '@material-ui/core'
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import operationStates from '../../../common/operationStates'
import clientSettings from '../../../clientSettings'
import { updateSubscriptionDetails } from './paymentService'
import CreditCardDetails from './CreditCardDetails'
import CustomDialog from '../../../common/CustomDialog'

const stripePromise = loadStripe(clientSettings.stripe.publicKey)
export const EditSubscriptionDetailsDialog = ({ isOpen, onClose }) => {
	const [isFormValid, setIsFormValid] = useState(false)
	const [updateStatus, setUpdateStatus] = useState('')

	const onDetailsSubmit = useCallback(
		async (tokenizedPaymentDetails, userData) => {
			if (!isFormValid) return

			try {
				setUpdateStatus({
					status: operationStates.inProgress,
					message: 'Updating payment details...'
				})
				await updateSubscriptionDetails({ tokenizedPaymentDetails, ...userData })
				setUpdateStatus({
					status: operationStates.success,
					message: 'Payment details have been updated.'
				})
				onClose()
			} catch (error) {
				setUpdateStatus({
					status: operationStates.error,
					message:
						"Couldn't perform the update. Make sure the new credit card details are correct, and try again."
				})
				console.log(error)
			}
		},
		[isFormValid, onClose]
	)

	const resetModalState = useCallback(() => {
		onClose()
		setUpdateStatus('')
	}, [onClose])

	return (
		<Elements stripe={stripePromise}>
			<CustomDialog open={isOpen} onClose={onClose} title="Edit Subscription Details">
				<DialogContent>
					<DialogContentText>Update your credit card information</DialogContentText>

					<CreditCardDetails
						onFormValidationChange={isValid => setIsFormValid(isValid)}
						formSubmitCallback={onDetailsSubmit}
						transactionStatus={updateStatus}
						allowTransaction={isFormValid}
						submitButtonText={'Update subscription details'}
					/>

					<DialogActions>
						{updateStatus.status === operationStates.success && (
							<Button color="primary" variant="contained" onClick={resetModalState}>
								Close
							</Button>
						)}
					</DialogActions>
				</DialogContent>
			</CustomDialog>
		</Elements>
	)
}
