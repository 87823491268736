import { IconButton, Tooltip } from '@material-ui/core'

function VideoQuestionEditorAction({ tooltip, color = 'default', icon, disabled, type, onClick }) {
	return (
		<Tooltip title={tooltip}>
			<span>
				<IconButton size="medium" color={color} disabled={disabled} type={type} onClick={onClick}>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	)
}

export default VideoQuestionEditorAction
