import { useCallback, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Popover, CircularProgress, Switch, Typography } from '@material-ui/core'
import { FormControl, FormGroup, FormHelperText, FormControlLabel } from '@material-ui/core'
import operations from './interviewInvitationAsyncOperations'
import operationStates from '../../../../../common/operationStates'

const useStyles = makeStyles(({ spacing }) => ({
	emailCustomizationPopoverTrigger: {
		float: 'right',
		marginBottom: spacing(1)
	},
	emailCustomizationPopover: {
		padding: spacing(4)
	},
	loader: {
		position: 'absolute',
		inset: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: 'rgb(255,255,255, 0.5)'
	}
}))

export default function InterviewInvitationCustomizationToggles({
	settings = {},
	requestProgress,
	onToggleChange
}) {
	const { showLogo = false, showBackgroundColor = false, showBackgroundTexture = false } = settings
	const isLoading =
		(requestProgress.operation === operations.loadConfig ||
			requestProgress.operation === operations.saveConfig) &&
		requestProgress.state === operationStates.inProgress

	const [anchorEl, setAnchorEl] = useState(null)

	const onClose = useCallback(() => setAnchorEl(null), [])
	const togglePopover = useCallback(e => setAnchorEl(el => (el ? null : e.currentTarget)), [])

	const handleToggleChange = useCallback(
		({ target }) => {
			onToggleChange(target.name, target.checked)
		},
		[onToggleChange]
	)

	const isCustomizeEmailViewOpen = Boolean(anchorEl)
	const id = isCustomizeEmailViewOpen ? 'simple-popover' : undefined

	const classes = useStyles()
	return (
		<div className={classes.emailCustomizationPopoverTrigger}>
			<Button aria-describedby={id} variant="contained" color="secondary" onClick={togglePopover}>
				Customize E-mail Design
			</Button>
			<Popover
				id={id}
				open={isCustomizeEmailViewOpen}
				anchorEl={anchorEl}
				onClose={onClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<FormControl component="fieldset" className={classes.emailCustomizationPopover}>
					{requestProgress.state === operationStates.error && (
						<Typography color="error">{requestProgress.message}</Typography>
					)}
					{!(
						requestProgress.operation === operations.loadConfig &&
						requestProgress.state === operationStates.error
					) && (
						<>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch checked={showLogo} onChange={handleToggleChange} name="showLogo" />
									}
									label="Show Logo"
								/>
								<FormControlLabel
									control={
										<Switch
											checked={showBackgroundColor}
											onChange={handleToggleChange}
											name="showBackgroundColor"
										/>
									}
									label="Show Background Color"
								/>
								<FormControlLabel
									control={
										<Switch
											checked={showBackgroundTexture}
											onChange={handleToggleChange}
											name="showBackgroundTexture"
										/>
									}
									label="Show Background Texture"
								/>
							</FormGroup>
							<FormHelperText>
								*Some email clients may not correctly show the texture
							</FormHelperText>
						</>
					)}
					{isLoading && (
						<div className={classes.loader}>
							<CircularProgress size={17} />
						</div>
					)}
				</FormControl>
			</Popover>
		</div>
	)
}
