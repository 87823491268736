import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Paper } from '@material-ui/core'

import LinksIcon from 'mdi-material-ui/Link'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		margin: `${spacing(4)}px auto`,
		padding: spacing(4),
		maxWidth: 900
	},
	buttonContainer: {
		marginTop: spacing(3),
		display: 'flex',
		justifyContent: 'space-between'
	},
	skipButton: { marginLeft: spacing(4) }
}))

export default function JobApplicationSetupCompleteMessage({ onBack, onProceed, onSkip }) {
	const classes = useStyles()
	return (
		<Paper className={classes.root}>
			<Typography variant="h4" align="center" paragraph>
				Job Application Setup Complete!
			</Typography>
			<Typography paragraph>
				Congratulations! You’ve finished creating your Job Application. Now let’s create links to
				include in your postings on job boards and social media. When your candidates click on these
				links, it will take them to your Application.
			</Typography>
			<div className={classes.buttonContainer}>
				<Button size="large" onClick={onBack}>
					Back
				</Button>
				<Button
					size="large"
					variant="contained"
					color="primary"
					startIcon={<LinksIcon />}
					onClick={onProceed}
				>
					Setup Job Application Links
				</Button>
				<Button size="large" onClick={onSkip}>
					Skip For Now
				</Button>
			</div>
		</Paper>
	)
}
