import { useState, useCallback } from 'react'

import { useTheme } from '@material-ui/core/styles'
import { Grid, IconButton, Paper, List, Dialog, Button, Slide } from '@material-ui/core'
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core'

import DownloadIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'

import PdfFileIcon from '@material-ui/icons/PictureAsPdf'
import WordFileIcon from 'mdi-material-ui/FileWord'

import { getDocumentShortLivedAccessUrl } from '../../../../common/cloudStorageService'
import { docDisplayText } from '../../../../common/jobInfoMapper'
import EmployerNoteEditor from './EmployerNoteEditor'
import CandidateApplicationAnswers from './CandidateApplicationAnswers'
import DocumentPreview from './DocumentPreview'

const fileIcons = {
	pdf: <PdfFileIcon />,
	doc: <WordFileIcon />,
	docx: <WordFileIcon />
}

export default function CandidateApplicationDetails({
	jobDocs,
	jobApplicationQuestions,
	candidate
}) {
	const [previewDialogContext, setPreviewDialogContext] = useState({
		open: false,
		submittedDoc: {}
	})

	const openPreview = useCallback(
		({ currentTarget }) => {
			const submittedDocId = +currentTarget.id
			const submittedDoc = candidate.submittedDocs.find(sd => sd.id === submittedDocId)
			setPreviewDialogContext({
				open: true,
				submittedDoc,
				docType: jobDocs.find(jd => jd.id === submittedDoc.jobDocumentId).documentType
			})
		},
		[candidate.submittedDocs, jobDocs]
	)
	const closePreview = useCallback(
		() => setPreviewDialogContext({ open: false, submittedDoc: {} }),
		[]
	)

	const initiateDocumentDownload = useCallback(
		async ({ currentTarget }) => {
			const candidateDocId = +currentTarget.name

			const doc = candidate.submittedDocs.find(d => d.id === candidateDocId)

			// Todo: show some loader to the user
			const { url } = await getDocumentShortLivedAccessUrl(candidateDocId)

			// as of July 2022 still the cleanest way to initiate file download programmatically
			const link = document.createElement('a')
			link.download = doc.fileName
			link.href = url
			link.target = '_blank' // this is a fallback for 'download' attribute being ignored in some browsers or under some conditions like cross origin links
			link.click()
		},
		[candidate.submittedDocs]
	)

	const { spacing } = useTheme()

	return (
		<section style={{ padding: spacing(2) }}>
			<Grid container spacing={2}>
				<Grid item sm={8}>
					<CandidateApplicationAnswers
						jobApplicationQuestions={jobApplicationQuestions}
						candidate={candidate}
					/>
				</Grid>
				<Grid item sm={4}>
					{candidate?.submittedDocs.length > 0 && (
						<Paper elevation={2}>
							<List>
								{candidate.submittedDocs.map(it => (
									<ListItem key={it.id} id={it.id} dense button onClick={openPreview}>
										<ListItemIcon>
											{fileIcons[it.fileName.split('.').pop().toLowerCase()]}
										</ListItemIcon>
										<ListItemText>
											View{' '}
											{docDisplayText[
												jobDocs.find(({ id }) => id === it.jobDocumentId).documentType
											] || jobDocs.find(({ id }) => id === it.jobDocumentId).name}
										</ListItemText>
										<ListItemSecondaryAction>
											<IconButton name={it.id} color="primary" onClick={initiateDocumentDownload}>
												<DownloadIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						</Paper>
					)}
					<EmployerNoteEditor
						label="Notes"
						candidateId={candidate.id}
						noteType="candidateApplication"
						noteText={candidate.note}
					/>
				</Grid>
			</Grid>
			<Dialog
				open={previewDialogContext.open}
				fullScreen
				onClose={closePreview}
				TransitionComponent={Slide}
				TransitionProps={{ direction: 'up' }}
			>
				<Button
					size="large"
					startIcon={<CloseIcon />}
					style={{ height: spacing(8) }}
					onClick={closePreview}
				>
					Close Preview
				</Button>
				<DocumentPreview
					candidateDocId={previewDialogContext.submittedDoc.id}
					candidateId={candidate.id}
					noteText={candidate.note}
					filename={previewDialogContext.submittedDoc.fileName}
					docType={previewDialogContext.docType}
				/>
			</Dialog>
		</section>
	)
}
