import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import InterviewApp from '../../../../interview/InterviewApp'

const useStyles = makeStyles(({ spacing, palette }) => ({
	preview: {
		background: palette.background.default,
		position: 'relative',
		flex: 1
	},
	buttonContainer: { textAlign: 'center', padding: spacing(4) },
	closePreviewButton: { height: 50, borderRadius: 0 }
}))

export default function InterviewPreview({ questions }) {
	const [showPreview, togglePreview] = useState(false)

	const classes = useStyles()
	return (
		<>
			<Dialog
				open={showPreview}
				maxWidth="lg"
				fullScreen
				TransitionComponent={Slide}
				TransitionProps={{ direction: 'up' }}
				onClose={() => togglePreview(false)}
			>
				<Button
					variant="contained"
					color="secondary"
					size="large"
					startIcon={<CloseIcon />}
					className={classes.closePreviewButton}
					onClick={() => togglePreview(false)}
				>
					Close Applicant Experience Preview
				</Button>
				<div className={classes.preview}>
					<InterviewApp
						demoMode={true}
						demoQuestions={questions.map(({ id, text, readSeconds, answerSeconds }, index) => ({
							id,
							text,
							readSeconds,
							answerSeconds,
							order: index
						}))}
						totalQuestions={questions.length}
					/>
				</div>
			</Dialog>

			<div className={classes.buttonContainer}>
				<Button
					variant="contained"
					size="large"
					color="secondary"
					onClick={() => togglePreview(!showPreview)}
				>
					{showPreview ? 'Stop Preview' : 'Start Preview'}
				</Button>
			</div>
		</>
	)
}
