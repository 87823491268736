import { useContext, useState, useCallback, useEffect } from 'react'
import { TextField } from '@material-ui/core'

import { ApplicantDashboardContext } from './applicantDashboardStore'
import SimpleSavingIndicator from './SimpleSavingIndicator'
import { useMutation } from '@tanstack/react-query'

function EmployerNoteEditor({ label, candidateId, noteType, noteText }) {
	const actions = useContext(ApplicantDashboardContext)
	const [text, setText] = useState(noteText || '')

	const { mutate, reset, isLoading, isSuccess, isError } = useMutation({
		mutationFn: value => actions.updateNotes(candidateId, noteType, value),
		onError: e => console.log(e)
	})

	useEffect(() => {
		setText(noteText || '')
	}, [noteText])

	const persistNotes = useCallback(
		async ({ target }) => {
			if (noteText === target.value || (noteText == null && target.value === '')) return

			mutate(target.value, { onSuccess: () => setTimeout(reset, 3000) })
		},
		[noteText, mutate, reset]
	)

	return (
		<TextField
			label={label}
			variant="outlined"
			margin="normal"
			multiline
			minRows={5}
			maxRows={15}
			InputLabelProps={{ shrink: true }}
			fullWidth
			value={text}
			FormHelperTextProps={{ component: 'div' }}
			helperText={
				<SimpleSavingIndicator
					isLoading={isLoading}
					isSuccess={isSuccess}
					isError={isError}
					errorMsg="Error saving notes"
				/>
			}
			onChange={({ target }) => setText(target.value)}
			onBlur={persistNotes}
		/>
	)
}

export default EmployerNoteEditor
