import { get, put, putFormData } from '../restService'

export const getCompanyProfile = async () => await get('api/companies/me')
export const getEmployerProfile = async () => await get('api/employers/me')

export const getUsage = () => get('/api/employers/usage')

export const updateUserProfile = async userProfile => await put('/api/employers/me', userProfile)

export const updateCompanyProfile = async companyProfile => {
	const formData = new FormData()

	for (const key in companyProfile) {
		// == catches both null and undefined
		if (companyProfile[key] == null) continue

		formData.append(key, companyProfile[key])
	}

	return await putFormData(`api/companies/${companyProfile.id}`, formData)
}
