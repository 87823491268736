import { useRef, useState, useEffect } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Paper, Checkbox, TextField } from '@material-ui/core'
import RequiredToggle from './RequiredToggle'

const useStyles = makeStyles(({ spacing }) => ({
	listItem: {
		display: 'flex',
		alignItems: 'center',
		padding: spacing(1, 1, 1, 2),
		margin: spacing(1)
	},
	listLabel: { flex: 1, cursor: isReadOnly => (isReadOnly ? 'default' : 'pointer') }
}))
export default function JobDocumentConfig({
	id,
	documentType,
	displayName,
	included,
	required,
	isReadOnly = false,
	errorMessage,
	toggleDocument,
	updateDocument
}) {
	const [customNameInputHasFocus, focus] = useState(false)
	const customNameInputRef = useRef()

	useEffect(() => {
		if (customNameInputHasFocus) customNameInputRef.current.focus()
	}, [customNameInputHasFocus])

	const classes = useStyles(isReadOnly)
	const { spacing } = useTheme()
	return (
		<Paper className={classes.listItem} elevation={included ? 4 : 1}>
			<label className={classes.listLabel}>
				<Checkbox
					checked={included}
					name={documentType}
					onChange={e => {
						toggleDocument(e.currentTarget.checked, { id, documentType, required, displayName })
						focus(e.currentTarget.checked)
					}}
					disabled={isReadOnly || !!errorMessage || (included && !id) || customNameInputHasFocus}
				/>

				<TextField
					inputRef={customNameInputRef}
					name={documentType}
					variant="outlined"
					placeholder="Other document"
					disabled={isReadOnly}
					value={displayName}
					inputProps={{ style: { padding: spacing(1) } }}
					style={{ verticalAlign: 'middle' }}
					error={!!errorMessage}
					helperText={errorMessage}
					onChange={({ target }) =>
						updateDocument({ id, documentType, displayName: target.value, required }, true)
					}
					onFocus={() => {
						focus(true)
						!included && toggleDocument(true, { documentType, required, displayName })
					}}
					onBlur={() => {
						// When after editing the doc name and clicking away, the user can click either on the doc item itself or anywhere else.
						// In case of clicking on the item it'll exclude the document,
						// which is undesired effect on simply clicking away,
						// so we keep the the checkbox disabled for a short period of time in case user's click lands on the item itself,
						// hence preventing excluding the document
						setTimeout(focus, 200, false)
						id
							? errorMessage
								? toggleDocument(false, { id, documentType })
								: updateDocument({ id, documentType, displayName, required })
							: toggleDocument(!!displayName, { documentType, required, displayName })
					}}
				/>
			</label>
			<RequiredToggle
				name={documentType}
				included={included}
				value={required}
				isReadOnly={isReadOnly || !id || !!errorMessage}
				onChange={e => {
					updateDocument({ id, documentType, required: e.currentTarget.checked, displayName })
				}}
			/>
		</Paper>
	)
}
