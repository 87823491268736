import { useCallback, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper, Step, StepLabel, Stepper, Typography, useMediaQuery } from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views'

import { useAuth } from '../auth/AuthProvider'
import EmailVerification from './EmailVerification'
import UserRegistration from './UserRegistration'
import CompanyRegistration from './CompanyRegistration'
import CompanyDetails from './CompanyDetails'

const steps = [
	{ label: 'Verify Your Email', component: EmailVerification },
	{ label: 'Create Your Account', component: UserRegistration },
	{ label: 'Create Your Company', component: CompanyRegistration },
	{ label: 'Company Information', component: CompanyDetails }
]

const getStep = ({ emailVerified, isAccountSetup, isCompanySetup, isCompanyDetailsSetup }) => {
	if (!emailVerified) return 0

	if (!isAccountSetup) return 1

	if (!isCompanySetup) return 2

	if (!isCompanyDetailsSetup) return 3
}

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		margin: `${spacing(3)}px auto`,
		maxWidth: 800,
		padding: spacing(3)
	}
}))

const Wizard = () => {
	const { currentUser } = useAuth()
	const swipeableViewsActionsRef = useRef()
	const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'))

	const onStepContentChange = useCallback(() => {
		swipeableViewsActionsRef.current.updateHeight()
	}, [])

	const activeStep = getStep(currentUser)
	return (
		<>
			<Stepper activeStep={activeStep} square={false}>
				{steps.map(({ label }) => (
					<Step key={label}>
						<StepLabel>{!isXs && label}</StepLabel>
					</Step>
				))}
			</Stepper>

			<SwipeableViews
				index={activeStep}
				animateHeight
				disabled
				action={actions => (swipeableViewsActionsRef.current = actions)}
			>
				{steps.map(({ component: Component, label }, index) => (
					<div key={label}>
						{isXs && (
							<Typography variant="h6" gutterBottom>
								{label}
							</Typography>
						)}
						<Component isActiveStep={index === activeStep} onContentChange={onStepContentChange} />
					</div>
				))}
			</SwipeableViews>
		</>
	)
}

export default function Registration() {
	const classes = useStyles()
	const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'))
	return isXs ? (
		<Box display="flex" flexDirection="column" px={isXs ? 2 : 0} width={isXs ? '100vw' : 'auto'}>
			<Wizard />
		</Box>
	) : (
		<Paper className={classes.root}>
			<Wizard />
		</Paper>
	)
}
