import { memo, useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import SimpleMDEReact from 'react-simplemde-editor'
import 'easymde/dist/easymde.min.css'

import JobSetupContext from '../jobSetupContext'

const useStyles = makeStyles(({ spacing, palette }) => ({
	root: {
		padding: spacing(0, 1),
		'& .CodeMirror-wrap': { borderColor: showError => (showError ? palette.error.main : '#ddd') },
		'& .CodeMirror-scroll': { maxHeight: 500 }
	}
}))

export default memo(function JobDescriptionEditor({
	isActiveView,
	description,
	validationSummary,
	updateHeight
}) {
	const actions = useContext(JobSetupContext)
	const [touched, setTouched] = useState(false)

	const editorRef = useRef()
	const classes = useStyles(touched && !validationSummary.isValid)

	const onChangeDescription = useCallback(
		description => {
			setTouched(true)
			actions.setDescription(description)
			updateHeight()
		},
		[actions, updateHeight]
	)

	useEffect(() => {
		let timeOutId
		if (isActiveView) {
			timeOutId = setTimeout(() => editorRef.current.codemirror.display.input.textarea.focus(), 300)
		}

		return () => clearTimeout(timeOutId)
	}, [isActiveView])

	const editorOptions = useMemo(
		() => ({
			hideIcons: ['side-by-side', 'fullscreen', 'preview'],
			status: false
		}),
		[]
	)

	return (
		<div className={classes.root}>
			<Typography variant="caption" color="textSecondary">
				Displayed to applicants, the more detailed the better. When formatting text below, you'll
				see extra characters because of the type of formatting tool we use. For example, Bold text
				will have "<strong>**</strong>" before and after the text. Look to the Preview on the right
				to see what the final formatting will look like.
			</Typography>
			<SimpleMDEReact
				getMdeInstance={instance => (editorRef.current = instance)}
				options={editorOptions}
				value={description}
				onChange={onChangeDescription}
			/>
			{!validationSummary.isValid && touched && (
				<Typography variant="caption" color="error">
					{validationSummary.description}
				</Typography>
			)}
		</div>
	)
})
