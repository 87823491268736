import { TextField, Box, Typography, InputAdornment } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import SaveIcon from '@material-ui/icons/Done'
import PhoneIcon from '@material-ui/icons/Smartphone'
import EmailIcon from '@material-ui/icons/Email'

import useFormWithZod from '../../common/hooks/useFormWithZod'
import { phoneRegex } from '../../common/commonRegex'
import StatefulButton from '../../common/StatefullButton'

const userSchema = z.object({
	phone: z.string().regex(phoneRegex, 'Invalid phone number format').or(z.literal(''))
})
export default function UserProfile({ firstName, lastName, email, phone, save }) {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useFormWithZod(userSchema)

	const { isLoading, isSuccess, isError, mutate } = useMutation({
		mutationFn: values => save({ firstName, lastName, email, ...values })
	})

	return (
		<form onSubmit={handleSubmit(mutate)}>
			<Typography variant="h4" gutterBottom>
				User
			</Typography>
			<Typography variant="h5">
				{firstName} {lastName}
			</Typography>
			<Typography gutterBottom>
				<EmailIcon style={{ verticalAlign: 'middle' }} /> {email}
			</Typography>

			<TextField
				{...register('phone')}
				label="Phone"
				placeholder="Enter your phone number"
				defaultValue={phone}
				error={!!errors.phone}
				helperText={errors.phone?.message}
				margin="normal"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<PhoneIcon />
						</InputAdornment>
					)
				}}
				fullWidth
			/>

			<StatefulButton
				startIcon={<SaveIcon />}
				type="submit"
				isLoading={isLoading}
				isSuccess={isSuccess}
			>
				Save
			</StatefulButton>

			{isError && (
				<Box sx={{ pt: 1 }}>
					<Alert severity="error">Error saving profile information</Alert>
				</Box>
			)}
		</form>
	)
}
