import { useCallback, useContext, useState } from 'react'

import { Grid, Button, TextField } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'

import { z } from 'zod'

import EmbeddedVideoPlayer from '../../../../../../common/employer-video/EmbeddedVideoPlayer'
import useFormWithZod from '../../../../../../common/hooks/useFormWithZod'
import videoHosts from '../../../../../../common/videoHosts'
import StatefulButton from '../../../../../../common/StatefullButton'
import EmployerVideosContext from './employerVideosContext'

const youtubeVideoRegex =
	/(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i

const schema = z.object({
	embeddedVideoLink: z.string().url().regex(youtubeVideoRegex, 'Invalid YouTube link')
})

function ExternalEmployerVideoSetup({ video }) {
	const { isVideoSaving, saveEmbeddedVideoLink } = useContext(EmployerVideosContext)
	const [showEditor, toggleLinkEditor] = useState(false)
	const {
		formState: { errors, isValid },
		register,
		handleSubmit
	} = useFormWithZod(schema, {
		defaultValues: {
			embeddedVideoLink:
				video?.videoHost === videoHosts.youtube
					? `https://youtu.be/${video.providerVideoId}`
					: undefined
		}
	})

	const showLinkEditor = () => toggleLinkEditor(true)
	const hideLinkEditor = useCallback(() => toggleLinkEditor(false), [])

	const save = useCallback(
		async ({ embeddedVideoLink }) => {
			const providerVideoId = embeddedVideoLink.match(youtubeVideoRegex)[8]
			saveEmbeddedVideoLink({ providerVideoId }, { onSuccess: hideLinkEditor })
		},
		[saveEmbeddedVideoLink, hideLinkEditor]
	)

	return (
		<Grid container spacing={2} justifyContent="center">
			{video?.videoHost === videoHosts.youtube && video?.providerVideoId && !showEditor ? (
				<>
					<Grid item xs={8}>
						<EmbeddedVideoPlayer sourceVideo={video.srcUrl} />
					</Grid>
					<Grid item xs={12} align="center">
						<Button
							variant="contained"
							color="secondary"
							endIcon={<EditIcon />}
							onClick={showLinkEditor}
						>
							Edit Link
						</Button>
					</Grid>
				</>
			) : (
				<Grid
					item
					container
					xs={9}
					spacing={1}
					justifyContent="center"
					alignItems="center"
					component="form"
					style={{ minHeight: 250 }}
					onSubmit={handleSubmit(save)}
				>
					<Grid item style={{ flex: 0.9 }}>
						<TextField
							id="embedded-video-text"
							label="Embedded Video"
							placeholder="Paste your YouTube link here"
							variant="outlined"
							{...register('embeddedVideoLink')}
							InputLabelProps={{ shrink: true }}
							fullWidth
							error={!!errors.embeddedVideoLink}
							helperText={errors.embeddedVideoLink?.message}
						/>
					</Grid>
					<Grid item>
						<StatefulButton
							variant="contained"
							color="secondary"
							size="large"
							isLoading={isVideoSaving}
							startIcon={<SaveIcon />}
							disabled={!isValid}
							type="submit"
						>
							Save
						</StatefulButton>
					</Grid>
					{video?.videoHost === videoHosts.youtube && video?.providerVideoId && (
						<Grid item>
							<Button
								variant="outlined"
								size="large"
								startIcon={<CancelIcon />}
								onClick={hideLinkEditor}
							>
								Cancel
							</Button>
						</Grid>
					)}
				</Grid>
			)}
		</Grid>
	)
}

export default ExternalEmployerVideoSetup
