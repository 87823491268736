import { get, post, postFormData } from '../common/restService'
import { docDisplayText, questionTypeMap } from '../common/jobInfoMapper'

export const getJobInfo = async linkToken => {
	const jobInfo = (await get(`/api/jobs?linkToken=${linkToken}`))[0]
	for (let doc of jobInfo.documents) {
		doc.displayName = doc.displayName || docDisplayText[doc.documentType]
	}

	// Todo: temp fix for legacy data, need to update the db with more meaningful values and then we can get rid of this mapping
	for (let q of jobInfo.questions) {
		q.questionType = questionTypeMap[q.questionType]
	}
	return jobInfo
}

export const saveJobApplicantContacts = async (contactInfo, jobLinkToken) =>
	post(`/api/candidates?jobLinkToken=${jobLinkToken}`, contactInfo)

export const uploadFiles = async (candidateId, uploads) => {
	const formData = new FormData()
	for (let upload of uploads) {
		if (!upload.file) continue

		formData.append('files', upload.file, `${upload.type}.${upload.file.name.split('.').pop()}`)
	}
	return await postFormData(`/api/candidates/${candidateId}/documents`, formData)
}

export const saveAnswers = async (candidateId, answers) =>
	post(`/api/candidates/${candidateId}/answers`, answers)

export const submitApplication = async (candidateId, consents) =>
	post(`/api/candidates/${candidateId}/consents`, consents.acknowledge)
