import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import {
	getEmployerStatus,
	signUp,
	login,
	signInWithGoogle,
	signInWithMicrosoft,
	logout,
	sendEmailVerification,
	onAuthStateChanged,
	getFreshUserData,
	applyConfirmationCode,
	updateProfile,
	verifyPasswordResetCode,
	sendPasswordResetEmail,
	confirmPasswordReset
} from './authService'

const AuthContext = createContext()
export function useAuth() {
	return useContext(AuthContext)
}

export function AuthProvider({ children }) {
	const [state, setState] = useState({ initializing: true })

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(async currentUser => {
			if (currentUser?.emailVerified) {
				const status = await getEmployerStatus()

				setState(prevState => ({
					...prevState,
					currentUser: { ...currentUser, ...status },
					initializing: false
				}))
			} else {
				setState(prevState => ({
					...prevState,
					currentUser,
					initializing: false
				}))
			}
		})

		return unsubscribe
	}, [])

	// Todo: seems like useMemo is useless here since it depends on state
	const haystackAuth = useMemo(
		() => ({
			...state,
			signUp,
			signIn: login,
			signInWithGoogle,
			signInWithMicrosoft,
			signOut: logout,
			refreshUser: async () => {
				const freshData = await getFreshUserData()
				setState(prevState => ({
					...prevState,
					currentUser: { ...prevState.currentUser, ...freshData }
				}))
			},
			refreshUserStatus: async () => {
				const status = await getEmployerStatus()
				setState(prevState => ({
					...prevState,
					currentUser: { ...prevState.currentUser, ...status }
				}))
			},
			updateUserStatus: status => {
				setState(prevState => ({
					...prevState,
					currentUser: { ...prevState.currentUser, ...status }
				}))
			},
			updateProfile: async ({ firstName, lastName }) => {
				const updatedUser = await updateProfile({ displayName: `${firstName} ${lastName}` })
				setState(prevState => ({
					...prevState,
					currentUser: { ...prevState.currentUser, ...updatedUser }
				}))
			},
			sendEmailVerification,
			applyConfirmationCode: async code => {
				const updatedUser = await applyConfirmationCode(code)

				if (!(updatedUser && state.currentUser)) return

				setState(prevState => ({
					...prevState,
					currentUser: { ...prevState.currentUser, ...updatedUser }
				}))
			},
			sendPasswordResetEmail,
			verifyPasswordResetCode,
			confirmPasswordReset
		}),
		[state]
	)

	return <AuthContext.Provider value={haystackAuth}>{children}</AuthContext.Provider>
}
