import { AuthErrorCodes } from 'firebase/auth'
const errorMap = {
	[AuthErrorCodes.CORS_UNSUPPORTED]: 'This browser is not supported.',
	[AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN]:
		'This operation is sensitive, please log in again to continue.',
	[AuthErrorCodes.EMAIL_EXISTS]: 'The email address is already in use by another account.',
	[AuthErrorCodes.EXPIRED_OOB_CODE]: 'The link has expired.',
	[AuthErrorCodes.EXPIRED_POPUP_REQUEST]:
		'Seems like there is another conflicting popup being opened.',
	[AuthErrorCodes.INVALID_EMAIL]: 'The email address is badly formatted.',
	[AuthErrorCodes.INVALID_OOB_CODE]: 'The link is invalid, expired, or has already been used.',
	[AuthErrorCodes.INVALID_PASSWORD]: 'The user name or password is invalid.',
	[AuthErrorCodes.INVALID_RECIPIENT_EMAIL]: 'Unable to send an email.',
	[AuthErrorCodes.INVALID_SENDER]: 'Unable to send an email.',
	[AuthErrorCodes.MISSING_PASSWORD]:
		'A non-empty at least 6 characters long password must be provided',
	[AuthErrorCodes.NEED_CONFIRMATION]:
		'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
	[AuthErrorCodes.NETWORK_REQUEST_FAILED]:
		'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
	[AuthErrorCodes.POPUP_BLOCKED]:
		'Unable to detect the popup. It may have been blocked by the browser.',
	[AuthErrorCodes.POPUP_CLOSED_BY_USER]:
		'The popup has been closed before finalizing the operation.',
	[AuthErrorCodes.PROVIDER_ALREADY_LINKED]:
		'User can only be linked to one identity for the given provider.',
	[AuthErrorCodes.TIMEOUT]: 'The operation has timed out.',
	[AuthErrorCodes.TOKEN_EXPIRED]: 'Your session is expired. Please sign in again.',
	[AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER]:
		'We have blocked all requests from this device due to unusual activity. Try again later.',
	[AuthErrorCodes.UNVERIFIED_EMAIL]: 'The operation requires a verified email.',
	[AuthErrorCodes.USER_CANCELLED]:
		'The application has not been given the permissions it requested.',
	[AuthErrorCodes.USER_DELETED]: 'The user name or password is invalid.',
	[AuthErrorCodes.USER_DISABLED]: 'The user account has been disabled.',
	[AuthErrorCodes.USER_MISMATCH]:
		'The supplied credentials do not correspond to the previously signed in user.',
	[AuthErrorCodes.USER_SIGNED_OUT]:
		'Your session has expired or you have been signed out. Please sign in again.',
	[AuthErrorCodes.WEAK_PASSWORD]: 'The password must be 6 characters long or more.',
	[AuthErrorCodes.WEB_STORAGE_UNSUPPORTED]:
		'This browser is not supported or 3rd party cookies and data may be disabled.'
}

export default errorMap
