import { useState, useEffect } from 'react'

import { useTheme } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core'

import SuccessIcon from '@material-ui/icons/DoneOutline'
import operationStates from './operationStates'

// Todo: implement error,
//  eventually move away from operationStates, potentially replace with booleans (isLoading, isError, etc.)
const StatefulButton = ({
	state = operationStates.initial,
	isLoading,
	isSuccess,
	disabled,
	startIcon,
	children,
	style,
	...props
}) => {
	const [inSuccessState, setInSuccessState] = useState(isSuccess)
	const { palette } = useTheme()

	useEffect(() => {
		setInSuccessState(isSuccess)
		if (isSuccess) {
			const timeout = setTimeout(setInSuccessState, 3000, false)
			return () => clearTimeout(timeout)
		}
	}, [isSuccess])

	return (
		<Button
			{...props}
			style={{ ...style, color: inSuccessState ? palette.success.dark : style?.color }}
			disabled={disabled || state === operationStates.inProgress || isLoading || inSuccessState}
			startIcon={
				state === operationStates.inProgress || isLoading ? (
					<CircularProgress color="secondary" size={14} />
				) : inSuccessState ? (
					<SuccessIcon color="inherit" />
				) : (
					startIcon
				)
			}
		>
			{inSuccessState ? 'Success' : children}
		</Button>
	)
}

export default StatefulButton
