import { Dialog, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(({ spacing }) => ({
	closeButton: {
		position: 'absolute',
		right: spacing(1),
		top: spacing(1)
	}
}))

function CustomDialog({
	open,
	onClose,
	title = undefined,
	titleIconComponent = undefined,
	children,
	...props
}) {
	const classes = useStyles()

	return (
		<Dialog open={open} onClose={onClose} {...props}>
			<DialogTitle align="center">
				{titleIconComponent}
				{title}
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			{children}
		</Dialog>
	)
}

export default CustomDialog
