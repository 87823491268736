import { useHistory, useParams } from 'react-router-dom'

import routes from '../../../routes'
import VideoInterviewConfigurator from './VideoInterviewConfigurator'

export default function VideoInterviewSetup() {
	const { slug } = useParams()
	const history = useHistory()

	return (
		<VideoInterviewConfigurator
			jobSlug={slug}
			onEditComplete={() => history.push(routes.jobs.getLink())}
		/>
	)
}
