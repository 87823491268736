import { Grid, TextField } from '@material-ui/core'
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'

import StatefulButton from '../../common/StatefullButton'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import { inviteNewUser } from './usersService'
import CustomDialog from '../../common/CustomDialog'

const validationSchema = z.object({
	email: z.string().email('Invalid email address').nonempty('Email is required')
})

export const queryKey = 'users'

export default function AddUserDialog({ isOpen, onClose }) {
	const {
		formState: { errors },
		register,
		handleSubmit,
		reset: resetForm
	} = useFormWithZod(validationSchema, { defaultValues: { email: '' } })

	const queryClient = useQueryClient()
	const { mutate, isLoading, error, reset } = useMutation({
		mutationFn: values => inviteNewUser(values.email),
		onSuccess: user => {
			queryClient.setQueryData([queryKey], users => [...users, user])
			resetForm()
			onClose()
		}
	})

	return (
		<CustomDialog
			open={isOpen}
			maxWidth="xs"
			fullWidth
			onClose={() => {
				onClose()
				resetForm()
				reset()
			}}
			title="Add New User"
		>
			<form onSubmit={handleSubmit(mutate)}>
				<DialogContent>
					<DialogContentText>Invite a user to this organization</DialogContentText>

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								{...register('email')}
								label="Email"
								error={!!errors.email}
								helperText={errors.email?.message}
								fullWidth
							/>
						</Grid>
						{error && (
							<Grid item xs={12}>
								<Alert severity="error">
									{error.detail || 'Something went wrong. Please try again.'}
								</Alert>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<StatefulButton isLoading={isLoading} color="primary" type="submit" variant="contained">
						Send Invitation
					</StatefulButton>
				</DialogActions>
			</form>
		</CustomDialog>
	)
}
