import { Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator
} from '@material-ui/lab'

const useStyles = makeStyles(({ spacing }) => ({
	label: { marginTop: spacing(0) },
	date: { fontWeight: 'bold' }
}))

export default function ApplicantActivityTimelineStep({
	date,
	label,
	isLastItem = false,
	overrideActive = false
}) {
	const classes = useStyles()

	return (
		<TimelineItem>
			<TimelineOppositeContent>
				<Tooltip title={date && date.toLocaleTimeString()}>
					<Typography className={classes.date}>
						{date &&
							date.toLocaleString('en-US', {
								weekday: 'long',
								year: 'numeric',
								month: 'long',
								day: 'numeric'
							})}
					</Typography>
				</Tooltip>
			</TimelineOppositeContent>
			<TimelineSeparator>
				<TimelineDot color={date || overrideActive ? 'primary' : 'grey'} />
				{!isLastItem && <TimelineConnector />}
			</TimelineSeparator>
			<TimelineContent>
				<p className={classes.label}>{label}</p>
			</TimelineContent>
		</TimelineItem>
	)
}
