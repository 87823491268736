import { Box, Typography } from '@material-ui/core'

function RoundedCornersTriangle({ size = 120, innerText }) {
	const sharedTriangleStyle = { width: size, height: size, borderTopRightRadius: '30%' }
	return (
		<Box
			sx={{
				py: `${size * 0.37}px`,
				pr: `${size * 0.21}px`,
				pl: `${size * 0.37}px `,
				display: 'inline-block',
				position: 'relative'
			}}
		>
			<Box
				sx={{
					position: 'relative',
					bgcolor: 'primary.main',
					borderTopRightRadius: '30%',
					transform: 'rotate(30deg) skewX(-30deg) scale(1,.866)',
					...sharedTriangleStyle
				}}
			>
				<Box
					sx={{
						backgroundColor: 'inherit',
						position: 'absolute',
						transform: 'rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%)',
						...sharedTriangleStyle
					}}
				/>
				<Box
					sx={{
						backgroundColor: 'inherit',
						position: 'absolute',
						transform: 'rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%)',
						...sharedTriangleStyle
					}}
				/>
			</Box>
			<Box
				sx={{
					position: 'absolute',
					top: size / 2 + 30,
					left: 16,
					color: '#ffffff',
					maxWidth: size * 1.2
				}}
			>
				<Typography color="inherit" align="left">
					{innerText}
				</Typography>
			</Box>
		</Box>
	)
}

export default RoundedCornersTriangle
