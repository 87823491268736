import { Button, Tooltip, Typography } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { makeStyles } from '@material-ui/core/styles'

import { ChromePicker } from 'react-color'

const useStyles = makeStyles(({ spacing, palette }) => ({
	colorPicker: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		paddingTop: spacing(2),
		flexDirection: 'column'
	},
	clearSettingsBtn: {
		marginTop: spacing(2)
	},
	tooltip: {
		fontSize: '14px',
		marginLeft: spacing(0.5),
		color: palette.text.secondary
	}
}))

export default function ColorPicker({ onColorChange, color }) {
	const classes = useStyles()
	return (
		<div>
			<Typography variant="subtitle2" gutterBottom>
				Color
				<Tooltip
					title={
						'Click in the color box to select the color you\'d like to appear behind your logo at the top of the application. Move the slider bar beneath the color box to change the spectrum of colors you can choose. Simply click the "Clear settings" button to return to white.'
					}
				>
					<HelpOutlineIcon className={classes.tooltip} />
				</Tooltip>
			</Typography>
			<div className={classes.colorPicker}>
				<ChromePicker
					disableAlpha
					color={color || '#fff'}
					onChangeComplete={({ hex }) => onColorChange(hex)}
				/>
				<Button
					variant="outlined"
					className={classes.clearSettingsBtn}
					onClick={() => onColorChange('#FFF')}
				>
					Clear settings
				</Button>
			</div>
		</div>
	)
}
