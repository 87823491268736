import { Typography, Grid } from '@material-ui/core'
import { Work, AssignmentTurnedIn, EmojiPeople, WorkOutline } from '@material-ui/icons'
import SubscriptionUsageItem from './SubscriptionUsageItem'

const SubscriptionUsage = ({
	activeJobPostings,
	completedInterviews,
	totalApplicants,
	totalJobPostings
}) => {
	return (
		<>
			<Typography paragraph>
				<strong>You're doing great!</strong>
			</Typography>
			<Grid container alignItems="center" spacing={4}>
				{activeJobPostings > 0 && (
					<SubscriptionUsageItem
						icon={<Work style={{ fontSize: '5rem' }} />}
						badgeContent={activeJobPostings}
						text={`${activeJobPostings} active job posting${activeJobPostings > 1 ? 's' : ''}.`}
					/>
				)}
				{completedInterviews > 0 && (
					<SubscriptionUsageItem
						icon={<AssignmentTurnedIn style={{ fontSize: '5rem' }} />}
						badgeContent={completedInterviews}
						text={`${completedInterviews} completed interview${
							completedInterviews > 1 ? 's' : ''
						}.`}
					/>
				)}
				{totalApplicants > 0 && (
					<SubscriptionUsageItem
						icon={<EmojiPeople style={{ fontSize: '5rem' }} />}
						badgeContent={totalApplicants}
						text={`${totalApplicants} applicant${totalApplicants > 1 ? 's' : ''} reached in total.`}
					/>
				)}
				{totalJobPostings > 0 && (
					<SubscriptionUsageItem
						icon={<WorkOutline style={{ fontSize: '5rem' }} />}
						badgeContent={totalJobPostings}
						text={`${totalJobPostings} job posting${
							totalJobPostings > 1 ? 's' : ''
						} created in total.`}
					/>
				)}
			</Grid>
		</>
	)
}

export default SubscriptionUsage
