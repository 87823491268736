import { useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { DialogContent, DialogActions } from '@material-ui/core'
import { Button } from '@material-ui/core'

import { ApplicantDashboardContext } from '../applicantDashboardStore'
import routes from '../../../../routes'
import CustomDialog from '../../../../../common/CustomDialog'

export default function VideoInterviewNotSetupWarningDialog({ applicant, jobSlug, open, onClose }) {
	const actions = useContext(ApplicantDashboardContext)

	const onAdvance = useCallback(() => {
		actions.advanceCandidate(applicant)
		onClose()
	}, [applicant, actions, onClose])

	return (
		<CustomDialog open={open} title="Warning: Video Interview Not Set Up" onClose={onClose}>
			<DialogContent>
				You have not yet set up your one way video interview questions. Would you like to set them
				up now? Otherwise, this candidate will <u>not</u> be sent a video invitation if you choose
				to advance.
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					variant="contained"
					component={Link}
					to={{ pathname: routes.editInterview.getLink(jobSlug) }}
				>
					Set Up Video Interview
				</Button>
				<Button color="secondary" variant="contained" onClick={onAdvance}>
					Advance Anyway
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</DialogActions>
		</CustomDialog>
	)
}
