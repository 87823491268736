import PropTypes from 'prop-types'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography, CircularProgress, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
	root: {
		maxWidth: 1000,
		margin: 'auto',
		[breakpoints.down('xs')]: {
			padding: spacing(0, 2)
		}
	},
	loadingIndicator: {
		minHeight: 52,
		textAlign: 'center'
	}
}))

export default function Question({ question, isPractice, totalQuestions = 0 }) {
	const { breakpoints } = useTheme()
	const isMobile = useMediaQuery(breakpoints.down('xs'))

	const classes = useStyles({ isPractice })

	return question ? (
		<div className={classes.root}>
			<Typography variant="body2">
				{isPractice ? (
					'PRACTICE QUESTION'
				) : (
					<span>
						QUESTION <strong>{question.order + 1}</strong> of <strong>{totalQuestions}</strong>
					</span>
				)}
			</Typography>
			<Typography variant={isMobile ? 'h6' : 'h5'}>{question.text}</Typography>
		</div>
	) : (
		<div className={classes.loadingIndicator}>
			<Typography variant="h6" component="span" style={{ verticalAlign: 'text-bottom' }}>
				Loading
			</Typography>
			&nbsp;&nbsp;
			<CircularProgress size={22} />
		</div>
	)
}

Question.propTypes = {
	question: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	totalQuestions: PropTypes.number,
	isPractice: PropTypes.bool.isRequired
}
