import { memo, useCallback, useContext, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import JobSetupContext from '../jobSetupContext'
import settings from '../../../../../sharedSettings.json'
import JobDocumentConfig from './JobDocumentConfig'
import CustomJobDocumentConfig from './CustomJobDocumentConfig'

const useStyles = makeStyles(theme => ({
	root: { padding: theme.spacing(0, 1, 1, 1) },
	buttonContainer: { textAlign: 'center', marginTop: theme.spacing(3) }
}))

export default memo(function JobDocumentsSetup({ documents, validationSummary }) {
	const actions = useContext(JobSetupContext)
	const [touched, setTouched] = useState({})

	const toggleDocument = useCallback(
		(checked, doc) => {
			if (checked) {
				actions.addDocument(doc)
			} else {
				setTouched(touched => ({ ...touched, [doc.documentType]: false }))
				actions.removeDocument(doc.id)
			}
		},
		[actions]
	)

	const updateDocument = useCallback(
		(doc, skipPersistence) => {
			setTouched(touched => ({ ...touched, [doc.documentType]: true }))
			actions.updateDocument(doc, skipPersistence)
		},
		[actions]
	)

	const supportedDocs = settings.candidateDocuments.supportedDocuments
	const documentItems = []
	let customDoc
	for (let i = 0; i < supportedDocs.length; i++) {
		const docConfig = supportedDocs[i]
		const doc = documents.find(doc => doc.documentType === docConfig.type)

		const docItem = {
			id: doc ? doc.id : undefined,
			documentType: docConfig.type,
			displayName: (doc && doc.displayName) || docConfig.displayName || '',
			included: !!doc,
			required: !!(doc ? doc.required : docConfig.requiredByDefault),
			isReadOnly: (doc && doc.isReadOnly) || validationSummary.isReadOnly
		}

		if (docConfig.type === 'custom') {
			customDoc = docItem
		} else {
			documentItems.push(docItem)
		}
	}

	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography variant="caption" color="textSecondary">
				Select the documents you want for candidates to upload. You can either make them required or
				optional.
			</Typography>

			{documentItems.map(doc => (
				<JobDocumentConfig
					key={doc.documentType}
					{...doc}
					toggleDocument={toggleDocument}
					updateDocument={updateDocument}
				/>
			))}
			<CustomJobDocumentConfig
				{...customDoc}
				toggleDocument={toggleDocument}
				updateDocument={updateDocument}
				errorMessage={
					customDoc.included &&
					touched[customDoc.documentType] &&
					validationSummary[customDoc.documentType]
				}
			/>
		</div>
	)
})
