import { Link } from 'react-router-dom' // Or wherever you're importing Link from
import routes from './routes'

export const getTrialWillEndMessage = trialEndsAt => {
	const date = new Date(trialEndsAt)
	const timeString = date.toLocaleTimeString('en-US', { timeStyle: 'short' })
	const dateString = date.toLocaleDateString('en-US', { dateStyle: 'full' })

	const startingTexts = [
		`Like magic, your free trial will vanish at ${timeString} on ${dateString}. Don't let the magic end. `,
		`The clock's ticking! At ${timeString} on ${dateString}, your free trial turns into a pumpkin. `,
		`Your free trial, like a spell, breaks at ${timeString} on ${dateString}. `,
		`The curtain falls on your free trial at ${timeString} on ${dateString}. Don't let the show end. `
	]

	const endingTexts = [
		`. You're valued here!`,
		` to keep the magic alive!`,
		` to keep the enchantment going. You're cherished here!`,
		`. We're excited to have you!`
	]

	// Get a random index
	const randomIndex = Math.floor(Math.random() * startingTexts.length)

	return (
		<>
			{startingTexts[randomIndex]}
			<Link to={routes.profile.path}>Update your payment details</Link>
			{endingTexts[randomIndex]}
		</>
	)
}
