import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { MTableAction } from '@material-table/core'

const useStyles = makeStyles(({ spacing }) => ({
	freeAction: { marginLeft: spacing(4) }
}))

export default function CustomizedFreeAction(props) {
	const classes = useStyles()
	const { isFreeAction, icon: Icon, onClick, componentProps, tooltip } = props.action
	return isFreeAction ? (
		<Button
			variant="outlined"
			color="primary"
			startIcon={<Icon />}
			className={classes.freeAction}
			onClick={onClick}
			{...componentProps}
		>
			{tooltip}
		</Button>
	) : (
		<MTableAction {...props} />
	)
}
