import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import PublishedVideoPlayer from '../../../../../../common/employer-video/PublishedVideoPlayer'
import VideoPlayer from './VideoPlayer'

const useStyles = makeStyles(({ palette }) => ({
	videoPlayerContainer: {
		display: 'flex',
		background: palette.common.black,
		justifyContent: 'center',
		padding: 0,
		maxWidth: 640,
		minHeight: 300
	}
}))

function VideoPlayerContainer({ srcUrl, isPublished }) {
	const classes = useStyles()
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} align="center">
				<div className={classes.videoPlayerContainer}>
					{isPublished ? (
						<PublishedVideoPlayer srcUrl={srcUrl} />
					) : (
						<VideoPlayer videoSrc={srcUrl} />
					)}
				</div>
			</Grid>
		</Grid>
	)
}

export default VideoPlayerContainer
