import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Tabs, Tab } from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views'

import routes from '../../../routes'

const tabNames = {
	application: 'application',
	videos: 'videos',
	activity: 'activity'
}
export default function CandidateApplicationDetailsLayout({ candidate, children }) {
	const history = useHistory()
	const { slug, stageName, detailsSectionName } = useParams()
	const [activeTab, setActiveTab] = useState(detailsSectionName || tabNames.application)
	return (
		<div>
			<Tabs
				value={activeTab}
				indicatorColor="primary"
				onChange={(e, tabName) => {
					history.push(routes.jobApplicants.getLink(slug, stageName, candidate.id, tabName))
					setActiveTab(tabName)
				}}
			>
				<Tab label="Candidate Application" value={tabNames.application} />
				{candidate?.videoAnswers.length !== 0 && (
					<Tab label="Candidate Videos" value={tabNames.videos} />
				)}
				<Tab label="Candidate Activity" value={tabNames.activity} />
			</Tabs>
			<SwipeableViews
				index={Object.keys(tabNames)
					.filter(t => candidate?.videoAnswers.length !== 0 || t !== 'videos')
					.indexOf(activeTab)}
			>
				{children.filter(it => !!it)}
			</SwipeableViews>
		</div>
	)
}
