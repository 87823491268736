import { useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { DialogTitle, DialogContent, Paper } from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views'

import {
	ApplicantDashboardContext,
	createActions,
	getInitialState
} from './applicantDashboardStore'
import CandidateActivityTimeline from './CandidateActivityTimeline'
import CandidateDetailsDialog from './CandidateDetailsDialog'
import CandidateGeneralInfo from './CandidateGeneralInfo'
import CandidateApplicationDetailsLayout from './CandidateApplicationDetailsLayout'
import CandidateApplicationDetails from './CandidateApplicationDetails'
import CandidateVideoInterviewDetails from './CandidateVideoInterviewDetails'
import ConfirmationDialog from './ConfirmationDialog'
import JobApplicationStages from './JobApplicationStages'
import VideoInterviewNotSetupWarningDialog from './applicants-per-stage/VideoInterviewNotSetupWarningDialog'
import RejectionEmailDialog from './applicants-per-stage/RejectionEmailDialog'

import useAsyncActions from '../../../../common/hooks/useAsyncActions'
import routes from '../../../routes'
import getStages from './applicantStages'

export default function ApplicantDashboard({
	id,
	slug,
	title,
	daysToCompleteInterview,
	companyName,
	candidates,
	jobApplicationQuestions,
	videoInterviewQuestions,
	docs
}) {
	const [state, actions] = useAsyncActions(createActions, { candidates, slug }, getInitialState)

	const isVideoInterviewConfigured = videoInterviewQuestions.length > 0
	const candidateStages = useMemo(
		() => getStages(actions, isVideoInterviewConfigured),
		[actions, isVideoInterviewConfigured]
	)
	const { stageName = candidateStages[0].name, candidateId: stringId } = useParams()
	const candidateId = +stringId

	const currentStageIndex = candidateStages.findIndex(stage => stage.name === stageName)
	const candidatesInStage = state[stageName].sortedCandidates || []
	const currentCandidate = candidatesInStage.find(c => c.id === candidateId)
	if (candidateId && !currentCandidate) {
		let stageToNavigateTo = candidateStages.find(stage =>
			(state[stage.name].sortedCandidates || []).some(c => c.id === candidateId)
		)
		return stageToNavigateTo ? (
			<Redirect to={routes.jobApplicants.getLink(slug, stageToNavigateTo.name, candidateId)} />
		) : (
			<Redirect to={routes.jobApplicants.getLink(slug, stageName)} />
		)
	}

	return (
		<ApplicantDashboardContext.Provider value={actions}>
			<JobApplicationStages
				currentStageIndex={currentStageIndex}
				stages={candidateStages}
				{...state}
			/>
			<Paper elevation={2}>
				<SwipeableViews index={currentStageIndex}>
					{candidateStages.map(({ Component, name, candidateActions }, index) => (
						<Component
							key={index}
							jobSlug={slug}
							stage={name}
							candidateActions={candidateActions}
							daysToCompleteInterview={daysToCompleteInterview}
							{...state[name]}
						/>
					))}
				</SwipeableViews>
			</Paper>
			<CandidateDetailsDialog
				open={!!candidateId}
				currentCandidateId={candidateId}
				candidates={candidatesInStage}
				isLoading={state[stageName].isLoading}
				renderCandidateDetails={({ index, key }) => {
					const candidate = candidatesInStage[index]
					return (
						<section key={key}>
							<DialogTitle>
								{candidate && (
									<CandidateGeneralInfo
										candidate={candidate}
										actions={candidateStages[currentStageIndex].candidateActions}
									/>
								)}
							</DialogTitle>
							<DialogContent>
								{candidate && (
									<CandidateApplicationDetailsLayout candidate={candidate}>
										<CandidateApplicationDetails
											slug={slug}
											jobDocs={docs}
											jobApplicationQuestions={jobApplicationQuestions}
											candidate={candidate}
										/>
										{candidate.videoAnswers.length !== 0 && (
											<CandidateVideoInterviewDetails
												jobId={id}
												videoInterviewQuestions={videoInterviewQuestions}
												candidate={candidate}
												isCurrentApplicant={candidate.id === candidateId}
											/>
										)}
										<CandidateActivityTimeline candidate={candidate} />
									</CandidateApplicationDetailsLayout>
								)}
							</DialogContent>
						</section>
					)
				}}
			/>
			<VideoInterviewNotSetupWarningDialog
				{...state.videoInterviewNotSetupWarningDialogContext}
				jobSlug={slug}
				onClose={actions.hideVideoInterviewNotSetupWarningDialog}
			/>
			<RejectionEmailDialog
				{...state.rejectionEmailDialogContext}
				jobTitle={title}
				companyName={companyName}
				onClose={actions.hideRejectionEmailDialog}
			/>
			<ConfirmationDialog
				{...state.confirmationDialogContext}
				onClose={actions.hideConfirmationDialog}
			/>
		</ApplicantDashboardContext.Provider>
	)
}
