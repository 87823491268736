import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
	videoPlayer: {
		margin: 'auto',
		border: 'none',
		width: '100%',
		aspectRatio: '16/9',
		height: '100%'
	}
}))

const PublishedVideoPlayer = ({ srcUrl }) => {
	const classes = useStyles()
	return (
		<iframe
			title="Employer video"
			src={`${srcUrl}?autoplay=false&preload=false`}
			loading="lazy"
			className={classes.videoPlayer}
			allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
			allowFullScreen
		/>
	)
}

export default PublishedVideoPlayer
