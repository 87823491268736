import { get, postFormData } from '../common/restService'

const interviewService = {
	uploadTimeSlice: (applicantToken, { answerId, sliceIndex, blob, isFinal }) => {
		const data = new FormData()
		data.append('slice', blob)
		data.append('final', isFinal)

		return postFormData(`/api/video-answers/${answerId}/video-slices/${sliceIndex}`, data)
	},

	getNextVideoQuestion: (applicantToken, isPractice) =>
		get(`/api/video-interviews/${applicantToken}/questions/next?practice=${isPractice}`),

	getStatus: applicantToken => get(`/api/video-interviews/${applicantToken}`)
}

export default interviewService
