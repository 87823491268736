import ApplicantsInStage from '../ApplicantsInStage'

export default function HiredStageApplicants({ stage, candidates, isLoading, candidateActions }) {
	const columns = [
		{
			title: 'Date Applied',
			field: 'appliedDateTime',
			type: 'datetime',
			render: applicant =>
				applicant.appliedDateTime.toLocaleString('en-US', {
					dateStyle: 'short',
					timeStyle: 'short'
				})
		},
		{
			title: 'Interview Completed',
			field: 'interviewCompletedDateTime',
			type: 'datetime',
			render: applicant =>
				applicant.interviewCompletedDateTime &&
				applicant.interviewCompletedDateTime.toLocaleString('en-US', {
					dateStyle: 'short',
					timeStyle: 'short'
				})
		}
	]

	return (
		<ApplicantsInStage
			title="Hired"
			description="Currently, the purpose of this page is to document your scheduling and hiring. 
			There are no active functions on this page. More features to come!"
			stage={stage}
			columns={columns}
			candidateActions={candidateActions}
			candidates={candidates}
			isLoading={isLoading}
		/>
	)
}
