import { useEffect } from 'react'

import { Box, Grid, MenuItem, TextField } from '@material-ui/core'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import StatefulButton from '../../common/StatefullButton'
import useFormWithZod from '../../common/hooks/useFormWithZod'
import { useAuth } from '../auth/AuthProvider'
import { createCompanyDetails } from './registrationService'
import { companyDetailsQuestions } from './companyDetailsQuestions'

const companySchema = z.object(
	companyDetailsQuestions.reduce(
		(acc, { name, options }) => ({
			...acc,
			[name]: z.enum(options, {
				errorMap: () => ({ message: 'Please select an item from the dropdown' })
			})
		}),
		{}
	)
)

export default function CompanyDetails({ onContentChange }) {
	const { updateUserStatus } = useAuth()
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useFormWithZod(companySchema)

	const { mutate, isLoading } = useMutation({
		mutationFn: createCompanyDetails,
		onSuccess: () =>
			updateUserStatus({
				isCompanyDetailsSetup: true,
				isSubscriptionActive: true,
				isRegistrationComplete: true
			}),
		onError: error => {
			console.log('onError - error', error)
		}
	})

	useEffect(() => {
		if (errors) {
			onContentChange()
		}
	}, [errors, onContentChange])

	return (
		<Box component="form" sx={{ p: 1.5 }} onSubmit={handleSubmit(mutate)}>
			<Grid container spacing={3}>
				{companyDetailsQuestions.map(({ name, title, options }, index) => (
					<Grid item xs={12} sm={6} key={index}>
						<TextField
							label={title}
							name={name}
							variant="outlined"
							defaultValue=""
							{...register(name)}
							error={!!errors[name]}
							helperText={errors[name]?.message}
							SelectProps={{
								MenuProps: {
									PaperProps: {
										style: {
											maxHeight: 400
										}
									}
								}
							}}
							select
							fullWidth
						>
							{options.map((option, index) => (
								<MenuItem key={name + index} value={option}>
									{option}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				))}

				<Grid item xs={12} align="center">
					<StatefulButton isLoading={isLoading} type="submit" variant="contained" color="primary">
						Create
					</StatefulButton>
				</Grid>
			</Grid>
		</Box>
	)
}
