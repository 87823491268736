import { useReducer, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

import Question from './Question'
import Timer from './Timer'
import UploadProgress from './UploadProgress'
import Video from './Video'
import Controls from './Controls'

import interviewService from '../interviewService'
import VideoInterviewUploadService from './VideoInterviewUploadService'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
	root: {
		padding: spacing(2),
		[breakpoints.down('xs')]: {
			padding: spacing(2, 0),
			display: 'flex',
			flexDirection: 'column',
			height: '100vh'
		}
	},
	interviewPanel: {
		paddingBottom: spacing(0.1),
		margin: `${spacing(2)}px auto`,
		maxWidth: 640,
		[breakpoints.down('xs')]: {
			margin: 0,
			flexGrow: 1
		}
	},
	videoWrapper: {
		position: 'relative',
		minHeight: 300
	},
	timer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 55,
		minWidth: 115,
		position: 'absolute',
		top: 0,
		right: 0,
		backgroundColor: 'rgba(255,255,255,0.8)',
		padding: spacing(0, 1),
		transition: 'transform 200ms, height 200ms, width 200ms, background-color 200ms',
		transformOrigin: 'top right',
		'&.fullScreen': {
			transform: 'scale(2)',
			height: '50%',
			width: '50%',
			backgroundColor: 'rgba(255,255,255,0.5)'
		}
	},
	timerLabel: {
		verticalAlign: 'middle',
		marginRight: spacing(0.5)
	}
}))

const getInitialState = (question, step, demoQuestions, isCameraReady) => ({
	question,
	step,
	isCameraReady,
	isRecording: false,
	controlsEnabled: step === 'practice',
	timer: {
		totalSeconds: isCameraReady && question && question.readSeconds,
		start: isCameraReady && question && question.readSeconds
	},
	timeIsUp: false,
	timerLabel: isCameraReady && question && 'Begin answering in',
	questions: demoQuestions
})
const reducer = (state, action) => {
	switch (action.type) {
		case 'toggleQuestionLoading':
			return {
				...state,
				loadingQuestion: action.loading,
				isRecording: false,
				timeIsUp: false
			}
		case 'setNewQuestion': {
			return getInitialState(action.question, action.step, state.questions, state.isCameraReady)
		}
		case 'setCameraReady':
			return getInitialState(state.question, state.step, state.questions, true)
		case 'startRecording':
			return {
				...state,
				timer: { totalSeconds: action.question.answerSeconds, start: true },
				timerLabel: action.step === 'practice' ? 'Practice' : 'Recording',
				isRecording: true,
				controlsEnabled: true
			}
		case 'finishRecording':
			return {
				...state,
				timeIsUp: true,
				isRecording: false,
				uploadStillInProgress: action.uploadStillInProgress,
				controlsEnabled: !action.uploadStillInProgress,
				timerLabel: action.isPractice
					? 'Practice question completed!'
					: `Question ${action.questionNumber + 1} completed!`
			}
		case 'updateUploadProgress':
			return {
				...state,
				uploadStillInProgress: action.percentage !== 100,
				controlsEnabled: action.percentage === 100,
				uploadProgressPercentage: action.percentage
			}
		default:
			return state
	}
}

export default function VideoInterview({
	demoMode,
	demoQuestions,
	handleStartInterview,
	step,
	setStep,
	totalQuestions,
	applicantToken
}) {
	const [
		{
			timer,
			timerLabel,
			timeIsUp,
			isRecording,
			controlsEnabled,
			question,
			questions,
			loadingQuestion,
			isCameraReady,
			uploadStillInProgress,
			uploadProgressPercentage = 0
		},
		dispatch
	] = useReducer(reducer, getInitialState(false, step, demoQuestions, false))
	const uploadServiceRef = useRef()

	useEffect(() => {
		uploadServiceRef.current = new VideoInterviewUploadService(applicantToken)
		uploadServiceRef.current.onError(slice => {
			console.log(`Failed to upload slice ${slice.sliceIndex} for question ${slice.questionId}`)
			setStep('failed')
		})
		return () => {
			uploadServiceRef.current.dispose()
		}
	}, [setStep, applicantToken])

	useEffect(() => {
		const promptUser = e => {
			e.preventDefault()
			// in most browsers the custom messages doesn't work
			const message =
				'The interview is in progress, your recording might be lost! Are you sure you want leave/refresh this page?'
			// some browsers require returnValue to be set, but still show the default message
			e.returnValue = message
			return message
		}
		window.addEventListener('beforeunload', promptUser)

		return () => {
			window.removeEventListener('beforeunload', promptUser)
		}
	}, [])

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const setQuestion = useCallback(
		question => dispatch({ type: 'setNewQuestion', question, step }),
		[step]
	)

	const loadNextQuestion = useCallback(() => {
		scrollToTop()

		if (demoMode) {
			if (questions.length === 0) {
				setStep('done')
			} else {
				let newQuestion = questions.shift()
				setQuestion(newQuestion)
			}
			return
		}

		dispatch({ type: 'toggleQuestionLoading', loading: true })
		interviewService
			.getNextVideoQuestion(applicantToken, step !== 'interview')
			.then(question => {
				dispatch({ type: 'toggleQuestionLoading', loading: false })

				if (question) {
					setQuestion(question)
				} else {
					setStep('done')
				}
			})
			.catch(e => {
				dispatch({ type: 'toggleQuestionLoading', loading: false })
				console.log('Error trying to get the next question', e)
				// Todo: handle error, use snackbar
				//swalex.swalError('Uh oh, something went wrong.  Please try again later')
			})
	}, [demoMode, questions, applicantToken, step, setQuestion, setStep])

	// Todo: this works, but logically makes no sense, it just happens that loadNextQuestion changes at the right time.
	//  should be changed to represent the actual logical flow, which is probably a step change
	useEffect(() => {
		loadNextQuestion()
	}, [loadNextQuestion])

	const handleUploadSlice = useCallback(
		(blob, sliceIndex, final) => {
			if (demoMode) return

			if (step === 'interview' && question.currentAnswerId > 0) {
				uploadServiceRef.current.uploadSlice(question.currentAnswerId, sliceIndex, blob, final)
			}
		},
		[demoMode, step, question.currentAnswerId]
	)

	const isPractice = step === 'practice'
	const isFinalQuestion = question && question.order === totalQuestions - 1

	const finishRecording = useCallback(
		questionNumber => {
			let uploadStillInProgress = false
			if (isFinalQuestion) {
				if (uploadServiceRef.current.isUploading()) {
					uploadStillInProgress = true
					uploadServiceRef.current.onUploadProgress(percentage => {
						dispatch({ type: 'updateUploadProgress', percentage })

						if (percentage >= 100) loadNextQuestion()
					})
				} else loadNextQuestion()
			}
			dispatch({ type: 'finishRecording', questionNumber, isPractice, uploadStillInProgress })
		},
		[isPractice, isFinalQuestion, loadNextQuestion]
	)

	const onTimerElapsed = useCallback(
		totalSeconds => {
			// totalSeconds serves as timer identifier for now, if there is a problem when read time and answer time are the same, we need to figure out another way
			if (totalSeconds === question.readSeconds) {
				dispatch({ type: 'startRecording', question, step })
			} else if (totalSeconds === question.answerSeconds) {
				finishRecording(question.order)
			}
		},
		[question, step, finishRecording]
	)

	const onProceedToNextQuestion = useCallback(() => {
		if (isRecording) finishRecording(question.order)

		if ((!uploadServiceRef.current.isUploading() && isFinalQuestion) || timeIsUp || isPractice)
			loadNextQuestion()
	}, [
		isRecording,
		timeIsUp,
		isFinalQuestion,
		isPractice,
		question,
		finishRecording,
		loadNextQuestion
	])

	const onCameraReady = useCallback(() => {
		dispatch({ type: 'setCameraReady' })
	}, [])

	let buttonCaption = isFinalQuestion
		? 'Finish Interview'
		: !controlsEnabled || (isRecording && !timeIsUp)
		? 'Done'
		: 'Begin Next Question'

	const classes = useStyles()
	const { palette } = useTheme()
	return (
		<div className={classes.root}>
			{(isPractice || step === 'interview') && (
				<>
					<Question
						question={loadingQuestion ? false : question}
						isPractice={isPractice}
						totalQuestions={totalQuestions}
					/>
					<div className={classes.interviewPanel}>
						<div className={classes.videoWrapper}>
							<Video
								handleUploadSlice={handleUploadSlice}
								record={!isPractice && isRecording}
								onCameraReady={onCameraReady}
							/>
							<div className={clsx(classes.timer, !isRecording && 'fullScreen')}>
								<Typography
									variant="subtitle2"
									color={isRecording ? 'error' : 'textPrimary'}
									className={classes.timerLabel}
								>
									{timerLabel}
								</Typography>

								{!timeIsUp && (isRecording || timer.totalSeconds === question.readSeconds) && (
									<Timer
										timer={timer}
										onTimerElapsed={onTimerElapsed}
										color={isRecording || timeIsUp ? palette.error.main : 'default'}
									/>
								)}
								{uploadStillInProgress && (
									<UploadProgress uploadProgressPercentage={uploadProgressPercentage} />
								)}
							</div>
						</div>
						<Controls
							enabled={controlsEnabled && !loadingQuestion && isCameraReady}
							handleNextQuestion={onProceedToNextQuestion}
							handleStartInterview={handleStartInterview}
							isPractice={isPractice}
							buttonCaption={buttonCaption}
						/>
					</div>
				</>
			)}
		</div>
	)
}

VideoInterview.propTypes = {
	demoMode: PropTypes.bool,
	demoQuestions: PropTypes.array,
	handleStartInterview: PropTypes.func.isRequired,
	step: PropTypes.string.isRequired,
	totalQuestions: PropTypes.number
}
