import { Box, CircularProgress, Typography, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useQuery } from '@tanstack/react-query'

import LargeLoader from '../../common/LargeLoader'
import { getUsage } from './profileService'

function Billing() {
	const {
		isLoading,
		error,
		data: subscriptionUsage
	} = useQuery({ queryKey: ['subscription-usage'], queryFn: getUsage })

	const baseInterviews =
		subscriptionUsage.currentPhaseInterviews < 20 ? subscriptionUsage.currentPhaseInterviews : 20
	const extraInterviews =
		subscriptionUsage.currentPhaseInterviews > 20
			? subscriptionUsage.currentPhaseInterviews - 20
			: 0

	return isLoading ? (
		<LargeLoader />
	) : error ? (
		<Alert severity="error">Unable to load billing information</Alert>
	) : (
		!subscriptionUsage.isTrial && (
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={12} sm={6}>
					<Grid container justifyContent="center" direction="column" alignItems="center">
						<Grid item>
							<Typography variant="h6">Included Interviews:</Typography>
						</Grid>
						<Grid item>
							<Box position="relative" display="inline-flex">
								<CircularProgress
									variant="determinate"
									value={(baseInterviews * 100) / 20}
									color="primary"
									size={120}
								/>
								<Box
									top={0}
									left={0}
									bottom={0}
									right={0}
									position="absolute"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Typography
										variant="caption"
										component="div"
										color="textSecondary"
										style={{ fontSize: '2rem' }}
									>{`${baseInterviews}/20`}</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item>
							<Typography paragraph align="center">
								Included interviews consumed this period.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				{extraInterviews > 0 && (
					<Grid item xs={12} sm={6}>
						<Grid container justifyContent="center" direction="column" alignItems="center">
							<Grid item>
								<Typography variant="h6">Extra interviews:</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant="caption"
									component="div"
									color="textSecondary"
									style={{ fontSize: '3.5rem' }}
								>{`${extraInterviews}`}</Typography>
							</Grid>
							<Grid item>
								<Typography paragraph align="center">
									{extraInterviews} Extra interviews consumed this period.
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		)
	)
}

export default Billing
