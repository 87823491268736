import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Button } from '@material-ui/core'

import routes from '../../routes'

export default function InteractiveCell({
	text,
	job,
	justifyContent = 'center',
	to = routes.jobApplicants.getLink(job.slug)
}) {
	return (
		<Button
			color={job.status === 'open' ? 'primary' : 'default'}
			style={{
				width: '100%',
				borderRadius: 0,
				height: '100%',
				justifyContent,
				padding: '6px 12px'
			}}
			component={Link}
			to={to}
		>
			<Box
				component="span"
				sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}
			>
				{text}
			</Box>
		</Button>
	)
}
