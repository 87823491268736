import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import LargeLoader from '../../../../common/LargeLoader'
import ApplicantDashboard from './ApplicantDashboard'
import { getJobWithCandidates } from '../../../jobs/jobService'

const useStyles = makeStyles(({ spacing }) => ({
	root: { margin: `${spacing(3)}px auto`, width: '100vw', maxWidth: 1200 },
	backdrop: { zIndex: 99999999999 }
}))

export default function JobCandidates() {
	const { slug } = useParams()
	const [job, setJob] = useState()
	const [error, setError] = useState(false)

	useEffect(() => {
		getJobWithCandidates(slug)
			.then(setJob)
			.catch(e => {
				console.log(e)
				setError(true)
			})
	}, [slug])

	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography variant="h4">
				Job Candidates
				{job && (
					<Typography variant="subtitle2" color="textSecondary" paragraph>
						{job.title}
					</Typography>
				)}
			</Typography>
			{error ? (
				<Alert severity="error" style={{ margin: '50px auto' }}>
					<AlertTitle>Error</AlertTitle>
					Unable to load candidate data
				</Alert>
			) : job ? (
				<ApplicantDashboard slug={slug} {...job} />
			) : (
				<LargeLoader title="Loading candidate data..." />
			)}
		</div>
	)
}
