import { useContext } from 'react'
import { useTheme } from '@material-ui/core/styles'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	IconButton,
	Typography,
	useMediaQuery
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import marked from 'marked'
import JobApplicationContext from './jobApplicationContext'

export default function JobDescription({ expanded = false }) {
	const { jobInfo, actions } = useContext(JobApplicationContext)
	const { breakpoints, spacing } = useTheme()
	const isMobile = useMediaQuery(breakpoints.down('md'))
	return (
		<Accordion
			elevation={0}
			expanded={!isMobile || expanded}
			onChange={() => isMobile && actions.toggleJobDescription()}
		>
			<AccordionSummary style={{ cursor: isMobile ? 'pointer' : 'initial' }}>
				{isMobile && (
					<IconButton color="secondary" style={{ marginLeft: spacing(-1) }}>
						<ExpandMoreIcon
							style={{
								transform: expanded ? 'rotate(180deg)' : 'none',
								transition: 'transform 200ms'
							}}
						/>
					</IconButton>
				)}
				<Typography variant="h5" display="inline" style={{ alignSelf: 'center' }}>
					Job Description
				</Typography>
			</AccordionSummary>
			<AccordionDetails style={{ paddingTop: 0 }}>
				<div
					dangerouslySetInnerHTML={{
						__html: marked(jobInfo.generalInfo.description || '', { breaks: true })
					}}
				/>
			</AccordionDetails>
		</Accordion>
	)
}
