import {
	saveJobApplicantContacts,
	uploadFiles,
	saveAnswers,
	submitApplication
} from './jobApplicationService'

const isEmptyString = string => !(string && string.trim())

const getAnswersToSave = (questions, answers) => {
	const answersToSave = []
	for (let { id, questionType } of questions) {
		const answer = { questionId: id }
		// Todo: have questionType in some config to lookup from
		switch (questionType) {
			case 'multiple':
				answer.selectedChoiceIds = answers[id]
				break
			case 'single':
				answer.selectedChoiceIds = [answers[id]]
				break
			default:
				answer.text = answers[id]
		}

		answersToSave.push(answer)
	}
	return answersToSave
}

export const createActions = (dispatch, jobLinkToken, jobInfo) => ({
	updateContactInfo: payload =>
		dispatch(state => {
			const newContactInfo = { ...state.contactInfo.values, ...payload }
			const validationSummary = { isValid: true }
			for (const key in newContactInfo) {
				if (key === 'referral') continue

				if (key === 'state' && newContactInfo.country !== 'United States') continue

				if (isEmptyString(newContactInfo[key])) {
					validationSummary.isValid = false
					validationSummary[key] = 'This field is required'
				} else if (key === 'emailConfirmation') {
					if (newContactInfo[key].toLowerCase() !== newContactInfo['email'].toLowerCase()) {
						validationSummary.isValid = false
						validationSummary[key] = "Emails don't match"
						validationSummary['email'] = "Emails don't match"
					}
				}
			}

			return { ...state, contactInfo: { values: newContactInfo, validationSummary } }
		}),
	saveApplicantInfo: async applicantInfo => {
		dispatch(state => ({
			...state,
			contactInfo: { ...state.contactInfo, inProgress: true, error: false }
		}))
		let id
		try {
			id = (await saveJobApplicantContacts(applicantInfo, jobLinkToken)).id
		} catch (e) {
			const error = e.json ? await e.json() : e
			console.log(error)
			dispatch(state => ({
				...state,
				contactInfo: {
					...state.contactInfo,
					inProgress: false,
					error,
					validationSummary: error.errors
						? { ...state.contactInfo.validationSummary, ...error.errors }
						: state.contactInfo.validationSummary
				}
			}))
			return
		}
		dispatch(state => ({
			...state,
			contactInfo: { ...state.contactInfo, inProgress: false, candidateId: id },
			jobApplicationStepIndex: state.jobApplicationStepIndex + 1
		}))
	},
	updateFiles: files =>
		dispatch(state => {
			const validationSummary = { isValid: true }
			files.forEach(({ type, required, file }) => {
				if (required && !file) {
					validationSummary.isValid = false
					validationSummary[type] = 'This file is required to apply for this job'
				}
			})
			return { ...state, uploads: { files, validationSummary } }
		}),
	uploadFiles: async (candidateId, uploads) => {
		if (!uploads || uploads.length === 0) {
			dispatch(state => ({
				...state,
				jobApplicationStepIndex: state.jobApplicationStepIndex + 1
			}))
			return
		}

		dispatch(state => ({
			...state,
			uploads: { ...state.uploads, inProgress: true, error: false }
		}))
		try {
			await uploadFiles(candidateId, uploads)
		} catch (e) {
			const error = e.json ? await e.json() : e
			console.log(error)
			dispatch(state => ({
				...state,
				uploads: {
					...state.uploads,
					inProgress: false,
					error,
					validationSummary: error.errors
						? { ...state.uploads.validationSummary, ...error.errors }
						: state.uploads.validationSummary
				}
			}))
			return
		}
		dispatch(state => ({
			...state,
			uploads: { ...state.uploads, inProgress: false },
			jobApplicationStepIndex: state.jobApplicationStepIndex + 1
		}))
	},
	updateAnswer: (questionId, answer) =>
		dispatch(state => {
			const newAnswers = { ...state.answers.values }
			newAnswers[questionId] = answer

			const validationSummary = { isValid: true }
			if (jobInfo.questions.length !== Object.keys(newAnswers).length)
				validationSummary.isValid = false
			else {
				for (let questionId in newAnswers) {
					const answer = newAnswers[questionId]
					if (answer instanceof Array && answer.length === 0) {
						validationSummary.isValid = false
						validationSummary[questionId] = 'Please select at least one answer'
					} else if (!answer) {
						validationSummary.isValid = false
						validationSummary[questionId] = 'Please provide an answer'
					}
				}
			}
			return { ...state, answers: { values: newAnswers, validationSummary } }
		}),
	saveAnswers: async (candidateId, answers) => {
		dispatch(state => ({
			...state,
			answers: { ...state.answers, inProgress: true, error: false }
		}))
		const answersToSave = getAnswersToSave(jobInfo.questions, answers)
		try {
			await saveAnswers(candidateId, answersToSave)
		} catch (e) {
			const error = e.json ? await e.json() : e
			console.log(error)
			dispatch(state => ({
				...state,
				answers: {
					...state.answers,
					inProgress: false,
					error,
					validationSummary: error.errors
						? { ...state.answers.validationSummary, ...error.errors }
						: state.answers.validationSummary
				}
			}))
			return
		}
		dispatch(state => ({
			...state,
			answers: { ...state.answers, inProgress: false },
			jobApplicationStepIndex: state.jobApplicationStepIndex + 1
		}))
	},
	toggleConsent: consent =>
		dispatch(state => {
			const updatedConsents = { ...state.consents.acknowledgements, ...consent }
			const validationSummary = { isValid: true }
			for (const key in updatedConsents) {
				if (!updatedConsents[key]) validationSummary.isValid = false
			}
			return { ...state, consents: { acknowledgements: updatedConsents, validationSummary } }
		}),
	submitConsents: async (candidateId, consents) => {
		dispatch(state => ({
			...state,
			consents: { ...state.consents, inProgress: true, error: false }
		}))
		let showEmailInfo = true
		try {
			const { emailConfirmationError } = await submitApplication(candidateId, consents)
			if (emailConfirmationError) {
				showEmailInfo = false
			}
		} catch (e) {
			const error = e.json ? await e.json() : e
			console.log(error)
			dispatch(state => ({
				...state,
				consents: {
					...state.consents,
					inProgress: false,
					error,
					validationSummary: error.errors
						? { ...state.consents.validationSummary, ...error.errors }
						: state.consents.validationSummary
				}
			}))
			return
		}
		dispatch(state => ({
			...state,
			consents: { ...state.consents, inProgress: false },
			submissionStatus: { type: 'success', showEmailInfo },
			jobApplicationStepIndex: state.jobApplicationStepIndex + 1
		}))
	},
	goToPreviousStep: () =>
		dispatch(state => ({ ...state, jobApplicationStepIndex: state.jobApplicationStepIndex - 1 })),
	toggleJobDescription: () =>
		dispatch(state => ({ ...state, jobDescriptionExpanded: !state.jobDescriptionExpanded })),
	showJobNotAvailableMessage: message =>
		dispatch(state => ({ ...state, jobNotAvailableMessage: message })),
	updateInjectedStateBasedOnJobInfo: jobInfo =>
		dispatch(state => {
			state = { ...state, employerVideos: jobInfo.employerVideos }
			if (jobInfo.documents.every(d => !d.required)) {
				return { ...state, uploads: { validationSummary: { isValid: true } } }
			} else return state
		})
})

export const reduce = (state, action) => action(state)
