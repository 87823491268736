import React, { useReducer, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import VideoInterviewSetup from './interview-setup/VideoInterviewSetup'
import StatusMessage from './video-interview/StatusMessage'
import VideoInterview from './video-interview/VideoInterview'
import StartInterviewNote from './video-interview/StartInterviewNote'
import interviewService from './interviewService'
import InterviewLoader from './InterviewLoader'
import PoweredByHaystack from '../common/PoweredByHaystack'

export const VideoInterviewContext = React.createContext()

const reducer = (state, action) => {
	switch (action.type) {
		case 'setStep':
			return {
				...state,
				step: action.step,
				totalQuestions: action.totalQuestions || state.totalQuestions,
				applicantName: action.applicantName,
				companyName: action.companyName,
				jobTitle: action.jobTitle,
				introductionVideos: action.introductionVideos || state.introductionVideos
			}
		case 'setMic':
			return { ...state, micDevice: { deviceId: { exact: action.deviceId } } }
		case 'setCam':
			return { ...state, vidDevice: { deviceId: { exact: action.deviceId } } }
		case 'startInterview':
			let newStep = state.step
			if (state.step === 'setup') {
				newStep = state.demoMode ? 'interview' : 'practice'
			} else {
				newStep = 'interview'
			}

			return {
				...state,
				step: newStep,
				question: action.step === 'done' ? {} : state.question,
				showInterviewNote: false
			}
		case 'toggleInterviewNote':
			return { ...state, showInterviewNote: action.show }
		default:
			return state
	}
}

function InterviewApp({ demoMode, demoQuestions, applicantToken, totalQuestions }) {
	const [state, dispatch] = useReducer(reducer, {
		demoMode: demoMode,
		demoQuestions: demoMode ? [...demoQuestions] : [],
		micDevice: true,
		step: demoMode ? 'interview' : false,
		vidDevice: true,
		showInterviewNote: false
	})

	const handleStartInterview = useCallback(
		() => dispatch({ type: 'toggleInterviewNote', show: true }),
		[]
	)

	const setStep = useCallback(step => dispatch({ type: 'setStep', step }), [])

	useEffect(() => {
		if (demoMode) return

		if (!window.MediaRecorder) {
			dispatch({ type: 'setStep', step: 'browserNotSupported' })
			return
		}

		interviewService
			.getStatus(applicantToken)
			.then(response => dispatch({ type: 'setStep', ...response }))
			.catch(e => {
				console.log('error', e)
				if (e.status === 404) dispatch({ type: 'setStep', step: 'invalidLink' })
				else if (e.status === 500) dispatch({ type: 'setStep', step: 'serverError' })
			})
	}, [applicantToken, demoMode])

	return (
		<VideoInterviewContext.Provider value={[state, dispatch]}>
			<PoweredByHaystack />
			{!state.step ? (
				<InterviewLoader />
			) : state.step === 'setup' ? (
				<VideoInterviewSetup
					jobTitle={state.jobTitle}
					companyName={state.companyName}
					applicantName={state.applicantName}
					videos={state.introductionVideos}
				/>
			) : ['practice', 'interview'].includes(state.step) ? (
				<VideoInterview
					{...{ demoMode, applicantToken, totalQuestions }}
					{...state}
					handleStartInterview={handleStartInterview}
					setStep={setStep}
				/>
			) : (
				<StatusMessage step={state.step} videos={state.introductionVideos} />
			)}
			<StartInterviewNote
				open={state.showInterviewNote}
				onCancelClick={() => dispatch({ type: 'toggleInterviewNote', show: false })}
				onStartClick={() => dispatch({ type: 'startInterview' })}
			/>
		</VideoInterviewContext.Provider>
	)
}

InterviewApp.propTypes = {
	applicantToken: PropTypes.string,
	demoMode: PropTypes.bool,
	demoQuestions: PropTypes.array
}

export default InterviewApp
