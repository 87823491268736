import { SvgIcon } from '@material-ui/core'

export function GoogleLogo(props) {
	return (
		<SvgIcon {...props}>
			<path
				fill="rgb(25.882353%,52.156863%,95.686275%)"
				d="M 12.238281 9.816406 L 12.238281 14.464844 L 18.824219 14.464844 C 18.535156 15.960938 17.667969 17.226562 16.367188 18.078125 L 20.335938 21.097656 C 22.652344 19.003906 23.988281 15.925781 23.988281 12.273438 C 23.988281 11.421875 23.910156 10.605469 23.765625 9.816406 Z M 12.238281 9.816406 "
			/>
			<path
				fill="rgb(20.392157%,65.882353%,32.54902%)"
				d="M 5.378906 14.285156 L 4.484375 14.957031 L 1.3125 17.378906 C 3.328125 21.292969 7.453125 24 12.238281 24 C 15.542969 24 18.3125 22.929688 20.335938 21.097656 L 16.363281 18.078125 C 15.273438 18.796875 13.882812 19.234375 12.238281 19.234375 C 9.054688 19.234375 6.351562 17.128906 5.382812 14.292969 Z M 5.378906 14.285156 "
			/>
			<path
				fill="rgb(98.431373%,73.72549%,1.960784%)"
				d="M 1.3125 6.621094 C 0.476562 8.238281 0 10.058594 0 12 C 0 13.941406 0.476562 15.761719 1.3125 17.378906 C 1.3125 17.390625 5.386719 14.28125 5.386719 14.28125 C 5.140625 13.558594 4.996094 12.796875 4.996094 12 C 4.996094 11.203125 5.140625 10.441406 5.386719 9.71875 Z M 1.3125 6.621094 "
			/>
			<path
				fill="rgb(91.764706%,26.27451%,20.784314%)"
				d="M 12.238281 4.777344 C 14.039062 4.777344 15.640625 5.390625 16.921875 6.566406 L 20.425781 3.132812 C 18.300781 1.1875 15.542969 0 12.238281 0 C 7.453125 0 3.328125 2.695312 1.3125 6.621094 L 5.382812 9.71875 C 6.351562 6.882812 9.054688 4.777344 12.238281 4.777344 Z M 12.238281 4.777344 "
			/>
		</SvgIcon>
	)
}

export function MicrosoftLogo(props) {
	return (
		<SvgIcon {...props}>
			<path fill="#f35325" d="M0 0h10v10H0z" />
			<path fill="#81bc06" d="M11 0h10v10H11z" />
			<path fill="#05a6f0" d="M0 11h10v10H0z" />
			<path fill="#ffba08" d="M11 11h10v10H11z" />
		</SvgIcon>
	)
}
