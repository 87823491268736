function invokeCallbacks(callbacks, item, queue) {
	for (let i = 0; i < callbacks.length; i++) {
		callbacks[i](item, queue)
	}
}

class ObservableQueue {
	constructor(array = []) {
		this._arr = array
		this._callbacks = { onEnqueue: [], onDequeue: [] }
	}

	onEnqueue(callback) {
		this._callbacks.onEnqueue.push(callback)
	}

	offEnqueue(callback) {
		this._callbacks.onEnqueue = this._callbacks.onEnqueue.filter(c => c !== callback)
	}

	onDequeue(callback) {
		this._callbacks.onDequeue.push(callback)
	}

	offDequeue(callback) {
		this._callbacks.onDequeue = this._callbacks.onDequeue.filter(c => c !== callback)
	}

	enqueue(item) {
		this._arr.push(item)
		invokeCallbacks(this._callbacks.onEnqueue, item, this)
	}

	dequeue() {
		const item = this._arr.shift()
		invokeCallbacks(this._callbacks.onDequeue, item, this)
		return item
	}

	peek(itemIndex = 0) {
		return this._arr[itemIndex]
	}

	isEmpty() {
		return this._arr.length === 0
	}

	count() {
		return this._arr.length
	}
}

export default ObservableQueue
