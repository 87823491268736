import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Fab, CircularProgress } from '@material-ui/core'

import PrevCandidateIcon from '@material-ui/icons/ChevronLeft'
import NextCandidateIcon from '@material-ui/icons/ChevronRight'

import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'

import routes from '../../../routes'
import CustomDialog from '../../../../common/CustomDialog'

const largeFabHeight = 56
const useStyles = makeStyles(({ spacing }) => ({
	root: {
		position: 'relative',
		'& .MuiDialog-paper': { overflow: 'visible' }
	},
	prevButton: {
		position: 'absolute',
		top: `calc(50% - ${largeFabHeight / 2}px)`,
		left: -largeFabHeight - spacing(3),
		'&[disabled]': { pointerEvents: 'auto' }
	},
	nextButton: {
		position: 'absolute',
		top: `calc(50% - ${largeFabHeight / 2}px)`,
		right: -largeFabHeight - spacing(3),
		'&[disabled]': { pointerEvents: 'auto' }
	},
	backdrop: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		backgroundColor: 'rgba(255,255,255, 0.7)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}))

const VirtualizedSwipeableViews = virtualize(SwipeableViews)

export default function CandidateDetailsDialog({
	open,
	currentCandidateId,
	isLoading,
	candidates,
	renderCandidateDetails
}) {
	const { slug, stageName } = useParams()
	const history = useHistory()

	const currentApplicantIndex = candidates.findIndex(c => c.id === currentCandidateId)

	const setCurrentApplicantIndex = useCallback(
		e =>
			history.push(
				routes.jobApplicants.getLink(
					slug,
					stageName,
					candidates[currentApplicantIndex + (e.currentTarget.name === 'prev' ? -1 : 1)].id
				)
			),
		[history, currentApplicantIndex, slug, stageName, candidates]
	)

	const onDialogClose = useCallback(
		e => history.push(routes.jobApplicants.getLink(slug, stageName)),
		[history, slug, stageName]
	)

	const classes = useStyles()
	return (
		<CustomDialog
			maxWidth="md"
			fullWidth
			open={open}
			className={classes.root}
			onClose={onDialogClose}
		>
			<Fab
				color="secondary"
				size="large"
				disabled={currentApplicantIndex === 0}
				className={classes.prevButton}
				name="prev"
				onClick={setCurrentApplicantIndex}
			>
				<PrevCandidateIcon />
			</Fab>
			<VirtualizedSwipeableViews
				index={currentApplicantIndex}
				slideRenderer={renderCandidateDetails}
				slideCount={candidates.length}
			/>
			<Fab
				color="secondary"
				size="large"
				disabled={currentApplicantIndex === candidates.length - 1}
				className={classes.nextButton}
				name="next"
				onClick={setCurrentApplicantIndex}
			>
				<NextCandidateIcon />
			</Fab>
			{isLoading && (
				<div className={classes.backdrop}>
					<CircularProgress color="secondary" />
				</div>
			)}
		</CustomDialog>
	)
}
