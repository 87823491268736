import { useState, useRef, useCallback, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useTheme, makeStyles } from '@material-ui/core/styles'

import { Typography, Tooltip, Box } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import VideoInterviewEditIcon from '@material-ui/icons/VoiceChat'
import CloneIcon from '@material-ui/icons/FileCopy'
import LinksIcon from '@material-ui/icons/Link'
import EditIcon from '@material-ui/icons/Create'
import NotificationsIcon from '@material-ui/icons/NotificationsActive'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import Search from '@material-ui/icons/Search'
import Clear from '@material-ui/icons/Clear'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ArrowUpward from '@material-ui/icons/ArrowUpward'

import MaterialTable, { MTableGroupRow } from '@material-table/core'

import OpenCloseJobSwitch from './OpenCloseJobSwitch'
import CustomizedFreeAction from '../../../common/CustomizedFreeAction'
import InteractiveCell from './InteractiveCell'
import NotificationSettingsDialog from './NotificationSettingsDialog'
import routes from '../../routes'
import PromptDialog from '../../../common/PromptDialog'
import { cloneJob } from '../jobService'
import { stageNames } from '../job/applicants/stageConstants'

const icons = {
	Search: Search,
	ResetSearch: Clear,
	FirstPage: FirstPage,
	LastPage: LastPage,
	NextPage: ChevronRight,
	PreviousPage: ChevronLeft,
	SortArrow: ArrowUpward,
	DetailPanel: ChevronRight
}

const useStyles = makeStyles(({ spacing }) => ({
	root: { margin: `${spacing(5)}px auto`, flex: 1, maxWidth: 1500 }
}))

export default function JobList({ jobs, toggleJobStatus }) {
	const history = useHistory()
	const [jobCloneConfirmationDialogIsOpen, togglePrompt] = useState(false)
	const [notificationSettingsDialogIsOpen, toggleNotificationSettingsDialog] = useState(false)
	const jobToActOnRef = useRef()
	const isFirstRenderRef = useRef(true)

	const componentOverrides = useMemo(
		() => ({
			Action: props => <CustomizedFreeAction {...props} />,
			GroupRow: props => {
				// this little hack is to automatically expand open jobs group, only on first render, for all later state changes obey the isExpanded state
				if (isFirstRenderRef.current && props.groupData.value === 'open') {
					props.groupData.isExpanded = true
					isFirstRenderRef.current = false
				}
				return <MTableGroupRow {...props} />
			}
		}),
		[]
	)

	const { spacing } = useTheme()

	const compactCellStyle = useMemo(() => ({ padding: 0, height: 'inherit' }), [])

	const onCloneJobClick = useCallback((e, job) => {
		jobToActOnRef.current = job
		togglePrompt(true)
	}, [])

	const onProceedWithJobCloning = async () => {
		const clonedJob = await cloneJob(jobToActOnRef.current.id)
		togglePrompt(false)
		history.push(routes.editJob.getLink(clonedJob.slug))
	}

	const actions = useMemo(
		() => [
			{
				isFreeAction: true,
				onClick: () => {},
				icon: () => <AddIcon color="primary" />,
				tooltip: 'New Job',
				componentProps: { component: Link, to: routes.editJob.getLink('new') }
			},
			{
				onClick: (e, job) => {
					history.push(routes.jobApplicationForm.getLink(job.slug))
				},
				tooltip: 'View/Edit Application',
				icon: () => <EditIcon color="primary" />
			},
			{
				onClick: (e, job) => {
					history.push(routes.jobLinks.getLink(job.slug))
				},
				tooltip: 'Manage Job Links',
				icon: () => <LinksIcon color="primary" />
			},
			{
				onClick: (e, job) => {
					history.push(routes.editInterview.getLink(job.slug))
				},
				tooltip: 'View/Edit Video Interview',
				icon: () => <VideoInterviewEditIcon color="primary" />
			},
			{
				onClick: onCloneJobClick,
				tooltip: 'Clone Job',
				icon: () => <CloneIcon color="primary" />
			},
			{
				onClick: (e, job) => {
					jobToActOnRef.current = job
					toggleNotificationSettingsDialog(true)
				},
				tooltip: 'Manage Notifications',
				icon: () => <NotificationsIcon color="primary" />
			}
		],
		[history, onCloneJobClick]
	)
	const columns = useMemo(
		() => [
			{
				title: 'Closed/Open',
				field: 'status',
				grouping: false,
				sorting: false,
				headerStyle: {
					textAlign: 'left',
					paddingLeft: spacing(0.5),
					paddingRight: 0
				},
				cellStyle: { ...compactCellStyle, paddingLeft: spacing(2) },
				render: job => (
					<OpenCloseJobSwitch
						jobId={job.id}
						status={job.status}
						onJobStatusChange={toggleJobStatus}
					/>
				)
			},
			{
				title: 'Status',
				field: 'status',
				headerStyle: { textAlign: 'left' },
				defaultGroupOrder: 0,
				defaultGroupSort: 'desc',
				render(data, renderType) {
					const status = renderType === 'group' ? data : data[this.field]
					return status === 'open' ? 'Open' : 'Closed'
				}
			},
			{
				title: 'Title',
				field: 'title',
				headerStyle: { textAlign: 'left' },
				cellStyle: compactCellStyle,
				render: job => (
					<Tooltip title="View Your Candidates" placement="bottom">
						<Box sx={{ height: '100%', width: 280 }}>
							<InteractiveCell text={job.title} job={job} justifyContent="flex-start" />
						</Box>
					</Tooltip>
				)
			},
			{
				title: 'Opened',
				field: 'openedAt',
				headerStyle: { textAlign: 'left' },
				type: 'datetime',
				defaultSort: 'desc',
				grouping: false,
				cellStyle: { ...compactCellStyle },
				render: job =>
					new Date(job.openedAt).toLocaleString('en-US', {
						dateStyle: 'short',
						timeStyle: 'short'
					})
			},
			{
				title: 'Application Started',
				field: 'totalStartedApplications',
				type: 'numeric',
				grouping: false,
				headerStyle: { textAlign: 'center' },
				draggable: false,
				cellStyle: { ...compactCellStyle, textAlign: 'center' }
			},
			{
				title: 'Applied',
				field: 'totalCandidates',
				type: 'numeric',
				grouping: false,
				headerStyle: { textAlign: 'center' },
				draggable: false,
				cellStyle: compactCellStyle,
				render: job => <InteractiveCell text={job.totalCandidates} job={job} />
			},
			{
				title: 'Interview Invited',
				field: 'invitedToVideoInterviewCandidates',
				type: 'numeric',
				grouping: false,
				headerStyle: { textAlign: 'center' },
				draggable: false,
				cellStyle: compactCellStyle,
				render: job => (
					<InteractiveCell
						text={job.invitedToVideoInterviewCandidates}
						job={job}
						to={routes.jobApplicants.getLink(job.slug, stageNames.video)}
					/>
				)
			},
			{
				title: 'Interview Completed',
				field: 'totalInterviews',
				type: 'numeric',
				headerStyle: { textAlign: 'center' },
				draggable: false,
				grouping: false,
				cellStyle: compactCellStyle,
				render: job => (
					<InteractiveCell
						text={job.totalInterviews}
						job={job}
						to={routes.jobApplicants.getLink(job.slug, stageNames.video)}
					/>
				)
			},
			{
				title: 'Face to Face',
				field: 'invitedToFaceToFaceCandidates',
				type: 'numeric',
				headerStyle: { textAlign: 'center' },
				draggable: false,
				grouping: false,
				cellStyle: compactCellStyle,
				render: job => (
					<InteractiveCell
						text={job.invitedToFaceToFaceCandidates}
						job={job}
						to={routes.jobApplicants.getLink(job.slug, stageNames.face)}
					/>
				)
			},
			{
				title: 'Hired',
				field: 'hiredCandidates',
				type: 'numeric',
				headerStyle: { textAlign: 'center' },
				draggable: false,
				grouping: false,
				cellStyle: compactCellStyle,
				render: job => (
					<InteractiveCell
						text={job.hiredCandidates}
						job={job}
						to={routes.jobApplicants.getLink(job.slug, stageNames.hired)}
					/>
				)
			}
		],
		[toggleJobStatus, compactCellStyle, spacing]
	)

	const options = useMemo(
		() => ({
			toolbarButtonAlignment: 'left',
			pageSize: 5,
			headerStyle: { textAlign: 'center' },
			rowStyle: { height: spacing(8) },
			actionsColumnIndex: 1,
			paging: false,
			padding: 'dense',
			actionsCellStyle: { ...compactCellStyle, paddingRight: spacing(2) }
		}),
		[spacing, compactCellStyle]
	)

	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Typography gutterBottom={true} variant="body2" color="textSecondary">
				<InfoIcon fontSize="inherit" style={{ verticalAlign: 'text-top' }} />
				Below are all your jobs. Click on the Job Title to see your candidates for that job.
			</Typography>
			<MaterialTable
				title="Jobs"
				data={jobs}
				actions={actions}
				components={componentOverrides}
				columns={columns}
				icons={icons}
				options={options}
			/>
			<PromptDialog
				open={jobCloneConfirmationDialogIsOpen}
				title="Clone Job?"
				text="Use the settings of this job as a starting point to set up a new job."
				onCancel={() => togglePrompt(false)}
				onProceed={onProceedWithJobCloning}
			/>
			<NotificationSettingsDialog
				open={notificationSettingsDialogIsOpen}
				job={jobToActOnRef.current && jobs.find(job => job.id === jobToActOnRef.current.id)}
				onClose={() => toggleNotificationSettingsDialog(false)}
			/>
		</div>
	)
}
