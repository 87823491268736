import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

import MultipleCheckboxesIcon from 'mdi-material-ui/CheckboxMultipleMarkedOutline'
import RadioButtonIcon from 'mdi-material-ui/RadioboxMarked'
import ShortTextIcon from 'mdi-material-ui/TextShort'
import LongTextIcon from '@material-ui/icons/Subject'

import AnswerList from './AnswerList'

const answerTypes = [
	{ value: 'single', Icon: RadioButtonIcon, label: 'Choose One' },
	{ value: 'multiple', Icon: MultipleCheckboxesIcon, label: 'Choose Many' },
	{ value: 'short', Icon: ShortTextIcon, label: 'Short Text' },
	{ value: 'long', Icon: LongTextIcon, label: 'Long Text' }
]

const choiceQuestionTypes = ['single', 'multiple']

const useStyles = makeStyles(({ spacing }) => ({
	answersHeader: { display: 'block', marginTop: spacing(2) }
}))

function AnswerChoicesEditor({ questionId, questionType, answerChoices, updateQuestion }) {
	const [showAnswersEditor, toggleAnswersEditor] = useState(
		choiceQuestionTypes.includes(questionType)
	)

	const onQuestionTypeChange = (e, value) => {
		toggleAnswersEditor(choiceQuestionTypes.includes(value))
		updateQuestion(questionId, { questionType: value })
	}

	const updateAnswer = ({ target }) => {
		const updatedChoices = [...answerChoices]
		updatedChoices[+target.name] = { ...(updatedChoices[+target.name] || {}), text: target.value }

		updateQuestion(questionId, { answerChoices: updatedChoices })
	}

	const deleteAnswer = ({ currentTarget }) => {
		const deletedChoiceIndex = +currentTarget.name
		const updatedChoices = answerChoices.filter((answer, index) => deletedChoiceIndex !== index)

		for (let i = 0; i < updatedChoices.length; i++) {
			updatedChoices[i].order = i
		}

		updateQuestion(questionId, { answerChoices: updatedChoices })
	}

	const updateAnswerOrder = ({ oldIndex, newIndex }) => {
		const reordered = [...answerChoices]
		const answer = reordered.splice(oldIndex, 1)[0]
		reordered.splice(newIndex, 0, answer)

		for (let i = 0; i < reordered.length; i++) {
			reordered[i].order = i
		}

		updateQuestion(questionId, { answerChoices: reordered })
	}

	const classes = useStyles()
	return (
		<>
			<Typography variant="subtitle2" gutterBottom>
				Answer Type
			</Typography>
			<ToggleButtonGroup
				size="small"
				value={questionType || 'not selected'}
				exclusive
				onChange={onQuestionTypeChange}
			>
				{answerTypes.map(({ value, Icon, label }) => (
					<ToggleButton key={value} value={value}>
						<Icon />
						&nbsp;{label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
			{showAnswersEditor && (
				<>
					<Typography variant="caption" className={classes.answersHeader}>
						Provide at least 2 answer choices
					</Typography>
					<AnswerList
						answerChoices={answerChoices}
						updateAnswer={updateAnswer}
						deleteAnswer={deleteAnswer}
						onSortEnd={updateAnswerOrder}
					/>
				</>
			)}
		</>
	)
}

export default AnswerChoicesEditor
