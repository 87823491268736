import { Box, CircularProgress, Typography } from '@material-ui/core'

import useTimer from '../../../../../common/hooks/useTimer'

export default function TimerPreview({ preview, onTimerElapsed }) {
	const [displayText, secondsRemaining] = useTimer(preview, onTimerElapsed)

	return (
		<Box sx={{ width: 36, height: 36, position: 'relative', m: '0 auto' }}>
			<CircularProgress
				variant="static"
				color="secondary"
				value={(100 * secondsRemaining) / preview.totalSeconds}
				size={36}
				thickness={5}
			/>
			<Box
				sx={{
					position: 'absolute',
					inset: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Typography variant="caption">{displayText}</Typography>
			</Box>
		</Box>
	)
}
