const appinsights_settings_dev = {
	instrumentationKey: 'b5d438e3-e0a7-42e6-b6c1-8d57d1bc27b2'
}

const appinsights_settings_prod = {
	instrumentationKey: 'c1b6dfe2-4951-44e4-b02a-d3629da316f9'
}

const appInsightsSettings =
	process.env.NODE_ENV === 'production' ? appinsights_settings_prod : appinsights_settings_dev

export default appInsightsSettings
