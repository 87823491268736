import { HubConnectionBuilder } from '@microsoft/signalr'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(({ spacing }) =>
	createStyles({
		close: {
			padding: spacing(0.5)
		}
	})
)

export const Notify = ({ companyId }) => {
	const classes = useStyles()
	const [open, setOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [connection, setConnection] = useState()

	useEffect(() => {
		const connect = new HubConnectionBuilder()
			.withUrl('/api/hubs/videos')
			.withAutomaticReconnect()
			.build()

		setConnection(connect)
	}, [])

	useEffect(() => {
		if (connection && companyId) {
			connection
				.start()
				.then(() => {
					connection
						.invoke('SubscribeToGroup', companyId.toString())
						.catch(err => console.error(err.toString()))
				})
				.catch(error => console.log(error))
			connection.on('ReceiveMessage', message => {
				setMessage(message)
				setOpen(true)
			})
		}
	}, [connection, companyId])

	const handleClose = () => {
		setMessage('')
		setOpen(false)
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			message={message}
			action={
				<React.Fragment>
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						className={classes.close}
						onClick={handleClose}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</React.Fragment>
			}
		/>
	)
}
