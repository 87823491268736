import { Tooltip, IconButton, Grid, Avatar, Typography, Link, Badge } from '@material-ui/core'

import EmailIcon from '@material-ui/icons/Email'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import AddressIcon from '@material-ui/icons/Home'
import { Rating } from '@material-ui/lab'

export default function CandidateGeneralInfo({ candidate, actions }) {
	return (
		<Grid container justifyContent="space-between">
			<Grid item>
				<Grid container spacing={2}>
					<Grid item>
						<Avatar
							variant="rounded"
							style={{ height: 130, width: 130 }}
							alt={candidate?.fullName}
						/>
					</Grid>
					<Grid item>
						<Badge
							color="secondary"
							badgeContent="?"
							invisible={!candidate.status.includes('not_sure')}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							style={{ paddingRight: 8 }}
						>
							<Typography variant="h5" display="inline">
								{candidate.fullName}
							</Typography>
						</Badge>
						<Typography>
							<EmailIcon color="disabled" style={{ verticalAlign: 'bottom' }} />{' '}
							<Link href={`mailto:${candidate.email}`}>{candidate.email}</Link>
						</Typography>
						<Typography>
							<SmartphoneIcon color="disabled" style={{ verticalAlign: 'bottom' }} />{' '}
							{candidate.phone}
						</Typography>
						<AddressIcon color="disabled" style={{ verticalAlign: 'top' }} />
						&nbsp;
						<Typography style={{ display: 'inline-block' }}>
							{candidate.address}
							<br />
							{candidate.city}, {candidate.state} {candidate.zipCode}
							<br />
							{candidate.country}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Grid container direction="column" justifyContent="space-between">
					<Grid item>
						<Typography variant="subtitle2" color="textSecondary">
							Date Applied
						</Typography>
						<Typography variant="body2" gutterBottom>
							{candidate.appliedDateTime.toLocaleString('en-US', {
								dateStyle: 'short',
								timeStyle: 'short'
							})}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle2" color="textSecondary">
							Source
						</Typography>
						<Typography variant="body2" gutterBottom>
							{candidate.source}
						</Typography>
					</Grid>
					{candidate.referral && (
						<Grid item>
							<Typography variant="subtitle2" color="textSecondary">
								Referred by
							</Typography>
							<Typography variant="body2">{candidate.referral}</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid item>
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<Typography variant="subtitle2" color="textSecondary">
							Average Video Rating
						</Typography>
						<Rating
							precision={0.5}
							value={
								candidate.videoAnswers.reduce((sum, va) => sum + va.rating, 0) /
								candidate.videoAnswers.length
							}
							readOnly
						/>
					</Grid>

					<Grid item>
						{actions.map((it, index) => {
							const action = it instanceof Function ? it(candidate) : it
							return (
								!action.hidden && (
									<Tooltip key={index} title={action.tooltip}>
										<IconButton
											color="inherit"
											onClick={e => action.onClick && action.onClick(e, candidate)}
										>
											{typeof action.icon instanceof Function ? action.icon() : <action.icon />}
										</IconButton>
									</Tooltip>
								)
							)
						})}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
