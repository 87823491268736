import { useState } from 'react'
import { Link } from 'react-router-dom'

import { IconButton, Menu, MenuItem } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'

import { useAuth } from './auth/AuthProvider'
import routes from './routes'

export default function ProfileMenu() {
	const { signOut } = useAuth()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	function showMenu(event) {
		setAnchorEl(event.currentTarget)
	}

	function hideMenu() {
		setAnchorEl(null)
	}

	return (
		<div>
			<IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={showMenu}
				color="inherit"
			>
				<AccountCircle />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={open}
				onClose={hideMenu}
			>
				<MenuItem onClick={hideMenu} component={Link} to={routes.profile.path}>
					Profile
				</MenuItem>
				<MenuItem onClick={signOut}>Sign Out</MenuItem>
			</Menu>
		</div>
	)
}
