import { useState, useCallback, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

import ImageUploader from './ImageUploader'
import JobSetupContext from '../jobSetupContext'
import CustomDialog from '../../../../../common/CustomDialog'

const useStyles = makeStyles(({ spacing }) => ({
	radioButtonContainer: { paddingLeft: spacing(1) },
	logoPreview: {
		height: 120,
		width: 300,
		border: '1px solid rgba(0,0,0,0.23)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	logo: { maxHeight: 120 - spacing(2), maxWidth: '100%' }
}))

export default function LogoSetup({ companyLogoUrl, isCustomLogo, customLogoUrl }) {
	const [dialogContext, setDialogContext] = useState({ open: false })
	const actions = useContext(JobSetupContext)

	const onImageChange = useCallback(file => setDialogContext({ open: true, file }), [])

	const closeDialog = useCallback(() => {
		setDialogContext({ open: false })
	}, [])

	const setLogo = useCallback(
		({ currentTarget }) => {
			actions.setLogo(currentTarget.name === 'custom', dialogContext.file)
			closeDialog()
		},
		[closeDialog, dialogContext.file, actions]
	)

	const changeLogoType = useCallback(
		({ target }) => {
			actions.setLogoMode(target.value)
		},
		[actions]
	)

	const logoMode = isCustomLogo
		? customLogoUrl
			? 'custom'
			: 'none'
		: companyLogoUrl
		? 'company'
		: 'initial'

	const classes = useStyles()
	return (
		<>
			<Typography variant="h6">Logo</Typography>
			{logoMode !== 'initial' && (
				<RadioGroup
					value={logoMode}
					className={classes.radioButtonContainer}
					row
					onChange={changeLogoType}
				>
					{companyLogoUrl && (
						<FormControlLabel value="company" control={<Radio />} label="Company" />
					)}
					<FormControlLabel value="custom" control={<Radio />} label="Custom" />
					<FormControlLabel value="none" control={<Radio />} label="No Logo" />
				</RadioGroup>
			)}

			{logoMode !== 'none' && (
				<>
					<section className={classes.logoPreview}>
						{(logoMode === 'custom' || logoMode === 'initial') && (
							<ImageUploader url={customLogoUrl} onImageChange={onImageChange} />
						)}
						{logoMode === 'company' && (
							<img alt="Logo preview" src={companyLogoUrl} className={classes.logo} />
						)}
					</section>
					{customLogoUrl && (
						<Typography gutterBottom variant="caption" color="textSecondary">
							To change the logo, click in the box above to select a file or drag and drop a file.
						</Typography>
					)}
				</>
			)}

			<CustomDialog open={dialogContext.open} onClose={closeDialog} title="Set as default?">
				<DialogContent>
					<DialogContentText>
						Selected image can be set as company logo and will be used as default logo for future
						jobs. You can change the default logo in the profile settings any time.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button name="company" onClick={setLogo}>
						Set as default
					</Button>
					<Button name="custom" onClick={setLogo}>
						Set for this job only
					</Button>
				</DialogActions>
			</CustomDialog>
		</>
	)
}
