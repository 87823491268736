const dbName = 'haystackClientDb'
const employerVideoSlicesStoreName = 'employerVideoSlices'
const videoTypeIndexName = 'videoType'
const videoTypeJobIdIndexName = 'videoType_jobId'
let db
let employerVideoSlicesRepo

async function openDb() {
	return new Promise((resolve, reject) => {
		if (db) {
			resolve(db)
			return
		}
		const request = window.indexedDB.open(dbName, 1)

		request.onerror = event => {
			console.log('error opening database', event)
			reject(`Error opening database ${dbName}`)
		}

		request.onsuccess = event => {
			db = event.target.result
			resolve(db)
		}

		request.onupgradeneeded = event => {
			const db = event.target.result
			const objectStore = db.createObjectStore(employerVideoSlicesStoreName, {
				keyPath: 'id',
				autoIncrement: true
			})
			objectStore.createIndex(videoTypeIndexName, ['videoType'], { unique: false })
			objectStore.createIndex(videoTypeJobIdIndexName, ['videoType', 'jobId'], { unique: false })
		}
	})
}

async function add(storeName, obj) {
	await openDb()
	return new Promise((resolve, reject) => {
		const transaction = db.transaction([storeName], 'readwrite')
		const objectStore = transaction.objectStore(storeName)
		const request = objectStore.add(obj)

		request.onerror = event => {
			console.log('error adding blob', event)
			reject(`Error adding blob to ${storeName}`)
		}

		request.onsuccess = event => {
			resolve()
		}
	})
}

async function getByIndex(storeName, indexName, indexValue) {
	await openDb()
	return new Promise((resolve, reject) => {
		const transaction = db.transaction([storeName], 'readonly')
		const objectStore = transaction.objectStore(storeName)
		const index = objectStore.index(indexName)
		const request = index.getAll(indexValue)

		request.onerror = event => {
			reject(`Error getting blobs from ${storeName}`)
		}

		request.onsuccess = event => {
			resolve(event.target.result)
		}
	})
}

async function deleteAllByIndex(storeName, indexName, indexValue) {
	await openDb()
	return new Promise((resolve, reject) => {
		const transaction = db.transaction([storeName], 'readwrite')
		const objectStore = transaction.objectStore(storeName)
		const index = objectStore.index(indexName)
		const range = IDBKeyRange.only(indexValue)
		const request = index.openCursor(range)

		request.onerror = event => {
			console.log('error deleting blobs', event)
			reject(`Error deleting blobs from ${storeName}`)
		}

		request.onsuccess = event => {
			const cursor = event.target.result
			if (cursor) {
				cursor.delete()
				cursor.continue()
			} else {
				resolve()
			}
		}
	})
}

const haystackDb = {
	get employerVideoSlicesRepo() {
		return (
			employerVideoSlicesRepo ||
			(employerVideoSlicesRepo = {
				async add(obj) {
					return add(employerVideoSlicesStoreName, obj)
				},
				async getFullVideoAsBlob(jobId, videoType) {
					let indexName = videoTypeIndexName
					let indexValue = [videoType]
					if (jobId) {
						indexName = videoTypeJobIdIndexName
						indexValue = [videoType, jobId]
					}

					const allSlices = await getByIndex(employerVideoSlicesStoreName, indexName, indexValue)
					return new Blob(
						allSlices.map(s => s.slice),
						{ type: allSlices[0].slice.type }
					)
				},
				async deleteAllVideoSlices(jobId, videoType) {
					let indexName = videoTypeIndexName
					let indexValue = [videoType]
					if (jobId) {
						indexName = videoTypeJobIdIndexName
						indexValue = [videoType, jobId]
					}
					return deleteAllByIndex(employerVideoSlicesStoreName, indexName, indexValue)
				}
			})
		)
	}
}

export default haystackDb
