import { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		maxHeight: 360,
		maxWidth: 640
	}
}))

const VideoPlayer = ({ videoSrc }) => {
	const [durationIsReady, setDurationIsReady] = useState(false)
	const videoControlsRef = useRef({})
	const videoControlsRefShadow = useRef({})

	useEffect(() => {
		if (!videoSrc) return

		const videoControl = videoControlsRef.current
		if (!videoControl || videoControl.src === videoSrc) return
		videoControl.src = videoSrc

		const shadowVideoControl = videoControlsRefShadow.current
		shadowVideoControl.src = videoSrc
		shadowVideoControl.onloadedmetadata = function () {
			// hacky fix duration for videos that were recorded as unseekable webm
			if (shadowVideoControl.duration === Infinity) {
				// set it to bigger than the actual duration
				shadowVideoControl.currentTime = Number.MAX_SAFE_INTEGER
				shadowVideoControl.ontimeupdate = function () {
					this.ontimeupdate = () => {}
					// setting it to just 0 doesn't always work, hence setting to something close to 0 first :-|
					shadowVideoControl.currentTime = 0.1
					shadowVideoControl.currentTime = videoControl.currentTime
					if (!videoControl.paused) {
						shadowVideoControl.play()
					}
					setDurationIsReady(true)
				}
			}
		}
	}, [videoSrc])

	const classes = useStyles()
	return durationIsReady ? (
		<video
			id="shadow-player"
			className={classes.root}
			controls
			ref={el => (videoControlsRefShadow.current = el)}
			onError={e => console.log('error event:', e.nativeEvent)}
		/>
	) : (
		<video
			id="player"
			className={classes.root}
			controls
			ref={el => (videoControlsRef.current = el)}
			onError={e => console.log('error event:', e.nativeEvent)}
		/>
	)
}

export default VideoPlayer
