import { Fragment, useState, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { DialogContent, DialogActions } from '@material-ui/core'
import { TextField, InputAdornment, Button, Typography } from '@material-ui/core'
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core'

import SearchIcon from 'mdi-material-ui/Magnify'
import CustomDialog from './CustomDialog'

const useStyles = makeStyles(({ spacing, palette }) => ({
	exampleList: { height: 500, overflow: 'auto', padding: 0, background: palette.background.paper },
	question: { paddingLeft: spacing(4) },
	noResultsFound: { textAlign: 'center', position: 'relative', paddingTop: spacing(4) }
}))

const ExampleQuestionPicker = ({ open, closeDialog, onQuestionSelect, exampleQuestions }) => {
	const [filteredQuestions, setFilteredQuestions] = useState({})

	useEffect(() => {
		setFilteredQuestions(exampleQuestions)
	}, [exampleQuestions])

	const handleCloseDialog = useCallback(() => {
		closeDialog()
		setFilteredQuestions(exampleQuestions)
	}, [closeDialog, exampleQuestions])

	const filterItems = useCallback(
		({ target }) => {
			if (target.value.trim() === '') {
				setFilteredQuestions(exampleQuestions)
			} else {
				const pattern = new RegExp(target.value, 'i')
				const filteredQuestions = {}

				Object.keys(exampleQuestions).forEach(category => {
					exampleQuestions[category].forEach(question => {
						if (pattern.test(question.text)) {
							filteredQuestions[category] = filteredQuestions[category] || []
							filteredQuestions[category].push(question)
						}
					})
				})

				setFilteredQuestions(filteredQuestions)
			}
		},
		[exampleQuestions]
	)

	const classes = useStyles()

	return (
		<CustomDialog
			open={open}
			maxWidth="md"
			fullWidth
			onClose={handleCloseDialog}
			title="Example Questions"
		>
			<DialogContent>
				<TextField
					label="Search"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)
					}}
					fullWidth
					onChange={filterItems}
				/>
				<List className={classes.exampleList} dense>
					{Object.keys(filteredQuestions).length === 0 ? (
						<Typography variant="h5" className={classes.noResultsFound}>
							No results found
						</Typography>
					) : (
						Object.keys(filteredQuestions).map(category => (
							<Fragment key={category}>
								<ListSubheader>{category}</ListSubheader>
								{filteredQuestions[category].map((question, index) => (
									<ListItem
										className={classes.question}
										key={index}
										button
										onClick={() => {
											handleCloseDialog()
											onQuestionSelect(question)
										}}
									>
										<ListItemText
											primary={question.text}
											secondary={
												question.answers &&
												question.answers.length !== 0 &&
												question.answers.join(', ')
											}
										/>
									</ListItem>
								))}
							</Fragment>
						))
					)}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDialog}>Close</Button>
			</DialogActions>
		</CustomDialog>
	)
}

export default ExampleQuestionPicker
