import React, { useContext } from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { VideoInterviewContext } from '../InterviewApp'

export default function Complete() {
	const dispatch = useContext(VideoInterviewContext)[1]
	return (
		<div>
			<Typography variant="h6" gutterBottom>
				Alright, all done with set up!
			</Typography>
			<Typography>
				Next we're going to start you off with a <b>practice question</b> to get you familiar with
				the interview flow. You can re-do a practice question as many times as you'd like.
			</Typography>
			<Button
				size="large"
				variant="contained"
				color="secondary"
				style={{ margin: '1em auto', display: 'block' }}
				onClick={() => dispatch({ type: 'startInterview' })}
			>
				Let's Go!
			</Button>
		</div>
	)
}
