import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, Button } from '@material-ui/core'

import PlusIcon from '@material-ui/icons/Add'

import routes from '../routes'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		margin: `${spacing(4)}px auto`,
		padding: spacing(4),
		textAlign: 'center',
		maxWidth: 900
	}
}))

export default function WelcomeMessage() {
	const classes = useStyles()
	return (
		<Paper className={classes.root}>
			<Typography variant="h4" gutterBottom>
				Welcome to Haystack!
			</Typography>
			<Typography variant="h6" gutterBottom>
				You’re now ready to create your first job. Your job has 3 parts to configure:
			</Typography>
			<Typography variant="body1" paragraph component="div">
				<ol style={{ textAlign: 'left' }}>
					<li>
						<strong>Job Application:</strong> Set up the form candidates fill out when applying
					</li>
					<li>
						<strong>Links:</strong> Create separate links for each place you post your job for
						tracking (LinkedIn's Job Board, Facebook, etc.).
					</li>
					<li>
						<strong>One-Way Video Interview:</strong> Choose questions that will pop up on
						candidates’ screens while Haystack records them answering. The interview invitation will
						only be sent to those candidates you select.
					</li>
				</ol>
			</Typography>
			<Button
				component={Link}
				to={routes.editJob.getLink('new')}
				size="large"
				variant="contained"
				color="primary"
				startIcon={<PlusIcon />}
			>
				Create Job
			</Button>
		</Paper>
	)
}
