import { useState, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Paper, TextField, IconButton, Tooltip, Button, CircularProgress } from '@material-ui/core'

import CopyIcon from '@material-ui/icons/FileCopy'
import DoneIcon from 'mdi-material-ui/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import OpenInNew from 'mdi-material-ui/OpenInNew'
import LinkedInLogoIcon from '@material-ui/icons/LinkedIn'
import FacebookLogoIcon from '@material-ui/icons/Facebook'

const getJobApplicationUrl = (jobToken, linkToken) =>
	`${window.location.origin}/job/${jobToken}/${linkToken}`

const socialNetworks = {
	linkedin: {
		logoIcon: LinkedInLogoIcon,
		getPostLinkApiUrl: linkToken =>
			`https://www.linkedin.com/shareArticle?mini=true&url=${linkToken}`
	},
	facebook: {
		logoIcon: FacebookLogoIcon,
		getPostLinkApiUrl: linkToken => `https://www.facebook.com/sharer/sharer.php?u=${linkToken}`
	}
}

const useStyles = makeStyles(({ spacing }) => ({
	logoIcon: { marginRight: spacing(2) },
	linkContainer: {
		padding: spacing(2),
		margin: spacing(1, 0),
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	linkEditorWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center'
	},
	linkName: { flex: 1 },
	postButton: { marginTop: spacing(1) }
}))

export default function JobLink({
	jobToken,
	id,
	label,
	token,
	category,
	network,
	error,
	createLink,
	updateLink,
	cancel,
	showLoader
}) {
	const [name, setName] = useState(label)

	useEffect(() => {
		setName(label)
	}, [label])

	const onNameChange = useCallback(e => setName(e.target.value), [])
	const [copyTooltipTitle, setCopyTooltipTitle] = useState('Copy Link')

	const onCreateClick = useCallback(
		e => createLink(category, name, network),
		[createLink, category, name, network]
	)

	const onCancelClick = useCallback(() => cancel(category), [cancel, category])

	const onBlur = useCallback(
		() => id && label !== name && updateLink(category, id, name),
		[updateLink, category, id, name, label]
	)

	const postToSocialNetwork = useCallback(
		({ currentTarget }) =>
			window.open(
				socialNetworks[currentTarget.name].getPostLinkApiUrl(
					getJobApplicationUrl(jobToken, currentTarget.id)
				)
			),
		[jobToken]
	)

	const copyJobApplicationLink = useCallback(() => {
		navigator.clipboard.writeText(getJobApplicationUrl(jobToken, token))
		setCopyTooltipTitle('Copied!')
	}, [jobToken, token])

	const resetTooltipTitle = useCallback(() => {
		setTimeout(() => {
			setCopyTooltipTitle('Copy Link')
		}, 300)
	}, [])

	const LogoIcon = network && socialNetworks[network].logoIcon
	const classes = useStyles()
	return (
		<Paper variant="outlined" key={id} className={classes.linkContainer}>
			<div className={classes.linkEditorWrapper}>
				{LogoIcon && <LogoIcon className={classes.logoIcon} />}
				<TextField
					label="Name"
					placeholder="Name this link for tracking"
					value={name}
					error={!!error}
					helperText={
						error ||
						(token && getJobApplicationUrl(jobToken, token)) ||
						(label && 'Generating link...')
					}
					className={classes.linkName}
					autoFocus={!label}
					onChange={onNameChange}
					onBlur={onBlur}
				/>
				{token ? (
					<Tooltip title={copyTooltipTitle} onClose={resetTooltipTitle}>
						<IconButton
							color="primary"
							className={classes.linkActionButton}
							disabled={!token}
							onClick={copyJobApplicationLink}
						>
							<CopyIcon />
						</IconButton>
					</Tooltip>
				) : (
					<>
						<Tooltip title="Create">
							<IconButton
								color="primary"
								className={classes.linkActionButton}
								disabled={!name || showLoader}
								onClick={onCreateClick}
							>
								{showLoader ? <CircularProgress size={20} /> : <DoneIcon />}
							</IconButton>
						</Tooltip>
						<IconButton
							className={classes.linkActionButton}
							disabled={showLoader}
							onClick={onCancelClick}
						>
							<CancelIcon />
						</IconButton>
					</>
				)}
			</div>
			{network && token && (
				<Button
					variant="outlined"
					size="small"
					startIcon={<OpenInNew />}
					id={token}
					name={network}
					onClick={postToSocialNetwork}
					className={classes.postButton}
				>
					Post
				</Button>
			)}
		</Paper>
	)
}
