import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { jobVideoTypes, companyVideoTypes } from '../../../../../../common/outboundVideoTypes'

const suggestions = {
	[jobVideoTypes.jobPosition.name]: [
		'"Welcome! We\'re excited..."',
		'"My name is __, my role is __"',
		<>
			<span style={{ fontStyle: 'normal' }}>(If applicable)</span>{' '}
			<span>"I'll be the person who will review your interview, as opposed to an AI system"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Brief description:</span>{' '}
			<span>"Here’s a little more about the role..."</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Responsibilities:</span>{' '}
			<span>"Some of the primary responsibilities include…"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Role importance:</span>{' '}
			<span>"This role is essential to the success of our company because…"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Job satisfaction:</span>{' '}
			<span>"This is a dream job for anyone who enjoys…because…"</span>
		</>
	],
	[jobVideoTypes.workDay.name]: [
		<>
			<span style={{ fontStyle: 'normal' }}>Narrative:</span>{' '}
			<span>
				"A typical day will start out at 8am with…Then move on to…You’ll wrap up your day…"
			</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Common actions/activities:</span>{' '}
			<span>"Most of your time in the mornings will be spent…"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Interactions with colleagues and boss:</span>{' '}
			<span>
				"You’ll work closely with your team on projects…You’ll meet with me daily to discuss…"
			</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Frequent challenges:</span>{' '}
			<span>"Your inbox will fill up fast if you don’t stay on top of it so you’ll need to…"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Special days/events:</span>{' '}
			<span>"Every Friday at 2pm we have a all hands meeting to discuss…"</span>
		</>
	],
	[jobVideoTypes.meetYourBoss.name]: [
		<>
			<span style={{ fontStyle: 'normal' }}>Intro:</span>{' '}
			<span>"Hi I’m (name), I’m the (title) here at (company name)"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Responsibilities:</span>{' '}
			<span>"My role is to… and I’m responsible for…"</span>
		</>,
		'"What I like most about working here is…"',
		<>
			<span style={{ fontStyle: 'normal' }}>Work history/education:</span>{' '}
			<span>"I’ve been with (company name) for 5 years… Previously, I used to work as…"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Personal interests:</span>{' '}
			<span>"A little about me… I’m a chess fanatic… and I love…"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Wrap up:</span>{' '}
			<span>"I look forward to getting to know you a little better as well. Thank you."</span>
		</>
	],
	[companyVideoTypes.companyInfo.name]: [
		<>
			<span style={{ fontStyle: 'normal' }}>Intro:</span>{' '}
			<b>"Hi, I’m (name), from (company name)"</b>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Company's product/service:</span>{' '}
			<b>"Here at (company) we provide..."</b>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Company Structure:</span>{' '}
			<b>
				"We are located in (company location), you would be working primarily at (job location)"
			</b>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Company History:</span>{' '}
			<b>"(Founder) started (company) in (year) in order to..."</b>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Company Goals:</span>{' '}
			<b>"Our goal for the next year is to..."</b>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Unique Attributes:</span>{' '}
			<b>"Unlike our competitors, we..."</b>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Expectations:</span>{' '}
			<b>"From day one at (company), you can expect..."</b>
		</>
	],
	[companyVideoTypes.workWithUs.name]: [
		<>
			<span style={{ fontStyle: 'normal' }}>Intro:</span>{' '}
			<span>"Hi, I'm (name), the (title) at (company name)"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Connect:</span>{' '}
			<span>"I remember searching for jobs and how stressful..."</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Difference Maker:</span>{' '}
			<span>"Here at (company) I think you'll find we're better at..."</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Cultural Advantages:</span>{' '}
			<span>"Another thing makes (company) a great place to work..."</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Unique Benefit:</span>{' '}
			<span>"Something I've really appreciated over the years is our great (benefit plan)"</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Intangibles:</span>{' '}
			<span>"After you've been at (company) a while, what you appreciate the most is..."</span>
		</>,
		<>
			<span style={{ fontStyle: 'normal' }}>Summary:</span>{' '}
			<span>"I hope that answers some of you questions, and thank you for considering..."</span>
		</>
	]
}

const useStyles = makeStyles(({ spacing }) => ({
	suggestions: { fontStyle: 'italic' }
}))

function Outline({ videoType }) {
	const classes = useStyles()
	return (
		<Alert severity="info">
			<AlertTitle>Suggestion</AlertTitle>
			<Typography variant="body2">
				Write a brief outline of what you’d like to say in advance and place it near your camera for
				reference. Include information that would get candidates excited about the role. Sample
				outline:
			</Typography>
			<ol>
				{suggestions[videoType]?.map((suggestion, index) => (
					<li key={index}>
						<Typography variant="body2" className={classes.suggestions}>
							{suggestion}
						</Typography>
					</li>
				))}
			</ol>
		</Alert>
	)
}

export default Outline
