import { Link } from 'react-router-dom'

import { Box, Button, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { useMutation } from '@tanstack/react-query'

import StatefulButton from '../../common/StatefullButton'
import routes from '../routes'
import { useAuth } from './AuthProvider'

function EmailConfirmationError({ errorMessage }) {
	const { currentUser, sendEmailVerification } = useAuth()

	const { mutate, isLoading, isSuccess, error } = useMutation({ mutationFn: sendEmailVerification })

	return (
		<>
			<Alert severity="error">
				<AlertTitle>Error</AlertTitle>
				{errorMessage}
			</Alert>
			{currentUser ? (
				<Box sx={{ textAlign: 'center', pt: 3 }}>
					<StatefulButton variant="outlined" isLoading={isLoading} onClick={mutate}>
						Re-Send the Link
					</StatefulButton>
					<Box sx={{ pt: 2 }}>
						{isSuccess && (
							<Alert severity="success">
								The email has been sent to <strong>{currentUser.email} test@test.com</strong>.
							</Alert>
						)}
						{error && <Alert severity="error">{error.message} Test error message</Alert>}
					</Box>
				</Box>
			) : (
				<Box sx={{ textAlign: 'center', pt: 3 }}>
					<Typography paragraph>Try to login again and re-send the link</Typography>
					<Button variant="contained" color="primary" component={Link} to={routes.login.path}>
						Log In
					</Button>
				</Box>
			)}
		</>
	)
}

export default EmailConfirmationError
