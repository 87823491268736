import { useState, useContext } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Divider, Grid, Hidden, Typography, useMediaQuery } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

import PublishedVideoPlayer from '../common/employer-video/PublishedVideoPlayer'
import EmbeddedVideoIframe from '../common/employer-video/EmbeddedVideoPlayer'
import { companyVideoTypes } from '../common/outboundVideoTypes'
import videoHosts from '../common/videoHosts'
import VideoPlayer from '../employer/jobs/job/interview-setup/video-introduction/employer-recording-dashboard/VideoPlayer'
import JobApplicationHeader from './JobApplicationHeader'
import JobDescription from './JobDescription'
import JobApplicationForm from './JobApplicationForm'
import JobApplicationSubmissionStatus from './JobApplicationSubmissionStatus'
import JobApplicationContext from './jobApplicationContext'

const useStyles = makeStyles(({ spacing, palette }) => ({
	root: {
		backgroundColor: palette.background.paper,
		minHeight: '100vh',
		overflow: 'hidden'
	},
	buttonContainer: { marginTop: spacing(3), '& > :nth-child(n+1)': { marginRight: spacing(2) } },
	applicationForm: { marginTop: spacing(2) },
	jobNotAvailableMessage: { marginTop: spacing(5), padding: spacing(3) },
	toggleGroup: { width: '100%' }
}))

export default function JobApplication({ injectedState }) {
	const classes = useStyles()
	const [selectedVideo, setSelectedVideo] = useState(0)

	const { jobInfo } = useContext(JobApplicationContext)
	const companyVideos = jobInfo.employerVideos

	const { breakpoints, spacing } = useTheme()
	const isMobile = useMediaQuery(breakpoints.down('md'))

	const handleTabChange = (event, index) => {
		if (index !== null) setSelectedVideo(index)
	}

	const renderVideo = video => {
		if (!video) return

		return video.videoHost === videoHosts.youtube ? (
			<EmbeddedVideoIframe sourceVideo={video.srcUrl} />
		) : video.videoHost === videoHosts.bunny ? (
			<PublishedVideoPlayer srcUrl={video.srcUrl} />
		) : (
			<VideoPlayer videoSrc={video.srcUrl} />
		)
	}

	return (
		<div className={classes.root}>
			<JobApplicationHeader />
			{injectedState.jobNotAvailableMessage ? (
				<Typography variant="h5" align="center" className={classes.jobNotAvailableMessage}>
					{injectedState.jobNotAvailableMessage}
				</Typography>
			) : (
				<Grid container spacing={2} justifyContent="center">
					<Grid
						item
						xs={12}
						lg={5}
						xl={!injectedState.isPreview && 4}
						style={{ paddingLeft: !isMobile && spacing(5) }}
					>
						{companyVideos && companyVideos.length > 0 && (
							<>
								<Box sx={{ bgcolor: 'black' }}>{renderVideo(companyVideos[selectedVideo])}</Box>
								<div>
									<ToggleButtonGroup
										value={selectedVideo}
										onChange={handleTabChange}
										defaultValue={0}
										className={classes.toggleGroup}
										exclusive
									>
										{companyVideos.map((video, index) => (
											<ToggleButton key={index} value={index} className={classes.toggleGroup}>
												<span>
													{index + 1}. {companyVideoTypes[video.videoType].title}
												</span>
											</ToggleButton>
										))}
									</ToggleButtonGroup>
								</div>
							</>
						)}
						<JobDescription expanded={injectedState.jobDescriptionExpanded} />
					</Grid>
					<Hidden lgUp>
						<Grid item xs={12}>
							<Divider variant="middle" />
						</Grid>
					</Hidden>
					<Grid item md={12} lg={7} xl={!injectedState.isPreview && 5}>
						<JobApplicationForm injectedState={injectedState} />

						{injectedState.submissionStatus &&
							injectedState.submissionStatus.type === 'success' && (
								<JobApplicationSubmissionStatus
									showEmailInfo={injectedState.submissionStatus.showEmailInfo}
								/>
							)}
					</Grid>
				</Grid>
			)}
		</div>
	)
}
