import { makeStyles } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(({ spacing }) => ({
	list: {
		paddingLeft: 'unset'
	}
}))

const Tips = () => {
	const classes = useStyles()
	return (
		<Alert severity="info">
			<AlertTitle>Tips</AlertTitle>
			<ul className={classes.list}>
				<li>Look directly at your camera while speaking.</li>
				<li>Keep the recording short— preferably 1 to 3 minutes.</li>
				<li>
					Make sure you have good lighting to ensure the candidates can clearly see your face.
				</li>
				<li>Make sure the background of your video is not cluttered.</li>
				<li>
					Bring the appropriate energy level for the recording (enthusiastic, positive, etc.).
				</li>
				<li>Eliminate all background noise. You want the applicants to be able to hear you.</li>
			</ul>
		</Alert>
	)
}

export default Tips
