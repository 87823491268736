import { useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, ImageList, ImageListItem } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'

const daysArray = Array.from({ length: 30 }, (value, index) => index + 1)

const nrOfColumns = 10
const rowHeight = 50

const useStyles = makeStyles(({ spacing, palette }) => ({
	root: { textAlign: 'center', paddingBottom: spacing(2) },
	daysGrid: { width: rowHeight * nrOfColumns, display: 'inline-flex' },
	daysCell: {
		width: '100%',
		height: '100%',
		borderRadius: 0,
		'&.Mui-selected': {
			color: palette.secondary.contrastText,
			backgroundColor: palette.secondary.light
		}
	}
}))

export default function InterviewDeadline({ daysToComplete = 3, updateDaysToComplete }) {
	const onDeadlineChange = useCallback(
		(e, value) => updateDaysToComplete(value),
		[updateDaysToComplete]
	)

	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography variant="h6" paragraph>
				How many days do applicants have to complete the interview from the time you send them an
				invitation?
			</Typography>

			<ImageList rowHeight={rowHeight} cols={nrOfColumns} gap={0} className={classes.daysGrid}>
				{daysArray.map(d => (
					<ImageListItem key={d}>
						<ToggleButton
							className={classes.daysCell}
							value={d}
							selected={d === daysToComplete}
							onClick={onDeadlineChange}
						>
							{d}
						</ToggleButton>
					</ImageListItem>
				))}
			</ImageList>
		</div>
	)
}
