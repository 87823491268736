import { get } from '../employer/restService'
import clientSettings from '../clientSettings'

let storageRootSasUrl

// get new root URL
export const getCloudStorageRootUri = async (refreshUrl, companyId = -1) => {
	if (!storageRootSasUrl || refreshUrl) {
		storageRootSasUrl = await get(`/api/companies/${companyId}/storage-root-url`)
	}
	return storageRootSasUrl
}

const storageBaseUrl = clientSettings.storage.publicBaseUrl

export const getCompanyLogoSrc = (companyId, fileName) => {
	if (!fileName) return null

	// Todo: this logic (for container name) duplicates similar logic on the server, maybe we should just store whole URLs in the database
	const containerName = companyId.toString().padStart(10, '0')

	return `${storageBaseUrl}/${containerName}/assets/${fileName}`
}

export const getDocumentShortLivedAccessUrl = async candidateDocId =>
	get(`/api/documents/${candidateDocId}/access-url`)
