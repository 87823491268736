import { useCallback, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'

import useAsyncActions from '../../../../../common/hooks/useAsyncActions'
import {
	publishVideo,
	publishNewVideo,
	saveEmbeddedVideoUrl,
	toggleShowToCandidates
} from '../../../../employer-common/employerVideoService'
import EmployerVideoCard from './EmployerVideoCard'
import EmployerVideoSetupDialog from './employer-recording-dashboard/EmployerVideoSetupDialog'
import ShowToCandidatesToggle from './employer-recording-dashboard/ShowToCandidatesToggle'

const getInitialState = state => {
	return {
		...state,
		outboundVideoDialogContext: { show: false }
	}
}

const createActions = (dispatch, getState) => ({
	syncState: (companyId, jobId, onVideoAdd, onVideoChange) =>
		dispatch(state => ({
			...state,
			companyId,
			jobId,
			onVideoAdd,
			onVideoChange
		})),
	openVideoRecordingDialog: ({ name, title, description }) =>
		dispatch(state => ({
			...state,
			outboundVideoDialogContext: {
				...state.outboundVideoDialogContext,
				show: true,
				videoType: name,
				title,
				description
			}
		})),
	closeVideoRecordingDialog: () =>
		dispatch(state => ({
			...state,
			outboundVideoDialogContext: { ...state.outboundVideoDialogContext, show: false }
		})),
	addEmployerVideo: video => getState().onVideoAdd(video),
	updateEmployerVideo: video => getState().onVideoChange(video),
	saveEmbeddedVideoUrl: async video => {
		const { companyId, jobId } = getState()
		const employerVideo = await saveEmbeddedVideoUrl(companyId, jobId, video)
		employerVideo.isDirty = false
		return employerVideo
	},
	publishVideo: async ({ id, videoType }, blob) => {
		const { companyId, jobId, onVideoChange } = getState()

		const processingVideo = id
			? await publishVideo(id, blob)
			: await publishNewVideo({ companyId, jobId, videoType }, blob)

		processingVideo.isDirty = false
		onVideoChange(processingVideo)
	},
	toggleShowToCandidates: async (videoId, displayToCandidates) => {
		const updatedVideo = await toggleShowToCandidates(videoId, displayToCandidates)
		getState().onVideoChange(updatedVideo)
	}
})

const useStyles = makeStyles(({ spacing }) => ({
	videoUploadOptions: { marginTop: spacing(2) }
}))

const EmployerVideos = ({
	companyId,
	jobId,
	employerVideos,
	description,
	videoTypes,
	onVideoAdd,
	onVideoChange
}) => {
	const [state, actions] = useAsyncActions(
		createActions,
		{ companyId, jobId, onVideoAdd, onVideoChange },
		getInitialState
	)

	useEffect(() => {
		actions.syncState(companyId, jobId, onVideoAdd, onVideoChange)
	}, [actions, companyId, jobId, onVideoAdd, onVideoChange])

	const openVideoRecordingDashboard = useCallback(
		e => actions.openVideoRecordingDialog(videoTypes[e.currentTarget.name]),
		[actions, videoTypes]
	)

	const classes = useStyles()
	return (
		<Box sx={{ pl: 2, pr: 2 }}>
			{description}
			<Grid container justifyContent="space-around" className={classes.videoUploadOptions}>
				{Object.values(videoTypes).map(videoType => {
					const video = employerVideos?.find(v => v.videoType === videoType.name) || {
						displayToCandidates: false
					}

					return (
						<Grid key={videoType.name} item>
							<EmployerVideoCard
								title={videoType.title}
								description={videoType.description}
								videoType={videoType.name}
								thumbnailUrl={video.thumbnailUrl}
								srcUrl={video.srcUrl}
								onClick={openVideoRecordingDashboard}
							/>
							<Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
								<ShowToCandidatesToggle
									value={video.displayToCandidates}
									videoId={video.id}
									onToggle={actions.toggleShowToCandidates}
								/>
							</Box>
						</Grid>
					)
				})}
			</Grid>
			<EmployerVideoSetupDialog
				jobId={jobId}
				{...state.outboundVideoDialogContext}
				video={employerVideos?.find(
					v => v.videoType === state.outboundVideoDialogContext.videoType
				)}
				actions={actions}
			/>
		</Box>
	)
}

export default EmployerVideos
