import { useState, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Switch, CircularProgress, Tooltip } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

import operationStates from '../../../common/operationStates'
import { toggleJobStatus } from '../jobService'

const useStyles = makeStyles(theme => ({
	progress: {
		display: 'inline-block',
		verticalAlign: 'text-bottom',
		lineHeight: 0,
		visibility: operationStatus =>
			operationStatus === operationStates.inProgress || operationStatus === operationStates.error
				? 'visible'
				: 'hidden'
	}
}))

export default function OpenCloseJobSwitch({ jobId, status, onJobStatusChange }) {
	const [operationStatus, setOperationState] = useState(operationStates.initial)

	const changeStatus = useCallback(async () => {
		setOperationState(operationStates.inProgress)
		try {
			await toggleJobStatus(jobId)
		} catch (e) {
			setOperationState(operationStates.error)
			return
		}
		setOperationState(operationStates.success)
		onJobStatusChange(jobId)
	}, [jobId, onJobStatusChange])

	const classes = useStyles(operationStatus)
	return (
		<>
			<Switch
				size="small"
				checked={status === 'open'}
				onChange={changeStatus}
				disabled={operationStatus === operationStates.inProgress}
			/>
			<div className={classes.progress}>
				{operationStatus === operationStates.inProgress ? (
					<CircularProgress size={15} />
				) : (
					<Tooltip title="Error changing job status">
						<ErrorIcon fontSize="inherit" color="error" />
					</Tooltip>
				)}
			</div>
		</>
	)
}
