import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(2, 0),
		display: 'flex',
		justifyContent: 'space-around'
	}
}))

export default function Controls({
	handleNextQuestion,
	handleStartInterview,
	isPractice,
	enabled,
	buttonCaption
}) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			{isPractice && (
				<Button
					size="large"
					className={classes.button}
					variant="outlined"
					onClick={handleNextQuestion}
				>
					Practice Again
				</Button>
			)}
			<Button
				variant="contained"
				color="primary"
				size="large"
				disabled={!enabled}
				className={classes.button}
				onClick={isPractice ? handleStartInterview : handleNextQuestion}
			>
				{isPractice ? 'Start Interview' : buttonCaption}
			</Button>
		</div>
	)
}

Controls.propTypes = {
	handleNextQuestion: PropTypes.func.isRequired,
	handleStartInterview: PropTypes.func.isRequired,
	isPractice: PropTypes.bool.isRequired,
	buttonCaption: PropTypes.string.isRequired,
	enabled: PropTypes.bool.isRequired
}
