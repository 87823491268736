import { useState, useCallback, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Paper, TextField, Grid, Tooltip, MenuItem, Box } from '@material-ui/core'

import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import PreviewTimersIcon from '@material-ui/icons/Visibility'
import StopPreviewIcon from '@material-ui/icons/VisibilityOff'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveQuestionIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Clear'

import useFormWithZod from '../../../../../common/hooks/useFormWithZod'
import {
	createQuestion,
	updateQuestion as saveQuestionChanges,
	deleteQuestion
} from '../interviewSetupService'
import VideoQuestionEditorAction from './VideoQuestionEditorAction'
import TimerPreview from './TimerPreview'
import SimpleSavingIndicator from '../../applicants/SimpleSavingIndicator'

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		padding: spacing(2),
		marginTop: spacing(2),
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		'&:not(.dragged)': { transition: 'transform 200ms' },
		'&.dragged .handle': { cursor: 'grabbing !important', pointerEvents: 'auto !important' }
	},
	question: { flex: 1 }
}))

const readDurations = [
	{ text: '10 seconds', value: 10 },
	{ text: '20 seconds', value: 20 },
	{ text: '30 seconds', value: 30 }
]
const answerDurations = [
	{ text: '30 sec', value: 30 },
	{ text: '1 min', value: 60 },
	{ text: '1:30 min', value: 90 },
	{ text: '2 min', value: 120 },
	{ text: '2:30 min', value: 150 },
	{ text: '3 min', value: 180 }
]

export const defaultTimings = {
	read: readDurations[1].value,
	answer: answerDurations[answerDurations.length - 1].value
}

const validationSchema = z.object({
	text: z.string().nonempty('Question text is required'),
	// yes, first 2 literals are the same, but it's the most terse way to come up with the validation schema allowing to check a value against a set of valid values i could find
	readSeconds: readDurations.reduce(
		(acc, curr) => acc.or(z.literal(curr.value)),
		z.literal(readDurations[0].value)
	),
	answerSeconds: answerDurations.reduce(
		(acc, curr) => acc.or(z.literal(curr.value)),
		z.literal(answerDurations[0].value)
	)
})

function QuestionSetup({
	jobId,
	clientId,
	id,
	text,
	readSeconds = defaultTimings.read,
	answerSeconds = defaultTimings.answer,
	order,
	enabled,
	isExampleQuestion,
	deleteQuestion: removeQuestion,
	updateQuestion,
	onTextChange
}) {
	const [timers, setTimers] = useState({
		readTimer: { totalSeconds: false },
		answerTimer: { totalSeconds: false }
	})

	const { isLoading, isSuccess, isError, mutate } = useMutation({
		mutationFn: values => {
			console.log('submitting this:', values)
			const updatedQuestion = { id, order, ...values }
			return id ? saveQuestionChanges(updatedQuestion) : createQuestion(jobId, updatedQuestion)
		},
		onSuccess: question => updateQuestion(clientId, { ...question, isExampleQuestion: false })
	})

	const {
		formState: { errors, isValid },
		register,
		watch,
		handleSubmit
	} = useFormWithZod(validationSchema)

	useEffect(() => {
		if (!isExampleQuestion) return

		handleSubmit(mutate)()
	}, [isExampleQuestion, handleSubmit, mutate])

	useEffect(onTextChange, [watch('text'), onTextChange])

	const onReadTimerElapsed = useCallback(() => {
		setTimers(timers => ({ ...timers, answerTimer: { ...timers.answerTimer, start: true } }))
	}, [])

	const onAnswerTimerElapsed = useCallback(() => {
		setTimers({
			readTimer: { totalSeconds: false, start: false },
			answerTimer: { totalSeconds: false, start: false }
		})
	}, [])

	const initTimers = useCallback(
		() =>
			setTimers({
				readTimer: { totalSeconds: readSeconds, start: true },
				answerTimer: { totalSeconds: answerSeconds }
			}),
		[readSeconds, answerSeconds]
	)

	const stopTimers = useCallback(
		() =>
			setTimers({
				readTimer: { totalSeconds: false },
				answerTimer: { totalSeconds: false }
			}),
		[]
	)

	const classes = useStyles()
	return (
		<Paper variant="outlined" className={classes.root}>
			<Box sx={{ position: 'absolute', top: 2, left: '47%' }}>
				<SimpleSavingIndicator isLoading={isLoading} isSuccess={isSuccess} isError={isError} />
			</Box>
			<Grid
				component="form"
				container
				spacing={2}
				alignItems="center"
				onSubmit={handleSubmit(mutate)}
			>
				<Grid item className={classes.question}>
					<TextField
						label="Question"
						placeholder="Question"
						{...register('text', { onBlur: id ? handleSubmit(mutate) : undefined })}
						defaultValue={text}
						error={!!errors.text}
						helperText={errors.text?.message}
						disabled={!enabled || isLoading}
						multiline
						fullWidth
					/>
				</Grid>
				<Grid item xs={3} sm={2}>
					{timers.readTimer.totalSeconds ? (
						<TimerPreview preview={timers.readTimer} onTimerElapsed={onReadTimerElapsed} />
					) : (
						<TextField
							label={
								<Tooltip
									title="The time a candidate has to read and think about the question before the recording begins"
									placement="top"
								>
									<span>Read Timer</span>
								</Tooltip>
							}
							{...register('readSeconds', {
								valueAsNumber: true,
								onChange: id ? handleSubmit(mutate) : undefined
							})}
							select
							fullWidth
							defaultValue={readSeconds}
							disabled={!enabled || isLoading}
						>
							{readDurations.map(({ text, value }) => (
								<MenuItem key={value} value={value}>
									{text}
								</MenuItem>
							))}
						</TextField>
					)}
				</Grid>
				<Grid item xs={3} sm={2}>
					{timers.answerTimer.totalSeconds ? (
						<TimerPreview preview={timers.answerTimer} onTimerElapsed={onAnswerTimerElapsed} />
					) : (
						<TextField
							label={
								<Tooltip
									title="The amount of time a candidate has to record their answer"
									placement="top"
								>
									<span>Answer Timer</span>
								</Tooltip>
							}
							{...register('answerSeconds', {
								valueAsNumber: true,
								onChange: id ? handleSubmit(mutate) : undefined
							})}
							select
							fullWidth
							defaultValue={answerSeconds}
							disabled={!enabled || isLoading}
						>
							{answerDurations.map(({ text, value }) => (
								<MenuItem key={value} value={value}>
									{text}
								</MenuItem>
							))}
						</TextField>
					)}
				</Grid>
				{id ? (
					<Grid item>
						{timers.readTimer.totalSeconds || timers.answerTimer.totalSeconds ? (
							<VideoQuestionEditorAction
								tooltip="Stop timers"
								icon={<StopPreviewIcon />}
								onClick={stopTimers}
							/>
						) : (
							<VideoQuestionEditorAction
								tooltip="Preview timers"
								icon={<PreviewTimersIcon />}
								disabled={isLoading}
								onClick={initTimers}
							/>
						)}
						<VideoQuestionEditorAction
							tooltip="Delete question"
							icon={<DeleteIcon />}
							disabled={!enabled || isLoading}
							onClick={() => {
								removeQuestion(clientId)
								deleteQuestion(id)
							}}
						/>
					</Grid>
				) : (
					<Grid item>
						<VideoQuestionEditorAction
							tooltip="Save new question"
							icon={<SaveQuestionIcon />}
							disabled={!isValid || isLoading}
							type="submit"
						/>
						<VideoQuestionEditorAction
							tooltip="Cancel"
							icon={<CancelIcon />}
							disabled={isLoading}
							onClick={() => removeQuestion(clientId)}
						/>
					</Grid>
				)}
			</Grid>
		</Paper>
	)
}

export default QuestionSetup
