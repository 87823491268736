import { forwardRef } from 'react'
import { Link, matchPath, NavLink, useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Button, useMediaQuery } from '@material-ui/core'

import logo from '../assets/logo.svg'
import ProfileMenu from './ProfileMenu'

import routes from './routes'
import { useAuth } from './auth/AuthProvider'

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
	menuBar: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	navMenu: { display: 'flex' },
	logo: {
		margin: `0 ${spacing(4)}px`,
		height: 50,
		width: 173,
		[breakpoints.down('xs')]: {
			margin: `0 ${spacing(1.5)}px`
		}
	},
	active: {
		color: palette.secondary.main
	}
}))

const NavMenuLink = forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />)

function Header({ isRegistrationComplete }) {
	const { currentUser } = useAuth()
	const { pathname } = useLocation()
	const isOnLoginPage = !!matchPath(pathname, routes.login.path)
	const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'))

	const classes = useStyles()
	return (
		<AppBar position="static" color="transparent">
			<Toolbar className={classes.menuBar}>
				<div className={classes.navMenu}>
					<Link to={routes.jobs.getLink()}>
						<img alt="Haystack logo" src={logo} className={classes.logo} />
					</Link>
					{Object.values(routes).map(
						({ navLinkText, isPublic, isPublicOnly, path, isRestricted }) => {
							return (
								navLinkText &&
								(isPublic || (currentUser && isRegistrationComplete && !isPublicOnly)) && (
									<Button
										key={path}
										color="inherit"
										size="large"
										component={NavMenuLink}
										to={path}
										activeClassName={classes.active}
									>
										{navLinkText}
									</Button>
								)
							)
						}
					)}
				</div>
				{currentUser ? (
					<ProfileMenu />
				) : (
					!isOnLoginPage &&
					!isXs && (
						<Button variant="contained" color="primary" component={Link} to={routes.login.path}>
							Sign In
						</Button>
					)
				)}
			</Toolbar>
		</AppBar>
	)
}

export default Header
